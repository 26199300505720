<akit-shortcuts (save)="commit()" (run)="run()"></akit-shortcuts>
<div *ngIf="exercise" class="exercise-filter-editor cms-navigator">

    <div *ngIf="akitVersionMismatch" class="row row-spacing">
        <div class="col-xs-12">
            <div class="alert alert-warning">
                This exercise cannot be saved using the current version. Please switch to "{{courseAkitVersion}}" to save the exercise.
            </div>
        </div>
    </div>

    <form [formGroup]="form">
        <div class="panel-wrapper">
            <ul class="nav nav-tabs">
                <li class="nav-item" [ngClass]="{ 'active':activeTab==='general'}">
                    <a class="nav-link" (click)="setActiveTab('general')" href="#general" data-toggle="tab">General</a>
                </li>
                <li class="nav-item" [ngClass]="{ 'active':activeTab==='topics'}">
                    <a class="nav-link" (click)="setActiveTab('topics')" href="#topics" data-toggle="tab">Topics</a>
                </li>
                <li class="nav-item" *ngIf="exercise.state != 'DRAFT'" [ngClass]="{ 'active':activeTab==='version'}"
                    [attr.no-content]="!versionInfo">
                    <a class="nav-link" (click)="setActiveTab('version')" href="#version" data-toggle="tab">Version</a>
                </li>
                <li class="nav-item" [attr.no-content]="isEmptyMetadata()"
                    [ngClass]="{ 'active':activeTab==='metadata'}">
                    <a class="nav-link" (click)="setActiveTab('metadata')" href="#metadata" data-toggle="tab">Metadata
                        <span *ngIf="!metadataValid" class="icon-invalid fa fa-exclamation-circle"></span></a>
                </li>
                <!--
                <li class="nav-item">
                    <a class="nav-link" href="#settings" data-toggle="tab">Settings</a>
                </li>
                -->
            </ul>


            <div class="tab-content clearfix row-spacing">
                <div class="tab-pane" [ngClass]="{ 'active':activeTab==='general'}" id="general">
                    <div class="row row-spacing">
                        <div class="col-sm-2">
                            <label>Course</label>
                        </div>
                        <div class="col-sm-10">
                            <span class="static-value-field">{{course?.name}}</span>
                        </div>
                    </div>
                    <div class="row row-spacing">
                        <div class="col-sm-2">
                            <label>Subject</label>
                        </div>
                        <div class="col-sm-10">
                            <breadcrumb class="clickable" [folder]="subject" (parentChange)='setSubject($event)'
                                [showChilds]="true"></breadcrumb>
                        </div>
                    </div>
                    <div class="row row-spacing">
                        <div class="col-sm-2">
                            <label>Audience</label>
                        </div>
                        <div class="col-sm-4">
                            <select formControlName='audience' class='form-control'>
                                <option *ngFor="let audience of audiences" value="{{audience.baseAudienceId}}">
                                    {{audience.name}}</option>
                            </select>
                        </div>
                        <div class="col-sm-1">
                            <label>ID</label>
                        </div>
                        <div class="col-sm-5">
                            <span>{{exercise.id}}</span>
                        </div>
                    </div>
                    <div class="row row-spacing">
                        <div class="col-sm-2">
                            <label translate>Name</label>
                        </div>
                        <div class="col-sm-9">
                            <input formControlName='name' class='form-control'>
                        </div>
                        <div class="col-sm-1 col-lg-1">
                            <span class="fa fa-expand span-button" [hidden]="generalExtend"
                                (click)="generalExtend=!generalExtend"></span>
                            <span class="fa fa-compress span-button" [hidden]="!generalExtend"
                                (click)="generalExtend=!generalExtend"></span>
                        </div>
                    </div>
                    <div *ngIf="generalExtend">
                        <div class="row row-spacing">
                            <div class="col-sm-2">
                                <label translate>Title</label>
                            </div>
                            <div class="col-sm-9">
                                <input formControlName='title' class='form-control'>
                            </div>
                        </div>
                        <div class='row row-spacing'>
                            <div class="col-sm-2">
                                <label>Description</label>
                            </div>
                            <div class="col-sm-9">
                                <textarea formControlName='description' class='form-control'></textarea>
                            </div>
                        </div>
                        <div class='row row-spacing'>
                            <div class="col-sm-2">
                                <label>Comment</label>
                            </div>
                            <div class="col-sm-9">
                                <textarea formControlName='comment' class='form-control'></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane" [ngClass]="{ 'active':activeTab==='topics'}" id="topics" #topics>
                    <div *ngFor="let topicSpec of topicData; let i = index; trackBy: indexTrackId;"
                        class="exerciseFilter-goal-wrapper">
                        <div class="row">
                            <div class="col-sm-2">
                                <label>
                                    <span>Topic</span>
                                    <span>{{i+1}}</span>
                                </label>
                            </div>
                            <div class="col-sm-8">
                                <breadcrumb class="clickable" [folder]="topicSpec.topic" [showChilds]="true"
                                    (parentChange)="updateTopic($event,i, true)"></breadcrumb>
                            </div>
                            <div class="col-sm-2">
                                <div class="exercisefilter-button-wrapper">
                                    <span class="fa fa-lg fa-times akit-cms-button" (click)="deleteTopic(i)"></span>
                                    <!--  <span class="fa fa-lg fa-plus akit-cms-button" (click)="addGoal(i)"></span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2">
                        </div>
                        <div class="col-sm-3">
                            <button type="button" (click)="addTopic()">
                                <span class="fa fa-plus"></span>
                                <span>Add topic</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="tab-pane" [ngClass]="{ 'active':activeTab==='version'}" id="version">
                    <div *ngIf="lastPublishedVersion">
                        <div class="row row-spacing">
                            <div class="col-sm-2">
                                <label>Last published version</label>
                            </div>
                            <div class="col-sm-10">
                                <p>{{lastPublishedVersion.majorVersion}}.{{lastPublishedVersion.minorVersion}}
                                    <button class="btn btn-default" type="button" (click)="run(true)" title="Run">
                                        <i class="fa fa-eye"></i>
                                        <span>Run</span>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <version-info-panel *ngIf="exercise && versionInfo; else noVersion" [state]="exercise.state"
                        [versionInfo]="versionInfo"></version-info-panel>
                    <ng-template #noVersion>
                        No version data available.
                    </ng-template>
                </div>

                <div class="tab-pane" [ngClass]="{ 'active':activeTab==='metadata'}" id="metadata">
                    <metadata-collection-editor [forItem]="exercise" (afterValidate)="setMetadataValid($event)">
                    </metadata-collection-editor>
                </div>
            </div>
        </div>
        <div *ngIf="showEditingWarning" class="alert alert-danger panel-wrapper">
            <p>
                <i class="fa fa-exclamation-circle"></i> <strong>Alert:</strong> Do not edit arrangement exercises in
                this environment if they are intended for production. Always use the <a
                    href="https://cms.algebrakit.nl/">production
                    version</a> of the Algebrakit CMS to edit arrangement exercises.
            </p>
        </div>
        <stacked-tabs (addTab)='addLevel()' (activeTabChanged)="levelChanged($event)" [allowAdd]="true">
            <stacked-tab *ngFor="let levelSpec of exercise.exercisesPerLevel; let level = index;"
                [tabOptions]="getOptionsForTab(level)" [tabTitle]="level+1" [active]="level == currentActiveLevel"
                (optionEvent)='onOptionEvent(level, $event)'>
                <div class="tab-content">
                    <div class="exerciseFilter-level-wrapper">
                        <h3 class="panel-title">
                            <span>Level</span> {{level+1}}
                        </h3>
                        <br />

                        <exercise-filter-list [levelSpec]="levelSpec" [showButtons]="currentMode == 'LIST'"
                            (onExerciseClick)="previewScript($event)"
                            (onExerciseClientSpecClick)="previewCourseExercise($event)"
                            (switchToWizard)="useWizard(level)" (switchToBrowser)="useBrowser(level)" [course]="course">
                        </exercise-filter-list>

                        <ng-container *ngIf="course && (currentMode == 'WIZARD' || currentMode == 'BROWSE')">

                            <exercise-filter-wizard *ngIf="currentMode == 'WIZARD'"
                                [levelSpec]="attrPerTopicPerLevel[level]" [topicData]="topicData"
                                [deletedScripts]="deletedScripts" (previewScript)="previewScript($event)"
                                (cancel)="wizardCancelled(level)" (add)="exercisesAdded($event)"
                                (replace)="wizardReplace($event)" (selectTopics)="goToTopics()"
                                [interactionBlacklist]="interactionBlacklist" [currentVersion]="currentVersion">
                            </exercise-filter-wizard>

                            <exercise-script-browser *ngIf="currentMode == 'BROWSE'" [currentVersion]="currentVersion"
                                [favouriteTopics]="topicData" [level]="level" (cancel)="browserCancelled(level)"
                                (add)="exercisesAdded($event)" (selectTopics)="goToTopics()"
                                [courseRef]="exercise.course" [interactionBlacklist]="interactionBlacklist">
                            </exercise-script-browser>
                        </ng-container>
                    </div>
                </div>
            </stacked-tab>
        </stacked-tabs>

        <br />

        <div class="panel panel-default" *ngIf="!readOnly && ready() && exercise.state == 'APPROVED'">
            <div class="panel-heading">
                <h3 class="panel-title">Update exercise</h3>
            </div>
            <div class="panel-body">
                <div class="row row-spacing">
                    <div class="col-sm-2">
                        <label>Commit message</label>
                    </div>
                    <div class="col-sm-10">
                        <textarea formControlName='commitMessage' class='form-control'
                            [(ngModel)]="exercise.commitMessage" required
                            [ngClass]="{'input-invalid': commitMessageControl.errors}"></textarea>
                    </div>
                </div>
                <div class="row row-spacing">
                    <div class="col-sm-2">
                        <label>Type of update</label>
                    </div>
                    <div class="col-sm-7">
                        <select formControlName='updateType' class='form-control' [(ngModel)]="exercise.updateType">
                            <option value="MINOR" [selected]="true">Minor</option>
                            <option value="MAJOR">Major</option>
                        </select>
                    </div>
                    <div class="col-sm-3">
                        <button class="btn btn-md btn-primary btn-block" type="button" (click)="commit()"
                            [attr.title]="saveTitle" [disabled]="canDeactivate() || !form.valid || !metadataValid">
                            <i class="fa fa-save"></i>
                            <span>Save</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <publish-confirm *ngIf="confirmPublish" class="panel-wrapper" (confirm)="doPublish()" (cancel)="cancelPublish()">
    </publish-confirm>

    <div class="row row-spacing">
        <div *ngIf="!readOnly && ready()">
            <div class="col-xs-3" *ngIf="exercise.state == 'DRAFT' && !approveButtonVisible()">
                <button class="btn btn-md btn-primary btn-block" type="button" (click)="commit()"
                    [disabled]="canDeactivate()" [attr.title]="saveTitle">
                    <i class="fa fa-save"></i>
                    <span>Save</span>
                </button>
            </div>
            <div class="col-xs-3" *ngIf="approveButtonVisible()">
                <button class="btn btn-md btn-info btn-block" type="button" (click)="approve()"
                    title="Approve this exercise">
                    <i class="fa fa-check"></i>
                    <span>Approve exercise</span>
                </button>
            </div>
            <div class="col-xs-3" *ngIf="exercise.state == 'APPROVED'">
                <button class="btn btn-md btn-block" [ngClass]="exercise.tested ? 'btn-success' : 'btn-info'"
                    [disabled]="exercise.tested || changed" type="button"
                    [title]="!exercise.tested ? 'Mark this exercise as tested' : 'Current version is tested'"
                    (click)="setTested()">
                    <i class="fa fa-check"></i>
                    <span *ngIf="!exercise.tested">Confirm tested</span>
                    <span *ngIf="exercise.tested">Current version is tested</span>
                </button>
            </div>
        </div>
        <div class="col-xs-3">
            <button class="btn btn-md btn-default btn-block" type="button" (click)="run()" [attr.title]="runTitle"
                [disabled]="!canRun()">
                <i class="fa fa-eye"></i>
                <span>Run</span>
            </button>
        </div>
        <div class="col-xs-3">
            <button class="btn btn-md btn-default btn-block" type="button" (click)="cancel()"
                title="Discard changes and close the editor">
                <i class="fa fa-ban"></i>
                <span translate>Close</span>
            </button>
        </div>
        <div class="col-xs-3" *ngIf="!readOnly && exercise.state == 'APPROVED' && lastPublishedRetrieved && canPublish()">
            <button class="btn btn-md btn-warning btn-block" type="button" (click)="publish()"
                [title]="getPublishButtonTitle()" [disabled]="this.changed || compareVersionNumbers() < 1">
                <i class="fa fa-leanpub"></i>
                <span>Publish</span>
            </button>
        </div>
    </div>

</div>

<!-- modal to show demo of exercise script -->
<div class="modal fade exercise-demo">
    <div class="modal-dialog modal-lg" role="document">
        <div *ngIf="demoExerciseScript" class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">{{demoExerciseScript?.script?.name}}</h4>
            </div>
            <div class="modal-body">
                <div *ngIf="demoExerciseScript">
                    <div class="button-row">
                        <div class="label-container-xs" *ngIf='demoExerciseScript.attributes'>
                            <div *ngFor='let attr of demoExerciseScript.attributes' class='label-wrapper-xs'>
                                <span class='attr-label-xs'>{{attr}}</span>
                            </div>
                        </div>
                        <button class="edit-button" type="button" (click)="refreshExerciseScriptTest()">
                            <span class="fa fa-refresh"></span>
                        </button>
                        <button class="edit-button" type="button"
                            (click)="editExerciseScript(demoExerciseScript.exerciseId)">
                            <span *ngIf="userService.canEditLibrary()">edit</span>
                            <span *ngIf="!userService.canEditLibrary()">view</span>
                        </button>
                        <div style="clear:both"></div>
                    </div>
                    <exercise-script-preview #scriptPreviewComponent [exercise]="demoExerciseScript.script"
                        [audience]="{ baseAudienceId: exercise.audience, courseId: exercise.course.id }"
                        [showAudienceSelector]="false"></exercise-script-preview>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
        <div *ngIf="demoExerciseFilter" class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">Preview: {{titleCtrl.value || nameCtrl.value || "Unnamed"}}</h4>
            </div>
            <div class="modal-body">
                <div class="button-row">
                    <button class="edit-button" type="button" (click)="refreshExerciseTest()">
                        <span class="fa fa-refresh"></span>
                    </button>
                    <div style="clear:both"></div>
                </div>
                <exercise-preview #exercisePreviewComponent [scoringModel]="course?.options?.scoringModel"
                    [showEditViewButtenForScripts]="demoExerciseFilter.type != 'SPECIFICATION'"
                    [exerciseFilterReq]="demoExerciseFilter"></exercise-preview>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>