<div class="row row-spacing">
    <div class="col-xs-2">
        <button type="button" *ngIf="hasPrevious" class="btn btn-default previous"
            (click)="previous.emit()">Previous</button>
    </div>
    <div class="col-xs-6 col-md-3">
        <div class="input-group" *ngIf="!buttonsOnly">
            <input [(ngModel)]="pageSize" type="number" step="5" min="5" max="50" class="form-control"
                aria-describedby="itemsPerPageAddon">
            <span class="input-group-addon" id="itemsPerPageAddon">items per page</span>
            <span class="input-group-btn">
                <button type="button" class="btn btn-default" (click)="onPageSizeChanged()"><span
                        class="fa fa-refresh"></span></button>
            </span>
        </div>
    </div>
    <div class="col-xs-6 col-md-5">
        <div class="input-group" *ngIf="!buttonsOnly">
            <input (keydown)="searchFilterKeyDown($event)" [(ngModel)]="searchFilter" type="text" class="form-control">
            <span class="input-group-btn">
                <button type="button" class="btn btn-default" (click)="onSearch()"><span
                        class="fa fa-search"></span></button>
            </span>
        </div>
    </div>
    <div class="col-xs-2">
        <button type="button" *ngIf="hasNext" class="btn btn-default next" (click)="next.emit()">Next</button>
    </div>

</div>