
import { map, distinctUntilChanged, catchError } from 'rxjs/operators';

import { BehaviorSubject, throwError } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { User } from '../types/user.type';
import { NameIdPair } from '../../algebrakit/types/metadata.type';
import { BootService } from './boot-service';
import { CmsCourseRef } from '../../authoring/types/navigator.types';
import { MetadataService } from '../../algebrakit/services/metadata.service';
import { isArray } from 'util';
import { AppProfileService } from '../../app/services/app-profile.service';
import { checkPermissions } from './permission-utils';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientService } from '../../app/services/http-client.service';
import { AkitVersionsService } from '../../algebrakit/services/akit-versions.service';

const URL_UPDATE_ROLES = 'user/roles';
const URL_GET_ROLES = 'api/admin/roles';
const URL_GET_GENERAL_ROLES = 'api/admin/general-roles';
const URL_LOGIN = 'user';
const URL_LOGOUT = 'logout';
const URL_REGISTER = 'connect/register';
const URL_INVITE = 'connect/invite';
const URL_RESEND_INVITE = 'connect/resend-invite';
const URL_INVITED_REGISTER = 'connect/invited-register';
const URL_GET_USERS = 'user/list';
const URL_GET_USERDATA = 'user/userdata';
const URL_UPDATE_USER = 'user/update';
const URL_UPDATE_USER_ADMIN = 'api/admin/update-user';
const URL_UPDATE_PW = 'user/update-password';
const URL_REQUEST_PASSWORD_RESET = '/user/request-password-reset';
const URL_FORGOT_PW = 'user/forgot-password'
const URL_RESET_PW = 'user/reset-password';
const URL_GET_INVITED = 'connect/invited';
const URL_HELP = 'connect/support';
const URL_AUTHORITY_NAMES = 'user/authority-names';
const URL_TOGGLE_ACTIVATED = 'user/toggle-activated';
const URL_ACTIVATE = 'user/activate';

@Injectable()
export class UserService extends HttpClientService {

    private user = new BehaviorSubject<User>(null);
    public user$ = this.user.asObservable().pipe(distinctUntilChanged()); //clients subscribe to this

    private loggingOut = new BehaviorSubject<boolean>(false);
    public loggingOut$ = this.loggingOut.asObservable().pipe(distinctUntilChanged()); //clients subscribe to this

    constructor(
        protected http: HttpClient,
        protected toastr: ToastrService,
        protected akitVersionsService: AkitVersionsService,
        private router: Router,
        private ngZone: NgZone,
        private metadataService: MetadataService,
        private profileService: AppProfileService,
    ) {
        super(http, toastr, akitVersionsService);
     }

    getUser(): User {
        return this.user.getValue();
    }

    canEditLibrary(): boolean {
        return this.getUser() && this.getUser().canEditLibrary();
    }

    canPublishInLibrary() {
        return this.getUser() && this.getUser().canPublishLibrary();
    }

    hasCoursePublisherRole(courseId?: string, namespace?: string): boolean {
        return this.getUser() && this.getUser().isNamespacePublisher();
    }

    cnPublishSomeCourse() {
        return this.getUser() && this.getUser().canPublishSomeCourse();
    }

    hasMultipleCourses() {
        return this.getUser() && this.getUser().courses && this.getUser().courses.length > 1;
    }

    canCompareTestSessions() {
        return this.getUser() && this.getUser().canCompareTestSessions();
    }

    canUpdateTestSessions() {
        return this.getUser() && this.getUser().canUpdateTestSessions();
    }

    canSetIsTestCase() {
        return this.getUser() && this.getUser().hasRole('global.cms.testsessions.set-is-testcase');
    }

    getCurrentUserNamespaces(filterPermissionSuffix?: string | string[]): string[] {
        let filterArr: string[] = [];
        if (!isArray(filterPermissionSuffix)) {
            filterArr = [filterPermissionSuffix];
        }
        else {
            filterArr = filterPermissionSuffix;
        }
        let user = this.getUser();
        if (!user) {
            return [];
        }
        let result = user.roles
            .filter(r => !filterArr || filterArr.some(f => r.endsWith(f)))
            .map(r => {
                let suffixMatch = filterArr.find(f => r.endsWith(f));
                let prefixParts = r.substring(0, r.indexOf(suffixMatch)).split('.')
                return prefixParts[prefixParts.length - 1];
            });
        return Array.from(new Set(result));
    }

    canApproveCourseExercises(course: CmsCourseRef) {
        return course && this.getUser() && this.getUser().canApproveInCourse(course);
    }

    canPublishInCourse(course: CmsCourseRef): boolean {
        return course && this.getUser() && this.getUser().canPublishCourse(course);
    }

    canSetSubmitOnEnter(course: CmsCourseRef): boolean {
        return course && this.getUser() && this.getUser().canSetSubmitOnEnter(course);
    }

    canManageCourse(course: CmsCourseRef) {
        return course && this.getUser() && this.getUser().canManageCourse(course);
    }

    canEditCourse(course: CmsCourseRef) {
        return this.getUser() && this.getUser().canEditCourse(course);
    }

    canEditCoursesGlobal() {
        return this.getUser() && this.getUser().hasRole('global.course.edit');
    }

    canAssignResolvedAudiences(course: CmsCourseRef) {
        return this.getUser() && this.getUser().canAssignResolvedAudiences(course);
    }

    canChangeVersion() {
        return this.getUser() && this.getUser().hasRole('global.cms.version.switch');
    }

    getIssueCopySubject() {
        return this.getUser() && this.getUser().getIssueCopySubject();
    }

    getUserData(username: string): Promise<User> {
        return this.get(`${URL_GET_USERDATA}/${username}`).pipe(
            map((obj: any) => this._getUserFromResponse(obj)))
            .toPromise()
            .then();
    }

    getUsers(): Promise<User[]> {
        return this.get<User[]>(URL_GET_USERS).pipe(
            map((obj: any[]) => obj.map(elm => this._getUserFromResponse(elm))))
            .toPromise();
    }

    getInvitedUsers(): Promise<string[]> {
        return this.get<string[]>(URL_GET_INVITED)
            .toPromise();
    }

    //check if a session is active. Typically called at (re)start of the frontend application
    checkSession(): Promise<User> {
        return this.http
            .get(URL_LOGIN).pipe(
                map((obj: any) => this._getUserFromResponse(obj)),
                map(user => {
                    this.user.next(user);
                    this.metadataService.userAuthenticated();
                    return user;
                }),)
            .toPromise()
            .then(async (user) => {
                await this.checkUserCanUseThisCms(user);
                return user;
            })
            .catch(err => {
                this.user.next(null);
                throw err;
            });
    }

    login(username: string, password: string): Promise<User> {
        let code: string = btoa(username + ":" + password);
        let headers = new HttpHeaders({ 'Authorization': "Basic " + code });
        let options = { headers: headers };

        console.log(headers);

        return this.http
            .get(URL_LOGIN, options).pipe(
                map((obj: any) =>
                    this._getUserFromResponse(obj)
                ),
                map(user => {
                    this.user.next(user);
                    this.metadataService.userAuthenticated();
                    return user;
                }),)
            .toPromise()
            .then(async (user) => {
                await this.checkUserCanUseThisCms(user);
                return user;
            });
    }

    invite(email: string, authorities: string[]) {
        return this.post(`${URL_INVITE}`, { email: email, authorities: authorities }).toPromise();
    }

    resendInvite(email: string) {
        return this.post(`${URL_RESEND_INVITE}`, email).toPromise();
    }

    register(username: string, password: string, email: string, firstName: string, lastName: string): Promise<boolean> {
        let user: User = new User(username, email, [], password, firstName, lastName);
        return this.post(URL_REGISTER, user).toPromise().then(() => true).catch(this._serverError.bind(this)) as Promise<boolean>;
    }

    registerWithInvite(username: string, email: string, token: string, firstName: string, lastName: string, password: string) {
        let user: User = new User(username, email, [], password, firstName, lastName);
        let tokenUserPair = { token: token, user: user };
        return this.post(URL_INVITED_REGISTER, tokenUserPair).toPromise();
    }

    updateUser(username: string, password: string, email: string, firstName: string, lastName: string) {
        let user: User = new User(username, email, [], "", firstName, lastName);
        let updateUser = { user: user, currentPassword: password };
        return this.post(URL_UPDATE_USER, updateUser).toPromise();
    }

    updateUserByAdmin(username: string, password: string, email: string, firstName: string, lastName: string, roles: string[], activated: boolean) {
        let user: User = new User(username, email, roles, "", firstName, lastName, null, activated);
        let updateUser = { user: user, currentPassword: password };
        return this.post(URL_UPDATE_USER_ADMIN, updateUser).toPromise();
    }

    forgotPassword(email: string) {
        return this.post(`${URL_FORGOT_PW}`, email).toPromise();
    }

    getNamesForAuthorities(authorities: string[]) {
        return this.post(`${URL_AUTHORITY_NAMES}`, authorities).toPromise();
    }

    resetPassword(token: string, newPassword: string): Promise<any> {
        return this.post(URL_RESET_PW, { token: token, newPassword: newPassword }, { responseType: 'text' }).pipe(
            map((response: any) => response),
            catchError(error => {
                // Check if the error status is 500
                if (error.status === 500) {
                    // Display the toast message
                    this.toastr.error('An error occurred while resetting your password. Please contact Algebrakit.');
                }
                // Return the error to be handled further or to fail the observable.
                return throwError(error);
            })
        ).toPromise();
    }
    updatePassword(currentPassword: string, newPassword: string) {
        let updatePassword = { currentPassword: currentPassword, newPassword: newPassword };
        return this.post(URL_UPDATE_PW, updatePassword, { responseType: 'text' }).toPromise();
    }
    requestPasswordReset(email: string) {
        //get the url + /user/reset-password
        let resetUrl = window.location.href;

        // Replace the end of the URL with "/cms/user/reset-password"
        resetUrl = resetUrl.replace(/\/forgot-password$/, '/reset-password');

        return this.post(URL_REQUEST_PASSWORD_RESET, { email: email, resetUrl }, { responseType: 'text' })
            .pipe(
                map(response => response),
                catchError(error => {
                    if (error.status === 500) {
                        this.toastr.error('An error occurred while requesting a password reset. Please contact Algebrakit.');
                    }
                    return throwError(error);
                })
            ).toPromise();

    }

    logout(redirect: any[] = ['/']): void {
        this.loggingOut.next(true);
        this.get(URL_LOGOUT)
            .toPromise()
            .then(() => {
                this.user.next(null);
                this.metadataService.resetUserPromise();
                this.ngZone.runOutsideAngular(() => BootService.getbootControl().restart());
                this.router.navigate(redirect);
                this.loggingOut.next(false);
            })
            .catch(() => {
                this.user.next(null);
                this.metadataService.resetUserPromise();
                this.loggingOut.next(false);
            });
    }

    getPossibleRolesForUser(username: string): Promise<{ [roleType: string]: NameIdPair[] }> {
        return this.http
            .get(URL_GET_ROLES + '/' + username)
            .toPromise()
            .then((resp: { [roleType: string]: any[] }) => {
                for (let roleType of Object.keys(resp)) {
                    resp[roleType] = resp[roleType].map(role => this._getRoleFromResponse(role)).sort(compareRole)
                }
                return resp;
            }) as Promise<{ [roleType: string]: NameIdPair[] }>;
    }

    getAllGeneralRoles(): Promise<{ [roleType: string]: NameIdPair[] }> {
        return this.http
            .get(URL_GET_GENERAL_ROLES)
            .toPromise()
            .then((resp: { [roleType: string]: any[] }) => {
                for (let roleType of Object.keys(resp)) {
                    resp[roleType] = resp[roleType].map(role => this._getRoleFromResponse(role)).sort(compareRole)
                }
                return resp;
            }) as Promise<{ [roleType: string]: NameIdPair[] }>;
    }

    toggleActivated(username: string): Promise<boolean> {
        return this.http
            .post(URL_TOGGLE_ACTIVATED + '/' + username, {})
            .toPromise()
            .catch(this._serverError.bind(this)) as Promise<boolean>;
    }

    sendHelpMail(name: string, email: string, phone: string, subject: string, link: string, message: string) {
        return this.post(URL_HELP, {
            name: name,
            email: email,
            phone: phone,
            subject: subject,
            link: link,
            message: message
        })
            .toPromise();
    }

    /**
     * Check if user is allowed to use this version of the CMS
     */
    checkUserCanUseThisCms = async (user: User) => {
        let profile = await this.profileService.getProfile()
        if (profile != 'staging' || checkPermissions(user, ['global.cms.allow-staging'])) {
            return true;
        }
        //Redirect to production cms
        this.toastr.warning("You are not authorized to use this version of the Algebrakit CMS. You will be logged out and redirected to the production CMS within a few seconds...");
        this.logout();
        setTimeout(function () {
            window.location.href = "https://cms.algebrakit.nl";
        }, 5000)
    }

    private _getUserFromResponse(res: any): User {
        return new User(res.username, res.email, res.authorities, null, res.firstName, res.lastName, res.roles, res.activated, res.courses);
    }

    private _getRoleFromResponse(res: any): NameIdPair {
        return { name: res.name, id: res.id };
    }

    activate(input: any) {
        return this.post(URL_ACTIVATE, input,{ responseType: 'text' })
            .pipe(map((response: any) => {
                return response;
            })).toPromise();
    }
}

function compareRole(a: NameIdPair, b: NameIdPair) {
    return a.name.localeCompare(b.name);
}