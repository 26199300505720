import { HostListener } from "@angular/core";

export abstract class ComponentCanDeactivate {

    abstract canDeactivate(): boolean | Promise<boolean>;

    public abstract releaseLock(): void;

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        function afterCheck(result: boolean, self: ComponentCanDeactivate) {
            if (!result) {
                $event.returnValue = true;
            }
            else {
                self.releaseLock();
            }
        }

        let canDeactivate = this.canDeactivate();
        if (typeof(canDeactivate) == 'object') {
            (canDeactivate as Promise<boolean>).then((result) => afterCheck(result, this))
        }
        else {
            afterCheck(canDeactivate, this);
        }
        
    }
}