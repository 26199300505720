
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/Rx';

import {
    CmsPublishedVersion} from '../types/navigator.types';
import { AuthoringService } from './authoring.service';
import { ToastrService } from 'ngx-toastr';
import { PublishedSnapshot, PublishInProgress, PublishProgress } from '../types/published.types';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ConcurrencyService } from '../../app/services/concurrency.service';
import { HttpClient } from '@angular/common/http';
import { AkitVersionsService } from '../../algebrakit/services/akit-versions.service';

const URL_BASE_VERSIONS = "api/publish";

@Injectable()
export class PublishService extends AuthoringService {

    constructor(
        protected http: HttpClient,
        protected toastr: ToastrService,
        protected router: Router,
        protected sanitizer: DomSanitizer,
        protected concurrencyService: ConcurrencyService,
        protected akitVersionsService: AkitVersionsService
    ) { 
        super(http, toastr, concurrencyService, akitVersionsService);
    }

    publishSnapshot(courseId: string, name: string): Observable<any> {
        return this.post(`${URL_BASE_VERSIONS}/snapshot`, { courseId: courseId, name: name });
    }

    publishSubject(subjectId: string): Observable<any> {
        return this.post(`${URL_BASE_VERSIONS}/subject`, { subjectId: subjectId });
    }

    publishSnapshotProgress(): Observable<PublishProgress> {
        return this.get<PublishProgress>(`${URL_BASE_VERSIONS}/snapshot/progress`)
    }

    getSnapshots(courseId: string): Observable<PublishedSnapshot[]> {
        return this.get(`${URL_BASE_VERSIONS}/snapshots/${courseId}`).pipe(
            map((obj: any[]) =>
                obj.map(s => {
                    return {
                        ...s,
                        created: new Date(s.created)
                    }
                })
            ));
    }

    deleteSnapshot(courseId: string, snapshotId: string): Promise<boolean | any[]> {
        return this.delete(`${URL_BASE_VERSIONS}/snapshot/${courseId}/${snapshotId}`).pipe(
            map(() => true)).toPromise()
            .catch(this._serverError.bind(this));
    }

    publishInProgress(): Promise<PublishInProgress> {
        return this.get<PublishInProgress>(URL_BASE_VERSIONS + '/publish-in-progress')
            .toPromise()
            .catch(this._serverError.bind(this)) as Promise<PublishInProgress>;
    }

    exportSnapshotMetadata(snapshotId: string) {
        return this.get(URL_BASE_VERSIONS + '/snapshot-metadata/' + snapshotId)
            .toPromise()
            .then(res => {
                let theJSON = JSON.stringify(res);
                let blob = new Blob([theJSON], { type: 'application/json' });
                let url = window.URL.createObjectURL(blob);
                window.open(url, "_blank");
            });
    }

    //============================================== OLD!!! ==============================================

    getVersions(courseId: string): Observable<CmsPublishedVersion[]> {
        return this.get<CmsPublishedVersion[]>(URL_BASE_VERSIONS + '/course/' + courseId).pipe(
            map((arr: any[]) => arr.map(obj => this._getVersionFromJson(obj))),
            catchError(error => this._serverError(error)),);
    }

    publishCourse(courseId: string, versionName: string): Promise<any> {
        return this.post(`${URL_BASE_VERSIONS}/publish`,
            { courseId: courseId, versionName: versionName }).toPromise();
    }

    getPublishProgress() {
        return this.get(`${URL_BASE_VERSIONS}/publish-progress/`).toPromise();
    }

    deleteVersion(courseId: string, id: string): Promise<boolean | any[]> {
        return this.delete(URL_BASE_VERSIONS + '/delete/' + courseId + '/' + id).pipe(
            map(() => true)).toPromise()
            .catch(this._serverError.bind(this));
    }

    exportMetadata(versionId: string) {
        window.location.href = URL_BASE_VERSIONS + '/metadata/' + versionId;
    }

    private _getVersionFromJson(obj: any): CmsPublishedVersion {
        let date = new Date(obj.created);
        let month: string = '' + (date.getMonth() + 1);
        if (month.length < 2) month = '0' + month;
        let day: string = '' + date.getDate();
        if (day.length < 2) day = '0' + day;

        return {
            id: obj.id,
            courseId: obj.courseId,
            owner: obj.owner,
            created: date.getFullYear() + '-' + month + '-' + day,
            name: obj.name ? obj.name : "Unnamed version"
        }
    }
}