import { Injectable } from '@angular/core';
import 'rxjs/Rx';

import { AuthoringService } from './authoring.service';
import { ToastrService } from 'ngx-toastr';
import { ConcurrencyService } from '../../app/services/concurrency.service';
import { HttpClient } from '@angular/common/http';
import { AkitVersionsService } from '../../algebrakit/services/akit-versions.service';


@Injectable()
export class ResourceService extends AuthoringService {

    constructor(
        protected http: HttpClient,
        protected toastr: ToastrService,
        protected concurrencyService: ConcurrencyService,
        protected akitVersionsService: AkitVersionsService
    ) {
        super(http, toastr, concurrencyService, akitVersionsService);
    }

    public getResourceType(id: string): Promise<string> {
        return this.get(`api/resource/type/${id}`,  {responseType:'text'})
            .toPromise() as Promise<string>;
    }

    public copyAssets(oldId: string, newId: string) {
        return this.post(`api/resource/copy/assets/${oldId}/${newId}`, {})
            .toPromise();
    }
}