<div class="exercise-filter-editor">
    <div class="panel panel-default">
        <div class="panel-heading">
            <h3 class="panel-title">Move exercise scripts to another topic</h3>
        </div>
        <div class="panel-body">
            <div class="row row-spacing">
                <div class="col-sm-2">
                    <label>Selected exercise scripts</label>
                </div>
                <div class="col-sm-10">
                    <ul *ngIf="_selectedExerciseScripts.value">
                        <li *ngFor="let ex of _selectedExerciseScripts.value">
                            {{ex.name}}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row row-spacing">
                <div class="col-sm-2">
                    <label>Topic</label>
                </div>
                <div class="col-sm-10">
                    <breadcrumb [folder]="targetTopic" (parentChange)='setTopic($event)' [showChilds]="true">
                    </breadcrumb>
                </div>
            </div>
        </div>
    </div>
    <div class="row row-spacing" *ngIf="missingAttrs?.length > 0">
        <div class="col-xs-12">
            <div class="alert alert-warning" role="alert">
                The selected topic is missing the following attributes: {{missingAttrs.join('; ')}}. These attributes will be disabled in the selected exercises if they are moved.
            </div>
        </div>
    </div>
    <div class="row row-spacing">
        <div class="col-xs-3">
            <button class="btn btn-md btn-primary btn-block" type="button" (click)="commit()"
                [disabled]="!canConfirm()">
                <i class="fa fa-save"></i>
                <span>Confirm</span>
            </button>
        </div>
        <div class="col-xs-3">
            <button class="btn btn-md btn-default btn-block" type="button" (click)="close()">
                <i class="fa fa-ban"></i>
                <span translate>Close</span>
            </button>
        </div>
    </div>

</div>