<div *ngIf="_directReferences && _directReferences.length > 0" class="alert alert-danger">
    <strong>Cannot delete '{{name}}':</strong> the exercise template is directly referenced in the following exercises
    <br/>
    <br/>
    <reference-list [references]="_directReferences" [urlPrefix]="'/edit/exercise-reference/'"></reference-list>
    <br/>
    Please remove these references first.
</div>
<div *ngIf="_filterReferences && _filterReferences.length > 0" class="alert alert-warning">
    <strong>Warning:</strong> the exercise template '{{name}}' is referenced by the following exercises arrangements
    <br/>
    <br/>
    <reference-list [references]="_filterReferences" [urlPrefix]="'/edit/exercise-filter/'"></reference-list>
    <br/>
    <div *ngIf="!_directReferences || _directReferences.length == 0">
        Are you sure you want to delete this exercise template?
        <br/>
        <br/>
        <button (click)="onConfirm()" class="btn btn-warning">Yes</button>
        <button (click)="reset()" class="btn btn-default">No</button>
    </div>
</div>