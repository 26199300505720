<div id="versionCollapse">
     <div class="row row-spacing">
        <div class="col-sm-2">
            <label>State</label>
        </div>
        <div class="col-sm-10">
            {{state}}
        </div>
    </div>
    <div *ngIf="state == 'APPROVED' && versionInfo">
        <div class="row row-spacing">
            <div class="col-sm-2">
                <label>Version Number</label>
            </div>
            <div class="col-sm-10">
                {{versionInfo.majorVersion}}.{{versionInfo.minorVersion}}
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-sm-2">
                <label>Updated on</label>
            </div>
            <div class="col-sm-10">
                {{versionInfo.created}}
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-sm-2">
                <label>Updated by</label>
            </div>
            <div class="col-sm-10">
                {{versionInfo.editedBy}}
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-sm-2">
                <label>Commit message</label>
            </div>
            <div class="col-sm-10">
                {{versionInfo.commitMessage}}
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-sm-2">
                <label>Tested</label>
            </div>
            <div class="col-sm-10">
                <span>{{versionInfo.tested ? "Yes" : "No"}}</span>
            </div>
        </div>
    </div>
</div>
