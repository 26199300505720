<div class="foreground">
    <div class="foreground__top-row">
        <img class="foreground__top-row__logo" src="/ebc986c0a00280f3322d60d46aa24741.svg"
            alt="Algebrakit Logo">
    </div>
    <form [formGroup]="form" (ngSubmit)="login()" #loginForm="ngForm">
        <h2>Content Management System (CMS)</h2>
        <br>
        <div class=''>
            <h4 [tooltip]="'Email address'">Email address</h4>
            <input type="email" class="form-control" placeholder="Email address" title="user name" ng-minlength="4"
                autofocus formControlName="username" name="username" autocapitalize="off" autocorrect="off">
        </div>


        <div class="">
            <h4>Password</h4>
            <input type="password" class="form-control" placeholder="Password" title="password" ng-minlength="6"
                formControlName="password" name="password">
        </div>


        <br />


        <div class="row display-flex-center">
            <div class="col-sm-3 col-xs-6">
                <button class="btn btn-primary btn-block" type="submit" title="Log in"
                    [disabled]="!loginForm.form.valid">
                    <span>Log in</span>
                    &nbsp;
                    <i class="fa fa-sign-in"></i>

                </button>
            </div>
            <div class="col-sm-9 col-xs-6">
                <a [routerLink]="getForgotPasswordUrl()">
                    <span>Forgot password</span>
                </a>
            </div>
        </div>
    </form>
</div>