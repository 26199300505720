
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/Rx';

import {
    CmsDiffResult
} from '../types/navigator.types';
import { AuthoringService } from './authoring.service';
import { ToastrService } from 'ngx-toastr';
import { ConcurrencyService } from '../../app/services/concurrency.service';
import { HttpClient } from '@angular/common/http';
import { AkitVersionsService } from '../../algebrakit/services/akit-versions.service';

const URL_BASE_DIFF = "api/diff";

@Injectable()
export class DiffService extends AuthoringService {

    constructor(
        protected http: HttpClient,
        protected toastr: ToastrService,
        protected concurrencyService: ConcurrencyService,
        protected akitVersionsService: AkitVersionsService
    ) { 
        super(http, toastr, concurrencyService, akitVersionsService);
    }

    getDiff(courseId: string): Observable<CmsDiffResult> {
        return this.get<CmsDiffResult>(`${URL_BASE_DIFF}/for-course/${courseId}`).pipe(
            catchError(error => this._serverError(error)));
    }

    getErrorsDiff(courseId: string) {
        return this.get(`${URL_BASE_DIFF}/for-course/${courseId}/errors-only`).pipe(
            catchError(error => this._serverError(error)));
    }

    getDiffForPublish(courseId: string) {
        return this.get<CmsDiffResult>(`${URL_BASE_DIFF}/for-course/${courseId}/publish`).pipe(
        catchError(error => this._serverError(error)));
    }
}