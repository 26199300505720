import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CmsExerciseScript, CmsExerciseFilterLevelSpec, CmsTopic, CmsExerciseFilterScriptSpec, CmsTopicAttributeAssignment, CmsExerciseClientSpec, CmsExercise, CmsCourse, CmsSubjectRef } from '../../types/navigator.types';
import { TopicService } from '../../services/topic.service';
import { ExerciseScriptService } from '../../services/exercise-script.service';
import { Sorting } from '../../util/sorting';
import { ExerciseFilterLevelSpecType } from '../../types/published.types';
import { ExerciseService } from '../../services/exercise.service';

interface SubjectClientSpecObj {
    id: string;
    name: string;
    exercises: CmsExerciseClientSpec[];
}

/**
 * A topic-leaf is a 'file' in the navigation system, which corresponds to an
 * exercise. The model is defined by class of type CmsLeaf. Classes for the various 
 * types of exercises are derived from CmsLeaf
 */
@Component({
    selector: 'exercise-filter-list',
    templateUrl: './exercise-filter-list.component.html',
    styleUrls: ['./exercise-filter-list.component.css']
})
export class ExerciseFilterListomponent {

    _levelSpec: CmsExerciseFilterLevelSpec;
    topics: CmsTopic[] = [];

    scriptsPerTopic: { [topicId: string]: CmsExerciseFilterScriptSpec[] } = {};

    scriptMap: { [scriptId: string]: CmsExerciseScript } = {};
    clientSpecMap: { [scriptId: string]: CmsExerciseClientSpec } = {};

    attributeAssignmentMap: { [topicId: string]: CmsTopicAttributeAssignment[] } = {};

    ready: boolean = false;

    selectedScriptIndices: { [topicId: string]: number[] } = {};
    selectedClientSpecIds: string[] = [];

    canRemove: boolean = false;

    exerciseToTypeMap: { [id: string]: ExerciseFilterLevelSpecType };

    @Input() showButtons: boolean = true;

    @Input() set levelSpec(levelSpec: CmsExerciseFilterLevelSpec) {
        this._levelSpec = levelSpec;
        this.updateData();
    }

    @Input() course: CmsCourse;

    @Output() onExerciseClick: EventEmitter<CmsExerciseScript> = new EventEmitter();
    @Output() onExerciseClientSpecClick: EventEmitter<CmsExerciseClientSpec> = new EventEmitter();
    @Output() switchToWizard: EventEmitter<void> = new EventEmitter();
    @Output() switchToBrowser: EventEmitter<void> = new EventEmitter();

    constructor(
        private topicService: TopicService,
        private scriptService: ExerciseScriptService,
        private exerciseService: ExerciseService
    ) {
        
    }

    noExercises() {
        return (!this._levelSpec.scripts || this._levelSpec.scripts.length == 0)
            && (!this._levelSpec.courseExercises || this._levelSpec.courseExercises.length == 0);
    }

    selectedExerciseChanged(topicId: string, exerciseIndex: number, e: any) {
        if (!this.selectedScriptIndices[topicId]) {
            this.selectedScriptIndices[topicId] = [];
        }
        let checked = e.target.checked;
        let i = this.selectedScriptIndices[topicId].indexOf(exerciseIndex);
        if (checked && i === -1) {
            this.selectedScriptIndices[topicId].push(exerciseIndex);
        }
        else if (!checked && i !== -1) {
            this.selectedScriptIndices[topicId].splice(i, 1);
        }
        this.canRemove = this.exercisesSelected();
    }

    selectedClientSpecsChanged(id: string, e: any) {
        let checked = e.target.checked;
        if (checked && !this.isClientSpecSelected(id)) {
            this.selectedClientSpecIds.push(id);
        }
        else if (!checked && this.isClientSpecSelected(id)) {
            let i = this.selectedClientSpecIds.indexOf(id);
            this.selectedClientSpecIds.splice(i, 1);
        }
        this.canRemove = this.exercisesSelected();
    }

    selectAll(topicId: string, e: any) {
        let checked = e.target.checked;
        this.selectedScriptIndices[topicId] = [];
        if (checked) {
            let nrOfScripts = this.scriptsPerTopic[topicId]
                ? this.scriptsPerTopic[topicId].length
                : 0;
            for (let i = 0; i < nrOfScripts; i++) {
                this.selectedScriptIndices[topicId].push(i);
            }
        }
        this.canRemove = this.exercisesSelected();
    }

    selectAllClientSpecs(e: any) {
        let checked = e.target.checked;
        if (!checked) {
            this.selectedClientSpecIds = [];
        }
        else {
            this.selectedClientSpecIds = Object.keys(this.clientSpecMap);
        }
    }

    isSelected(topicId: string, index: number) {
        if (!this.selectedScriptIndices[topicId] || index < 0 || index >= this.scriptsPerTopic[topicId].length) {
            return false;
        }
        return this.selectedScriptIndices[topicId].indexOf(index) !== -1;
    }

    isClientSpecSelected(id: string) {
        return this.selectedClientSpecIds.indexOf(id) !== -1;
    }

    exercisesSelected() {
        for (let topicId of Object.keys(this.selectedScriptIndices)) {
            if (this.selectedScriptIndices[topicId].length > 0) {
                return true;
            }
        }
        return this.selectedClientSpecIds.length > 0;
    }

    removeSelected() {
        for (let topicId of Object.keys(this.selectedScriptIndices)) {
            let indices = this.selectedScriptIndices[topicId];
            let scriptIds: string[] = [];
            for (let i = 0; i < this.scriptsPerTopic[topicId].length; i++) {
                if (indices.indexOf(i) !== -1) {
                    scriptIds.push(this.scriptsPerTopic[topicId][i].id);
                }
            }
            this._levelSpec.scripts = this._levelSpec.scripts
                .filter(s => scriptIds.indexOf(s.id) === -1);
        }
        for (let exerciseId of this.selectedClientSpecIds) {
            delete this.clientSpecMap[exerciseId];
        }
        this._levelSpec.courseExercises = this._levelSpec.courseExercises
            .filter(e => this.selectedClientSpecIds.indexOf(e.id) === -1);
        this.updateData();
    }

    setAttributeAssignments() {
        for (let topic of this.topics) {
            let scriptIdsForTopic = this.scriptsPerTopic[topic.id]
                .map(s => s.id);

            let result: CmsTopicAttributeAssignment[] = [];

            scriptIdsForTopic.forEach(id => {
                this.scriptMap[id].attributeAssignments.forEach(attr => {
                    if (!!result.find(a => a.id === attr.id)) {
                        return;
                    }
                    result.push(attr);
                })
            })

            this.attributeAssignmentMap[topic.id] = Sorting.sortAttributeAssignments(result);
        }
    }

    getColorForTopicAttribute(attribute: CmsTopicAttributeAssignment): { [key: string]: string } {
        return this.topicService.getAttributeColorBg(attribute.difficulty);
    };

    getColorForScriptAttribute(attribute: CmsTopicAttributeAssignment, script: CmsExerciseScript): { [key: string]: string } {
        let result = {};
        if (script.attributeAssignments.find(attr => attr.id === attribute.id)) {
            result = this.topicService.getAttributeColorBg(attribute.difficulty);
            if (attribute.difficulty.complexity > 4) result['color'] = 'white';
        }
        return result;
    };

    getAllScripts(): CmsExerciseScript[] {
        return Object.keys(this.scriptMap).map(id => this.scriptMap[id]);
    }

    getAllClientSpecs(): CmsExerciseClientSpec[] {
        return Object.keys(this.clientSpecMap).map(id => this.clientSpecMap[id]).sort((e1, e2) => e1.name.localeCompare(e2.name));
    }

    getSubjectsClientSpecs() {
        let resultMap: { [id: string]: SubjectClientSpecObj } = {};
        for (let ex of this.getAllClientSpecs()) {
            if (!resultMap[ex.subject.id]) {
                resultMap[ex.subject.id] = {
                    id: ex.subject.id,
                    name: this.getSubjectName(ex.subject),
                    exercises: []
                };
            }
            resultMap[ex.subject.id].exercises.push(ex);
        }
        return Object.keys(resultMap).map(id => resultMap[id]);
    }

    getSubjectName(subject: CmsSubjectRef) {
        if (subject == null) {
            return "";
        }
        return (this.course.rootSubject && this.course.rootSubject.id == subject.id) ? this.course.name : subject.name;
    }

    updateData() {
        this.ready = false;
        this.selectedScriptIndices = {};
        this.canRemove = false;
        //Remove old topics
        this.topics = this.topics
            .filter(t => !!this._levelSpec.scripts.find(s => s.topicId === t.id));

        //Remove old exercises
        Object.keys(this.scriptMap).forEach(scriptId => {
            if (!this._levelSpec.scripts.find(s => s.id === scriptId)) {
                delete this.scriptMap[scriptId];
            }
        });
        this.scriptsPerTopic = {};
        this._levelSpec.scripts.map(s => {
            if (!this.scriptsPerTopic[s.topicId]) {
                this.scriptsPerTopic[s.topicId] = [];
            }
            this.scriptsPerTopic[s.topicId].push(s);
        });

        let scriptIds: string[] = [];
        let topicIds: string[] = [];
        this._levelSpec.scripts
            .forEach(s => {
                if (!this.scriptMap[s.id]) {
                    scriptIds.push(s.id);
                }
                if (this.topics.find(t => t.id === s.topicId)
                    || topicIds.indexOf(s.topicId) !== -1) {
                    return;
                }
                topicIds.push(s.topicId);
            })
        let clientSpecIds: string[] = [];
        if (this._levelSpec.courseExercises) {
            this._levelSpec.courseExercises.forEach(e => {
                if (!this.clientSpecMap[e.id]) {
                    clientSpecIds.push(e.id);
                }
            })
        }

        if (topicIds.length == 0 && clientSpecIds.length == 0) {
            this.ready = true;
            return;
        }

        let clientSpecPromise = clientSpecIds.length === 0
            ? Promise.resolve()
            : this.exerciseService.getExerciseClientSpecs(clientSpecIds)
                .then(clientSpecs => {
                    for (let ex of clientSpecs) {
                        this.clientSpecMap[ex.id] = ex;
                    }
                });

        let topicPromise = this.topicService.getTopics(topicIds)
            .toPromise()
            .then(topics => {
                this.topics = [
                    ...this.topics,
                    ...topics
                ].sort(function (a, b) {
                    return a.name.localeCompare(b.name, 'en');
                })
            });
        let scriptPromise = this.scriptService.getExercises(scriptIds)
            .toPromise()
            .then(scripts => {
                for (let script of scripts) {
                    this.scriptMap[script.id] = script;
                }
            });

        Promise.all([topicPromise, scriptPromise, clientSpecPromise]).then(() => {
            this.setAttributeAssignments();
            this.sortScripts();
            this.ready = true;
        })
    }

    sortScripts() {
        for (let topic of this.topics) {
            let result: CmsExerciseFilterScriptSpec[] = [];
            let scripts: CmsExerciseScript[] = [];
            let indexMap: { [id: string]: number } = {};
            for (let scriptSpec of this.scriptsPerTopic[topic.id]) {
                scripts.push(this.scriptMap[scriptSpec.id]);
            }
            scripts = Sorting.sortItemsByDifficulty(scripts, topic.attributeAssignments, 1);
            for (let i = 0; i < scripts.length; i++) {
                let script = scripts[i];
                indexMap[script.id] = i;
            }
            for (let scriptSpec of this.scriptsPerTopic[topic.id]) {
                let i = indexMap[scriptSpec.id];
                result[i] = scriptSpec
            }
            this.scriptsPerTopic[topic.id] = result;
        }
    }

}



