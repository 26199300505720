import { ICMS2ExerciseSpec } from "./cms2-metadata.types";

export interface PublishedSnapshot {
    snapshotId: string;
    name: string;
    created: Date;
    owner: string;
    course: string;
    exercises: {[id: string]: number};
}

export interface PublishedAkitResource {
    id: string;
    name: string;
    majorVersion: number;
    minorVersion: number;
    contentVersion: string;
}

export interface PublishedExerciseScript extends PublishedAkitResource {
    definition: ICMS2ExerciseSpec;
    learningGoalId: string;
    attributeAssignments: string[];
}

export interface PublishedExercise extends PublishedAkitResource {
    audience: string;
    course?: string;
    metadata: {[key: string]: string};
    subjectId: string;
    type: string;
}

export interface PublishedExerciseClientSpec extends PublishedExercise {
    definition: ICMS2ExerciseSpec;
}

export enum ExerciseFilterLevelSpecType {
    SCRIPT = "SCRIPT",
    CLIENT_SPEC = "CLIENT_SPEC",
}

export interface PublishedExerciseFilter extends PublishedExercise {
    exercisesPerLevel: {[id: string]: number}[];
    exerciseToTypeMap?: {[id: string]: ExerciseFilterLevelSpecType}
}

export interface PublishedExerciseReference extends PublishedExercise {
    exerciseScriptId: string;
    exerciseScriptMajorVersion: number;
}
    
export interface PublishInProgress {
    inProgress: boolean;
    showProgress: boolean;
}

export interface PublishProgress {
    progress: number;
    finished: boolean;
}