import {Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl, ValidationErrors } from '@angular/forms';
import { TopicService } from '../../services/topic.service';

import {CmsTopicAttribute, CmsMetadata} from '../../types/navigator.types';


@Component({
  selector: 'metadata-editor',
  templateUrl: './metadata-editor.component.html',
  styleUrls: ['./metadata-editor.component.css']
})
export class MetadataEditorComponent implements OnInit {
    
    @Input() metadata: CmsMetadata;
    @Output() onMetadataChanged: EventEmitter<FormControl> = new EventEmitter<FormControl>();

    form: FormGroup;

    nameCtrl = new FormControl('', this.validateField);
    valueCtrl = new FormControl('', this.validateField);

    constructor(
        private formBuilder : FormBuilder,
    ) { 
        this.form = this.formBuilder.group({
            name: this.nameCtrl,
            value: this.valueCtrl
        })
    }

    validateField(control: AbstractControl): ValidationErrors | null {
        return !!control.value && !!control.value.trim() ? null : {notEmptyOrWhitespace: {valid: false}};
    }
    
    ngOnChanges() {
        if (this.metadata) {
            this.nameCtrl.setValue(this.metadata.name);
            this.valueCtrl.setValue(this.metadata.value);
        }
    }
    
    ngOnInit() {
        this.nameCtrl.valueChanges.subscribe(name => {
            if(this.metadata) this.metadata.name = name
            this.onMetadataChanged.emit(this.nameCtrl);
        });
        this.valueCtrl.valueChanges.subscribe(value => {
            if(this.metadata) this.metadata.value = value
            this.onMetadataChanged.emit(this.valueCtrl);
        });
    }   
}




