
import {mergeMap, map, filter} from 'rxjs/operators';
import { Component, NgZone, OnInit, ViewContainerRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { UserService } from '../../security/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { SetTitleService } from '../services/set-title.service';
import { IAlgebraKIT } from '../../algebrakit/types/algebrakit.type';
import { resolve } from 'dns';
import { AppProfileService } from '../services/app-profile.service';
import { ContentVersionService } from '../services/content-version-service';

declare const AlgebraKIT: IAlgebraKIT;

@Component({
    selector: 'akit-cms',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent extends SetTitleService implements OnInit {

    busy: boolean = false;
    versionMismatch: boolean = false;

    constructor(
        private userService: UserService,
        private router: Router,
        public _toastr: ToastrService,
        public _viewContainerRef: ViewContainerRef,
        private titleService: Title,
        private activatedRoute: ActivatedRoute,
        private contentVersionService: ContentVersionService,
        private zone: NgZone
    ) {
        super(titleService);
    }

    async ngOnInit() {
        //if session already exists, get the user data from the backend
        this.userService.loggingOut$.subscribe(loggingOut => {
            this.busy = loggingOut;
        })
        this.contentVersionService.isVersionMismatch().then((mismatch) => {
            this.versionMismatch = mismatch;
        })

        this.userService.checkSession()
            .catch(() => console.log("Not logged in"));
        //Set the page title
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                while (route.firstChild) route = route.firstChild;
                return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data),)
            .subscribe((event) => {
                if (event["dynamicTitle"]) {
                    return;
                }
                this.setTitle(event['title'])
            });

        //Make the widgets use the CMS's toastrs to prevent z-index problems:
        AlgebraKIT._api.alertDanger = (message: string) => {
            this.showToast(message, "error");
        }
        AlgebraKIT._api.alertWarn = (message: string) => {
            this.showToast(message, "warning");
        }
        AlgebraKIT._api.alertInfo = (message: string) => {
            this.showToast(message, "info");
        }
        AlgebraKIT._api.alertSuccess = (message: string) => {
            this.showToast(message, "success");
        }
    }

    showToast = (message: string, type: string) => {
        this.zone.run(() => {
            switch (type) {
                case 'error': return this._toastr.error(message);
                case 'warning': return this._toastr.warning(message);
                case 'success': return this._toastr.success(message);
                default: return this._toastr.info(message);
            }
        });
    }
}