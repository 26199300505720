import { Component, OnInit, Input, Injectable, AfterViewInit } from '@angular/core';
import { CmsFolder, CmsAkitResourceRef } from '../../types/navigator.types';
import { UserService } from '../../../security/services/user.service';
import { Router, NavigationEnd, ActivatedRoute  }   from '@angular/router';
import { HelpItemLIDirective } from '../../directives/helpitem-listitem/helpitem-listitem.directive';

///Users/marloustheunissen/Documents/GitHub/AKIT-CMS/client/src/app/app/services/app-profile.service.ts
///Users/marloustheunissen/Documents/GitHub/AKIT-CMS/client/src/app/authoring/containers/help/help.component.ts
import { AppProfileService } from '../../../app/services/app-profile.service';
import { HttpClient } from '@angular/common/http';

declare let $: any;

var qgj = require('../../../../assets/json/quickguide.json');
var libj = require('../../../../assets/json/library.json');
var crsj = require('../../../../assets/json/courses.json');
var pubj = require('../../../../assets/json/publishing.json');

const imgUrls = {
    /// quickguide
    'icon_female_blackhair_smile' : require('../../../../assets/images/quickguide/icon_female_blackhair_smile.png'),
    'icon_female_blackhair' : require('../../../../assets/images/quickguide/icon_female_blackhair.png'),
    'icon_female_white' : require('../../../../assets/images/quickguide/icon_female_white.png'),
    'icon_male_leraar_white' : require('../../../../assets/images/quickguide/icon_male_leraar_white.png'),
    'icon_male_white' : require('../../../../assets/images/quickguide/icon_male_white.png'),
    'icon_trans_blackhair' : require('../../../../assets/images/quickguide/icon_trans_blackhair.png'),
    'algebrakit_profile_tandwielen' : require('../../../../assets/images/quickguide/algebrakit_profile_tandwielen.png'),
    'algebrakit_concepts_overview' : require('../../../../assets/images/quickguide/algebrakit_concepts_overview.png'),
    'infinitetraining' : require('../../../../assets/images/quickguide/infinitetraining.png'),
    'edit' : require('../../../../assets/images/quickguide/edit.png'),
    'feedback' : require('../../../../assets/images/quickguide/feedback.png'),
    'features_feedback' : require('../../../../assets/images/quickguide/features_feedback.png'),
    'exercise_concepts_terminology' : require('../../../../assets/images/quickguide/exercise_concepts_terminology.png'),
    'exercise_concepts_interaction' : require('../../../../assets/images/quickguide/exercise_concepts_interaction.png'),
    'algebrakit_library_screenshot' : require('../../../../assets/images/quickguide/algebrakit_library_screenshot.png'),
    'algebrakit_library_rightclick' : require('../../../../assets/images/quickguide/algebrakit_library_rightclick.png'),
    'algebrakit_library_preview' : require('../../../../assets/images/quickguide/algebrakit_library_preview.png'),
    'algebrakit_syntax_exercisespec' : require('../../../../assets/images/quickguide/algebrakit_syntax_exercisespec.png'),
    'syntax_extended_exercise' : require('../../../../assets/images/quickguide/syntax_extended_exercise.png'),
    'syntax_extended_syntax' : require('../../../../assets/images/quickguide/syntax_extended_syntax.png'),
    'syntax_extended_script' : require('../../../../assets/images/quickguide/syntax_extended_script.png'),
    'syntax_extended_interactions' : require('../../../../assets/images/quickguide/syntax_extended_interactions.png'),
    'syntax_extended_backticks1' : require('../../../../assets/images/quickguide/syntax_extended_backticks1.png'),
    'syntax_extended_backticks2' : require('../../../../assets/images/quickguide/syntax_extended_backticks2.png'),
    'syntax_extended_backticks3' : require('../../../../assets/images/quickguide/syntax_extended_backticks3.png'),
    'syntax_extended_backticks4' : require('../../../../assets/images/quickguide/syntax_extended_backticks4.png'),
    'authoring_concepts_menu' : require('../../../../assets/images/quickguide/authoring_concepts_menu.png'),
    'authoring_concepts_edit' : require('../../../../assets/images/quickguide/authoring_concepts_edit.png'),
    'authoring_concepts_definitions' : require('../../../../assets/images/quickguide/authoring_concepts_definitions.png'),
    'authoring_concepts_definitions2' : require('../../../../assets/images/quickguide/authoring_concepts_definitions2.png'),
    'setup_course_menu' : require('../../../../assets/images/quickguide/setup_course_menu.png'),
    'setup_course_edit' : require('../../../../assets/images/quickguide/setup_course_edit.png'),
    'create_basic' : require('../../../../assets/images/quickguide/create_basic.png'),
    'create_difficult' : require('../../../../assets/images/quickguide/create_difficult.png'),
    'create_exercise_reference' : require('../../../../assets/images/quickguide/create_exercise_reference.png'),
    'create_exercise_specification' : require('../../../../assets/images/quickguide/create_exercise_specification.png'),
    'create_exercise_arrangement' : require('../../../../assets/images/quickguide/create_exercise_arrangement.png'),
    'create_arrangement_preview' : require('../../../../assets/images/quickguide/create_arrangement_preview.png'),
    'verify_course' : require('../../../../assets/images/quickguide/verify_course.png'),
    'verify_course_errors' : require('../../../../assets/images/quickguide/verify_course_errors.png'),
    'publishing_publish' : require('../../../../assets/images/quickguide/publishing_publish.png'),
    'publishing_versions' : require('../../../../assets/images/quickguide/publishing_versions.png'),
    'publishing_id' : require('../../../../assets/images/quickguide/publishing_id.png'),
    'publishing_published_menu' : require('../../../../assets/images/quickguide/publishing_published_menu.png'),
    'versioning_majorminor' : require('../../../../assets/images/quickguide/versioning_majorminor.png'),
    'versioning_info' : require('../../../../assets/images/quickguide/versioning_info.png'),
    'versioning_menu' : require('../../../../assets/images/quickguide/versioning_menu.png'),
    'sandbox-multistep-2' : require('../../../../assets/images/quickguide/sandbox-multistep-2.png'),
    'sandbox-multistep-1' : require('../../../../assets/images/quickguide/sandbox-multistep-1.png'),
    'sandbox-arithmetic' : require('../../../../assets/images/help/sandbox-arithmetic.png'),
    'sandbox-multistep' : require('../../../../assets/images/help/sandbox-multistep.png'),
    'algebrakit_profile_loggedinas' : require('../../../../assets/images/quickguide/algebrakit_profile_loggedinas.png'),
    /// library
    'templates-search-bar' : require('../../../../assets/images/help/templates-search-bar.png'),
    'templates-search-navigate' : require('../../../../assets/images/help/templates-search-navigate.png'),
    
    /// courses
    'courses_exercises_extended_exercise' : require('../../../../assets/images/help/exercises_extended_exercise.png'),
    'courses_exercises_multistep' : require('../../../../assets/images/help/exercises_multistep_interaction_exercise.png'),
    'courses_exercises_arithmeticnotebook' : require('../../../../assets/images/help/exercises_arithmeticnotebook_interaction_exercise.png'),
    'courses_exercises_longarithmetic' : require('../../../../assets/images/help/exercises_longarithmetic_interaction_exercise.png'),
    'courses_exercises_mathtable' : require('../../../../assets/images/help/exercises_mathtable_interaction_exercise.png'),
    'courses_exercises_multiplechoice' : require('../../../../assets/images/help/exercises_mc_interaction_exercise.png'),

    /// publishing
    'publishing-1' : require('../../../../assets/images/help/publishing-1.png'),
    'publishing-2' : require('../../../../assets/images/help/publishing-2.png'),
    
}

/**
 * Class to define HELP page for authoring environment
 */
@Component({
    selector: 'help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
    sub: any;
    component: string;
    protected sectionid: string;
    protected id: string;

    loading: boolean = false;
    
    protected quickguideJSON = qgj;
    protected libraryJSON = libj;
    protected coursesJSON = crsj;
    protected publishingJSON = pubj;
    // protected sandboxJSON : Array<Item>;

    protected idIndexMap: {[id: string]: number};

    constructor(
        private router : Router,
        private route: ActivatedRoute,
        private profileService: AppProfileService,
        private userService: UserService,
        private _http: HttpClient
    ) { 
        this.idIndexMap = {};
        for (let i = 0; i < this.quickguideJSON.length; i++) {
            let id = this.quickguideJSON[i].id;
            this.idIndexMap[id] = i;
        }
    }
    
    showComponent(comp: string){
        return this.component == comp;
    }
    showId(id: string){
        return this.id == id;
    }
    showQuestions(component: string) {
        this.router.navigate(['/help', component, 'intro']);
    }
    showQuestion(component: string, id: string) {
        this.router.navigate(['/help', component, id]);
    }
    showSectionQuestion(component: string, section: string, id: string) {
        this.router.navigate(['/help', component, section, id]);
    }
    hasImage(image: String): boolean {
	return image && image.length > 0 //+ Eventueel nog meer checks
		? true
		: false;
    }
    previousId(id: string) {
        let index = this.idIndexMap[id];
        return index > 0
            ? this.quickguideJSON[index-1].id
            : null;
    }

    nextId(id: string) {
        let index = this.idIndexMap[id];
        return index < this.quickguideJSON.length-1
            ? this.quickguideJSON[index+1].id
            : null;
    }
    
    public getHTML(htmlC: string) {
        let htmlContentDOM = $('<div>').append(htmlC);
        let baseUrl = $('base').attr('href');
        htmlContentDOM
            .find('.help-expand-img')
            .each(function() {
                    //find correct src and replace etc.....
                    let currentImg = $(this).find('img');
                    currentImg.attr('src', imgUrls[currentImg.attr('src')]);
            });
        htmlContentDOM
            .find('.help-expand-inline-img')
            .each(function() {
                    //find correct src and replace etc.....
                    let currentImg = $(this).find('img');
                    currentImg.attr('src', imgUrls[currentImg.attr('src')]);
            });
        htmlContentDOM
            .find('a[rel="img"]')
            .each(function() {
                    //find correct src and replace etc.....
                    let currentImg = $(this).attr('href');
                    $(this).attr('href', imgUrls[currentImg]);
            });
        htmlContentDOM
            .find('a[rel="help"]')
            .each(function() {
                    //find correct src and replace etc.....
                    let help_loc = baseUrl + "/help" + $(this).attr('href');
                    $(this).attr('href', "" + help_loc);
            });
        //At the end of yout getHtml function:
        return htmlContentDOM.html()
        
    }

    public getUrlImage(img: string) {
        return imgUrls[img];
    }
        
    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
           var compList = ['library', 'courses', 'publishing', 'contact', 'quickguide'];
           if (params['component'] != null && compList.indexOf(params['component']) !== -1 ){
               this.component = params['component'];
           }
           else {
               this.component = "other";
           }
           var sectionsList = ['courses', 'exercises', 'arrangements', 'syntax'];
           if (params['section'] != null && sectionsList.indexOf(params['section']) !== -1 ){
               this.sectionid = params['section'];
           }
           else {
               this.sectionid = "courses";
           }
           if (params['id'] != null ){
               this.id = params['id']; 
           }
           else {
               this.id = 'intro';
           }
        });

    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
    
}