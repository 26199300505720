import { ToastrService } from 'ngx-toastr';
import { ErrorWrapper } from '../../authoring/types/navigator.types';
import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { renderMixedLatexStringToHTML } from '../../authoring/util/render-utils';

const DEFAULT_ERROR_MSG = "An error occurred";

/**
 * Extend services with this class if they need to be able to handle http errors.
 */
export abstract class HttpErrorHandler  {

    constructor(
        protected toastr: ToastrService
    ) { }

    /**
     * 
     * @param err An object containing some error information
     * @param showToast If true, show an error toast message
     */
    protected _serverError(err: any, showToast: boolean = false): never {
        console.error('server error:');  // debug
        console.error(err);
        if (err instanceof HttpErrorResponse) {
            let msg = this.getErrorMessage(err);
            let error = new Error(msg);
            (error as ErrorWrapper).originalError = err.error;
            if (showToast) {
                this.toastr.error(renderMixedLatexStringToHTML(error.message, false), "Backend server error", {enableHtml:true});
            }
            throw error;
        } else {
            let msg = this.getErrorMessage(err);
            if (showToast) {
                this.toastr.error(renderMixedLatexStringToHTML(msg), "Backend server error");
            }
            throw new Error(msg || 'Backend server error');
        }
    }

    /**
     * 
     * @param e Errors are not defined consistently so use this function to figure out the correct error message
     */
    protected getErrorMessage(err: any) {
        let message = null;

        function getInnerError(errorObj: any) {
            message = errorObj.message || errorObj.msg;
            if (message == null && errorObj.error instanceof String) {
                message = errorObj.error;
            }
            return message;
        }

        if (err.error instanceof Object && err.error) {
            message = getInnerError(err.error)
        }
        else {
            message = getInnerError(err)
        }

        return message != null ? message : DEFAULT_ERROR_MSG;
    }

}