
import {filter} from 'rxjs/operators';
import { Component, OnInit, ViewContainerRef, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

import { ToastrService } from 'ngx-toastr';

import { CmsSubject, NameIdPair, CmsSubjectRef, CmsContainerType, CmsMetadata, CmsItem, Asset } from '../../types/navigator.types';
import { TopicService } from '../../services/topic.service';
import { SubjectService } from '../../services/subject.service';
import { AssetService } from '../../services/asset.service';
import { BehaviorSubject } from 'rxjs';

const ERROR_MSG_DUPLICATE = "Metadata cannot contain duplicate names.";
const ERROR_MSG_EMPTY = "Metadata fields cannot be empty. Please fill in all empty fields or delete them.";

function getCommandOrCtrl() {
    return isMac()
        ? 'Cmd'
        : 'Ctrl'
}

function isMac() {
    let userAgent = navigator.userAgent;
    return userAgent && userAgent.indexOf("Mac") !== -1;
}

@Component({
    selector: 'akit-shortcuts',
    templateUrl: './akit-shortcuts.component.html'
})
export class AkitShortcutComponent {

    @Output() save: EventEmitter<void> = new EventEmitter<void>();
    @Output() run: EventEmitter<void> = new EventEmitter<void>();

    public static getSaveShortcut() {
        return `${getCommandOrCtrl()}+Alt+S`;
    }

    public static getRunShortCut() {
        return `${getCommandOrCtrl()}+Alt+R`;
    }

    shortcuts = [
        {
            key: ["cmd + alt + s"],
            preventDefault: true,
            command: (e: any) => {
                this.save.emit();
            }
        },
        {
            key: "ctrl + alt + s",
            preventDefault: true,
            command: (e: any) => {
                this.save.emit();
            }
        },
        {
            key: ["cmd + alt + r"],
            preventDefault: true,
            command: (e: any) => {
                this.run.emit();
            }
        },
        {
            key: "ctrl + alt + r",
            preventDefault: true,
            command: (e: any) => {
                this.run.emit();
            }
        }
    ];
}

