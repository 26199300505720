// password-validator.service.ts
import { Injectable } from '@angular/core';
import * as PasswordValidator from 'password-validator';

@Injectable({
    providedIn: 'root'
})
export class PasswordValidatorService {
    private passSchema = new PasswordValidator();

    constructor() {
        this.passSchema
            .is().min(8)
            .is().max(255)
            .has().uppercase()
            .has().lowercase()
            .has().digits()
            .has().symbols();
    }

    validate(password: string): string[] {
        const errors: string[] = [];

        let validation = this.passSchema.validate(password, { details: true })

        //consider if validation is empty
        if (!validation) {
            return errors;
        } else {

            //check if validation is an array
            if (!Array.isArray(validation)) {
                validation = [validation];
            }

            //validation is an array of objects, each object has a message property. add all messages to the errors array
            validation.forEach(v => {
                errors.push(" " + v.message.toLowerCase());
            });
        }

        return errors;
    }
}
