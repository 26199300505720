import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CmsSubject, CmsCourse, CmsExercise, CmsCourseAudience, CmsExerciseScript, CmsTopic } from '../../types/navigator.types';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ExerciseService } from '../../services/exercise.service';
import { CourseService } from '../../services/course.service';
import { SubjectService } from '../../services/subject.service';
import { BehaviorSubject } from 'rxjs';
import { getExtendedToastOption } from '../../../app/options/options';
import { IdNamePair } from '../../types/cms2-metadata.types';
import { ExerciseScriptService } from '../../services/exercise-script.service';
import { TopicService } from '../../services/topic.service';

declare let $: any;

/**
 * A topic-leaf is a 'file' in the navigation system, which corresponds to an
 * exercise. The model is defined by class of type CmsLeaf. Classes for the various 
 * types of exercises are derived from CmsLeaf
 */
@Component({
    selector: 'move-exercise-scripts',
    templateUrl: './move-exercise-scripts.component.html',
    styleUrls: ['./move-exercise-scripts.component.css']
})
export class MoveExerciseScriptsComponent {

    @Input() set selectedExerciseScripts(scripts: CmsExerciseScript[]) {
        this._selectedExerciseScripts.next(scripts);
    }

    _selectedExerciseScripts: BehaviorSubject<CmsExerciseScript[]> = new BehaviorSubject(null);

    @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSuccess: EventEmitter<void> = new EventEmitter<void>();

    missingAttrs: string[] = [];

    targetTopic: CmsTopic;

    refreshTimer: any;

    constructor(
        private toastr: ToastrService,
        private exerciseScriptService: ExerciseScriptService,
        private courseService: CourseService,
        private topicService: TopicService) {
    }

    canConfirm() {
        return this.targetTopic && this._selectedExerciseScripts.value
            && this.targetTopic.id !== this._selectedExerciseScripts.value[0].topic.id;
    }

    close() {
        this.onClose.emit();
    }

    commit() {
        this.exerciseScriptService.moveExerciseScripts(this._selectedExerciseScripts.value.map(e => e.id), this.targetTopic.id)
            .then(() => {
                let baseUrl = $('base').attr('href');
                let messageBody = `Exercise Script(s) moved to <a style="text-decoration: underline;" target="_blank" href="${baseUrl}/subject/${this.targetTopic.id}"><i><strong>${this.targetTopic.name}</strong></i></a>.`;

                this.toastr.toastrConfig.enableHtml = true;
                this.toastr.success(messageBody, 'Success', getExtendedToastOption());
                this.close();
                this.onSuccess.emit();
            })
            .catch((err: any) => {
                this.toastr.error(err.message, 'Error');
            });
    }

    setTopic(id: string): void {
        this.topicService.getTopic(id).toPromise()
            .then((topic: CmsTopic) => {
                this.targetTopic = topic;

                let sourceAttrs: string[] = [];
                if (this._selectedExerciseScripts.value) {
                    this._selectedExerciseScripts.value.forEach(ex => {
                        if (!ex.attributeAssignments) {
                            return;
                        }
                        sourceAttrs = sourceAttrs.concat(
                            ex.attributeAssignments
                                .filter(a => sourceAttrs.indexOf(a.attribute.name) === -1)
                                .map(a => a.attribute.name)
                        );
                    })
                    let targetAttributes = this.targetTopic.attributeAssignments.map(a => a.attribute.name);
                    this.missingAttrs = sourceAttrs.filter(source => targetAttributes.indexOf(source) === -1)
                }
                else {
                    this.missingAttrs = [];
                }
            })
            .catch(err => {
                console.log(err);
                this.toastr.error("Error occurred while retrieving subject", 'Error');
            });
    }

    init() {
        this.topicService.getTopic(this._selectedExerciseScripts.value[0].topic.id)
            .toPromise()
            .then(topic => {
                this.targetTopic = topic;
            });
    }

    ngOnInit() {
        this._selectedExerciseScripts.subscribe(() => this.init());
    }

}



