<div class='topic-navigator cms-navigator'>
    <h2>Search exercises by ID</h2>
    <exercise-reference-exporter [selectedExerciseScripts]="exportScripts" (onClose)="exporterClose()"
        *ngIf="referenceExporterVisible"></exercise-reference-exporter>
    <exercise-client-spec-exporter [selectedExerciseScripts]="exportScripts" (onClose)="exporterClose()"
        *ngIf="specExporterVisible"></exercise-client-spec-exporter>
    <div class="row">
        <div class="col-xs-12">
            <search-field #idSearchField (onSearchTextChanged)="onSearchTextChanged($event)" [manualSubmit]="true"
                [placeholder]="'Type ID here'"></search-field>
        </div>
    </div>
    <br />
    <ng-container *ngIf="searchResults && searchResults.length > 0">
        <div class="row" *ngIf="searchResults && searchResults.length > 0">
            <div class='col-xs-4'>

            </div>
            <div class='col-xs-2' *ngIf="!versionId">
                <strong>Location</strong>
            </div>
            <div class='col-xs-4' *ngIf="!versionId">
                <strong>Path</strong>
            </div>
            <div class='col-xs-2' *ngIf="!versionId">
                <strong>State</strong>
            </div>
        </div>

        <div>
            <id-search-leaf *ngFor="let item of searchResults" [item]="item" [itemActions]="getActions(item)" [enabled]="!isVersionMismatch(item.item)" [errorBadgeTitle]="getVersionMismatchError(item.item)">
            </id-search-leaf>
        </div>
    </ng-container>
    <ng-container *ngIf="(searchString && searchString.length > 0) && (!searchResults || searchResults.length == 0)">
        No exercises found.
    </ng-container>
</div>