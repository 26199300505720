// CMS metadata mirrors Algebrakit metadata more or less. We do not reuse the Algebrakit types as those types can evolve
// independent from the CMS content.
// So: algebrakit.IAlgebraExerciseSpec is similar to cms.IAlgebraExercise, but not the same

import {
    AudienceSpec, NameIdPair, EvaluationMode, PaletteType, RenderMode,
    TableTaskSpec, ArithmeticTemplate, QuestionMode,
    InstructionType, StepActionType, ElementType,
    LocalVariable, IExerciseReferenceType

} from '../../algebrakit/types/metadata.type';

import {
    ICMS18NContentMap
} from '../types/cms-metadata.types';

import { CmsExerciseClientSpec, CmsExerciseScript } from '../types/navigator.types';

// re-export types that are exactly the same
export {
    AudienceSpec, NameIdPair, EvaluationMode, PaletteType, QuestionMode, RenderMode,
    TableTaskSpec, InstructionType, ArithmeticTemplate, StepActionType,
    ElementType, IExerciseReferenceType,
}

export interface IdNamePair {
    id: string;                         //uuid of the interaction
    name: string;                       //name of the interaction
    lastValidName?: string;
    reference?: string;
    expr?: string;
}

export interface ScriptVariableSpec {
    name: string;
    type: ScriptVariableType;
    id: string;
    // if regular definition:
    definitionLatex?: string
    // if range
    min?: string;
    max?: string;
    // if rand select
    options?: string[];

    allowDuplicates?: boolean;
    nr?: number;

}

export enum ScriptVariableType {
    DEFINITION = 'DEFINITION',
    RANDOM_INT = 'RANDOM_INT',
    RANDOM_SELECT = 'RANDOM_SELECT'
}

/**
 * The exercise definition is not managed by the CMS, but passed as is to the widgets and backend.
 * We only define the properties here that the CMS frotned needs to read from the definition.
 */
export interface ICMS2ExerciseSpec {
    type: string;
    audience: string;
    course?: string; //For new audience mechanism: (baseAudience, course)
    questionMode: QuestionMode;
    elements: ICMS2ExerciseElement[]; //questions and text blocks
    definitions?: ICMS2MathData[];
    modes?: string | string[];
    scriptRef?: string;
    script?: string;
    scriptVariables?: ScriptVariableSpec[];
    translations?: ICMS18NContentMap
    localVars?: LocalVariable[];
    exerciseContext?: ICMS2ExerciseContext;
    didacticalConfigurations?: any;
}

export interface ICMS2ExerciseContext {
    properties: { [name: string]: ICMS2SymbolProperty[] };
    localVars: [];
}

export interface ICMS2SymbolProperty {
    type: string;
    synonym?: string;
    args?: string[];
}

export interface ICMSExerciseReference {
    id: string;
    type: IExerciseReferenceType;
    exerciseId: string;
    definition?: ICMS2ExerciseSpec;
    clientExercise?: CmsExerciseClientSpec;
    libraryExercise?: CmsExerciseScript;
}

export interface ICMS2ResourceSpec {
    name: string;
    id: string;
    expr: string;
    inline?: boolean;
}

export interface ICMS2InlineInteractionSpec {
    id: string;
    name: string;
    content: string;
    showHints?: boolean;
    interactions: ICMS2InteractionSpec[];
}

/**
 * Represents text blocks or questions in an exercise
 */
export interface ICMS2ExerciseElement {
    id: string;
    type: ElementType
    instructionType: InstructionType;
    content: string;
    instructionExpr?: string //required if InstructionType == KEYWORD_EXPRESSION
    mathRendering: RenderMode;
    exerciseReferences?: ICMSExerciseReference[]
    resources?: (ICMS2ResourceSpec | ICMS2ResourceRef)[];
    interactions?: (ICMS2InteractionSpec | ICMS2InlineInteractionSpec | ICMS2InteractionRef)[];
}

export enum WidgetType {
    AUTOMATIC,
    CALCBOOK = 'CALCBOOK'
}

export function isResource(r: ICMS2ResourceSpec | ICMS2ResourceRef): r is ICMS2ResourceSpec {
    if (!(r as ICMS2ResourceRef).referenceId) {
        return true;
    }
    return false;
}

export function isResourceReference(r: ICMS2ResourceSpec | ICMS2ResourceRef): r is ICMS2ResourceRef {
    if ((r as ICMS2ResourceRef).referenceId) {
        return true;
    }
    return false;
}

export function isInteraction(inter: (ICMS2InteractionSpec | ICMS2InlineInteractionSpec | ICMS2InteractionRef)): inter is ICMS2InteractionSpec {
    if ((inter as ICMS2InteractionSpec).ans) {
        return true
    }
    return false
}
export function isInlineInteraction(inter: (ICMS2InteractionSpec | ICMS2InlineInteractionSpec | ICMS2InteractionRef)): inter is ICMS2InlineInteractionSpec {
    if ((inter as ICMS2InlineInteractionSpec).interactions) {
        return true
    }
    return false
}
export function isInteractionReference(inter: (ICMS2InteractionSpec | ICMS2InlineInteractionSpec | ICMS2InteractionRef)): inter is ICMS2InteractionRef {
    if ((inter as ICMS2InteractionRef).referenceId) {
        return true
    }
    return false
}

export interface ICMS2InteractionRef {
    id: string;                     //format: referenceId_questionId_referenceResourceId
    name: string;
    referenceId: string;            //name of the reference (defined by author)
    referenceInteractionId: string; //uuid of interaction in the referenced exercise
}

export interface ICMS2ResourceRef {
    id: string;                     //format: referenceId_questionId_referenceResourceId
    name: string;
    referenceId: string;            //name of the reference (defined by author)
    referenceResourceId: string;    //uuid of resource in the referenced exercise
}

export interface ICMS2InteractionSpec {
    id: string;
    name: string;
    modes?: string | string[];
    contextHints?: string[];
    ans: ICMS2StepSpec;           //required answer step
    steps?: ICMS2StepSpec[];      //optional intermediate steps
    buggySteps?: ICMS2StepSpec[]; //optional strategic error steps
    showHints?: boolean;
    inline?: boolean;
    palette?: string | number;
    prefixLabel?: string;
    widgetType?: WidgetType; // widget is default determined from ans, but sometimes it must be prescribed. Eg for arithmetic wordproblems
    extendedInline?: boolean;
    disableCalculator?: boolean; // Used for calcbook to determine if interaction with (remote) calculator is allowed
}

export interface ICMS2StepSpec {
    id: string
    args?: string[];
    type?: string;
    description?: string;
    buggyStrategy?: boolean;
    parameter?: boolean;
    parameterExpression?: string;
    feedback?: string;
    action?: StepActionType;
    hidden?: boolean;
}

export enum CMS2MathType {
    STANDARD = "STANDARD", PARAMETER = "PARAMETER", QUANTITY = "QUANTITY"
}

export interface DefinitionUnit {
    name: string;
    units: string[];
}

export interface Mode {
    id: number;
    name: string;
    description: string;
    tags: string;
    internal: string;
    deprecated: string;
    insteadofdeprecated: string;
    "example-in": string;
    "example-out-1": string;
    "example-out-2": string;
}

export class SymbolSpec {
    name: string;
    type: string;
    latex: string;
    id?: string;
    synonyms?: SymbolSpec[];
    args?: string[];
}

export interface ICMS2MathData {
    id: string;
    name: string;
    type: MathDataType;
    data: any;
}

export enum MathDataType {
    DATASET = "DATASET",
}

export interface ReferenceItem {
    category: string;
    items: string[];
}
