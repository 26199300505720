
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/Rx';

import {
    CmsLeaf, CmsSubject,
    CmsExerciseFilter, CmsExerciseReference, CmsExerciseClientSpec, CmsAcceptedFilter,
    CmsAttributeAssignmentType,
    CmsExercise,
    CmsItemState,
    CmsVersioningInfo,
    VersionNumbers,
    RefConverter,
    CmsCourseRef,
    ExerciseSaveResult
} from '../types/navigator.types';
import { ExerciseScriptService } from './exercise-script.service';
import { AuthoringService } from './authoring.service';
import { ToastrService } from 'ngx-toastr';
import { PublishedExercise, PublishedExerciseReference, PublishedExerciseClientSpec, PublishedExerciseFilter, ExerciseFilterLevelSpecType } from '../types/published.types';
import { ConcurrencyService } from '../../app/services/concurrency.service';
import { TypeHelpers } from '../util/type-helpers';
import { HttpClient } from '@angular/common/http';
import { QuestionMode } from '../types/cms2-metadata.types';
import { AkitVersionsService } from '../../algebrakit/services/akit-versions.service';

const URL_BASE_EXERCISE = "api/exercises";
const URL_BASE_EXERCISE_FILTER = `${URL_BASE_EXERCISE}/filters/instance`;
const URL_BASE_EXERCISE_REFERENCE = `${URL_BASE_EXERCISE}/references/instance`;
const URL_BASE_EXERCISE_CLIENT_SPEC = `${URL_BASE_EXERCISE}/client-specifications`;
const URL_BASE_PUBLISHED_EXERCISE = `/api/publish/exercise`
const URL_BASE_LATEST_VERSION_NUMBERS = `/api/versioning/numbers/latest/by-ids`;
const URL_BASE_EXERCISE_TYPE_MAP = `${URL_BASE_EXERCISE}/get-exercise-filter-type-map`;

@Injectable()
export class ExerciseService extends AuthoringService {

    constructor(
        protected http: HttpClient,
        protected scriptService: ExerciseScriptService,
        protected toastr: ToastrService,
        protected concurrencyService: ConcurrencyService,
        protected akitVersionsService: AkitVersionsService
    ) {
        super(http, toastr, concurrencyService, akitVersionsService);
    }

    getCourseExercises(parent: CmsSubject): Observable<CmsLeaf[]> {
        return this.get(URL_BASE_EXERCISE + '/subject/' + parent.course.id + '/' + parent.id).pipe(
            map((arr: any[]) => arr.map(obj => this._getExerciseFilterFromJson(obj))),
            catchError(error => this._serverError(error)));

    }

    getAllApprovedExercisesInSubtree(subjectId: string): Promise<CmsExercise[]> {
        return this.get<CmsExercise[]>(URL_BASE_EXERCISE + '/subject-subtree-approved/' + subjectId).pipe(
            catchError(error => this._serverError(error)))
            .toPromise();
    }

    getExerciseFilter(id: string): Observable<CmsExerciseFilter> {
        return this.get(`${URL_BASE_EXERCISE_FILTER}/${id}`).pipe(
            map((obj: any) => this._getExerciseFilterFromJson(obj)),
            catchError(error => this._serverError(error)));
    }

    getExerciseReference(id: string): Observable<CmsExerciseReference> {
        return this.get(`${URL_BASE_EXERCISE_REFERENCE}/${id}`).pipe(
            map((obj: any) => this._getExerciseReferenceFromJson(obj)),
            catchError(error => this._serverError(error)));
    }

    getExerciseClientSpec(id: string): Observable<CmsExerciseClientSpec> {
        return this.get(`${URL_BASE_EXERCISE_CLIENT_SPEC}/instance/${id}`).pipe(
            map((obj: any) => this._getExerciseClientSpecFromJson(obj)),
            catchError(error => this._serverError(error)));
    }

    getExerciseClientSpecs(ids: string[]): Promise<CmsExerciseClientSpec[]> {
        return this.post<CmsExerciseClientSpec[]>(`${URL_BASE_EXERCISE_CLIENT_SPEC}/get-by-ids`, ids).pipe(
            map((resp) => resp.map(obj => this._getExerciseClientSpecFromJson(obj))))
            .toPromise()
            .catch(error => this._serverError(error));
    }

    storeExerciseFilter(exercise: CmsExerciseFilter): Promise<string> {
        let storeObj = this._getAmazonObjectFromCMSExerciseFilter(exercise);
        return this.post<string>(URL_BASE_EXERCISE_FILTER, storeObj, { responseType: 'text' })
            .toPromise()
            .catch(this._serverError.bind(this));
    }

    storeExerciseReference(exercise: CmsExerciseReference): Promise<string | any[]> {
        let storeObj = this._getAmazonObjectFromCMSExerciseReference(exercise);
        return this.post<string>(URL_BASE_EXERCISE_REFERENCE, storeObj, { responseType: 'text' })
            .toPromise()
            .catch(this._serverError.bind(this));
    }

    storeExerciseClientSpec(exercise: CmsExerciseClientSpec): Promise<ExerciseSaveResult> {
        let storeObj = this._getAmazonObjectFromCMSExerciseClientSpec(exercise);
        return this.post<ExerciseSaveResult>(URL_BASE_EXERCISE_CLIENT_SPEC + '/instance', storeObj, { responseType: 'json' })
            .toPromise()
            .catch(this._serverError.bind(this));
    }

    deleteExerciseFilter(id: string): Promise<boolean | any[]> {
        return this.delete(`${URL_BASE_EXERCISE_FILTER}/${id}`).pipe(
            map(() => true)).toPromise()
            .catch(this._serverError.bind(this));
    }

    deleteExerciseReference(id: string): Promise<boolean | any[]> {
        return this.delete(`${URL_BASE_EXERCISE_REFERENCE}/${id}`).pipe(
            map(() => true)).toPromise()
            .catch(this._serverError.bind(this));
    }

    deleteExerciseClientSpec(id: string): Promise<boolean | any[]> {
        return this.delete(`${URL_BASE_EXERCISE_CLIENT_SPEC}/instance/${id}`).pipe(
            map(() => true)).toPromise()
            .catch(this._serverError.bind(this));
    }

    deleteExercises(ids: string[]): Promise<boolean | any[]> {
        return this.post(`${URL_BASE_EXERCISE}/delete-all`, ids).pipe(
            map(() => true)).toPromise()
            .catch(this._serverError.bind(this));
    }

    getExerciseFilterTypeMap(ids: string[]): Promise<{ [id: string]: ExerciseFilterLevelSpecType }> {
        return this.post<{ [id: string]: ExerciseFilterLevelSpecType }>(`${URL_BASE_EXERCISE_TYPE_MAP}/delete-all`, ids)
            .toPromise()
            .catch(this._serverError.bind(this)) as Promise<{ [id: string]: ExerciseFilterLevelSpecType }>;
    }

    copyExercise(id: string) {
        return this.post(`${URL_BASE_EXERCISE}/copy/${id}`, {}, { responseType: 'text' })
            .toPromise()
            .catch(this._serverError.bind(this));
    }

    copyExerciseTo(exerciseId: string, subjectId: string, newName: string) {
        return this.post(`${URL_BASE_EXERCISE}/copy-exercise-to`, { exerciseId: exerciseId, subjectId: subjectId, newName: newName }, { responseType: 'text' })
            .toPromise()
            .catch(this._serverError.bind(this));
    }

    copyExercises(ids: string[]) {
        return this.post(`${URL_BASE_EXERCISE}/copy`, ids,  {responseType:'text'})
            .toPromise()
            .catch(this._serverError.bind(this));
    }

    createExerciseFilter(subject: CmsSubject, course: CmsCourseRef): CmsExerciseFilter {
        return {
            id: null,
            course: course,
            name: 'New exercise arrangement',
            numberOfLevels: 1,
            subject: RefConverter.toSubjectRef(subject),
            topicFilters: [],
            exercisesPerLevel: [{
                level: 1,
                scripts: []
            }],
            audience: subject.defaultAudience
        }
    }

    createExerciseReference(subject: CmsSubject, course: CmsCourseRef): CmsExerciseReference {
        return {
            id: null,
            course: course,
            name: 'New exercise reference',
            subject: RefConverter.toSubjectRef(subject),
            exerciseScript: null,
            audience: subject.defaultAudience
        }
    }

    createExerciseClientSpec(subject: CmsSubject, course: CmsCourseRef): CmsExerciseClientSpec {
        return {
            id: null,
            course: course,
            name: 'New interaction exercise',
            subject: RefConverter.toSubjectRef(subject),
            definition: {
                type: 'EXERCISE',
                audience: null,
                questionMode: QuestionMode.ONE_BY_ONE,
                elements: []
            },
            audience: subject.defaultAudience
        }
    }

    exportReferences(scriptIds: string[], subject: CmsSubject, audience: string, title: string = "", name: string = "", keepIds: boolean = false): Promise<void> {
        let storeObj = {
            scriptIds: scriptIds,
            subject: subject,
            audience: audience,
            keepIds: keepIds,
            title: title,
            name: name
        }
        return this.post<void>(URL_BASE_EXERCISE + "/reference-export", storeObj).toPromise();
    }

    exportClientSpecs(scriptIds: string[], subject: CmsSubject, audience: string, title: string = "", name: string = "", keepIds: boolean = false): Promise<void> {
        let storeObj = {
            scriptIds: scriptIds,
            subject: subject,
            audience: audience,
            keepIds: keepIds,
            title: title,
            name: name
        }
        return this.post<void>(URL_BASE_EXERCISE + "/client-spec-export", storeObj).toPromise();
    }

    moveExercise(exerciseIds: string[], subjectId: string, audience?: string) {
        console.log("move ", exerciseIds, " to folder ", subjectId, " with audience ", audience);
        return this.post(URL_BASE_EXERCISE + "/move", {
            exerciseIds: exerciseIds,
            subjectId: subjectId,
            audience: audience
        }).toPromise()
            .catch(error => this._serverError(error));
    }

    getLastAcceptedVersion(exerciseId: string, courseId: string): Observable<CmsAcceptedFilter> {
        return this.get<CmsAcceptedFilter>(`${URL_BASE_EXERCISE}/filters/accepted-version/${courseId}/${exerciseId}`).pipe(
            catchError(error => this._serverError(error)));
    }

    publish(exercise: CmsExercise, versionInfo: CmsVersioningInfo, lastPublishedVersion: PublishedExercise) {
        if (exercise.state !== CmsItemState.APPROVED) {
            this.toastr.error('Exercise is not approved.');
            return;
        }
        let currentMajor = versionInfo ? versionInfo.majorVersion : 1;
        if (currentMajor == null) {
            this.toastr.error('An error occurred when trying to get the current major version number.', 'Error');
            return;
        }
        let publishedMajor = lastPublishedVersion ? (lastPublishedVersion.majorVersion || 0) : 0;
        let msg = 'You are about to publish this exercise'
        if (publishedMajor === 0) {
            msg += ` for the first time. Are you sure?`;
        } else if (publishedMajor === currentMajor) {
            msg += `. Only minor updates have been performed on this exercise since the last publish. The exercise will be published under the same version number as last publish`;
        } else if (publishedMajor < currentMajor) {
            msg += `. One or more major updates have been performed on this exercise since the last publish. The exercise will be published under version ${currentMajor}`;
        } else {
            this.toastr.error('The current version number and published version number have incorrect values.');
            return;
        }
        if (confirm(msg)) {
            return this.doPublish(exercise.id)
                .then((pubEx) => {
                    this.toastr.success(`Published exercise with id=[${exercise.id}] under major version ${currentMajor}.`);
                    return pubEx;
                })
                .catch(error => {
                    let message = error.message || (error ? error.message : null) || "Error occurred while publishing the exercise";
                    this.toastr.error(message, 'Error');
                })
        }
        return Promise.resolve(null);
    }

    doPublish(id: string): Promise<PublishedExercise> {
        return this.post(`${URL_BASE_PUBLISHED_EXERCISE}/${id}`, {}).pipe(
            map(resp => this._getPublishedExerciseFromJson(resp)))
            .toPromise();
    }

    getPublishedReference(id: string, majorVersion?: number): Promise<PublishedExerciseReference> {
        let url = majorVersion
            ? `${URL_BASE_PUBLISHED_EXERCISE}/reference/${id}/${majorVersion}`
            : `${URL_BASE_PUBLISHED_EXERCISE}/reference/${id}`
        return this.get(url).pipe(
            map(resp => this._getPublishedExerciseFromJson(resp) as PublishedExerciseReference))
            .toPromise();
    }

    getPublishedSpecification(id: string, majorVersion?: number): Promise<PublishedExerciseClientSpec> {
        let url = majorVersion
            ? `${URL_BASE_PUBLISHED_EXERCISE}/specification/${id}/${majorVersion}`
            : `${URL_BASE_PUBLISHED_EXERCISE}/specification/${id}`
        return this.get(url).pipe(
            map(resp => {
                if (resp == null || resp == "") {
                    return null;
                }
                return this._getPublishedExerciseFromJson(resp) as PublishedExerciseClientSpec
            }))
            .toPromise();
    }

    getPublishedFilter(id: string, majorVersion?: number): Promise<PublishedExerciseFilter> {
        let url = majorVersion
            ? `${URL_BASE_PUBLISHED_EXERCISE}/filter/${id}/${majorVersion}`
            : `${URL_BASE_PUBLISHED_EXERCISE}/filter/${id}`
        return this.get(url).pipe(
            map(resp => this._getPublishedExerciseFromJson(resp) as PublishedExerciseFilter))
            .toPromise();
    }

    getLatestVersionNumbers(exercises: CmsExercise[]): Promise<{ [id: string]: VersionNumbers }> {
        if (!exercises || exercises.length == 0) {
            return Promise.resolve({});
        }
        let ids: string[] = exercises
            .filter(ex => ex.state == CmsItemState.APPROVED)
            .map(ex => ex.id);
        if (ids.length === 0) {
            return Promise.resolve({});
        }
        return this.post<{ [id: string]: VersionNumbers }>(URL_BASE_LATEST_VERSION_NUMBERS, ids)
            .toPromise();
    }



    private _getExerciseFilterFromJson(obj: any): CmsExerciseFilter {
        for (let goalId = 0; goalId < obj.topicFilters.length; goalId++) {
            for (let level = 0; level < obj.topicFilters[goalId].levels.length; level++) {
                let attrArr = obj.topicFilters[goalId].levels[level];
                for (let ii = 0; ii < attrArr.attributeAssignments.length; ii++) {
                    const key = attrArr.attributeAssignments[ii].attributeAssignmentType as keyof typeof CmsAttributeAssignmentType;
                    attrArr.attributeAssignments[ii].attributeAssignmentType = CmsAttributeAssignmentType[key];
                }
            }
        }
        obj.folderId = obj.subjectId;
        delete obj.subjectId;
        let result: CmsExerciseFilter = {
            ...obj,
        }

        return result;
    }

    private _getExerciseReferenceFromJson(obj: any): CmsExerciseReference {
        obj.folderId = obj.subjectId;
        delete obj.subjectId;
        let result: CmsExerciseReference = {
            ...obj,
        }

        return result;
    }

    private _getExerciseClientSpecFromJson(obj: any): CmsExerciseClientSpec {
        obj.folderId = obj.subjectId;
        delete obj.subjectId;
        let definition = obj.definition;
        let result: CmsExerciseClientSpec = {
            ...obj,
            definition: definition
        }
        return result;
    }

    private _getAmazonObjectFromCMSExerciseFilter(exercise: CmsExerciseFilter): any {
        let obj = {
            ...exercise,
            subject: exercise.subject,
        } as any;
        for (let topicFilter of obj.topicFilters) {
            for (let levelSpec of topicFilter.levels) {
                for (let ii = 0; ii < levelSpec.length; ii++) {
                    const key = levelSpec[ii].type as keyof typeof CmsAttributeAssignmentType;
                    levelSpec[ii].type = CmsAttributeAssignmentType[key];
                }
            }
        }
        delete obj.folderId;
        obj = TypeHelpers._sanitizeJsonForAws(obj);
        return obj;
    }

    private _getAmazonObjectFromCMSExerciseReference(exercise: CmsExerciseReference): any {
        let obj = {
            ...exercise,
            subject: exercise.subject,
        } as any;
        delete obj.folderId;
        obj = TypeHelpers._sanitizeJsonForAws(obj);
        return obj;
    }

    private _getAmazonObjectFromCMSExerciseClientSpec(exercise: CmsExerciseClientSpec): any {
        let obj = {
            ...exercise,
            subject: exercise.subject,
            definition: exercise.definition
        } as any;
        delete obj.folderId;
        obj = TypeHelpers._sanitizeJsonForAws(obj);
        return obj;
    }

    _getPublishedExerciseFromJson(obj: any): PublishedExercise {
        if (obj == null) {
            return obj;
        }
        let result = {
            ...obj,
        }
        if (obj.definition) {
            result.definition = JSON.parse(obj.definition)
        }
        return result as PublishedExercise;
    }

}