import { Component, OnInit, OnDestroy }  from '@angular/core';
import { Subscription }                  from 'rxjs';
import { IErrorState }                      from '../../types/algebrakit.type';
import { SessionService }                   from '../../services/session.service';
import 'rxjs/Rx';

declare let $:any;

@Component({
  selector: 'akit-error',
  templateUrl: './akit-error.component.html',
  styleUrls: ['./akit-error.component.css']
})
export class AkitErrorComponent implements OnInit, OnDestroy {
    errorState: IErrorState;
    subscriptions: Subscription[] = [];
    constructor(private sessionService: SessionService) {}

    showDetails() {
        setTimeout(() => {
            var modal = $('.akit-error-details').first();
            modal.modal('show');
        },0)
    }
    clear() {
        this.errorState = null;
        var modal = $('.akit-error-details').first();
        modal.modal('hide');
    }
    
    ngOnInit() {
        let s = this.sessionService.errorState$.subscribe(error => {
            this.errorState = error;
        });
        this.subscriptions.push(s);
    }
    
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
    
}
