import { Component, Input } from "@angular/core";
import {IDebugInformation } from '../../types/cms-metadata.types';

@Component({
    selector: 'debug-view',
    templateUrl: './debug-view.component.html',
    styleUrls: ['./debug-view.component.css']
})
export class DebugViewComponent {

    @Input() debug: IDebugInformation;

    visibleBuckets() {
        return this.debug.buckets.filter(b => b.description!='Interpretations');
    }
}