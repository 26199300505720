import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { generate } from 'generate-password-browser'
import { UserService } from '../../../security/services/user.service';
import { ClipboardService } from 'ngx-clipboard';
import { User } from '../../types/user.type';
import { PasswordValidatorService } from '../../services/password-validator.service';

@Component({
    templateUrl: './activate-user.component.html',
    styleUrls: ['./activate-user.component.less', '../../../../assets/shared.less']
})
export class ActivateUserComponent implements OnInit {
    user: User;

    oldValues: any;
    form: FormGroup;

    emailCtrl = new FormControl('', [Validators.required, Validators.email]);
    firstNameCtrl = new FormControl('', Validators.required);
    surnameCtrl = new FormControl('', Validators.required);
    passwordCtrl = new FormControl('', Validators.required);
    repeatPasswordCtrl = new FormControl('', Validators.required);
    agreeToPolicyCtrl = new FormControl('', Validators.required)

    generatedPassword = "";

    permissions: string[] = [];

    token: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private toastr: ToastrService,
        private userService: UserService,
        private clipboardService: ClipboardService,
        private passwordValidatorService: PasswordValidatorService
    ) {
    }

    cancel() {
        this.location.back();
    }

    passWordValidatorMethod(formgroup: FormGroup) {
        let passwordCtrl = formgroup.controls['password'];
        let repeatPasswordCtrl = formgroup.controls['repeatPassword'];
        return this.validatePasswordCtrls(passwordCtrl, repeatPasswordCtrl);
    }

    validatePasswordCtrls(passwordCtrl: AbstractControl, repeatPasswordCtrl: AbstractControl) {
        const errors = this.passwordValidatorService.validate(passwordCtrl.value);
        if (errors.length) {
            passwordCtrl.setErrors({ invalidPassword: errors });
            return { invalidPassword: errors };
        }
        if (passwordCtrl.value !== repeatPasswordCtrl.value) {
            repeatPasswordCtrl.setErrors({ passwordsDontMatch: true });
            return { passwordsDontMatch: true };
        }
        return null;
    }

onSubmit() {
        let email = this.emailCtrl.value.trim();
        let firstName = this.firstNameCtrl.value;
        let surname = this.surnameCtrl.value;
        let pwValue = this.passwordCtrl.value.trim();
        let agreeToPolicy = this.agreeToPolicyCtrl.value;

        let input: any = {
            registerToken: this.token,
            email: email,
            firstName: firstName,
            surname: surname,
            password: pwValue && pwValue.length > 0 ? pwValue : null,
            agreeToPolicy: agreeToPolicy
        };

        this.userService.activate(input).then((response: any) => {
            this.toastr.success(`Your account has been successfully activated.`, "Success")
            this.userService.login(email, pwValue).then(() => {
                this.router.navigate(['/']);
            });
        }).catch((error: any) => {
            let message = this.getErrorMessage(error);
            this.toastr.error(message, "Error")
        })
    }
    getErrorMessage(error: any) {
        let errorObj;
        try {
            errorObj = error;
        }
        catch (e) {
            errorObj = error;
        }
        return errorObj.message || errorObj.msg || "An error occurred";
    }


    ngOnInit() {
        this.form = this.formBuilder.group({
            email: this.emailCtrl,
            firstName: this.firstNameCtrl,
            surname: this.surnameCtrl,
            password: this.passwordCtrl,
            repeatPassword: this.repeatPasswordCtrl,
            agreeToPolicy: this.agreeToPolicyCtrl
        });

        this.emailCtrl.disable();

        this.route.params.subscribe(params => {
            let fullToken = atob(params['token']);
            this.token = fullToken.substr(fullToken.lastIndexOf('.') + 1);
            this.emailCtrl.setValue(fullToken.substr(0, fullToken.lastIndexOf('.')))
        });

        this.form.setValidators(this.passWordValidatorMethod.bind(this));
    }



    generatePassword() {
        this.generatedPassword = this.generatePasswordString();
        this.passwordCtrl.setValue(this.generatedPassword);
        this.repeatPasswordCtrl.setValue(this.generatedPassword);
    }
    generatePasswordString() {

        return generate({
            length: 12,
            numbers: true,
            symbols: true,
            strict: true
        });
    }

    copyPassword() {
        this.clipboardService.copyFromContent(this.generatedPassword);
        this.toastr.info("Password was copied to clipboard");
        this.generatedPassword = "";
    }

    formChanged() {
        for (var key in this.oldValues) {
            if (this.oldValues.hasOwnProperty(key)) {
                if (this.oldValues[key] !== this.form.value[key]) {
                    return true;
                }
            }
        }
        return false;
    }
}
