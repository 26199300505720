import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteReuseStrategy, DetachedRouteHandle, ActivatedRouteSnapshot } from '@angular/router';
import { compareVersion } from '../../authoring/util/sorting';
import { UserService } from '../../security/services/user.service';
import { IProfileSettings, ProfileKey, ProfileSettings } from './profile.settings';
import { ToastrService } from 'ngx-toastr';
import { AkitVersionsService } from '../../algebrakit/services/akit-versions.service';
import { HttpClientService } from './http-client.service';

// Save the state of routes which have that attribute data: {sticky: true}
@Injectable()
export class AppProfileService extends HttpClientService {

    private profile: string = null;
    private settings: ProfileSettings;
    private managementConsoleUrl: string;

    constructor(
        protected http: HttpClient,
        protected toastr: ToastrService,
        protected akitVersionsService: AkitVersionsService,
    ) {
        super(http, toastr, akitVersionsService);
    }

    public getManagementConsoleUrl(): Promise<string> {
        return this.managementConsoleUrl != null
            ? Promise.resolve(this.managementConsoleUrl)
            : this.get('connect/management-console-url/',  {responseType:'text'})
            .toPromise()
            .then((url: string) => {
                this.managementConsoleUrl = url;
                return url;
            })
    }

    public getProfile(): Promise<string> {
        return this.profile
            ? Promise.resolve(this.profile)
            : this.get('connect/app-profile/',  {responseType:'text'})
                .toPromise()
                .then((profile: ProfileKey) => {
                    this.profile = profile;
                    this.settings = new ProfileSettings(profile);
                    return profile
                })
    }

    public getSettings(): Promise<ProfileSettings> {
        if (!this.settings) {
            return this.getProfile()
                .then(() => this.settings);
        }
        return Promise.resolve(this.settings);
    }

    public getSetting(key: keyof ProfileSettings): Promise<any> {
        return this.getSettings()
            .then((settings) => settings[key]);
    }
}