import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * Class to define img HELP item selector in overview help page for authoring environment
 */

@Component({
  selector: 'helpitem-img',
  templateUrl: './helpitem-img.component.html',
  styleUrls: ['./helpitem-img.component.css']
}) 
export class HelpItemImgComponent {
    @Input() itemClass: string;
    @Input() selectedClass: boolean;
    @Input() faq: boolean;
}

