import { Injectable }   from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { CourseService } from './course.service';
import { CmsCourse } from '../types/navigator.types';
import { AkitVersionsService } from '../../algebrakit/services/akit-versions.service';

@Injectable()
export class CourseResolverService implements Resolve<CmsCourse> {
    constructor(private courseService: CourseService, private akitVersionsService: AkitVersionsService) {}
    
    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<CmsCourse> {
        let courseId = route.params['id'];
        const course = await this.courseService.getCourse(courseId).toPromise();
        if (route.data.matchVersion && course) {
            await this.akitVersionsService.changeVersionIfRequired(course.options.akitVersion, route);
        }
        return course;
    }
}


