export enum ValidationStatus {
    PASSED = 'PASSED',
    FAILED = 'FAILED',
    UNAVAILABLE = 'UNAVAILABLE'
}

export enum ValidationProperty {
    runsSuccessfully = 'runsSuccessfully',
    symbolsConfirmed = 'symbolsConfirmed'
}

/**
 * Validation report as it arrives from the server
 */
export interface IValidationReport {
    exerciseHash: string; //Hash of the definition the report was created for
    validationResults: { [key in ValidationProperty]?: ValidationStatus };
}

export class ValidationReport {
    exerciseHash: string; 

    validationResults: { [key in ValidationProperty]?: ValidationStatus };

    constructor(report: IValidationReport) {
        this.exerciseHash = report.exerciseHash;
        this.validationResults = report.validationResults;
    }

    get itemValidationStatus(): ValidationStatus {
        if (this.validationResults) {
            let statusList = Object.values(this.validationResults);
            if (statusList.every(status => status === ValidationStatus.PASSED)) {
                return ValidationStatus.PASSED;
            }
            else if (statusList.every(status => status === ValidationStatus.UNAVAILABLE)) {
                return ValidationStatus.UNAVAILABLE;
            }
            else if (statusList.includes(ValidationStatus.FAILED)) {
                return ValidationStatus.FAILED;
            }
        }
        return null;
    }

    get numberOfPassed() {
        return Object.values(this.validationResults).filter(status => status === ValidationStatus.PASSED).length;
    }

    get numberOfFailed() {
        return Object.values(this.validationResults).filter(status => status === ValidationStatus.FAILED).length;
    }

    get numberOfUnavailable() {
        return Object.values(this.validationResults).filter(status => status === ValidationStatus.UNAVAILABLE).length;
    }

    getValidationReportForUser(form: 'short' | 'long'): ValidationUserReportEntry[] {
        const report: ValidationUserReportEntry[] = [];
        if (this.itemValidationStatus !== null) {
            for (let key of Object.keys(this.validationResults)) {
                let result: ValidationStatus = this.validationResults[key];
                let title: string;
                if (form === 'short') {
                    title = reportKeyToUserFriendlyTextShort[key as ValidationProperty];
                }
                else {
                    title = reportKeyToUserFriendlyTextLong[key as ValidationProperty][result];
                }
                report.push({
                    title: title,
                    status: result
                })
            }
        }
        return report;
    }
}

type ValidationReportTexts = {
    [key in ValidationStatus]: string;
};

export const reportKeyToUserFriendlyTextShort: { [key in ValidationProperty]: string } = {
    'runsSuccessfully': 'Runs successfully',
    'symbolsConfirmed': 'Symbols confirmed'
}

export const reportKeyToUserFriendlyTextLong: { [key in ValidationProperty]: ValidationReportTexts } = {
    'runsSuccessfully': { 
        PASSED: 'Preliminary checks passed', 
        FAILED: "Preliminary checks failed",
        UNAVAILABLE: "Unable to determine result of preliminary checks"
    },
    'symbolsConfirmed': { 
        PASSED: 'The exercise does not have unconfirmed symbols', 
        FAILED: "The exercise has unconfirmed symbols",
        UNAVAILABLE: "Unable to determine if the exercise has unconfirmed symbols"
    },
}

export interface ValidationUserReportEntry {
    title: string;
    status: ValidationStatus;
}