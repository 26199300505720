<div class="akit-error" *ngIf="errorState">
  <div class="error-bar">
      <a class="akit-error-button">
          <span class="fa fa-lg fa-exclamation-triangle" (click)="showDetails()"></span>
      </a>
      <a class="clear-button" (click)="clear()">
          <span class="fa fa-lg fa-times"></span>
      </a>
  </div>
  <!-- modal to show demo of exerise -->
  <div class="modal fade akit-error-details">
      <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 class="modal-title">Error details</h4>
              </div>
              <div class="modal-body">
                  <div [innerHTML]="errorState.stack"></div>
              </div>
              <div class="modal-footer">
                  <button class="btn-primary btn" (click)="clear()">Clear</button>
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
          </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->
</div>