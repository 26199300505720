import { Component } from '@angular/core';
import { UserService } from '../../../security/services/user.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

declare let AKIT_CMS_PROFILE: string;

@Component({
  templateUrl: './app-home.component.html',
  styleUrls: ['./app-home.component.less']
})
export class AppHomeComponent {
    isTest: boolean = (typeof(AKIT_CMS_PROFILE)==='string' && (AKIT_CMS_PROFILE==='test' || AKIT_CMS_PROFILE==='staging_detached'));
    form: FormGroup;
    
    canEditTopicsScripts() {
        return this.userService.canEditLibrary();
    }
    
    public showAdminComp() {
        return this.canEditTopicsScripts();
    }

    constructor(
        private userService: UserService,
        private formBuilder: FormBuilder,
        private router: Router,
    ) {
        this.form = this.formBuilder.group({
            exerciseID: new FormControl("")
        });
    }

    search() {
        let id = this.form.get('exerciseID').value;
        if(id) {
            this.router.navigate(['id-search', id])
        }
    }
}

