import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TopicData } from '../../types/cms-metadata.types';
import { CmsTopicAttributeAssignment, CmsExerciseScript, CmsExerciseFilterLevelSpec, CmsExerciseClientSpec, CmsCourseRef } from '../../types/navigator.types';
import { TopicService } from '../../services/topic.service';
import { UserService } from '../../../security/services/user.service';
import { compareVersion } from '../../util/sorting';

/**
 * A topic-leaf is a 'file' in the navigation system, which corresponds to an
 * exercise. The model is defined by class of type CmsLeaf. Classes for the various 
 * types of exercises are derived from CmsLeaf
 */
@Component({
    selector: 'exercise-script-browser',
    templateUrl: './exercise-script-browser.component.html',
    styleUrls: ['./exercise-script-browser.component.less']
})
export class ExerciseScriptBrowserComponent {

    @Input() courseRef: CmsCourseRef;
    @Input() level: number;
    @Input() currentVersion: string;

    _favouriteTopics: TopicData[] = [];

    @Input() set favouriteTopics(data: TopicData[]) {
        this._favouriteTopics = data.sort(function (a, b) {
            return a.topic.name.localeCompare(b.topic.name, 'en');
        })
        //Remove exercises with blacklisted interactions
        for (let topic of this._favouriteTopics) {
            topic.exercisesAKIT = topic.exercisesAKIT.filter(ex =>
                !ex.interactionTypes || !this.interactionBlacklist || ex.interactionTypes.every(type => this.interactionBlacklist.indexOf(type) === -1)
            );
        }
    }

    @Input() interactionBlacklist: string[] = [];

    @Output() cancel: EventEmitter<void> = new EventEmitter();
    @Output() exerciseClicked: EventEmitter<CmsExerciseScript> = new EventEmitter();
    @Output() add: EventEmitter<CmsExerciseFilterLevelSpec> = new EventEmitter();
    @Output() selectTopics: EventEmitter<void> = new EventEmitter();

    selectedScriptsLib: CmsExerciseScript[] = [];
    selectedExercisesCourse: CmsExerciseClientSpec[] = [];
    selectedScriptsTopics: { [id: string]: CmsExerciseScript } = {};
    selectedScripts: { [id: string]: CmsExerciseScript } = {};

    showDeprecated: boolean = false;

    constructor(private topicService: TopicService, private userService: UserService) {

    }

    getColorForTopicAttribute(attribute: CmsTopicAttributeAssignment): { [key: string]: string } {
        return this.topicService.getAttributeColorBg(attribute.difficulty);
    };

    getColorForScriptAttribute(attribute: CmsTopicAttributeAssignment, script: CmsExerciseScript): { [key: string]: string } {
        let result = {};
        if (script.attributeAssignments && script.attributeAssignments.find(attr => attr.id === attribute.id)) {
            result = this.topicService.getAttributeColorBg(attribute.difficulty);
            if (attribute.difficulty.complexity > 4) result['color'] = 'white';
        }
        return result;
    };

    selectedTopicExerciseChanged(script: CmsExerciseScript, e: any) {
        let checked = e.target.checked;
        if (checked && !this.selectedScriptsTopics[script.id]) {
            this.selectedScriptsTopics[script.id] = script;
        }
        else if (!checked && this.selectedScriptsTopics[script.id]) {
            delete this.selectedScriptsTopics[script.id];
        }
        this.setSelectedScripts();
    }

    selectedLibraryExerciseChanged(scripts: CmsExerciseScript[]) {
        this.selectedScriptsLib = scripts;
        this.setSelectedScripts();
    }

    selectedCourseExerciseChanged(exercises: CmsExerciseClientSpec[]) {
        this.selectedExercisesCourse = exercises;
    }

    canEditLibrary() {
        return this.userService.canEditLibrary();
    }

    showScript(script: CmsExerciseScript) {
        if (this.showDeprecated) {
            return true;
        }
        return script.state !== "DEPRECATED";
    }

    setSelectedScripts() {
        this.selectedScripts = { ...this.selectedScriptsTopics };
        for (let script of this.selectedScriptsLib) {
            if (this.selectedScripts[script.id]) {
                continue;
            }
            this.selectedScripts[script.id] = script;
        }
    }

    topicScriptClicked(script: CmsExerciseScript) {
        if (this.isVersionMismatch(script)) {
            return;
        }
        this.exerciseClicked.emit(script)
    }

    exercisesAreSelected() {
        return Object.keys(this.selectedScripts).length > 0 || this.selectedExercisesCourse.length > 0;
    }

    getVersionMismatchError(exercise: CmsExerciseScript) {
        return this.isVersionMismatch(exercise)
            ? "This exercise was edited in a newer version and cannot be added to this arrangement"
            : null;
    }

    isVersionMismatch(exercise: CmsExerciseScript) {
        return compareVersion(this.currentVersion, exercise.contentVersion) < 0
    }

    addSelection() {
        let scripts: CmsExerciseScript[] = Object.keys(this.selectedScripts).map(id => this.selectedScripts[id]);
        let event: CmsExerciseFilterLevelSpec = {
            level: this.level,
            scripts: scripts.map(s => {
                let majorVersion = s.latestVersionNumbers
                    ? s.latestVersionNumbers.publishedMajorVersion
                    : s.publishedMajorVersion;
                return {
                    id: s.id,
                    topicId: s.topic.id,
                    majorVersion: majorVersion
                }
            }),
            courseExercises: this.selectedExercisesCourse.map(e => {
                let majorVersion = e.latestVersionNumbers
                    ? e.latestVersionNumbers.publishedMajorVersion
                    : e.publishedMajorVersion;
                return {
                    id: e.id,
                    topicId: e.subject.id,
                    majorVersion: majorVersion
                }
            })
        }
        this.add.emit(event)
    }

}



