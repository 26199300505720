<div>
    <ng-container *ngIf="testRefs && testRefs.length > 0 else noTests">
        <div class="row list-row">
            <div class="col-xs-1"></div>
            <div class="col-xs-4 list-entry"><strong>Description</strong></div>
            <div class="col-xs-1 list-entry"><strong>Testcase</strong></div>
            <div class="col-xs-2 list-entry"><strong>Created</strong></div>
            <div class="col-xs-2 list-entry"><strong>Last Update</strong></div>
            <div class="col-xs-2 list-entry"><strong>Created by</strong></div>
        </div>
        <div *ngFor="let testRef of sortRefs(testRefs)" class="row list-row">
            <div class="col-xs-1">
                <div class="btn-group">
                    <button class="btn btn-default btn-xs" (click)="playTest(testRef)"><span
                            class="fa fa-play"></span></button>
                    <button class="btn btn-default btn-xs" (click)="deleteTest(testRef)"><span
                            class="fa fa-times"></span></button>
                </div>
            </div>
            <div class="col-xs-4 list-entry">
                {{testRef.description}}
            </div>
            <div class="col-xs-1 list-entry">
                <span *ngIf="!canUpdateTestCase && testRef.isTestCase" class="fa fa-check"></span>
                <label *ngIf="canUpdateTestCase">
                    <input type="checkbox" [checked]="testRef.isTestCase" (change)="updateTestCase(testRef, $event)">
                </label>
            </div>
            <div class="col-xs-2 list-entry">{{testRef.created ? (testRef.created | date:'medium') : "-"}}</div>
            <div class="col-xs-2 list-entry">{{testRef.timestamp | date:'medium'}}</div>
            <div class="col-xs-2 list-entry">{{testRef.userId}}</div>
        </div>
    </ng-container>
    <ng-template #noTests>
        <div class="row list-row">
            <div class="col-xs-12">No saved test runs</div>
        </div>
    </ng-template>
    <ng-containter *ngIf="testSessionViewData || testResultSessionId">
        <div class="row margin-top">
            <div class="col-xs-12">
                <button class="btn btn-default btn-xs" (click)="toggleTestCollapse()"><span
                        [ngClass]="{ fa: true, 'fa-expand': !testCollapsed, 'fa-compress': testCollapsed}"></span></button>
            </div>
        </div>
        <ng-container *ngIf="testCollapsed">
            <div class="row margin-top" *ngIf="canCompare">
                <div class="col-xs-12 col-sm-6" *ngIf="testSessionViewData">
                    <strong>Algebrakit dated {{testSessionDate | date:'d MMMM y HH:mm'}}</strong>
                </div>
                <div class="col-xs-12 col-sm-6" *ngIf="testResultSessionId">
                    <strong>Current Algebrakit version</strong>
                </div>
            </div>
            <div class="row margin-top">
                <div [ngClass]="{'col-xs-12': true, 'col-sm-6': canCompare}" *ngIf="testSessionViewData">
                    <cms-interaction [html]="testSessionViewData.html"></cms-interaction>
                </div>
                <div class="col-xs-12 col-sm-6" *ngIf="canCompare && testResultSessionId">
                    <cms-exercise [sessionId]="testResultSessionId" [reviewMode]="true"></cms-exercise>
                </div>
            </div>
            <div class="row margin-top" *ngIf="canCompare && testResultLog && testResultLog.length > 0">
                <pre class="test-results-log" [innerHTML]="testResultLog"></pre>
            </div>
            <div class="row margin-top"
                *ngIf="canCompare && canUpdateTestCase && enableUpdatePanel && allowTestSessionUpdate">
                <div class="col-xs-12 col-sm-9">
                </div>
                <div class="col-xs-12 col-sm-3">
                    <button type="button" class="btn btn-md btn-default btn-block" (click)="updateTest()"
                        ttip="Overwrites the old test session using the current run">Update with current test</button>
                </div>
            </div>
        </ng-container>
    </ng-containter>
</div>