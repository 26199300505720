import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CmsExerciseScript, CmsTopicAttribute, CmsTopic, CheckedEvent, CmsTopicAttributeAssignment } from '../../types/navigator.types';
import { UserService } from '../../../security/services/user.service';

//interpolate between green and red
function getColor(value: number) {
    //value from 0 to 1
    var hue = ((1 - value) * 120).toString(10);
    return ["hsl(", hue, ",100%,50%)"].join("");
}

/**
 * A topic-leaf is a 'file' in the navigation system, which corresponds to an
 * exercise. The model is defined by class of type CmsLeaf. Classes for the various 
 * types of exercises are derived from CmsLeaf
 */
@Component({
    selector: 'topic-leaf-search',
    templateUrl: './topic-leaf-search.component.html',
    styleUrls: ['./topic-leaf-search.component.css', '../../../../assets/shared.less']
})
export class TopicLeafSearchComponent {

    @Input() leaf: CmsExerciseScript;
    @Input() topic: CmsTopic; //required for attribute definitions

    @Input() selectable: boolean = false;
    @Input() selected: boolean = false;
    @Input() readOnly = false;

    @Input() searchHighlight: string;

    @Input() errorBadgeTitle: string;
    @Input() hideState: boolean = false;
    @Input() enabled = true;

    @Output() onChecked: EventEmitter<CheckedEvent<CmsExerciseScript>> = new EventEmitter<CheckedEvent<CmsExerciseScript>>();

    constructor(private userService: UserService) { }

    checked(e: any) {
        let value: boolean = e.target.checked;
        this.onChecked.emit({ item: this.leaf, checked: value });
    }

    getLeafName() {
        let name = this.leaf.name;
        if (this.searchHighlight) {
            let regex = new RegExp(`${this.searchHighlight}`, 'i');
            name = name.replace(regex, `<strong>$&</strong>`);
        }
        return name;
    }

    getColorForAttribute(attribute: CmsTopicAttributeAssignment): { [key: string]: string } {
        let result = {};
        if (this.leaf.attributeAssignments.find(attr => attr.id === attribute.id)) {
            result["background-color"] = getColor(attribute.difficulty.complexity / 8.0);
            if (attribute.difficulty.complexity > 4) result['color'] = 'white';
        }
        return result;
    };

    getColorForLeafAttribute(attrName: string): { [key: string]: string } {
        let result = {};
        if (!this.topic.attributeAssignments) return result;

        let attribute = this.topic.attributeAssignments.find(attr => attr.attribute.name === attrName);
        if (!attribute) return result;

        result["background-color"] = getColor(attribute.difficulty.complexity / 8.0);
        if (attribute.difficulty.complexity > 4) result['color'] = 'white';
        return result;
    };

    onLeafClick(): void {
        if (this.enabled && this.leaf.onClick) this.leaf.onClick(this.leaf);
    }

    getActions() {
        if (this.readOnly) {
            return [];
        }
        let canEdit: boolean = this.userService.getUser() && this.userService.getUser().canEditLibrary();
        return canEdit
            ? this.leaf.actions
            : this.leaf.actions.filter(a => !a.adminOnly);
    }
}



