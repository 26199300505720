<div class="spec-collapsable" [ngClass]="{open:expanded, collapsed:!expanded}">
    <div class="summary">
        <div class="summary-txt" (click)="setCollapsed(expanded)" >
            <ng-content select=".sc-summary"></ng-content>
        </div>
        <span class="fa fa-plus add-button" (click)="addClicked()" *ngIf="showAddButton"></span>
        <span class="fa fa-trash-o remove-button" (click)="removeClicked()" *ngIf="showDeleteButton"></span>
        <span class="fa fa-arrow-down move-button-down" (click)="moveDownClicked()" *ngIf="showMoveDownButton"></span>
        <span class="fa fa-arrow-up move-button-up" (click)="moveUpClicked()" *ngIf="showMoveUpButton"></span>
    </div>
    <div class="content collapsed" #content>
        <div class="content-inner">
            <ng-content select=".sc-content"></ng-content>
            <button (click)="setCollapsed(true)" class="btn btn-primary form-control close-button" style="display:inline-block; width:auto" type="button">Close</button>
            <div style="clear:both"></div>
        </div>
    </div>
</div>
