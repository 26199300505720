
import {switchMap} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

import { ToastrService } from 'ngx-toastr';

import { CmsTopicAttribute } from '../../types/navigator.types';
import { REGIONS } from '../../types/cms-metadata.types';
import { TopicAttributeService } from '../../services/topic-attribute.service.';

@Component({
    templateUrl: './topic-attribute-editor.component.html',
    styleUrls: ['./topic-attribute-editor.component.css']
})
export class TopicAttributeEditorComponent implements OnInit {
    regions = REGIONS;
    form: FormGroup;

    attribute: CmsTopicAttribute;
    attributes: CmsTopicAttribute[];

    attributeExistsValidator = (): ValidatorFn => {
        return (control: FormControl): { [key: string]: any } | null => {
            if (!this.attributes) {
                return null;
            }
            let newName = control.value.toLowerCase().trim();
            let existingAttribute = this.attributes.find(attr => attr.name === newName);
            const nameExists =  existingAttribute && this.attribute.id != existingAttribute.id
            return nameExists ? { 'nameExists': { value: control.value } } : null;
        };
    }

    nameCtrl = new FormControl('', [Validators.required, this.attributeExistsValidator()]);
    descriptionCtrl = new FormControl('');

    constructor(
        private attributeService: TopicAttributeService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private location: Location,
        private formBuilder: FormBuilder,
    ) {
        this.form = this.formBuilder.group({
            name: this.nameCtrl,
            description: this.descriptionCtrl
        })
    }

    cancel() {
        this.location.back();
    }

    ready() {
        return !!this.attribute && !!this.attributes;
    }

    onSubmit() {
        this.attribute.name = this.nameCtrl.value;
        this.attribute.description = this.descriptionCtrl.value;
        this.attributeService.saveAttribute(this.attribute)
            .then(() => {
                this.attributeService.setDirty();
                this.attributeService.getAllAttributes().then(() => {
                    this.toastr.success('Attribute saved succesfully', 'Success');
                    this.location.back();
                });
            })
            .catch(err => {
                console.log(err);
                this.toastr.error("An error occured while saving the attribute", "Error");
            })
    }

    ngOnInit() {
        this.route.params.pipe(
            switchMap((params: Params) => {
                if (!params['id']) {
                    return Promise.resolve(null);
                }
                return this.attributeService.getAttribute(params['id'])
            }))
            .subscribe((attribute: CmsTopicAttribute) => {
                if (attribute === null) {
                    attribute = {
                        id: null,
                        name: ""
                    }
                }
                this.attribute = attribute;
                this.nameCtrl.setValue(attribute.name);
                this.descriptionCtrl.setValue(attribute.description);
                this.attributeService.getAllAttributes().then(
                    attributes => {
                        this.attributes = attributes.map(attr => {
                            attr.name = attr.name.toLowerCase();
                            return attr;
                        })
                    });
            });
    }
}

