
import {switchMap} from 'rxjs/operators';
import { Component, OnInit, ViewContainerRef, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

import { ToastrService } from 'ngx-toastr';

import { CmsTopic, CmsTopicAttribute, CmsContainerType, CmsMetadata, ReferencedBy, CmsTopicAttributeAssignment, Difficulty } from '../../types/navigator.types';
import { TopicService } from '../../services/topic.service';
import { TopicAttributeService } from '../../services/topic-attribute.service.';
import { UserService } from '../../../security/services/user.service';

interface AttributeSpec {
    attributeAssignment: CmsTopicAttributeAssignment;
    deleted: boolean;
    new?: boolean;
}

interface ModifyCheckResult {
    attrId: string;
    attrName: string;
    referencedBy: ReferencedBy[];
}

@Component({
    templateUrl: './topic-editor.component.html',
    styleUrls: ['./topic-editor.component.css']
})
export class TopicEditorComponent implements OnInit {
    form: FormGroup;
    nameCtrl = new FormControl('', Validators.required);
    algebrakitOnlyCtrl = new FormControl('');
    topic: CmsTopic;

    modifyChecking: boolean = false;
    modifyCheckResult: { [attrId: string]: ModifyCheckResult } = {};

    attributeSpecs: AttributeSpec[];

    metadataValid = true;

    constructor(
        private topicService: TopicService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private location: Location,
        private attributeService: TopicAttributeService,
        private userService: UserService
    ) {
        this.form = this.formBuilder.group({
            name: this.nameCtrl,
            algebrakitOnly: this.algebrakitOnlyCtrl
        })
    }

    keys(obj: any) {
        return Object.keys(obj);
    }

    createAttributeAssignment = () => {
        let attribute: CmsTopicAttributeAssignment = {
            id: null,
            attribute: null,
            difficulty: null,
            topic: { id: this.topic.id, name: this.topic.name, containerType: CmsContainerType.FOLDER }
        };
        this.attributeSpecs = [{
            attributeAssignment: attribute,
            deleted: false,
            new: true
        }]
    }

    addAttribute = (index: number) => {
        let attribute = this.attributeSpecs[index].attributeAssignment;

        this.attributeSpecs.splice(index + 1, 0, {
            attributeAssignment: {
                id: null,
                attribute: null,
                difficulty: attribute.difficulty,
                topic: { id: this.topic.id, name: this.topic.name, containerType: CmsContainerType.FOLDER }
            },
            deleted: false,
            new: true
        });
    }

    deleteAttribute = (index: number) => {
        if (this.attributeSpecs[index].new) {
            this.attributeSpecs.splice(index, 1);
            return;
        }
        this.modifyChecking = true;
        this.attributeSpecs[index].deleted = true;
        let attrId = this.attributeSpecs[index].attributeAssignment.id;
        this.topicService.checkAttributeDelete(attrId)
            .toPromise()
            .then((result: ReferencedBy[]) => {
                this.modifyCheckResult[attrId] = {
                    attrId: attrId,
                    attrName: this.attributeSpecs[index].attributeAssignment.attribute.name,
                    referencedBy: result,
                };
                this.modifyChecking = false;
            })
    }

    restoreAttribute = (index: number) => {
        this.attributeSpecs[index].deleted = false;
        delete this.modifyCheckResult[this.attributeSpecs[index].attributeAssignment.id];
    }

    usedAttributes(excluding?: string) {
        return this.attributeSpecs
            .filter(as => as.attributeAssignment.attribute && as.attributeAssignment.attribute.id !== excluding)
            .map(as => as.attributeAssignment.attribute.id);
    }

    setMetadataValid(valid: boolean) {
        this.metadataValid = valid;
    }

    canSubmit(): boolean {
        for (let attrId of Object.keys(this.modifyCheckResult)) {
            for (let reference of this.modifyCheckResult[attrId].referencedBy) {
                if (reference.type == 'ExerciseScript') {
                    return false;
                }
            }
        }
        return true;
    }

    cancel = () => {
        this.location.back();
    }

    onSubmit = () => {
        this.topic.name = this.nameCtrl.value;
        this.topic.algebrakitOnly = this.algebrakitOnlyCtrl.value;
        this.topic.attributeAssignments = this.attributeSpecs
            .filter(a => !a.deleted)
            .map(a => a.attributeAssignment);
        this.topicService.storeTopic(this.topic)
            .then(() => {
                this.attributeSpecs = this.attributeSpecs.filter(spec => !spec.deleted);
                this.location.back()
                this.toastr.success("Topic was succesfully saved", "Success");
            })
            .catch(err => {
                this.toastr.error("Could not store the topic", 'Error');
                console.log(err);
                return null;
            });
    }

    commit() {
        console.log('SAVE!')
    }

    attributesValid() {
        return this.attributeSpecs.every(
            spec => spec.attributeAssignment.attribute != null
                && spec.attributeAssignment.attribute.id != '-1');
    }

    ngOnInit() {
        this.route.params.pipe(
            switchMap((params: Params) => this.topicService.getTopic(params['id'])))
            .subscribe(t => {
                this.topic = t;
                this.attributeSpecs = t.attributeAssignments
                    .map(a => {
                        return {
                            attributeAssignment: a,
                            deleted: false,
                            new: false
                        }
                    });
                this.nameCtrl.setValue(t.name);
                this.algebrakitOnlyCtrl.setValue(t.algebrakitOnly);
            });
    }
}

