import { NgModule }      from '@angular/core';
import { RouterModule }  from '@angular/router';

import { AppHomeComponent }         from './components/app-home/app-home.component';
import { PageNotFoundComponent }    from './components/page-not-found/page-not-found.component';
import { AuthUserGuard }            from './services/auth-user-guard.service';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ActivateUserComponent } from '../security/components/activate-user/activate-user.component';


const routes = [
   {path: 'home',   component: AppHomeComponent, canActivate: [AuthUserGuard]},
   {path: 'user/profile',   component: UserProfileComponent, canActivate: [AuthUserGuard], data: {title: 'Update Profile'}},
   {path: 'user/change-password',   component: ChangePasswordComponent, canActivate: [AuthUserGuard], data: {title: 'Change Password'}},
   {path: 'user/forgot-password',   component: ForgotPasswordComponent, data: {title: 'Forgot Password'}},
   { path: 'activate/:token', component: ActivateUserComponent, data: { title: 'Activate your account' } },

   {path: 'user/reset-password/:token',   component: ResetPasswordComponent, data: {title: 'Reset Password'}},
   {path: 'akit-test', redirectTo: '/login', data: {title: 'Login'}},
   {path: 'akit-staging', redirectTo: '/login', data: {title: 'Login'}},
   {path: 'akit', redirectTo: '/login', data: {title: 'Login'}},
   {path: 'akit-beta', redirectTo: '/login', data: {title: 'Login'}},
   {path: '',       redirectTo: '/home', pathMatch: 'full'},
   {path: '**',     component: PageNotFoundComponent, data: {title: 'Not found'}}
];

@NgModule({
  imports:      [ RouterModule.forRoot(routes)  ],
  exports:      [ RouterModule ]
})
export class AppRoutingModule { }


