import { IAlgebraKIT, ProxyConfig } from "./app/algebrakit/types/algebrakit.type";
import { testEnvs } from "./app/algebrakit/types/metadata.type";

declare const AlgebraKIT: IAlgebraKIT;

/**
 * Checks whether the widgets javascript has already been added to the page and adds them if necessary
 * Will determine endpoints based on info from sessionstorage, URL parameters and cookies in that order.
 * @param profile the current profile, used to determine whether to load minified scripts
 */
export async function addWidgetsScript(profile: string) {
    const akitwidgetsScriptAttr = 'data-akit-widgets';

    //Load widget scripts if necessary
    let widgetResolve: (value?: any) => void;
    const widgetLoadPromise = new Promise(resolve => {
        widgetResolve = resolve;
    })
    //If script tag with "data-akit-widgets" is absent, add it dynamically using a cookie
    var widgetsScriptTag = document.querySelector(`script[${akitwidgetsScriptAttr}]`);
    if (!widgetsScriptTag) {
        const params = new URLSearchParams(window.location.search);
        //If the endpoint is set in the local storage, use that first
        const akitEndpoint = window.sessionStorage.getItem('akitEndpoint') || params.get('akitEndpoint');
        var fallback = "https://widgets.algebrakit.com" //If cookie is not set, fallback to prod
        let cookie = document.cookie;
        let host;
        if (akitEndpoint && akitEndpoint !== "default") {
            //Localhost
            if (akitEndpoint === 'local') {
                host = 'http://localhost:4000';
            }
            //staging
            else if (akitEndpoint === 'staging') {
                host = 'https://widgets.staging.algebrakit.com';
            }
            else {
                //test envs
                const separator = testEnvs.includes(akitEndpoint) ? '-' : '.';
                const endpointPrefix = akitEndpoint !== "prod"
                    ? akitEndpoint+separator
                    : '';

                //all other environments
                host = `https://${endpointPrefix}widgets.algebrakit.com`;
            }

            //Ensure that every request done by the widgets factory also adds the endpoint as a header so the proxy picks it up
            addHeadersToProxy(AlgebraKIT.config.proxy, akitEndpoint);
            addHeadersToProxy(AlgebraKIT.config.secureProxy, akitEndpoint);
        }
        else if (cookie) {
            let cookieRows = cookie.split(/[\n;]/);
            let entry = cookieRows.find(row => row.includes('WIDGETS-HOST='));
            if (entry) {
                host = entry.split('=')[1].trim();
            }
        }

        if (!host || host.length == 0) {
            host = fallback;
        }
        let script = document.createElement('script');
        script.setAttribute(akitwidgetsScriptAttr, '');
        let minified = profile !== 'local';
        script.src = `${host}/akit-widgets${minified ? '.min' : ''}.js`;

        script.addEventListener('load', widgetResolve);

        document.body.appendChild(script);
    }
    else {
        widgetResolve();
    }

    await widgetLoadPromise;
}

/**
 * Adds the akit endpoint header to the provided proxy config
 * @param proxyConfig 
 * @param endpoint 
 */
function addHeadersToProxy(proxyConfig: ProxyConfig, endpoint: string) {
    if (!proxyConfig.headers) {
        proxyConfig.headers = {};
    }
    proxyConfig.headers['x-api-endpoint'] = endpoint;
}