import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { compareVersion } from "../../authoring/util/sorting";
import { UserService } from "../../security/services/user.service";
import { HttpClientService } from "./http-client.service";
import { ToastrService } from "ngx-toastr";
import { AkitVersionsService } from "../../algebrakit/services/akit-versions.service";
import { AkitVersion, AkitVersionsResult, testEnvs } from "../../algebrakit/types/metadata.type";

@Injectable()
export class ContentVersionService extends HttpClientService {

    private contentVersion: string; //Currently set contentversion
    private defaultContentVersion: string; //Contentversion for the default version that this CMS uses (local, prod, staging)
    private prodContentVersion: string; //Contentversion for the production version
    private localContentVersion: string; //Contentversion for the local version

    constructor(
        protected http: HttpClient,
        protected toastr: ToastrService,
        protected akitVersionsService: AkitVersionsService,
        protected userService: UserService
    ) {
        super(http, toastr, akitVersionsService);
     }

    public getContentVersion(): Promise<string> {
        return this.contentVersion
            ? Promise.resolve(this.contentVersion)
            : this.get('connect/content-version/', { responseType: 'text' })
                .toPromise()
                .then((contentVersion: string) => {
                    this.contentVersion = contentVersion;
                    return contentVersion
                })
    }

    public getDefaultContentVersion(): Promise<string> {
        return this.defaultContentVersion
            ? Promise.resolve(this.defaultContentVersion)
            : this.http.get('connect/content-version/', { responseType: 'text' })
                .toPromise()
                .then((contentVersion: string) => {
                    this.defaultContentVersion = contentVersion;
                    return contentVersion
                })
    }

    public getLocalContentVersion(): Promise<string> {
        return this.localContentVersion
            ? Promise.resolve(this.localContentVersion)
            : this.http.get('connect/local-content-version/', { responseType: 'text' })
                .toPromise()
                .then((contentVersion: string) => {
                    this.localContentVersion = contentVersion;
                    return contentVersion
                })
    }

    public getProdContentVersion(): Promise<string> {
        return this.prodContentVersion
            ? Promise.resolve(this.prodContentVersion)
            : this.get('connect/prod-content-version/', { responseType: 'text' })
                .toPromise()
                .then((prodContentVersion: string) => {
                    this.prodContentVersion = prodContentVersion;
                    return prodContentVersion
                })
    }

    public async getAllVersionData(includingLocal?: boolean, includingTestNamespaces?: boolean): Promise<AkitVersionsResult> {
        let defaultContentVersion = await this.getDefaultContentVersion();
        let versions: AkitVersion[] = await this.akitVersionsService.getAkitVersions(true, includingTestNamespaces).then(result => {
            return Object.keys(result).map(name => {
                let versionStr = result[name];
                return {
                    name: name,
                    contentVersion: versionStr != 'offline' ? versionStr : null,
                    offline: versionStr == 'offline'
                }
            })
        });

        if (includingLocal) {
            versions.push({
                name: "local",
                contentVersion: await this.getLocalContentVersion()
            });
        }

        versions.sort((a, b) => {
            if (testEnvs.includes(a.name) && !testEnvs.includes(b.name)) {
                return 1;
            }
            if (!testEnvs.includes(a.name) && testEnvs.includes(b.name)) {
                return -1;
            }
            return 0;
        });

        return {
            defaultContentVersion: defaultContentVersion,
            versions: versions
        }
    }

    public isVersionMismatch() {
        let promises = [this.getContentVersion(), this.getProdContentVersion()];
        return Promise.all(promises).then(() => {
            return compareVersion(this.prodContentVersion, this.contentVersion) < 0;
        });
    }

    public showVersionAlert(force?: boolean) {
        if (!force && this.userService.getUser().hasRole("global.cms.skip-version-alert")) {
            return;
        }
        this.isVersionMismatch().then((mismatch) => {
            if (mismatch) {
                alert(`You are currently working with content version ${this.contentVersion}. The current content version on production is ${this.prodContentVersion}. Exercises saved with the current content version will not work on production yet!`);
            }
        });
    }
}
