import { Pipe, PipeTransform } from '@angular/core';
import { IAlgebraKIT } from '../../types/algebrakit.type';
declare let AlgebraKIT: IAlgebraKIT;

/*
 * Render latex into html. Use with innerHTML and async
 * <div [innherHTML]=" 'x^2' | latex | async"></div>
*/
@Pipe({name: 'latex'})
export class ToLatexPipe implements PipeTransform {
  transform(latex: string): Promise<string> {
    return AlgebraKIT.elements2html(latex);
  }
}