import { Component, OnInit, Input } from '@angular/core';
import { CmsFolder, CmsAkitResourceRef, CmsDiffAnomaly, CmsDiffSeverityLevel } from '../../types/navigator.types';
import { Observable } from 'rxjs';
import { SortedCheckResults, AnomalyType, Anomaly, CheckedResource, Severity } from '../../types/verify.types';

/**
 * A topic-leaf is a 'file' in the navigation system, which corresponds to an
 * exercise. The model is defined by class of type CmsLeaf. Classes for the various 
 * types of exercises are derived from CmsLeaf
 */

const STATUS_CLASSES = {};
STATUS_CLASSES[Severity.INFO] = "list-group-item-info";
STATUS_CLASSES[Severity.WARNING_LOW_PRIO] = "list-group-item-warning";
STATUS_CLASSES[Severity.WARNING_HIGH_PRIO] = "list-group-item-warning";
STATUS_CLASSES[Severity.ERROR] = "list-group-item-danger";

//Exercise types
const EXERCISE_TRANS = {};
EXERCISE_TRANS["ExerciseClientSpec"] = "exercise";
EXERCISE_TRANS["ExerciseReference"] = "eingle reference";
EXERCISE_TRANS["ExerciseFilter"] = "arrangement exercise";
EXERCISE_TRANS["ExerciseScript"] = "library exercise";

const ANOMALY_TRANS = {};
ANOMALY_TRANS[AnomalyType.EXERCISE_VERSION_MISMATCH] = "has been edited using a newer version of the Algebrakit CMS and will not be published";
ANOMALY_TRANS[AnomalyType.EXERCISE_AUDIENCE_EMPTY] = "(has an empty audience";
ANOMALY_TRANS[AnomalyType.EXERCISE_IS_DRAFT] = "is in DRAFT state and will not be published";
ANOMALY_TRANS[AnomalyType.EXERCISE_FILTER_INVALID] = "is an invalid exercise arrangement";
ANOMALY_TRANS[AnomalyType.EXERCISE_FILTER_NO_LEVELS] = " has no levels";
ANOMALY_TRANS[AnomalyType.EXERCISE_FILTER_EMPTY_LEVEL] = "has no exercises";
ANOMALY_TRANS[AnomalyType.EXERCISE_REFERENCE_NO_SCRIPT] = "does not reference a valid library exercise";
ANOMALY_TRANS[AnomalyType.EXERCISE_SPECIFICATION_NO_DEF] = "has an invalid specification";
ANOMALY_TRANS[AnomalyType.EXERCISE_SCRIPT_NO_DEF] = "has an invalid specification";

//Verify only
ANOMALY_TRANS[AnomalyType.MAJOR_UPDATE] = "has one or more major updates";
ANOMALY_TRANS[AnomalyType.MINOR_UPDATE] = "has one or more minor updates";
ANOMALY_TRANS[AnomalyType.VERSION_NUMBER_ERROR] = "has a corrupt version number";

@Component({
    selector: 'verify-results',
    templateUrl: './verify-results.component.html',
    styleUrls: ['./verify-results.component.css']
})
export class VerifyResultsComponent {

    _results: SortedCheckResults[];

    @Input() set results(value: SortedCheckResults[]) {
        this._results = value;
    }

    getStatusClass(checkResults: SortedCheckResults) {
        let classes = ['list-group-item'];
        if (checkResults) {
            classes.push(STATUS_CLASSES[checkResults.severity]);
        }
        return classes;
    }

    getResourceTypeTranslation(resource: CheckedResource) {
        return `The ${EXERCISE_TRANS[resource.resourceType]}`;
    }

    getAnomalyTranslation(anomaly: Anomaly, resource: CheckedResource) {
        return `${this.getPrefixedDetails(anomaly)} ${ANOMALY_TRANS[anomaly.type]}${this.getSuffixedDetails(anomaly)}.`;
    }

    getPrefixedDetails(anomaly: Anomaly) {
        if (!anomaly.details || Object.keys(anomaly.details).length === 0) {
            return "";
        }

        if (anomaly.type === AnomalyType.EXERCISE_FILTER_EMPTY_LEVEL) {
            return `Level ${anomaly.details['level']}`;
        }

        return '';
    }

    getSuffixedDetails(anomaly: Anomaly) {
        if (!anomaly.details || Object.keys(anomaly.details).length === 0) {
            return "";
        }

        if (anomaly.type === AnomalyType.MAJOR_UPDATE || anomaly.type === AnomalyType.MINOR_UPDATE) {
            return `. Old version: ${anomaly.details['oldVersion']}, new version: ${anomaly.details['newVersion']}`;
        }

        return '';
    }

    filterResults() {
        return this._results.filter(r => r.checkedResources && Object.keys(r.checkedResources).length > 0).reverse();
    }

    getFindings(severity: number): CheckedResource[] {
        return this._results[severity]
            ? Object.keys(this._results[severity].checkedResources).map(id => this._results[severity].checkedResources[id])
            : [];
    }

    getLink(checkedResource: CheckedResource): string {
        if (checkedResource.resourceType === 'PublishedExerciseFilter'
            || checkedResource.resourceType === 'ExerciseFilter') {
            return `/edit/exercise-filter/${checkedResource.resource.id}?onCloseUrl=_BACK`;
        }
        if (checkedResource.resourceType === 'ExerciseReference'
            || checkedResource.resourceType === 'PublishedExerciseReference') {
            return `/edit/exercise-reference/${checkedResource.resource.id}?onCloseUrl=_BACK`;
        }
        if (checkedResource.resourceType === 'ExerciseClientSpec'
            || checkedResource.resourceType === 'PublishedExerciseSpecification') {
            return `/edit/exercise-spec-client/${checkedResource.resource.id}?onCloseUrl=_BACK`;
        }
        if (checkedResource.resourceType === 'ExerciseScript'
            || checkedResource.resourceType === 'PublishedPublishedExerciseScript') {
            return `/edit/exercise/${checkedResource.resource.id}?onCloseUrl=_BACK`;
        }
        return null;
    }


}



