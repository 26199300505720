import { Component, OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { BehaviorSubject ,  Observable ,  Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { UserService } from '../../../security/services/user.service';
import { User } from '../../../security/types/user.type';

var modejson = require('../../../../assets/json/modes.json');

declare let $: any;

@Component({
    selector: 'modes-overview',
    templateUrl: './modes-overview.component.html',
    styleUrls: ['./modes-overview.component.css']
})
export class ModesOverviewComponent implements OnInit {

    public expandedItemIndex:Array<number> = [];

    subscription: Subscription;
    user: User;
    
    public modesitems:Array<any>;
    public filteredItems:Array<any>;

    loading: boolean = false;

    canEditTopicsScripts() {
        return this.userService.canEditLibrary();
    }

    public showFilterComp(internal:any) {
        
        if(internal!=='internal-hidden') {
            internal === 'internal';
            if(internal && this.canEditTopicsScripts()) {
                return true;
            }
            return !internal;
        }
        return false;
    }

    public allcolumns:Array<any> = [
      {name: 'name'},
      {name: 'description'},
      {name: 'internal'},
      {name: 'deprecated'},
      {name: 'insteadofdeprecated'},
      {name: 'example-in'},
      {name: 'example-out-1'},
      {name: 'example-out-2'}
    ];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private userService: UserService
    ) {
    }

    ngOnInit() {
        this.user = this.userService.getUser();
        this.modesitems = modejson.sort( function(mode1:any, mode2:any) {
            if ( mode1.name < mode2.name ){
                return -1;
            }else if( mode1.name > mode2.name ){
                return 1;
            }else{
                return 0;	
            }
        });
        this.filteredItems = modejson.sort( function(mode1:any, mode2:any) {
            if ( mode1.name < mode2.name ){
                return -1;
            }else if( mode1.name > mode2.name ){
                return 1;
            }else{
                return 0;	
            }
        });
    }
        
    public filterModes(event:any) {
        let filteredJSON:Array<any> = [];
        this.modesitems.forEach( (item:any) => {
          let flag = false;
          this.allcolumns.forEach( (column:any) => {
            if ( this.findValue(item[column.name], event.target.value) ) {
              flag = true;
            }
          });
          if (flag) {
              filteredJSON.push(item);
          }
        });
        this.filteredItems = filteredJSON;
    }
    
    public findValue(item:any, value:any):boolean {
        let bool=false;
        if ( typeof item === 'object' ) {
            for (let key of Object.keys(item)) {
                bool = bool || this.findValue(item[key], value);
            }
        }
        else {
            if (item.toString().toLowerCase().match( this.escapeRegExp(value.toLowerCase()) )) {
                bool = true;
            }
        }
        return bool;
    }
    
    public escapeRegExp(str:string) {
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    }
       
    public showExpandedItem(itemNum:number) {
        let index = this.expandedItemIndex.indexOf(itemNum);
        if (index > -1) {
            return true;
        }
        else {
            return false;
        }
    }
    
    public isItemEmpty(item:any){
        return item['example-in']=='' && 
               item.deprecated!=='true' &&
               item.internal!=='internal';
    }
    
    public toggleItemExpansion(itemNum:number, isEmpty:boolean) {
        
        if (!isEmpty) {
            let index = this.expandedItemIndex.indexOf(itemNum);
            if (index > -1) {
                this.expandedItemIndex.splice(index, 1);
            }
            else {
                this.expandedItemIndex.push(itemNum);
            }
        }
    };
    
}