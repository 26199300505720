<div class='syntax-navigator syntax-table'>
    <div class="row">
        <div class="col-md-4">
            <input placeholder="Search..." class="form-control" (keyup)="filterSyntax($event)" />
        </div>
        <div [ngClass]="{'col-xs-6 col-xs-offset-2 col-md-4 col-md-offset-4 rightalign': true, 'expand-hand': !showLegendItem()}" (click)="toggleLegendExpansion()">
            <div *ngIf="!showLegendItem()" class="col-xs-12"><i class="syntax-expand-icon fa fa-icon-class fa-info-circle"></i>
            </div>
        </div>
    </div>
    <br>

    <div *ngIf="showLegendItem()" class='col-xs-12 syntax-row'>
        <div class="row expand-hand" (click)="toggleLegendExpansion()">
            <div class="col-xs-12">
                <div class="col-xs-12 rightalign"><i class="syntax-expand-icon fa fa-icon-class fa-info-circle"></i></div>
                
                <div class="legend-name col-xs-12">Legend:</div>
                <div  class="legend-block syntax-row col-xs-12">
                    <div *ngFor="let legenditem of helpLegend">
                        <div class="col-xs-12">
                            <div class="code col-xs-4">{{legenditem.name}}</div>
                            <div class="col-xs-8">{{legenditem.description}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    

    <div *ngIf="showLegendItem()"><br></div>

    <div *ngFor="let item of filteredItems">
        <div *ngIf="showFilterComp(item.properties.internal==='internal')" class='col-xs-12 syntax-row'>
            <div [ngClass]="{'row': true, 'expand-hand': !isItemEmpty(item)}"  (click)="toggleItemExpansion(item.id, isItemEmpty(item))" >
                <div class='syntax-quickview'>
                    <div class="syntax-quickview-table col-xs-12">
                        <div class="row">
                            <div class='syntax-name col-xs-10'>
                                
                                <div *ngIf="!isItemEmpty(item)" class='syntax-expand-icon'>
                                    <i [ngClass]="{'fa-icon-class fa': true, 'fa-plus-circle': !showExpandedItem(item.id), 'fa-minus-circle' : showExpandedItem(item.id)}"></i>
                                </div>
                                <div *ngIf="isItemEmpty(item)" class='syntax-expand-icon syntax-empty-expand'>
                                    <i class="fa-icon-class fa fa-plus-circle"></i>
                                </div>
                                
                                <span [ngClass]="{'syntax-deprecated': item.properties.deprecated=='true'}">{{item.name}}</span>
                            </div>
                            <div class='syntax-type-icon col-xs-2'>
                                <span>{{item.properties.type}}</span>
                            </div>
                        </div>
                        <div *ngIf="item.description !== ''" class='row'>
                            <div class="syntax-description col-xs-12" [innerHtml]="item.description"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div *ngIf="showExpandedItem(item.id)" class='col-xs-12 syntax-expand'>
                    <syntax-item-details [syntax]=item></syntax-item-details>
                </div>
            </div>
        </div>
    </div>
</div>