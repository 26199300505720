
import {filter} from 'rxjs/operators';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CmsSubject, CmsCourse, CmsExercise, CmsCourseAudience, CmsFolder, CmsTopic } from '../../types/navigator.types';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ExerciseService } from '../../services/exercise.service';
import { CourseService } from '../../services/course.service';
import { SubjectService } from '../../services/subject.service';
import { BehaviorSubject } from 'rxjs';
import { getExtendedToastOption } from '../../../app/options/options';
import { IdNamePair } from '../../types/cms2-metadata.types';
import { TopicService } from '../../services/topic.service';

declare let $: any;

enum FolderType {
    TOPIC = 'TOPIC',
    SUBJECT = 'SUBJECT',
}

/**
 * A topic-leaf is a 'file' in the navigation system, which corresponds to an
 * exercise. The model is defined by class of type CmsLeaf. Classes for the various 
 * types of exercises are derived from CmsLeaf
 */
@Component({
    selector: 'move-folder',
    templateUrl: './move-folder.component.html',
    styleUrls: ['./move-folder.component.css']
})
export class MoveFolderComponent<T extends CmsTopic | CmsSubject> implements OnInit {

    @Input() set selectedFolder(folder: T) {
        this._selectedFolder.next(folder);
    }

    @Input() folderType: FolderType;

    @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSuccess: EventEmitter<void> = new EventEmitter<void>();

    form: FormGroup;

    _selectedFolder: BehaviorSubject<T> = new BehaviorSubject(null);
    targetFolder: T;

    refreshTimer: any;

    isInitialized: boolean;

    courses: IdNamePair[];
    course$: BehaviorSubject<CmsCourse> = new BehaviorSubject(null);
    missingAudiences: string[];
    audiences: CmsCourseAudience[] = [];

    oldCourse: CmsCourse;

    courseCtrl = new FormControl('', Validators.required);
    audienceCtrl = new FormControl('', Validators.required);

    constructor(
        private toastr: ToastrService,
        private topicService: TopicService,
        private courseService: CourseService,
        private formBuilder: FormBuilder,
        private subjectService: SubjectService) {
        this.form = this.formBuilder.group({
            audience: this.audienceCtrl,
            course: this.courseCtrl
        })
    }

    close() {
        this.onClose.emit();
    }

    canConfirm() {
        return this.targetFolder
            && this._selectedFolder
            && this.targetFolder.id !== this._selectedFolder.value.id
            && (!this.missingAudiences || this.missingAudiences.length === 0 || (this.audienceCtrl.value != null && this.audienceCtrl.value != ""));
    }

    commit() {
        let movePromise: Promise<any>;
        if (this.folderType === FolderType.TOPIC) {
            movePromise = this.topicService.moveTopic(this._selectedFolder.value.id, this.targetFolder.id);
        }
        else {
            let audience = this.missingAudiences && this.missingAudiences.length > 0
                ? this.audienceCtrl.value
                : null;
            movePromise = this.subjectService.moveSubject(this._selectedFolder.value.id, this.targetFolder.id, audience);
        }
        movePromise
            .then(() => {
                let messageBody = 'Folder moved to ';
                let baseUrl = $('base').attr('href');
                let folderName = (
                    this.targetFolder.name !== 'root'
                    || this.targetFolder.course == null
                    || this.targetFolder.course.name == null
                )
                    ? this.targetFolder.name
                    : this.targetFolder.course.name;
                messageBody += `<a style="text-decoration: underline;" href="${baseUrl}/subject/${this.targetFolder.id}"><i><strong>${folderName}</strong></i></a>.`;
                this.toastr.toastrConfig.enableHtml = true;
                this.toastr.success(messageBody, 'Success', getExtendedToastOption());
                this.close();
                this.onSuccess.emit();
            })
            .catch((err: any) => {
                if (err.error) {
                    err = err.error;
                }
                this.toastr.error(err.message, 'Error');
            });
    }

    setSubject(id: string): void {
        let getFolderPromise: Promise<CmsTopic | CmsSubject>;
        if (this.folderType === FolderType.TOPIC) {
            getFolderPromise = this.topicService.getTopic(id).toPromise();
        }
        else {
            getFolderPromise = this.subjectService.getSubject(id).toPromise();
        }

        getFolderPromise
            .then((folder) => {
                this.targetFolder = folder as T;
            })
            .catch(err => {
                console.log(err);
                this.toastr.error("Error occurred while retrieving folder", 'Error');
            });
    }

    init() {
        let getParentPromise: Promise<any>;
        if (this.folderType == FolderType.SUBJECT) {
            getParentPromise = this.subjectService.getParentSubject(this._selectedFolder.value.id).toPromise();
            this.courseService.getCourse((this._selectedFolder.value as CmsSubject).course.id)
                .toPromise()
                .then(course => {
                    this.oldCourse = course;
                })
        }
        else {
            getParentPromise = this.topicService.getParentTopic(this._selectedFolder.value.id).toPromise();
        }
        getParentPromise.then((folder: T) => this.targetFolder = folder);
    }

    ngOnInit(): void {

        if (this.folderType === FolderType.SUBJECT) {
            this.courseService.getCourseNameList()
                .toPromise()
                .then((arr: IdNamePair[]) => {
                    this.courses = arr;
                    let currentCourseId = this._selectedFolder.value.course.id;
                    this.courseCtrl.setValue(currentCourseId);
                }).catch((err: any) => {
                    console.log(err);
                    this.toastr.error('Could not get your courses from the server', 'Error');
                });

            this.courseCtrl.valueChanges.pipe(
                filter(value => value != null))
                .subscribe(value => {
                    this.courseService.getCourse(value)
                        .toPromise()
                        .then(course => {
                            this.course$.next(course);
                            this.audiences = course.audiences;

                            //determine missing audiences
                            this.missingAudiences = this.oldCourse.audiences.filter(
                                oldA => !this.audiences.some(newA => newA.baseAudienceId === oldA.baseAudienceId)
                            ).map(a => a.name);

                            if (this.missingAudiences.length > 0) {
                                this.audienceCtrl.setValue("");
                            }

                            if (this.isInitialized) {
                                this.subjectService.getRootSubject(value)
                                    .toPromise()
                                    .then(subject => this.targetFolder = subject as T);
                            }
                            else {
                                this.subjectService.getParentSubject(this._selectedFolder.value.id)
                                    .toPromise()
                                    .then(subject => {
                                        this.targetFolder = subject as T;
                                        this.isInitialized = true;
                                    });
                            }
                        });
                });
        }

        if (this.folderType == null) {
            if ((this._selectedFolder as any).attributeAssignments) {
                this.folderType = FolderType.TOPIC;
            }
            else {
                this.folderType = FolderType.SUBJECT;
            }
        }
        this._selectedFolder.subscribe(() => this.init());
    }
}



