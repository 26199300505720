import { Component, OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { BehaviorSubject ,  Observable ,  Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { CmsItemAction, CmsCourse, CmsItem } from '../../types/navigator.types';
import { UserService } from '../../../security/services/user.service';
import { User } from '../../../security/types/user.type';
import { CourseService } from '../../services/course.service';
import { I18nLabelService } from '../../services/i18n-label.service';
import { I18nLabel, REGIONS } from '../../types/cms-metadata.types';

declare let $: any;

@Component({
    selector: 'labels-overview',
    templateUrl: './labels-overview.component.html',
    styleUrls: ['./labels-overview.component.css']
})
export class LabelsOverviewComponent implements OnInit {

    labels: I18nLabel[];

    regions = REGIONS;
    selectedRegion = 'en';

    actions: CmsItemAction[] = [{
        name: 'edit',
        iconClass: 'fa fa-lg fa-pencil-square',
        callback: ((course: CmsCourse) => this.editLabel(course.id))
    }, {
        name: 'delete',
        iconClass: 'fa fa-lg fa-times',
        callback: ((course: CmsCourse) => this.removeLabel(course.id))
    }
    ];

    loading: boolean = false;

    subscription: Subscription;
    user: User;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private labelService: I18nLabelService,
        private userService: UserService
    ) {
    }

    editLabel(id: string): void {
        this.router.navigate(['edit/label', id]);
    }

    getTranslation(label: I18nLabel) {
        let item = label.items[this.selectedRegion];
        return item 
            ? item.text
            : '';
    }

    canEditTopicsScripts() {
        return this.userService.canEditLibrary();
    }

    createLabel(): void {
        this.router.navigate(['create/label']);
    }

    removeLabel(id: string): Promise<boolean> {
        if (confirm('Are you sure you want to remove this label?')) {
            return this.labelService.deleteLabel(id).then(() => {
                this.refresh();
                this.toastr.success('Label succesfully removed.', 'Success');
                return true;
            });
        }
    }

    refresh() {
        this.labelService.refreshLabels()
            .then(labels => this.labels = labels);
    }

    ngOnInit() {
        this.labelService.labels$.subscribe(labels => 
            this.labels = labels.sort((a, b) => {
                return a.id.localeCompare(b.id);
            })
        );
        this.user = this.userService.getUser();
    }
}