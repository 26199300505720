import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, Params, UrlSegment, NavigationEnd} from '@angular/router';
import { UserService } from '../../../security/services/user.service';
import { User } from '../../../security/types/user.type';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

declare let $:any;
 
@Component({
  selector: 'helpitem-contactform',
  templateUrl: './helpitem-contactform.component.html',
  styleUrls: ['./helpitem-contactform.component.css']
}) 
export class ContactFormComponent {
    user: User;
    form: FormGroup;

    public constructor(
        private formBuilder : FormBuilder,
        private router: Router,
        private toastr: ToastrService,
        private userService: UserService,
        private location: Location,
    ) { 
        this.form = this.formBuilder.group({
            name: new FormControl('', [Validators.required, Validators.minLength(3)]),
            email: new FormControl('', [Validators.required, Validators.email]),
            phone: new FormControl(''),
            questiontype: new FormControl(''),
            url: new FormControl(''),
            message: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(1000)])
        });
    }
    
    onSubmit() {
        //     sendHelpMail(name: string, email: string, phone: string, subject: string, link: string, message: string) {
        if (this.form.valid) {
                let userinputform = {
                ...this.form.value,
            };
            this.userService.sendHelpMail(userinputform.name, userinputform.email, userinputform.phone, userinputform.questiontype, userinputform.link, userinputform.message)
                .then(() => {
                    this.toastr.success(`Your email has been sent successfully. We will reply to your question as soon as possible.`, 'Success');
                    this.location.back();
                })
                .catch((msg:any) => {
                    this.toastr.error(msg.message, 'Your message could not be sent. Make sure all required fields are filled out!');
                });
        }
        else {
            this.toastr.error('Your message could not be sent. Make sure all required fields are filled out!');
        }
    }
    
    ngOnInit() {
        this.user = this.userService.getUser();
        this.form.patchValue({'name' : this.user.firstName + ' ' + this.user.lastName, 'email': this.user.email});
    }
    
    get name() { return this.form.get('name'); }
    get email() { return this.form.get('email'); }
    get message() { return this.form.get('message'); }
    
}

