
import {switchMap} from 'rxjs/operators';
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ResourceService } from "../../services/resource.service";

const typeToUrlMap = {
    'arrangement': 'edit/exercise-filter',
    'reference': 'edit/exercise-reference',
    'specification': 'edit/exercise-spec-client',
    'library-exercise': 'edit/exercise',
    'topic': 'edit/topic',
    'subject': 'edit/subject',
    'other': 'home',
}

@Component({
    template: '<p>Please wait...</p>'
})
export class ResourceRedirectorComponent implements OnInit {
    
    constructor(
        private authoringService: ResourceService,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.route.params.pipe(
            switchMap((params: Params) => {
                let id = params['id'];
                return this.authoringService.getResourceType(id)
                    .then((type: string) => {
                        return {
                            type: type,
                            id: id
                        }
                    })
            }))
            .subscribe(result => {
                this.router.navigate([typeToUrlMap[result.type], result.id]);
            });
    }

}