
import { switchMap } from 'rxjs/operators';
import { Component, OnInit, ViewContainerRef, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { MetadataService, PALETTES, QUESTION_MODES } from '../../../algebrakit/services/metadata.service';

import { ToastrService } from 'ngx-toastr';

import { CmsSubject, NameIdPair, CmsSubjectRef, CmsContainerType, CmsMetadata, CmsCourse, GeneralUpdateRequest } from '../../types/navigator.types';
import { TopicService } from '../../services/topic.service';
import { SubjectService } from '../../services/subject.service';
import { CourseService } from '../../services/course.service';
import { IAlgebraKIT } from '../../../algebrakit/types/algebrakit.type';
import { UserService } from '../../../security/services/user.service';
import { EditorAssetResolveResult } from '../../../algebrakit/types/metadata.type';
import { AkitVersionsService } from '../../../algebrakit/services/akit-versions.service';

declare let $: any;
declare let AlgebraKIT: IAlgebraKIT;

@Component({
    templateUrl: './subject-editor.component.html',
    styleUrls: ['./subject-editor.component.less']
})
export class SubjectEditorComponent implements OnInit {
    form: FormGroup;
    nameCtrl = new FormControl('', Validators.required);
    subject: CmsSubject;
    parent: CmsSubject;
    course: CmsCourse;

    editorAssetResolveList: EditorAssetResolveResult[];

    metadataValid = true;

    palettes: NameIdPair[] = AlgebraKIT._api.getDefaultEditors();
    questionModes = QUESTION_MODES;

    defaultEditorCtrl = new FormControl('');
    applyEditorToChildsCtrl = new FormControl('');
    defaultQuestionModeCtrl = new FormControl('');
    defaultAudienceCtrl = new FormControl('');
    applyQuestionModeToChildsCtrl = new FormControl('');
    applyAudienceToChildsCtrl = new FormControl('');

    overwriteEditorInDraftChildsCtrl = new FormControl('');
    overwriteQuestionModeInDraftChildsCtrl = new FormControl('');
    overwriteAudienceInDraftChildsCtrl = new FormControl('');
    overwriteEditorInApprovedChildsCtrl = new FormControl('');
    overwriteQuestionModeInApprovedChildsCtrl = new FormControl('');
    overwriteAudienceInApprovedChildsCtrl = new FormControl('');

    get applyEditorToChildExercises(): boolean {
        return this.canManageCourse() && this.applyEditorToChildsCtrl.value;
    }
    get applyQuestionModeToChildExercises(): boolean {
        return this.canManageCourse() && this.applyQuestionModeToChildsCtrl.value;
    }
    get applyAudienceToChildExercises(): boolean {
        return this.canManageCourse() && this.applyAudienceToChildsCtrl.value;
    }

    get customPalettes(): string[] {
        return this.editorAssetResolveList
            ? this.editorAssetResolveList.map(editor => editor.formulaEditorId)
            : [];
    }

    get allPalettes() {
        let customPalettes = this.customPalettes
            ? this.customPalettes
                .filter(id => !this.palettes.find(editor => editor.id == id))
                .map(id => {
                    return {
                        id: id,
                        name: id,
                    }
                })
            : [];
        return this.palettes.concat(customPalettes);
    }

    constructor(
        private subjectService: SubjectService,
        private courseService: CourseService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private location: Location,
        private userService: UserService,
        private metaDataService: MetadataService,
        private akitVersionsService: AkitVersionsService
    ) {
        this.form = this.formBuilder.group({
            name: this.nameCtrl,
            defaultEditor: this.defaultEditorCtrl,
            applyEditorToChilds: this.applyEditorToChildsCtrl,
            defaultQuestionMode: this.defaultQuestionModeCtrl,
            defaultAudience: this.defaultAudienceCtrl,
            applyQuestionModeToChilds: this.applyQuestionModeToChildsCtrl,
            applyAudienceToChilds: this.applyAudienceToChildsCtrl,

            overwriteEditorInDraftChilds: this.overwriteEditorInDraftChildsCtrl,
            overwriteQuestionModeInDraftChilds: this.overwriteQuestionModeInDraftChildsCtrl,
            overwriteAudienceInDraftChilds: this.overwriteAudienceInDraftChildsCtrl,
            overwriteEditorInApprovedChilds: this.overwriteEditorInApprovedChildsCtrl,
            overwriteQuestionModeInApprovedChilds: this.overwriteQuestionModeInApprovedChildsCtrl,
            overwriteAudienceInApprovedChilds: this.overwriteAudienceInApprovedChildsCtrl,
        })
    }

    setParent(id: string): void {
        this.subjectService.getSubject(id).toPromise()
            .then(parent => {
                this.parent = parent;
                let ref: CmsSubjectRef = {
                    name: parent.name,
                    id: parent.id,
                    containerType: CmsContainerType.FOLDER
                }
                this.subject.parents.pop();
                this.subject.parents.push(ref);
            })
    }

    setMetadataValid(valid: boolean) {
        this.metadataValid = valid;
    }

    cancel() {
        this.location.back();
    }

    canManageCourse() {
        return this.userService.canManageCourse(this.course);
    }

    get overwriteNotifications() {
        let msgArr: string[] = [];
        let recursivelyText = "<strong>recursively in all subfolders</strong>";
        if (this.overwriteEditorInDraftChildsCtrl.value == true) {
            msgArr.push(`Overwrite formula editors in all DRAFT child exercises ${this.applyEditorToChildExercises ? recursivelyText : ""}`);
        }
        if (this.overwriteEditorInApprovedChildsCtrl.value == true) {
            msgArr.push(`Overwrite formula editors in all APPROVED child exercises ${this.applyEditorToChildExercises ? recursivelyText : ""}`);
        }
        if (this.overwriteQuestionModeInDraftChildsCtrl.value == true) {
            msgArr.push(`Overwrite question modes in all DRAFT child exercises ${this.applyQuestionModeToChildExercises ? recursivelyText : ""}`);
        }
        if (this.overwriteQuestionModeInApprovedChildsCtrl.value == true) {
            msgArr.push(`Overwrite question modes in all APPROVED child exercises ${this.applyQuestionModeToChildExercises ? recursivelyText : ""}`);
        }
        if (this.overwriteAudienceInDraftChildsCtrl.value == true) {
            msgArr.push(`Overwrite audiences in all DRAFT child exercises ${this.applyAudienceToChildExercises ? recursivelyText : ""}`);
        }
        if (this.overwriteAudienceInApprovedChildsCtrl.value == true) {
            msgArr.push(`Overwrite audiences in all APPROVED child exercises ${this.applyAudienceToChildExercises ? recursivelyText : ""}`);
        }
        return msgArr;
    }

    onSubmit() {
        if (this.overwriteNotifications.length > 0) {
            //show confirmation popup first
            var modal = $('.confirmation-modal').first();
            modal.modal('show');
        }
        else {
            this.confirmSubmit();
        }
    }

    confirmSubmit() {

        this.subject.name = this.nameCtrl.value;
        this.subject.defaultEditor = this.defaultEditorCtrl.value;
        this.subject.defaultQuestionMode = this.defaultQuestionModeCtrl.value;
        this.subject.defaultAudience = this.defaultAudienceCtrl.value;
        this.subject.parents = this.parent.parents ? this.parent.parents : [];
        this.subject.parents.push({
            id: this.parent.id,
            name: this.parent.name,
            containerType: CmsContainerType.FOLDER,
            root: !!this.parent.rootFolder
        });
        let updateReq = new GeneralUpdateRequest();
        updateReq.applyAudienceToChilds = this.applyAudienceToChildsCtrl.value;
        updateReq.applyEditorToChilds = this.applyEditorToChildsCtrl.value;
        updateReq.applyQuestionModeToChilds = this.applyQuestionModeToChildsCtrl.value;

        updateReq.overwriteEditorInDraftChilds = this.overwriteEditorInDraftChildsCtrl.value;
        updateReq.overwriteEditorInApprovedChilds = this.overwriteEditorInApprovedChildsCtrl.value;
        updateReq.overwriteQuestionModeInDraftChilds = this.overwriteQuestionModeInDraftChildsCtrl.value;
        updateReq.overwriteQuestionModeInApprovedChilds = this.overwriteQuestionModeInApprovedChildsCtrl.value;
        updateReq.overwriteAudienceInDraftChilds = this.overwriteAudienceInDraftChildsCtrl.value;
        updateReq.overwriteAudienceInApprovedChilds = this.overwriteAudienceInApprovedChildsCtrl.value;

        updateReq.defaultAudience = this.defaultAudienceCtrl.value;
        updateReq.defaultEditor = this.defaultEditorCtrl.value;
        updateReq.defaultQuestionMode = this.defaultQuestionModeCtrl.value;

        this.subjectService.storeSubject(this.subject, updateReq)
            .then(() => {
                var modal = $('.confirmation-modal').first();
                modal.modal('hide');
                this.toastr.success(`Subject "${this.subject.name}" was saved.`, 'Success');
                this.location.back()
            })
            .catch(err => {
                var modal = $('.confirmation-modal').first();
                modal.modal('hide');
                this.toastr.error("Could not store the subject.", 'Error');
                console.log(err);
                return null;
            });
    }

    ngOnInit() {
        this.route.params.pipe(
            switchMap((params: Params) => this.subjectService.getSubject(params['id'])),
            switchMap(s => {
                this.subject = s;
                this.nameCtrl.setValue(s.name)
                this.defaultEditorCtrl.setValue(s.defaultEditor);
                this.defaultQuestionModeCtrl.setValue(s.defaultQuestionMode);

                let versionPromise = Promise.resolve();
                //SubkectEditorComponent resolves the subject itself instead of using a resolver so we also change version here
                if (s && s.course && s.course.options) {
                    versionPromise = this.akitVersionsService.changeVersionIfRequired(s.course.options.akitVersion, this.route.snapshot);
                }

                versionPromise.then(() =>
                    this.courseService.getCourse(s.course.id).toPromise()
                        .then((course) => {
                            this.course = course;
                            this.defaultAudienceCtrl.setValue(s.defaultAudience);
                            //add custom formula editors
                            if (this.course.options && this.course.options.theme) {
                                let widgetConfig = AlgebraKIT._api.getProfile(this.course.options.theme);
                                let newPalettes: string[];
                                if (widgetConfig && widgetConfig.widgets.akitFormulaEditor && widgetConfig.widgets.akitFormulaEditor.palettes) {
                                    newPalettes = Object.keys(widgetConfig.widgets.akitFormulaEditor.palettes);
                                }
                                else {
                                    newPalettes = [];
                                }
                            }

                            return this.metaDataService.getEditorAssetResolveList(course.id);
                        }).then(resolveList => {
                            this.editorAssetResolveList = resolveList;
                        })
                );
                let pair = this.subject.parents[this.subject.parents.length - 1];
                return this.subjectService.getSubject(pair.id);
            }),)
            .subscribe(t => {
                this.parent = t;
            });

    }
}

