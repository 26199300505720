import { CmsExerciseScript, CmsTopicAttribute, SortBy, CmsExerciseScriptRef, CmsTopicAttributeAssignment, Difficulty, CmsItemState } from "../types/navigator.types";
import { TypeHelpers } from "./type-helpers";

export class Sorting {

    public static sortItemsByDifficulty(exArr: any[], attrArr: CmsTopicAttributeAssignment[], sortType: number) {
        if (!exArr || !attrArr) return exArr;
        let complArr: { [key: string]: number } = {};
        attrArr.forEach(attr => {
            complArr[attr.id] = attr.difficulty.complexity;
        });
        let objArr = exArr.map(ex => {
            let score = 0;
            for (let attribute of ex.attributeAssignments) {
                let value = complArr[attribute.id];
                if (value) score += value * value;
            }
            score = Math.sqrt(score);
            return {
                score: score,
                ex: ex
            };
        });
        objArr.sort((s1, s2) => {
            let result;
            if (s1.score < s2.score) result = -1;
            else if (s1.score > s2.score) result = 1;
            else if (s1.ex.name.toLowerCase() < s2.ex.name.toLowerCase()) result = -1;
            else if (s1.ex.name.toLowerCase() > s2.ex.name.toLowerCase()) result = 1;
            else result = 0;
            return result * sortType;
        });
        return objArr.map(obj => obj.ex);
    }

    public static sortItemsByOtherProperty(exArr: any[], sortBy: SortBy, sortType: number): CmsExerciseScript[] {
        function compareName(e1: CmsExerciseScript, e2: CmsExerciseScript) {
            let result;
            if (e1.name.toLowerCase() < e2.name.toLowerCase()) result = -1;
            else if (e1.name.toLowerCase() > e2.name.toLowerCase()) result = 1;
            else result = 0;
            return result * sortType;
        }

        function compareType(e1: CmsExerciseScript, e2: CmsExerciseScript) {
            let result;
            let type1 = TypeHelpers.isSingleInteraction(e1.definition);
            let type2 = TypeHelpers.isSingleInteraction(e2.definition);
            if (!type1) {
                if (type2) return 1;
                return 0;
            } else {
                if (!type2) return -1;
            }
            if (type1 < type2) result = -1;
            else if (type1 > type2) result = 1;
            else result = 0;
            return result * sortType;
        }

        function compareState(e1: CmsExerciseScript, e2: CmsExerciseScript) {
            let result;
            if (e1.state < e2.state) result = -1;
            else if (e1.state > e2.state) result = 1;
            else if (e1.state === CmsItemState.APPROVED && e2.state === CmsItemState.APPROVED) {
                if (e1.latestVersionNumbers && !e2.latestVersionNumbers) result = 1;
                if (e2.latestVersionNumbers && !e1.latestVersionNumbers) result = -1;
                if (e1.latestVersionNumbers && e2.latestVersionNumbers) {
                    result = e1.latestVersionNumbers.majorVersion - e2.latestVersionNumbers.majorVersion;
                    if (result === 0) {
                        result = e1.latestVersionNumbers.minorVersion - e1.latestVersionNumbers.minorVersion;
                    }
                }
            }
            else result = 0;
            return result * sortType;
        }

        function comparePublished(e1: CmsExerciseScript, e2: CmsExerciseScript) {
            let result;
            if (e2.publishedMajorVersion && !e1.publishedMajorVersion) result = -1;
            else if (e1.publishedMajorVersion && !e2.publishedMajorVersion) result = 1;
            else if (e1.publishedMajorVersion && e2.publishedMajorVersion) {
                if (e1.latestVersionNumbers && !e2.latestVersionNumbers) result = 1;
                if (e2.latestVersionNumbers && !e1.latestVersionNumbers) result = -1;
                if (e1.latestVersionNumbers && e2.latestVersionNumbers) {
                    result = e1.latestVersionNumbers.publishedMajorVersion - e2.latestVersionNumbers.publishedMajorVersion;
                    if (result === 0) {
                        result = e1.latestVersionNumbers.publishedMinorVersion - e1.latestVersionNumbers.publishedMinorVersion;
                    }
                }
            }
            else result = 0;
            return result * sortType;
        }

        function compareTested(e1: CmsExerciseScript, e2: CmsExerciseScript) {
            let result;
            if (e1.tested < e2.tested) result = -1;
            else if (e1.tested > e2.tested) result = 1;
            else result = 0;
            return result * sortType;
        }

        let compareFunction: (e1: CmsExerciseScript, e2: CmsExerciseScript) => number;

        switch (sortBy) {
            case SortBy.NAME:
                compareFunction = compareName;
                break;
            case SortBy.TYPE:
                compareFunction = compareType;
                break;
            case SortBy.STATE:
                compareFunction = compareState;
                break;
            case SortBy.PUBLISHED:
                compareFunction = comparePublished;
                break;
            case SortBy.TESTED:
                compareFunction = compareTested;
                break;
        }

        exArr.sort(compareFunction);
        return exArr;
    }

    public static sortAttributeAssignments(attributeAssignments: CmsTopicAttributeAssignment[]): CmsTopicAttributeAssignment[] {
        attributeAssignments.sort((a, b) =>
            a.difficulty.complexity == b.difficulty.complexity
                ? 0
                : a.difficulty.complexity < b.difficulty.complexity ? -1 : 1
        );
        return attributeAssignments;
    }

    public static sortAttributes(attributes: CmsTopicAttribute[]): CmsTopicAttribute[] {
        attributes.sort((a, b) =>
            a.name == b.name
                ? 0
                : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
        );
        return attributes;
    }

    public static sortDifficulties(difficulties: Difficulty[]): Difficulty[] {
        difficulties.sort((a, b) =>
            a.complexity == b.complexity
                ? 0
                : a.complexity < b.complexity ? -1 : 1
        );
        return difficulties;
    }
}

/**
 * Returns a value < 0 if version b is newer than a.
 * Returns a value > 0 if version a is newer than b
 * @param a 
 * @param b 
 */
 export function compareVersion(a: string, b: string) {
    let partsA = a.split('.');
    let partsB = b.split('.');

    let length = partsA.length;
    if (partsB.length > partsA.length) {
        length = partsB.length;
    }

    for (let i = 0; i < length; i++) {
        let verA = parseInt(partsA[i] || "0");
        let verB = parseInt(partsB[i] || "0");

        let result = verA - verB;
        if (result != 0) {
            return result;
        }
    }
    return 0;
}