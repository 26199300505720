<ng-template #state>
    <ng-container [ngSwitch]="getState()">
        <span class="label label-default" *ngSwitchCase="'DRAFT'" title="Draft">
            <i class="fa fa-flask"></i>
        </span>
        <span class="label label-info" *ngSwitchCase="'APPROVED'" title="Approved">
            <i class="fa fa-handshake-o"></i> <span *ngIf="leaf.latestVersionNumbers"
                class="hidden-sm">{{getApprovedVersionNumbers(leaf)}}</span>
        </span>
    </ng-container>
</ng-template>
<ng-template #published>
    <span class="label label-warning" title="Published Version" *ngIf='isPublished'>
        <i class="fa fa-leanpub"></i>
        {{leaf.latestVersionNumbers.publishedMajorVersion}}.{{leaf.latestVersionNumbers.publishedMinorVersion}}
    </span>
</ng-template>
<ng-template #tested>
    <span class="label label-success" *ngIf="getTested() == true">
        <i class="fa fa-check"></i>
    </span>
</ng-template>
<ng-template #validationStatus>
    <ng-container *ngIf="itemValidationStatus != null" [ngSwitch]="itemValidationStatus">
        <span class="validation-info-wrapper" (keyup.enter)="toggleValidationInfo()" (click)="toggleValidationInfo()" (focusout)="closeValidationInfo()" tabindex="0">
            <span class="label label-danger" title="Invalid Exercise" *ngSwitchCase="'FAILED'">
                <i class="fa fa-flag"></i>
            </span>
            <span class="label label-success" title="Exercise successfully validated" *ngSwitchCase="'PASSED'">
                <i class="fa fa-flag"></i>
            </span>
            <span class="label label-default" title="Validation information incomplete or unavailable"
                *ngSwitchCase="'UNAVAILABLE'">
                <i class="fa fa-question-circle "></i>
            </span>
            <div *ngIf="isShowValidationInfo" class="validation-info">
                <ul class="list-group">
                    <li class="list-group-item" *ngIf="validationReportForUser.length === 0">No detailed validation
                        information available</li>
                    <li class="list-group-item validation-report-item" *ngFor="let item of validationReportForUser">
                        <span class="validation-item-title">{{ item.title }}</span>
                        <div class="validation-item-status">
                            <ng-container [ngSwitch]="item.status">
                                <span *ngSwitchCase="'PASSED'" class="label label-success">Passed</span>
                                <span *ngSwitchCase="'FAILED'" class="label label-danger">Failed</span>
                                <span *ngSwitchCase="'UNAVAILABLE'" class="label label-default">Unavailable</span>
                            </ng-container>
                        </div>
                    </li>
                </ul>
            </div>
        </span>
    </ng-container>
</ng-template>

<div class='leaf row cms-navigator-item' [ngClass]="errorBadgeTitle ? 'cms-navigator-item--grayed-out':''">
    <div class='col-sm-5 col-xs-12'>
        <div class="row display-flex-center">
            <div class="col-sm-3">
                <input *ngIf="selectable" type="checkbox" [checked]="selected" (change)="checked($event)" />
                &nbsp;
                <div class="dropdown">
                    <ng-container *ngIf="!readOnly">
                        <button class="dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="true">
                            <span [ngClass]="leaf.iconClass"></span>&nbsp;<i class="fa fa-chevron-down"></i>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenu2"
                            *ngIf="leaf.actions && getActions().length > 0">
                            <li *ngFor="let action of getActions()" (click)="action.callback(leaf)"
                                class='dropdown-entry'>
                                <a class="clickable">
                                    <span [ngClass]="action.iconClass"></span>
                                    <span class='dropdown-entry-label'>{{action.name}}</span>
                                </a>
                            </li>
                            <li class='dropdown-entry' ngxClipboard [cbContent]="leaf.id">
                                <a class="clickable">
                                    <span class='fa fa-lg fa-clipboard'></span>
                                    <span class='dropdown-entry-label'>Copy exercise ID to clipboard</span>
                                </a>
                            </li>

                            <li class='dropdown-entry'>
                                <a class="clickable" (click)="onLeafClick()">
                                    <span class='fa fa-lg fa-eye'></span>
                                    <span class='dropdown-entry-label'>Preview</span>
                                </a>
                            </li>
                        </ul>
                    </ng-container>
                    <div *ngIf="readOnly" class="icon-container">
                        <span [ngClass]="leaf.iconClass"></span>
                    </div>
                </div>
            </div>
            <div class="col-sm-7">
                <a [href]="'cms/edit/'+leaf.id"><span [ngClass]="{'clickable': enabled, 'disabled': !enabled}">
                        {{leaf.name}}
                        <i *ngIf="errorBadgeTitle" [tooltip]="errorBadgeTitle"
                            class="error-badge fa fa-exclamation-circle"></i></span>
                </a>
            </div>

            <div class="col-sm-2">

                <span class="hidden-sm hidden-lg hidden-md cms-state-label" *ngIf="!browsingPublished">
                    <ng-container *ngTemplateOutlet="state"></ng-container>
                    <ng-container *ngTemplateOutlet="published"></ng-container>
                    <ng-container *ngTemplateOutlet="tested"></ng-container>
                    <ng-container *ngTemplateOutlet="validationStatus"></ng-container>
                </span>
            </div>


        </div>



    </div>


    <div class="col-sm-1 label-container-sm" *ngIf="!browsingPublished">
        <span class="cms-state-label cms-state-label-lg">
            <ng-container *ngTemplateOutlet="state"></ng-container>
        </span>
    </div>
    <div class="col-sm-1 label-container-sm">
        <span class="cms-state-label cms-state-label-lg" *ngIf='isPublished'>
            <ng-container *ngTemplateOutlet="published"></ng-container>
        </span>
    </div>
    <div class="col-sm-1 label-container-sm" *ngIf="!browsingPublished">
        <span class="cms-state-label">
            <ng-container *ngTemplateOutlet="tested"></ng-container>
        </span>
    </div>
    <div class="col-sm-1 label-container-sm" *ngIf="!browsingPublished">
        <span class="cms-state-label">
            <ng-container *ngTemplateOutlet="validationStatus"></ng-container>
        </span>
    </div>
</div>