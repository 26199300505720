import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CmsExerciseScript, CmsTopicAttribute, CmsTopic, CheckedEvent, CmsTopicAttributeAssignment, CmsItem, IdSearchResult, CmsItemAction, CmsLeaf, CmsExerciseFilter, CmsExerciseReference, CmsExerciseClientSpec, IdSearchResultType, isExerciseFilter, isExerciseReference, isExerciseClientSpec, isExerciseScript, CmsExercise } from '../../types/navigator.types';
import { UserService } from '../../../security/services/user.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TypeHelpers } from '../../util/type-helpers';

//interpolate between green and red
function getColor(value: number) {
    //value from 0 to 1
    var hue = ((1 - value) * 120).toString(10);
    return ["hsl(", hue, ",100%,50%)"].join("");
}

/**
 * A topic-leaf is a 'file' in the navigation system, which corresponds to an
 * exercise. The model is defined by class of type CmsLeaf. Classes for the various 
 * types of exercises are derived from CmsLeaf
 */
@Component({
    selector: 'id-search-leaf',
    templateUrl: './id-search-leaf.component.html',
    styleUrls: ['./id-search-leaf.component.css', '../../../../assets/shared.less']
})
export class IdSearchLeaf {

    @Input() item: IdSearchResult;
    @Input() itemActions: CmsItemAction[];
    @Input() errorBadgeTitle: string;
    @Input() enabled = true;

    @Output() onLeafClick: EventEmitter<IdSearchResult> = new EventEmitter<IdSearchResult>();

    constructor(
        private userService: UserService,
        private router: Router,
        private toastr: ToastrService
    ) { }

    onClick(): void {
        this.onLeafClick.emit(this.item);
    }

    getIconClass() {
        return TypeHelpers.getIconClass(this.item.item);
    }

    getLocation() {
        return this.item.type === IdSearchResultType.LIBRARY
            ? 'Library'
            : (this.item.item as CmsExercise).course.name;
    }

    getPath() {
        let path = this.item.path.map(p => p.name)
        let pathString = path.join(' / ');
        if (pathString.length > 0) {
            pathString = " / " + pathString;
        }
        return pathString;
    }

    getParentLink() {
        if (this.item.type === IdSearchResultType.LIBRARY) {
            let exercise = this.item.item as CmsExerciseScript;
            return exercise.topic
                ? '/topic/' + exercise.topic.id
                : null;
        }
        else {
            let exercise = this.item.item as CmsExercise;
            return exercise.subject
                ? '/subject/' + exercise.subject.id
                : null;
        }
    }

    getRouterLink() {
        let url;
        if (this.item.type === IdSearchResultType.LIBRARY) {
            url = "/edit/exercise";
        }
        else if (isExerciseFilter(this.item.item)) {
            url = "/edit/exercise-filter";
        }
        else if (isExerciseReference(this.item.item)) {
            url = "/edit/exercise-reference";
        }
        else if (isExerciseClientSpec(this.item.item)) {
            url = "/edit/exercise-spec-client";
        }
        return [url, this.item.item.id];
    }
}



