
import {switchMap} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

import { ToastrService } from 'ngx-toastr';

import { I18nLabel, REGIONS } from '../../types/cms-metadata.types';
import { I18nLabelService } from '../../services/i18n-label.service';

@Component({
    templateUrl: './label-editor.component.html',
    styleUrls: ['./label-editor.component.css']
})
export class LabelEditorComponent implements OnInit {
    regions = REGIONS;

    existingLabels: I18nLabel[]  = [];

    label: I18nLabel;

    checkLangs = ['nl', 'en'];

    currentId: string;

    constructor(
        private labelService: I18nLabelService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private location: Location
    ) {
    }

    cancel() {
        this.location.back();
    }

    checkIdExists() {
        if (this.currentId === this.label.id) {
            return false;
        }
        return !!this.existingLabels.find(label => label.id === this.label.id);
    }

    valid(): boolean {
        if (!this.label.id) {
            return false;
        }
        if (this.checkIdExists()) {
            return false;
        }
        for (let lang of this.checkLangs) {
            if (!this.label.items[lang].text || this.label.items[lang].text.trim() == "") {
                return false;
            }
        }
        return true
    }

    getInputClass(value: string, isId?: boolean, lang?: string) {
        if (lang && this.checkLangs.indexOf(lang) == -1) {
            return [];
        }
        if (!value || value.trim() == "" || (isId && this.checkIdExists())) {
            return ['input-invalid'];
        }
        return [];
    }

    onSubmit() {
        this.labelService.saveLabel(this.label)
            .then(() => {
                this.toastr.success('Label saved succesfully', 'Success');
                this.labelService.refreshLabels();
                this.location.back();
            })
            .catch(err => {
                console.log(err);
                this.toastr.error("An error occured while saving the label", "Error");
            })
    }

    ngOnInit() {
        this.labelService.labels$.subscribe((labels) => {
            this.existingLabels = labels;
        })
        this.route.params.pipe(
            switchMap((params: Params) => {
                if (!params['id']) {
                    return Promise.resolve(null);
                }
                return this.labelService.getLabel(params['id'])
            }))
            .subscribe((label: I18nLabel) => {
                if (label === null) {
                    label = this.labelService.createLabel();
                }
                this.currentId = label.id;
                for (let region of this.regions) {
                    if (!label.items[region.id]) {
                        label.items[region.id] = { lang: region.id, text: "" };
                    }
                }
                this.label = label;
            });
    }
}

