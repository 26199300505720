import {merge, catchError, tap, switchMap} from 'rxjs/operators';
import { Component, Input, Output, ViewChild, EventEmitter, ElementRef, OnInit, OnDestroy, NgZone} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ICMS2ExerciseSpec } from '../../../authoring/types/cms2-metadata.types'
import { IAlgebraKIT, ISessionId, IErrorState } from '../../types/algebrakit.type';
import { SessionService } from '../../services/session.service';
import 'rxjs/Rx';

declare let AlgebraKIT: IAlgebraKIT;

// Angular wrapper for akit-exercise.
// parameters:
// - app-id
// - session-id (optional)
// - exercise-spec (optional), if given, a new session will be created.
// Either exercise-spec or session-id must be given

@Component({
  selector: 'cms-calcbook',
  template: `
    <akit-calcbook #widget [attr.app-id]="appId" [attr.session-id]="__sessId" [attr.mode]="mode"></akit-calcbook>
  `
})
export class CmsCalcbookComponent implements OnInit, OnDestroy {
    _exerciseSpec = new BehaviorSubject<ICMS2ExerciseSpec>(null);
    _sessionId = new BehaviorSubject<string>(null);
    __sessId: string;
    
    @Input() appId : string;
    @Input() mode: string;
    // publish session-id when session is created
    @Output() newSessionId: EventEmitter<ISessionId> = new EventEmitter<ISessionId>();
    @Output() onTestOK: EventEmitter<ISessionId> = new EventEmitter<ISessionId>();
    @Output() onError: EventEmitter<IErrorState> = new EventEmitter<IErrorState>();
    
    @ViewChild('widget', {static: false}) widget : ElementRef; 
    
    constructor(
        private sessionService: SessionService,
        private toastr: ToastrService,
        private _ngZone: NgZone
    ) { 
    }
    
    @Input()
    set exerciseSpec(spec: ICMS2ExerciseSpec) {
        if(spec) this._exerciseSpec.next(spec);
    }

    @Input()
    set sessionId(id: string) {
        if(id) this._sessionId.next(id);
    }

    onExerciseError(eventName: string, event : ({valid:boolean, msg:string})) {
        let errState : IErrorState = {
            status: -1,
            message: event.msg,
            stack: null
        };
        this.onError.emit(errState);
    }
    
    onExerciseTestOk() {
        this.onTestOK.emit({
                    appId: this.appId,
                    sessionId: this.__sessId
                  });
    }
    
    ngOnInit() {
        //on setting spec OR session id, trigger refresh of widgets
        let specStream = this._exerciseSpec.asObservable().pipe(
            switchMap(spec => this.sessionService.createSession(spec)),
            tap(sessionData => this.newSessionId.emit({
                    appId: this.appId,
                    sessionId: sessionData.sessionId   
                  })
             ),
            catchError( (error:any) => {
                this.toastr.error("An error occurred. Click for details", "Error");
                window.scrollTo(0, 0);
                return [];
            }),);
            
        let sessionStream = this._sessionId.asObservable();
        
        specStream.pipe(merge(sessionStream))
            .subscribe(id => {
                if(typeof(id)==='string'){
                    if (this.__sessId) {
                        AlgebraKIT.removeWidgetBySessionId(this.__sessId, false);
                    }
                    this.__sessId = id;
                }
            }
        )
    }
    
    ngOnDestroy() {
        if (this.__sessId) {
            AlgebraKIT.removeWidgetBySessionId(this.__sessId, false);
        }
    }
}