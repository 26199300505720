import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { CmsExerciseScript } from '../types/navigator.types';
import { ExerciseScriptService } from './exercise-script.service';
import { ToastrService } from 'ngx-toastr';
import { SetTitleService } from '../../app/services/set-title.service';
import { Title } from '@angular/platform-browser';
import { VersioningService } from './versioning.service';

@Injectable()
export class ExerciseResolverService extends SetTitleService implements Resolve<CmsExerciseScript> {
    constructor(
        protected exerciseScriptService: ExerciseScriptService,
        protected toastr: ToastrService,
        protected titleService: Title,
        protected versioningService: VersioningService
    ) {
        super(titleService);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<CmsExerciseScript> {
        let id = route.params['id'];
        return this.exerciseScriptService.lockResourceExceptReadOnly(id).then(() => {
            return this.exerciseScriptService.getExercise(id).toPromise();
        })
            .then((ex) => {
                this.setTitle(ex.name);
                return ex;
            })
            .catch((error: any) => {
                this.versioningService.handleVersionMisMatch(error);
                this.exerciseScriptService.showLockErrorMessage(error, true);
                throw new Error(error.message);
            });
    }
}

