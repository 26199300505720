import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';



import { UserService } from '../../services/user.service';
import { FormBuilder, FormGroup, FormControl, Validators, EmailValidator } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
    templateUrl: './invite-register.component.html',
    styleUrls: ['./invite-register.component.css']
})
export class InviteRegisterComponent implements OnInit {

    redirect: string; //navigate to this route after succes

    form: FormGroup;

    token: string;

    usernameCtrl = new FormControl('', [Validators.required, Validators.minLength(4), this.validateUsernameChars]);
    emailCtrl = new FormControl('', [Validators.required]);
    firstNameCtrl = new FormControl('', [Validators.required]);
    lastNameCtrl = new FormControl('', [Validators.required]);
    passwordCtrl = new FormControl('', [Validators.required, Validators.minLength(6)]);
    confirmPasswordCtrl = new FormControl('', [Validators.required, Validators.minLength(6)]);
    tokenCtrl = new FormControl('', [Validators.required]);

    constructor(
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
    ) {
        this.form = this.formBuilder.group({
            username: this.usernameCtrl,
            email: this.emailCtrl,
            firstName: this.firstNameCtrl,
            lastName: this.lastNameCtrl,
            passwords: formBuilder.group({
                password: this.passwordCtrl,
                confirmPassword: this.confirmPasswordCtrl,
            }, { validator: this.validatePasswordsAreEqual }),
        });
    }

    validatePasswordsAreEqual(group: FormGroup) {
        return group.controls['password'].value === group.controls['confirmPassword'].value ? null : { notEqual: true };
    }

    validateUsernameChars(control: FormControl) {
        let regex = new RegExp('^[A-z0-9_]*$');
        return regex.test(control.value) ? null : { invalidChars: true };
    }

    register(): void {
        let user = {
            ...this.form.value
        }
        this.userService.registerWithInvite(user.username, user.email, this.token, user.firstName, user.lastName, user.passwords.password)
            .then(() => {
                this.toastr.success('Registration successfull.', 'Success');
                this.userService.login(user.username, user.passwords.password).then(() => {
                    if (this.redirect) {
                        this.router.navigate([this.redirect]);
                    }
                    else {
                        this.router.navigate(['home']);
                    }
                });
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    this.toastr.error('An invalid email address and/or token was specified in the link you have received. Please reload the link from your invitation email.', 'Error');
                }
                else {
                    this.toastr.error(error.message, 'Error');
                }
            });
    }

    ngOnInit() {
        this.form.patchValue({
            email: this.route.snapshot.params['email'],
        })
        this.token = this.route.snapshot.params['token'];
    }
}

