
import { filter, map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild, HostListener, Input, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

import { CmsItemAction, CmsItem, CmsSubject, CmsLeaf, CmsExerciseFilter, CmsExerciseReference, CmsExercise, CmsExerciseScript, CmsExerciseClientSpec, CheckedEvent, CmsItemState, CmsExerciseRef, BrowseMode, CmsContainerType, CmsCourseRef, ExerciseSaveResult } from '../../types/navigator.types';
import { UserService } from '../../../security/services/user.service';
import { QuestionMode } from '../../../algebrakit/types/metadata.type';
import { NameIdPair, ExerciseRef, CmsExerciseType } from "../../types/cms-metadata.types";
import { CourseService } from '../../services/course.service';
import { SubjectService } from '../../services/subject.service';
import { ExerciseService } from '../../services/exercise.service';
import { ExerciseScriptService } from '../../services/exercise-script.service';
import { ExercisePreviewComponent } from '../../components/exercise-preview/exercise-preview.component';
import { TypeHelpers } from '../../util/type-helpers';
import { ResourceService } from '../../services/resource.service';
import { VersioningService } from '../../services/versioning.service';
import { IErrorData } from '../../../algebrakit/types/algebrakit.type';
import { IAlgebraKIT } from '../../../algebrakit/types/algebrakit.type';
import { AppProfileService } from '../../../app/services/app-profile.service';
import { compareVersion } from '../../util/sorting';
import { ContentVersionService } from '../../../app/services/content-version-service';

declare let $: any;
declare let AlgebraKIT: IAlgebraKIT;

@Component({
    selector: 'course-navigator',
    templateUrl: './course-navigator.component.html',
    styleUrls: ['./course-navigator.component.less', '../../../../assets/shared.less']
})
export class CourseNavigatorComponent implements OnInit, OnDestroy {
    //If used as a component, these properties can be used
    @Input() browseMode: BrowseMode = BrowseMode.EDIT;
    @Input() useAsComponent: boolean = false; //Must be set if used as a component!

    @Output() selectedChanged: EventEmitter<CmsExercise[]> = new EventEmitter();

    @Input() //Input is used when the course-navigator isused as a component
    courseRef: CmsCourseRef;

    @Input() incompatibleExercisesSelectable = true;

    @Input()
    forFilterSpecs: boolean = false;

    currentFolder: BehaviorSubject<CmsSubject> = new BehaviorSubject(null);
    childSubjects: CmsSubject[];
    exercises: CmsExercise[];
    subscriptions: Subscription[] = [];
    isDirty: boolean = false; //if true, it needs refresh 
    testObj: ExerciseRef | CmsExerciseScript;
    testAudience: string = null;
    testType: CmsExerciseType;

    changeCourseDialogVisible: boolean = false;
    changeCourseSubjectDialogVisible: boolean = false;

    historyItem: CmsItem;

    @ViewChild('exercisePreview', { static: false }) exercisePreview: ExercisePreviewComponent;

    loading: boolean = false;

    selectedExercises: { [id: string]: boolean } = {};
    exercisesToMove: CmsExercise[] = [];
    subjectToMove: CmsSubject;

    approveFailures: NameIdPair[];

    currentVersion: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private exerciseService: ExerciseService,
        private exerciseScriptService: ExerciseScriptService,
        private subjectService: SubjectService,
        private courseService: CourseService,
        private userService: UserService,
        private resourceService: ResourceService,
        private versioningService: VersioningService,
        private profileService: AppProfileService,
        private contentVersionService: ContentVersionService,
    ) {
    }

    showHistory(leaf: CmsItem) {
        if (leaf.state !== CmsItemState.APPROVED) {
            alert("No history available for this item");
            return;
        }
        this.testObj = null;
        this.historyItem = leaf;
        let self = this;
        setTimeout(() => {
            var modal = $('.item-history').first();
            modal.modal('show').on('hide.bs.modal', function (e: any) {
                self.historyItem = null;
            });
        }, 0)
    }

    onInteractionError(err: IErrorData) {
        this.toastr.error(err.msg, 'Could not run exercise');
    }

    refresh() {
        this.changeCourseDialogVisible = false;
        this.loadSubject(this.currentFolder.value.id, this.courseRef.id);
    }

    loadSubject(id: string, courseId: string) {
        let promise: Promise<CmsSubject>
        if (this.forFilterSpecs) {
            promise = this.subjectService.getSubjectForFilterSpec(id).toPromise();
        }
        else {
            promise = this.subjectService.getSubject(id, null, courseId).toPromise();
        }
        promise.then(subject => this.currentFolder.next(subject));
    }

    refreshPreview() {
        this.exercisePreview.generate();
    }

    editSubject(id: string) {
        this.isDirty = true;
        this.router.navigate(['edit/subject', id])
    }

    editExercise() {
        let exercise = (this.testObj as ExerciseRef);
        let id = exercise.exerciseId;
        if (!id) {
            this.toastr.error("Cannot edit this object", "Error");
            return;
        }

        this.closeModal();

        let type;

        if (exercise.type === 'FILTER') {
            type = "exercise-filter";
        }
        else if (exercise.type === 'REFERENCE') {
            type = "exercise-reference";
        }
        else if (exercise.type === 'SPECIFICATION') {
            type = "exercise-spec-client";
        }

        this.router.navigate([`edit/${type}`, id]);
    }

    getName(exercise: ExerciseRef | CmsExerciseScript | CmsExerciseRef) {
        return exercise
            ? (
                (exercise as any).title
                    ? (exercise as any).title
                    : exercise.name
            )
            : "";
    }

    deleteSubject(subject: CmsItem) {
        this.subjectService.checkDeleteSubject(subject.id)
            .then(() => {
                this.confirmDelete(subject);
            })
            .catch((error) => {
                if (this.userService.canManageCourse(this.courseRef)) {
                    this.promptDelete(subject);
                }
                else {
                    this.handleDeleteSubjectError(error);
                }
            });
    }

    confirmDelete(subject: CmsItem) {
        if (confirm(`Are you sure you want to delete the folder '${subject.name}'?`)) {
            this.subjectService.deleteSubject(subject.id)
                .then((res: any) => {
                    this.refresh();
                    this.toastr.success("Succesfully deleted the folder");
                }, error => {
                    this.handleDeleteSubjectError(error);
                })
        }
    }

    promptDelete(subject: CmsItem) {
        let subjectName = prompt(`You are about to delete the folder "${subject.name}". This folder still contains exercises and/or child folder. Performing this action will also delete these child folder and exercises. Are you sure you want to proceed? Please enter the name of the folder to confirm`, '')
        if (subjectName.trim() == subject.name.trim()) {
            this.subjectService.deleteSubject(subject.id)
                .then((res: any) => {
                    this.refresh();
                    this.toastr.success("Succesfully deleted the folder");
                }, error => {
                    this.handleDeleteSubjectError(error);
                })
        }
        else {
            alert('You did not type the correct folder name.')
        }
    }

    handleDeleteSubjectError(error: any) {
        console.log(error);
        let msg = 'Could not delete this folder';
        if (error.message) {
            msg = msg + ': ' + error.message;
        }
        this.toastr.error(msg, 'Error');
    }

    deleteExercise(exercise: CmsItem) {
        if (confirm(`Are you sure you want to delete the exercise '${exercise.name}'?`)) {
            this.exerciseService.deleteExerciseFilter(exercise.id)
                .then(() => {
                    this.refresh();
                    this.toastr.success("Succesfully deleted the exercise");
                }).catch(err => {
                    console.log(err);
                    if (!err.originalError || !this.exerciseService.showLockErrorMessage(err.originalError, false)) {
                        this.toastr.error('Could not delete this exercise', 'Error');
                    }
                    this.refresh();
                })
        }
    }

    deleteExerciseReference(exercise: CmsItem) {
        if (confirm(`Are you sure you want to delete the exercise '${exercise.name}'?`)) {
            this.exerciseService.deleteExerciseReference(exercise.id)
                .then(() => {
                    this.refresh();
                    this.toastr.success("Succesfully deleted the exercise");
                }).catch(err => {
                    console.log(err);
                    if (!err.originalError || !this.exerciseService.showLockErrorMessage(err.originalError, false)) {
                        this.toastr.error('Could not delete this exercise', 'Error');
                    }
                    this.refresh();
                })
        }
    }

    deleteExerciseClientSpec(exercise: CmsItem) {
        if (confirm(`Are you sure you want to delete the exercise '${exercise.name}'?`)) {
            this.exerciseService.deleteExerciseClientSpec(exercise.id)
                .then(() => {
                    this.refresh();
                    this.toastr.success("Succesfully deleted the exercise");
                }).catch(err => {
                    console.log(err);
                    if (!err.originalError || !this.exerciseService.showLockErrorMessage(err.originalError, false)) {
                        this.toastr.error('Could not delete this exercise', 'Error');
                    }
                    this.refresh();
                })
        }
    }

    deleteExercises() {
        if (confirm('Are you sure you want to delete all selected exercises?')) {
            let ids: string[] = this.getSelectedExercises().map(e => e.id);
            this.exerciseService.deleteExercises(ids)
                .then(() => {
                    this.refresh();
                    this.toastr.success("Succesfully deleted the selected exercises");
                });
        }
    }

    getSelectedExercises(ids?: string[]): CmsExercise[] {
        let result: string[] = ids
            ? ids
            : Object.keys(this.selectedExercises)
                .filter(key => this.selectedExercises[key] === true)
                .map(key => key);
        return this.exercises.filter(e => result.indexOf(e.id) !== -1).map(e => {
            e.subject = {
                id: this.currentFolder.value.id,
                name: this.currentFolder.value.name,
                containerType: CmsContainerType.FILE
            };
            return e;
        });
    }

    createSubject(): void {
        let subject = this.subjectService.createSubject(this.currentFolder.value);
        this.subjectService.storeSubject(subject)
            .then((id: string) => {
                this.refresh();
                this.editSubject(id);
            }).catch(err => {
                console.log(err);
                this.toastr.error('Could not store the new folder', 'Error');
                this.refresh();
            });

    }

    createExerciseFilter() {
        this.courseService.getCourse(this.courseRef.id).toPromise().then(
            course => {
                let ex = this.exerciseService.createExerciseFilter(
                    this.currentFolder.value,
                    this.courseRef
                );
                this.exerciseService.storeExerciseFilter(ex)
                    .then((id: string) => {
                        this.refresh();
                        this.editExerciseArrangement(id);
                    }).catch(err => {
                        console.log(err);
                        this.toastr.error('Could not store the new exercise arrangement', 'Error');
                        this.refresh();
                    })
            }
        );
    }

    createExerciseReference() {
        this.courseService.getCourse(this.courseRef.id).toPromise().then(
            course => {
                let ex = this.exerciseService.createExerciseReference(
                    this.currentFolder.value,
                    this.courseRef
                );
                this.exerciseService.storeExerciseReference(ex)
                    .then((id: string) => {
                        this.refresh();
                        this.editExerciseReference(id);
                    }).catch(err => {
                        console.log(err);
                        this.toastr.error('Could not store the new exercise reference', 'Error');
                        this.refresh();
                    })
            }
        );
    }

    createExerciseSpecification(): void {
        this.courseService.getCourse(this.courseRef.id).toPromise().then(
            course => {
                let ex = this.exerciseService.createExerciseClientSpec(
                    this.currentFolder.value,
                    this.courseRef
                );
                let questionMode = this.currentFolder.value.defaultQuestionMode;
                if (questionMode != null && questionMode != '') {
                    ex.definition.questionMode = QuestionMode[questionMode];
                }
                this.exerciseService.storeExerciseClientSpec(ex)
                    .then((obj: ExerciseSaveResult) => {
                        this.refresh();
                        this.editExerciseClientSpec(obj.id);
                    }).catch(err => {
                        console.log(err);
                        this.toastr.error('Could not store the new exercise reference', 'Error');
                        this.refresh();
                    })
            }
        );
    }

    setParent(id: string): void {
        this.exportersClose();
        this.approveFailures = [];
        if (!this.useAsComponent) {
            this.router.navigate(['subject', id]);
        }
        else {
            this.loadSubject(id, null)
        }
    }

    changeExerciseCourse(exercises?: CmsExercise[]) {
        this.exportersClose();
        this.exercisesToMove = exercises ? exercises : this.getSelectedExercises();
        if (this.exercisesToMove.length === 0) {
            this.toastr.warning("Please select at least one item", "Warning");
            return;
        }
        this.changeCourseDialogVisible = true;
        window.scrollTo(0, 0)
    }

    moveSubject(subject: CmsSubject) {
        this.exportersClose();
        this.subjectToMove = subject;
        if (!this.subjectToMove) {
            this.toastr.warning("Please select a folder", "Warning");
            return;
        }
        this.changeCourseSubjectDialogVisible = true;
        window.scrollTo(0, 0);
    }

    exportersClose() {
        this.changeCourseDialogVisible = false;
        this.changeCourseSubjectDialogVisible = false;
    }

    getVersionMismatchError(exercise: CmsExercise) {
        return this.isVersionMismatch(exercise)
            ? "This exercise was edited in a newer version and cannot be opened"
            : null;
    }

    isVersionMismatch(exercise: CmsExercise) {
        return compareVersion(this.currentVersion, exercise.contentVersion) < 0
    }

    testExercise(leaf: CmsExercise) {
        if (compareVersion(this.currentVersion, leaf.contentVersion) < 0) {
            //Exercise version is too high, cannot preview.
            return;
        }
        let self = this;
        let courseRef = this.currentFolder.value.course;
        let theme = courseRef.options && courseRef.options.theme
            ? courseRef.options.theme
            : 'akit';
        AlgebraKIT._api.changeTheme(theme);
        self.testAudience = null;
        self.testObj = {
            type: leaf.type,
            exerciseId: leaf.id,
            courseId: this.courseRef.id,
            name: leaf.name,
            title: leaf.title,
            multiplePreviews: leaf.multiplePreviews,
            contentVersion: leaf.contentVersion
        }
        var modal = $('.exercise-demo').first();
        modal.modal('show').on('hide.bs.modal', function (e: any) {
            self.testObj = null;//trigger destroy of widgets
        });
    }

    //Close modals by pressing ESC
    @HostListener('document:keyup', ['$event'])
    onKeyUp(ev: KeyboardEvent) {
        if (!ev.key) {
            return;
        }
        if (ev.key.toLowerCase() === 'escape') {
            this.closeModal();
        }
    }

    closeModal() {
        var modal = $('.modal');
        if (modal) modal.modal('hide');
    }

    editExerciseArrangement(id: string) {
        this.isDirty = true;
        this.router.navigate(['edit/exercise-filter', id]);
    }

    editExerciseReference(id: string) {
        this.isDirty = true;
        this.router.navigate(['edit/exercise-reference', id]);
    }

    editExerciseClientSpec(id: string) {
        this.isDirty = true;
        this.router.navigate(['edit/exercise-spec-client', id]);
    }

    copyExerciseArrangement(id: string): void {
        this.exerciseService.getExerciseFilter(id).pipe(
            map(ex => {
                let exCopy: CmsExerciseFilter = { ...ex };
                exCopy.id = null;
                exCopy.name = exCopy.name + '-copy';
                return TypeHelpers.removeMetaDataIds(exCopy) as CmsExerciseFilter;
            })).toPromise()
            .then(ex => this.exerciseService.storeExerciseFilter(ex))
            .then(() => {
                this.refresh();
                this.toastr.success("Exercise successfully copied");
            })
            .catch(err => {
                console.log(err);
                this.toastr.error('Could not create a copy of this exercise', 'Error');
            })

    }

    copyExerciseReference(id: string): void {
        this.exerciseService.getExerciseReference(id).pipe(
            map(ex => {
                let exCopy: CmsExerciseReference = { ...ex };
                exCopy.id = null;
                exCopy.name = exCopy.name + '-copy';
                return TypeHelpers.removeMetaDataIds(exCopy) as CmsExerciseReference;
            })).toPromise()
            .then(ex => this.exerciseService.storeExerciseReference(ex))
            .then(() => {
                this.refresh();
                this.toastr.success("Exercise successfully copied");
            })
            .catch(err => {
                console.log(err);
                this.toastr.error('Could not create a copy of this exercise', 'Error');
            })
    }

    copySubject(id: string): void {
        if (confirm('Are you sure you want to copy this folder including its contents?')) {
            this.loading = true;
            this.subjectService.copySubject(id)
                .then(() => {
                    this.refresh()
                    this.toastr.success("Copy folder successful");
                })
                .catch(err => {
                    console.log(err);
                    this.toastr.error('Could not create a copy of this folder', 'Error');
                }).finally(() => {
                    this.loading = false;
                })
        }
    }

    copyExerciseClientSpec(id: string): void {
        this.exerciseService.copyExercise(id)
            .then(() => {
                this.refresh();
                this.toastr.success("Exercise successfully copied");
            })
            .catch(err => {
                console.log(err);
                this.toastr.error('Could not create a copy of this exercise', 'Error');
            })
    }

    copyExercises() {
        let ids: string[] = this.getSelectedExercises().map(e => e.id);
        this.exerciseService.copyExercises(ids)
            .then(() => {
                this.refresh()
                this.toastr.success("Succesfully copied the selected exercises");
            });
    }

    inTrash() {
        let current = this.currentFolder.value;
        return current.trash
            || (current.parents && current.parents[0] && current.parents[0].trash);
    }

    allSelected() {
        return this.exercises.every(e => !!this.selectedExercises[e.id]);
    }

    checkAll(e: any) {
        let checked: boolean = !!e.target.checked;
        this.selectedExercises = {};
        for (let script of this.exercises) {
            if (checked) {
                this.selectedExercises[script.id] = true;
            } else {
                this.selectedExercises[script.id] = false;
            }
        }
        this.emitSelected();
    }

    exerciseSelectedChanged(event: CheckedEvent<CmsLeaf>) {
        let exercise = this.exercises.find(e => e.id === event.item.id);
        if (!exercise) {
            return;
        }
        if (event.checked) {
            this.selectedExercises[exercise.id] = true;
        }
        else if (!event.checked) {
            this.selectedExercises[exercise.id] = false;
        }
        this.emitSelected();
    }

    getExerciseActions(type: CmsExerciseType): CmsItemAction[] {
        if (!this.canEditCourse()) {
            return [];
        }
        let actions: CmsItemAction[] = [];
        switch (type) {
            case CmsExerciseType.FILTER:
                actions = [
                    {
                        name: 'edit',
                        iconClass: 'fa fa-lg fa-pencil-square',
                        callback: ((leaf: CmsLeaf) => this.editExerciseArrangement(leaf.id))
                    }, {
                        name: 'copy',
                        iconClass: 'fa fa-lg fa-files-o',
                        callback: ((leaf: CmsLeaf) => this.copyExerciseArrangement(leaf.id))
                    }, {
                        name: 'delete',
                        iconClass: 'fa fa-lg fa-times',
                        callback: ((leaf: CmsLeaf) => this.deleteExercise(leaf))
                    },
                    {
                        name: 'history',
                        iconClass: 'fa fa-lg fa-clock-o',
                        callback: ((leaf: CmsExerciseFilter) => this.showHistory(leaf))
                    }];
                break;
            case CmsExerciseType.REFERENCE:
                actions = [
                    {
                        name: 'edit',
                        iconClass: 'fa fa-lg fa-pencil-square',
                        callback: ((leaf: CmsLeaf) => this.editExerciseReference(leaf.id))
                    }, {
                        name: 'copy',
                        iconClass: 'fa fa-lg fa-files-o',
                        callback: ((leaf: CmsLeaf) => this.copyExerciseReference(leaf.id))
                    }, {
                        name: 'delete',
                        iconClass: 'fa fa-lg fa-times',
                        callback: ((leaf: CmsLeaf) => this.deleteExerciseReference(leaf))
                    }];
                break;
            case CmsExerciseType.SPECIFICATION:
                actions = [
                    {
                        name: 'edit',
                        iconClass: 'fa fa-lg fa-pencil-square',
                        callback: ((leaf: CmsLeaf) => this.editExerciseClientSpec(leaf.id))
                    }, {
                        name: 'copy',
                        iconClass: 'fa fa-lg fa-files-o',
                        callback: ((leaf: CmsLeaf) => this.copyExerciseClientSpec(leaf.id))
                    }, {
                        name: 'delete',
                        iconClass: 'fa fa-lg fa-times',
                        callback: ((leaf: CmsLeaf) => this.deleteExerciseClientSpec(leaf))
                    },
                    {
                        name: 'history',
                        iconClass: 'fa fa-lg fa-clock-o',
                        callback: ((leaf: CmsExerciseClientSpec) => this.showHistory(leaf))
                    }];
                break;
        }

        return [
            ...actions, {
                name: 'move',
                iconClass: 'fa fa-lg fa-share',
                callback: ((leaf: CmsExercise) => this.changeExerciseCourse(this.getSelectedExercises([leaf.id])))
            }
        ];
    }

    clearSelected(exerciseList: CmsItem[]) {
        this.selectedExercises = {};
        for (let script of exerciseList) {
            this.selectedExercises[script.id] = false;
        }
        this.emitSelected();
    }

    emitSelected() {
        let selected: CmsExercise[] = this.getSelectedExercises();
        this.selectedChanged.emit(selected);
    }

    getLatestVersionNumbers() {
        this.exerciseService.getLatestVersionNumbers(this.exercises)
            .then(versionNumbers => {
                for (let exercise of this.exercises) {
                    exercise.latestVersionNumbers = versionNumbers[exercise.id];
                }
            })
    }

    canEditCourse = () => {
        return this.userService.canEditCourse(this.courseRef);
    }

    canApprove(): boolean {
        return this.userService.canApproveCourseExercises(this.courseRef);
    }

    approveSelected() {
        let selected: CmsExercise[] = this.getSelectedExercises();

        if (confirm("You are about to move the selected exercises from the DRAFT to the APPROVED state and enable versioning. Are you sure?")) {
            let ids = selected.map(ex => ex.id);
            this.versioningService.approveCourseExercises(ids)
                .then((failed: CmsItem[]) => {
                    this.refresh();
                    if (failed && failed.length > 0) {
                        if (failed.length === selected.length) {
                            this.toastr.error("None of the selected exercises could be approved. Correct the errors in these exercises before approving them", 'Error');
                        }
                        else {
                            this.toastr.warning("One or more selected exercises could not be approved. Correct the errors in these exercises before approving them", 'Warning');
                        }
                    }
                    else {
                        this.toastr.success("The selected exercises were succesfully approved.", 'Success');
                    }
                    this.approveFailures = failed;
                })
                .catch((err: any) => {
                    console.log(err);
                    this.toastr.error("An error occurred while approving the exercises");
                })
        }
    }

    ngOnInit() {
        this.contentVersionService.getContentVersion().then(contentVersion => this.currentVersion = contentVersion);
        let s1 = this.route.data.subscribe((data: { subject: CmsSubject }) => {
            if (this.useAsComponent) {
                if (this.courseRef && !this.currentFolder.value) {
                    this.subjectService.getRootSubject(this.courseRef.id)
                        .toPromise()
                        .then((subject) => {
                            this.approveFailures = [];
                            this.currentFolder.next(subject);
                        })
                }
                return;
            }
            this.changeCourseDialogVisible = false;
            this.loading = true;
            let subject = data.subject;
            this.currentFolder.next(subject);
            this.loading = false;
            this.courseRef = subject.course || null;
        });

        let folderActions: CmsItemAction[] = [
            {
                name: 'edit',
                iconClass: 'fa fa-lg fa-pencil-square',
                callback: ((subject: CmsSubject) => this.editSubject(subject.id))
            }, {
                name: 'copy',
                iconClass: 'fa fa-lg fa-copy',
                callback: ((subject: CmsSubject) => this.copySubject(subject.id))
            },
            {
                name: 'move',
                iconClass: 'fa fa-lg fa-share',
                callback: ((subject: CmsSubject) => this.moveSubject(subject))
            }, {
                name: 'delete',
                iconClass: 'fa fa-lg fa-times',
                callback: ((subject: CmsSubject) => this.deleteSubject(subject))
            }];

        if (this.userService.hasMultipleCourses()) {
            folderActions = [
                ...folderActions, {
                    name: 'move to course',
                    iconClass: 'fa fa-lg fa-share',
                    callback: ((subject: CmsSubject) => this.moveSubject(subject))
                }
            ];
        }

        if (this.browseMode === BrowseMode.EDIT && this.userService.canPublishInCourse(this.currentFolder.value.course)) {
            folderActions.push({
                name: 'publish folder',
                iconClass: 'fa fa-lg fa-book',
                callback: ((subject: CmsSubject) => this.router.navigate(['publish-subject', subject.id]))
            })
        }

        let s2 = this.currentFolder.pipe(
            filter(folder => folder != null),
            map(folder => {
                this.isDirty = false;
                this.courseRef = folder.course;
                return (!folder.childFolders) ? [] : folder.childFolders
                    .map(childRef => ({
                        name: childRef.name,
                        id: childRef.id,
                        course: folder.course,
                        onClick: (item: CmsItem) => this.setParent(item.id),
                        actions: this.canEditCourse() ? folderActions : [],
                        iconClass: TypeHelpers.getIconClass(childRef),
                    }))
                    .sort((f1, f2) => {
                        if (f1.name.toLowerCase() < f2.name.toLowerCase()) return -1;
                        if (f1.name.toLowerCase() > f2.name.toLowerCase()) return 1;
                        return 0;
                    })
            }),)
            .subscribe(items => {
                this.childSubjects = this.sortByName(items) as CmsSubject[];
            }, (err) => {
                this.toastr.error('Could not retrieve folders', 'Error');
                return [];
            });


        let s3 = this.currentFolder.pipe(
            filter(folder => folder != null),
            map(folder =>
                (!folder.childLeafs) ? [] : folder.childLeafs
                    .map(childRef => ({
                        name: childRef.name,
                        id: childRef.id,
                        title: childRef.title,
                        course: folder.course,
                        onClick: (item: CmsExercise) => this.testExercise(item),
                        actions: this.getExerciseActions(childRef.exerciseType),
                        iconClass: TypeHelpers.getIconClass(childRef),
                        type: childRef.exerciseType,
                        audience: childRef.audience,
                        state: childRef.state,
                        tested: childRef.tested,
                        multiplePreviews: childRef.multiplePreviews,
                        contentVersion: childRef.contentVersion,
                        validationReport: childRef.validationReport
                    }))
            ),)
            .subscribe(items => {
                this.exercises = this.sortByName(items) as CmsExercise[];
                this.clearSelected(this.exercises);
                this.getLatestVersionNumbers();
            }, (err) => {
                this.toastr.error('Could not retrieve folders', 'Error');
                console.log(err);
                return [];
            });

        this.subscriptions = [s1, s2, s3];

        if (this.useAsComponent) {
            this.loading = true;
            let promise;
            if (this.forFilterSpecs) {
                promise = this.subjectService.getRootSubjectForFilterSpec(this.courseRef.id)
                    .toPromise();
            }
            else {
                promise = this.subjectService.getRootSubject(this.courseRef.id)
                    .toPromise()
            }
            promise.then(rootSubject => {
                this.changeCourseDialogVisible = false;
                this.loading = false;
                this.currentFolder.next(rootSubject);
            })
        }
        else {
            let url: string = this.route.snapshot.url.map(u => u.path).join('/');
            let s4 = this.route.url.subscribe(val => {
                let newUrl = val.map(u => u.path).join('/');
                if (this.isDirty && newUrl === url) {
                    this.isDirty = false;
                    this.refresh();
                }
            });
            this.subscriptions.push(s4);
        }
    }

    ngOnDestroy() {
        this.changeCourseDialogVisible = false;
        if (this.subscriptions) for (let s of this.subscriptions) s.unsubscribe();
    }

    sortByName(items: CmsItem[]): CmsItem[] {
        return items.sort((i1, i2) => {
            if (i1.name.toLowerCase() < i2.name.toLowerCase()) return -1;
            if (i1.name.toLowerCase() > i2.name.toLowerCase()) return 1;
            return 0;
        });
    }
}
