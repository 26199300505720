import { Observable ,  Subject } from 'rxjs';
export class BootService {
    
  private static instance: BootService;
  private _reboot: Subject<boolean> = new Subject();
  private reboot$ = this._reboot.asObservable();

  static getbootControl() {
    if (!BootService.instance) {
        BootService.instance = new BootService();
    }
    return BootService.instance;
  }

  public watchReboot() {
    return this.reboot$;
  }

  public restart() {
    this._reboot.next(true);
  }
}