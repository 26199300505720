<akit-shortcuts (save)="commit()" (run)="preview()"></akit-shortcuts>
<div *ngIf="exercise" class="exercise-filter-editor cms-navigator">
    <h1>Exercise Ref Editor</h1>
    <div *ngIf="akitVersionMismatch" class="row row-spacing">
        <div class="col-xs-12">
            <div class="alert alert-warning">
                This exercise cannot be saved using the current version. Please switch to "{{courseAkitVersion}}" to save the exercise.
            </div>
        </div>
    </div>
    <form [formGroup]="form">

        <div class="panel-wrapper">
            <ul class="nav nav-tabs">
                <li class="nav-item active">
                    <a class="nav-link active" href="#general" data-toggle="tab">General</a>
                </li>
                <li class="nav-item" *ngIf="exercise.state != 'DRAFT'" [attr.no-content]="!versionInfo">
                    <a class="nav-link" href="#version" data-toggle="tab">Version</a>
                </li>
                <li class="nav-item" [attr.no-content]="isEmptyMetadata()">
                    <a class="nav-link" href="#metadata" data-toggle="tab">Metadata <span *ngIf="!metadataValid"
                            class="icon-invalid fa fa-exclamation-circle"></span></a>
                </li>
            </ul>

            <div class="tab-content clearfix row-spacing">
                <div class="tab-pane active" id="general">
                    <div class="row row-spacing">
                        <div class="col-sm-2">
                            <label>Course</label>
                        </div>
                        <div class="col-sm-10">
                            <span class="static-value-field">{{course?.name}}</span>
                        </div>
                    </div>
                    <div class="row row-spacing">
                        <div class="col-xs-3 col-sm-2">
                            <label>Subject</label>
                        </div>
                        <div class="col-xs-9 col-sm-8">
                            <breadcrumb class="clickable" [folder]="subject" (parentChange)='setSubject($event)'
                                [showChilds]="true"></breadcrumb>
                        </div>
                        <div class='col-xs-12 col-sm-2'>
                            <h4 *ngIf="exercise.state == 'DRAFT'">DRAFT</h4>
                            <h4 *ngIf="exercise.tested">
                                <span class="fa fa-check"></span> Tested
                            </h4>
                        </div>
                    </div>
                    <div class="row row-spacing">
                        <div class="col-sm-2">
                            <label>Audience</label>
                        </div>
                        <div class="col-sm-4">
                            <select formControlName='audience' class='form-control'>
                                <option *ngFor="let audience of audiences" value="{{audience.baseAudienceId}}">
                                    {{audience.name}}</option>
                            </select>
                        </div>
                        <div class="col-sm-1">
                            <label>ID</label>
                        </div>
                        <div class="col-sm-5">
                            <span>{{exercise.id}}</span>
                        </div>
                    </div>
                    <div class="row row-spacing">
                        <div class="col-sm-2">
                            <label translate>Name</label>
                        </div>
                        <div class="col-sm-9">
                            <input formControlName='name' class='form-control'>
                        </div>
                        <div class="col-sm-1 col-lg-1">
                            <span class="fa fa-expand span-button" [hidden]="generalExtend"
                                (click)="generalExtend=!generalExtend"></span>
                            <span class="fa fa-compress span-button" [hidden]="!generalExtend"
                                (click)="generalExtend=!generalExtend"></span>
                        </div>
                    </div>
                    <div *ngIf="generalExtend">
                        <div class="row row-spacing">
                            <div class="col-sm-2">
                                <label translate>Title</label>
                            </div>
                            <div class="col-sm-9">
                                <input formControlName='title' class='form-control'>
                            </div>
                        </div>
                        <div class='row row-spacing'>
                            <div class="col-sm-2">
                                <label>Description</label>
                            </div>
                            <div class="col-sm-9">
                                <textarea formControlName='description' class='form-control'></textarea>
                            </div>
                        </div>
                        <div class='row row-spacing'>
                            <div class="col-sm-2">
                                <label>Comment</label>
                            </div>
                            <div class="col-sm-9">
                                <textarea formControlName='comment' class='form-control'></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane" id="version">
                    <div *ngIf="lastPublishedVersion">
                        <div class="row row-spacing">
                            <div class="col-sm-2">
                                <label>Last published version</label>
                            </div>
                            <div class="col-sm-10">
                                <p>{{lastPublishedVersion.majorVersion}}.{{lastPublishedVersion.minorVersion}}
                                    <button class="btn btn-default" type="button" (click)="preview(true)" title="Run">
                                        <i class="fa fa-eye"></i>
                                        <span>Run</span>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <br />
                    <version-info-panel *ngIf="exercise && versionInfo; else noVersion" [state]="exercise.state"
                        [versionInfo]="versionInfo"></version-info-panel>
                    <ng-template #noVersion>
                        No version data available.
                    </ng-template>
                </div>

                <div class="tab-pane" id="metadata">
                    <div class="row row-spacing">
                        <div class="col-sm-2">
                            <label translate>Additional metadata</label>
                        </div>
                        <div class="col-sm-10">
                            <metadata-collection-editor [forItem]="exercise" (afterValidate)="setMetadataValid($event)">
                            </metadata-collection-editor>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="showEditingWarning" class="alert alert-danger panel-wrapper">
            <p>
                <i class="fa fa-exclamation-circle"></i> <strong>Alert:</strong> Do not edit reference exercises in
                this environment if they are intended for production. Always use the <a
                    href="https://cms.algebrakit.nl/">production
                    version</a> of the Algebrakit CMS to edit reference exercises.
            </p>
        </div>
        <stacked-tabs [allowAdd]="false">
            <stacked-tab [tabTitle]="getEditorIcon()">
                <div class="tab-content">
                    <div class="exerciseFilter-goal-wrapper">
                        <div class="row">
                            <div class="col-sm-2">
                                <label>
                                    <span>Exercise Script</span>
                                </label>
                            </div>
                            <div class="col-sm-10">
                                <breadcrumb [folder]="topic" [leaf]="exerciseScript" [showChilds]="true"
                                    [showLeafs]="true" [currentVersion]="currentVersion" [hideTrashLeafs]="true"
                                    (parentChange)="setTopic($event)" (leafSelect)="selectExercise($event)">
                                </breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
            </stacked-tab>
        </stacked-tabs>

        <br />

        <div class="panel panel-default update-panel"
            *ngIf="!readOnly && viewReady && exerciseReady && exercise.state == 'APPROVED'">
            <div class="panel-heading">
                <h3 class="panel-title">Update exercise</h3>
            </div>
            <div class="panel-body">
                <div class="row row-spacing">
                    <div class="col-sm-2">
                        <label>Commit message</label>
                    </div>
                    <div class="col-sm-10">
                        <textarea formControlName='commitMessage' class='form-control'
                            [(ngModel)]="exercise.commitMessage" required
                            [ngClass]="{'input-invalid': commitMessageControl.errors}"></textarea>
                    </div>
                </div>
                <div class="row row-spacing">
                    <div class="col-sm-2">
                        <label>Type of update</label>
                    </div>
                    <div class="col-sm-7">
                        <select formControlName='updateType' class='form-control' [(ngModel)]="exercise.updateType">
                            <option value="MINOR" [selected]="true">Minor</option>
                            <option value="MAJOR">Major</option>
                        </select>
                    </div>
                    <div class="col-sm-3">
                        <button class="btn btn-md btn-primary btn-block" type="button" (click)="commit()"
                            [attr.title]="saveTitle"
                            [disabled]="canDeactivate() || !form.valid || !metadataValid || !exerciseScript">
                            <i class="fa fa-save"></i>
                            <span>Save</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <publish-confirm *ngIf="confirmPublish" class="panel-wrapper" (confirm)="doPublish()" (cancel)="cancelPublish()">
    </publish-confirm>

    <div class="row row-spacing">
        <div class="col-xs-3" *ngIf="!readOnly && exercise.state == 'DRAFT' && !approveButtonVisible()">
            <button class="btn btn-md btn-primary btn-block" type="button" (click)="commit()" [attr.title]="saveTitle"
                [disabled]="canDeactivate()">
                <i class="fa fa-save"></i>
                <span>Save</span>
            </button>
        </div>
        <div class="col-xs-3" *ngIf="!readOnly && approveButtonVisible()">
            <button class="btn btn-md btn-info btn-block" type="button" (click)="approve()"
                title="Approve this exercise">
                <i class="fa fa-check"></i>
                <span>Approve exercise</span>
            </button>
        </div>
        <div class="col-xs-3">
            <button class="btn btn-md btn-default btn-block" type="button" (click)="preview()" [attr.title]="runTitle">
                <i class="fa fa-eye"></i>
                <span>Preview</span>
            </button>
        </div>
        <div class="col-xs-3">
            <button class="btn btn-md btn-default btn-block" type="button" (click)="cancel()"
                title="Discard changes and close the editor">
                <i class="fa fa-ban"></i>
                <span translate>Close</span>
            </button>
        </div>
        <div class="col-xs-3" *ngIf="!readOnly && publishButtonVisible()">
            <button class="btn btn-md btn-warning btn-block" type="button" (click)="publish()"
                [title]="getPublishButtonTitle()" [disabled]="changed || compareVersionNumbers() < 1">
                <i class="fa fa-leanpub"></i>
                <span>Publish</span>
            </button>
        </div>
    </div>

</div>

<div class="modal fade exercise-demo">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">{{demoExercise?.name}}</h4>
            </div>
            <div class="modal-body">
                <div *ngIf="demoExercise">
                    <div class="button-row">
                        <div class="label-container-xs" *ngIf='!publishedDemo && demoExercise.attributes'>
                            <div *ngFor='let attr of demoExercise.attributes' class='label-wrapper-xs'
                                [ngStyle]="getColorForAttribute(attr)">
                                <span class='attr-label-xs'>{{attr.name}}</span>
                            </div>
                        </div>
                        <button class="edit-button" type="button" (click)="refreshTest()">
                            <span class="fa fa-refresh"></span>
                        </button>
                        <a *ngIf="!publishedDemo" [routerLink]="'/edit/exercise/'+demoExercise.id" target="_blank">
                            <button class="edit-button" type="button">
                                <span *ngIf="userService.canEditLibrary()">edit</span>
                                <span *ngIf="!userService.canEditLibrary()">view</span>
                            </button>
                        </a>
                        <div style="clear:both"></div>
                    </div>
                    <exercise-script-preview #previewComponent [exercise]="demoExercise?.definition"
                        [showAudienceSelector]="false" [scoringModel]="course?.options?.scoringModel">
                    </exercise-script-preview>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>