<div>
    <div class='row'>
        <div class="col-xs-12">
            <h1>Published snapshots</h1>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-xs-3 col-sm-2">
            <label>Course</label>
        </div>
        <div class="col-xs-9 col-sm-5">
            <select class='form-control' [formControl]="courseCtrl">
                <option value="0" selected>Select a course</option>
                <option *ngFor="let course of courses" value="{{course.id}}">{{course.name}}</option>
            </select>
        </div>
    </div>
    <br />
    <br />
    <div class="row" *ngIf="selectedCourse">
        <div class="col-xs-12">
            <h3>Published snapshots</h3>
        </div>
    </div>
    <div class="row" *ngIf="selectedCourse && (!snapshots || snapshots.length === 0)">
        <div class="col-xs-12">
            <p>There are no published resources available.</p>
        </div>
    </div>
    <div *ngIf="snapshots && snapshots.length>0">
        <div class='row' style='font-weight:bold'>
            <div class="col-xs-3">
                <span class="placeholder-for-menu"></span>
                <span>Snapshot ID</span>
            </div>
            <div class="col-xs-3">
                <span>Snapshot name</span>
            </div>
            <div class="col-xs-2">
                <span>Created by</span>
            </div>
            <div class="col-xs-2">
                <span>Date</span>
            </div>
            <div class="col-xs-2">
                <span>Number of exercises</span>
            </div>
        </div>
        <div class="row file-list-row" *ngFor="let snapshot of snapshots">
            <div class="col-xs-3">
                <div class="dropdown">
                    <button class="dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="true">
                        <span class="fa fa-bookmark"></span>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenu2" *ngIf="actions">
                        <li *ngFor="let action of actions" (click)="action.callback(snapshot)" class='dropdown-entry'>
                            <a>
                                <span [ngClass]="action.iconClass"></span>
                                <span class='dropdown-entry-label'>{{action.name}}</span>
                            </a>
                        </li>
                        <li class='dropdown-entry' ngxClipboard [cbContent]="snapshot.snapshotId">
                            <a>
                                <span class='fa fa-lg fa-clipboard'></span>
                                <span class='dropdown-entry-label'>copy snapshot ID to clipboard</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <span>{{snapshot.snapshotId}}</span>
            </div>
            <div class="col-xs-3 version-list-item">
                <span>{{snapshot.name}}</span>
            </div>
            <div class="col-xs-2 version-list-item">
                <span>{{snapshot.owner}}</span>
            </div>
            <div class="col-xs-2 version-list-item">
                <span>{{snapshot.created | date}}</span>
            </div>
            <div class="col-xs-2 version-list-item">
                <span>{{countExercises(snapshot)}}</span>
            </div>
        </div>
    </div>
    <br />
    <div class="row" *ngIf="selectedCourse">
        <div class="col-xs-2">
            <button type="button" (click)="verify()" class="btn btn-md btn-warning btn-block">
                <span class="fa fa-check"></span>
                <span>Verify course</span>
            </button>
        </div>
    </div>
    <br />
    <div class="row" [hidden]="!busyCheck">
        <br />
        <div class="col-sm-6">
            <busy-gears></busy-gears>
            Checking for errors...
        </div>
    </div>
    <div *ngIf="selectedCourse && !busyPublish && !busyCheck && !hasWarnings() && !hasErrors()" class="row">
        <div class="col-xs-12">
            <div class="alert alert-success">
                <i class="fa fa-check-circle" aria-hidden="true"></i> No errors or warnings for this course.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <verify-results *ngIf="selectedCourse && sortedCheckResults && !busyCheck"
                [results]="sortedCheckResults"></verify-results>
        </div>
    </div>
    <br />
    <div class="row" [hidden]="!busyPublish">
        <div class="col-sm-12">
            <busy-gears></busy-gears>
            <progress-bar [progress]="progress"></progress-bar>
        </div>
    </div>
    <div *ngIf="!canPublish() && hasErrors()" class="row">
        <div class="col-xs-12">
            <div class="alert alert-danger">
                <i class="fa fa-times-circle" aria-hidden="true"></i> Please fix any errors before publishing.
            </div>
        </div>
    </div>
    <ng-container *ngIf="publishingWarning">
        <div class="alert alert-warning">
            <p>
                <i class="fa fa-exclamation-circle"></i> <strong>Warning:</strong> Snapshots published in this
                environment cannot be used in production. Always use the <a href="https://cms.algebrakit.nl/">production
                    version</a> of the Algebrakit CMS to publish items intended for production.
            </p>
        </div>
    </ng-container>
    <div *ngIf="canPublish() && !busyPublish">
        <br />
        <div class="row">
            <div class="col-xs-12">
                <h3>Publish new snapshot</h3>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-3 col-sm-2">
                <label>Name</label>
            </div>
            <div class="col-xs-9 col-sm-10">
                <input [formControl]="newVersionCtrl" class="form-control" />
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-xs-2">
                <button type="button" (click)="publish()" class="btn btn-md btn-primary btn-block">
                    <span class="fa fa-book"></span>
                    <span>Publish</span>
                </button>
            </div>
        </div>
    </div>
</div>