<div class="background-img">

</div>

<div class="foreground">
    <h2>Forgot Password</h2>
    <form (ngSubmit)="submit()" [formGroup]="form">
        <div class="row">
            <div class='col-sm-10 col-xs-12'>
                <p>
                    Please enter your email address in the field below and submit.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3 col-xs-5">
                <label>Email address</label>
            </div>
            <div class='col-sm-7 col-xs-7'>
                <input type="email" class="form-control" placeholder="Email address" title="Email address"
                    formControlName='email' ng-minlength="4" autofocus autocapitalize="off" autocorrect="off">
            </div>
        </div>
        <br />

        <div class="row">
            <div class="col-sm-3 col-xs-6">
                <button class="btn btn-primary btn-block" type="submit" title="Submit" [disabled]="!form.valid">
                    <i class="fa fa-sign-in"></i>
                    <span>Submit</span>
                </button>
            </div>
        </div>
    </form>
</div>