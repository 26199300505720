import { Component, OnInit, Input, Output, ViewChild, ContentChildren, QueryList,
         ViewContainerRef, ComponentFactoryResolver, Directive, TemplateRef,
         AfterContentInit, EventEmitter } from '@angular/core';
//import {StackedTabComponent} from '../stacked-tab/stacked-tab.component';

export class StackedTabOption {
    name: string;
    id: string;
    icon: string;
    
    constructor(id:string, name:string, icon:string) {
        this.id = id; this.name = name; this.icon = icon;
    }
}

/**
 * single tab in stacked-tabs 
 */
@Component({
  selector: 'stacked-tab',
  template: `
    <div [hidden]="!active">
      <ng-content></ng-content>
      <ng-container *ngIf="template" [ngTemplateOutlet]="template"></ng-container>
    </div>
  `,
  styleUrls: ['./stacked-tab.component.css']
})
export class StackedTabComponent {
    @Input() tabTitle: string;
    @Input() tabOptions: StackedTabOption[];
    @Input() tabStates: string[];
    @Input() template: TemplateRef<any>;
    @Input() active:boolean;
    @Output() optionEvent: EventEmitter<string> = new EventEmitter<string>();
    
    onOption(id: string) {
        this.optionEvent.emit(id);
    }
}

let labels = 'abcdefghijklmnopqrstuvwxyz';

/**
 * A vertically oriented tabs panel, where the user is able to add tabs and to
 * change the order of the tabs. Used to contain exercise questions.
 */
@Component({
  selector: 'stacked-tabs',
  templateUrl: './stacked-tabs.component.html',
  styleUrls: ['./stacked-tabs.component.css']
})
export class StackedTabsComponent implements AfterContentInit{
    public questionTemplate: TemplateRef<any>;
    @Input() allowAdd = true;
    @Output() addTab: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() activeTabChanged: EventEmitter<number> = new EventEmitter();
    
    @ContentChildren(StackedTabComponent) tabs: QueryList<StackedTabComponent>;
    
    constructor() {}

    public showElementIndex(index: number) {
       this.selectTab(this.tabs.toArray()[index], index);
    }

    selectTab(tab: StackedTabComponent, index: number) {
        this.tabs.toArray().forEach(tab => tab.active = false);
        tab.active = true;
        this.activeTabChanged.emit(index);
    }

    createTab() {
        this.addTab.emit();
        let self = this;
        setTimeout(function(){
            let tabs = self.tabs.toArray();
            if(tabs.length===0) return;
            self.selectTab(tabs[tabs.length-1], tabs.length-1);
        });

    }
    isValid(tab:StackedTabComponent) {
        return !tab.tabStates || !tab.tabStates.some(str => str=='invalid');
    }
    
    // contentChildren are set
    ngAfterContentInit() {
        // get all active tabs
        let activeTabs = this.tabs.filter((tab)=>tab.active);

        // if there is no active tab set, activate the first
        if (activeTabs.length === 0 && this.tabs.length>0) {
          this.selectTab(this.tabs.first, 0);
        }
    }
}

