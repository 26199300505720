import { NameIdPair } from "../../algebrakit/types/metadata.type";
import { CmsCourse, CmsCourseRef } from "../../authoring/types/navigator.types";
import { checkPermission, checkPermissionsAll, checkPermissions } from "../services/permission-utils";

export const ROLE_MIGRATOR = "global.migrator";
export const ROLE_LIBRARY_EDITOR = "global.library.edit";
export const ROLE_PUBLISH_LIBRARY = "global.library.publish";
export const ROLE_TESTER = "global.cms-items.test";
export const ROLE_LABEL_EDITOR = "global.labels.edit";

export const SUFFIX_PUBLISH = "publish";
export const SUFFIX_MANAGE = "manage";
export const SUFFIX_EDIT = "edit";
export const SUFFIX_READ = "read";

export class User {
    constructor(
        public username: string,
        public email: string,
        public roles: string[],
        public password?: string,
        public firstName?: string,
        public lastName?: string,
        public roleNames?: string[],
        public activated?: boolean,
        public courses?: NameIdPair[]
    ) { }

    hasRole(roleName: string): boolean {
        return this.roles.some(role => role === roleName);
    }

    canEditLibrary(): boolean {
        return this.roles.some((role: string) => {
            return [ROLE_MIGRATOR, ROLE_LIBRARY_EDITOR].includes(role);
        })
    }

    canPublishLibrary() {
        return this.roles.some((role: string) => {
            return [ROLE_MIGRATOR, ROLE_PUBLISH_LIBRARY].includes(role);
        })
    }

    canCompareTestSessions() {
        return this.roles.some(role => role === 'global.cms.testsessions.compare');
    }

    canUpdateTestSessions() {
        return this.roles.some(role => role === 'global.cms.testsessions.update');
    }

    isCourseManager(namespace?: string) {
        let permissions = namespace
            ? [`*${namespace}.course.manage`]
            : [`*.course.manage`];
        permissions.push('global.course.edit');
        return checkPermissions(this, permissions)
    }

    isCourseEditor(namespace?: string) {
        let permissions = namespace
            ? [`*${namespace}.course.edit`]
            : [`*.course.edit`];
        permissions.push('global.course.edit');
        return checkPermissions(this, permissions)
    }

    canPublishSomeCourse() {
        return checkPermissionsAll(this, [`*.course.*publish`]);
    }

    isNamespacePublisher(namespace?: string) {
        let permissions = namespace
            ? [`*${namespace}.course.publish`]
            : [`*.course.publish`];
        permissions.push('global.course.edit');
        return checkPermissions(this, permissions)
    }

    getIssueCopySubject() {
        const prefix = 'global.issue-subject'
        let permissionMatch = this.roles.find(p => p.startsWith(prefix));
        return permissionMatch
            ? permissionMatch.substr(prefix.length + 1)
            : null;
    }

    isNamespaceApprover(namespace?: string) {
        let permissions = namespace
            ? [`*${namespace}.course.approve`]
            : [`*.course.approve`];
        permissions.push('global.course.approve');
        return checkPermissions(this, permissions)
    }

    canManageCourse(course: CmsCourseRef): boolean {
        return checkPermissionsAll(this, [`*.course.${course.id}.manage`])
            || this.isCourseManager(course.namespace);
    }

    canEditCourse(course: CmsCourseRef): boolean {
        return checkPermissionsAll(this, [`*.course.${course.id}.edit`])
            || this.isCourseEditor(course.namespace);
    }

    canPublishCourse(course: CmsCourseRef): boolean {
        return checkPermissionsAll(this, [`*.course.${course.id}.publish`])
            || this.isNamespacePublisher(course.namespace);
    }

    canAssignResolvedAudiences(course: CmsCourseRef): boolean {
        return checkPermissions(this, [
            `*.course.${course.id}.assign-resolved-audiences`,
            `global.course.assign-resolved-audiences`
        ]);
    }

    canSetSubmitOnEnter(course: CmsCourseRef): boolean {
        return checkPermissionsAll(this, [`*.course.${course.id}.set-submit-on-enter`])
            || checkPermissions(this, ["global.course.set-submit-on-enter"]);
    }

    canApproveInCourse(course: CmsCourseRef): boolean {
        return checkPermissionsAll(this, [`*.course.${course.id}.approve`])
            || this.isNamespaceApprover(course.namespace);
    }

    canUseExperimentalWidgets() {
        return checkPermissionsAll(this, [`global.cms.allow-experimental-widgets`]);
    }
};
