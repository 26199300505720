import { Component, Input } from "@angular/core";

@Component({
    selector: 'progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent {

    @Input() progress: number;

    getPercentage() {
        return Math.floor(this.progress * 100);
    }
}