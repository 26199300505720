<div>
    <div class='row'>
        <div class="col-xs-12">
            <h1>Publish folder</h1>
        </div>
    </div>
    <br />
    <div class="row" [hidden]="!busyPublish">
        <div class="col-sm-12">
            <busy-gears></busy-gears>
            <progress-bar [progress]="progress"></progress-bar>
        </div>
    </div>
    <ng-container *ngIf="publishingWarning">
        <div class="alert alert-warning">
            <p>
                <i class="fa fa-exclamation-circle"></i> <strong>Warning:</strong> Exercises published in this
                environment cannot be used in production. Always use the <a href="https://cms.algebrakit.nl/">production
                    version</a> of the Algebrakit CMS to publish items intended for production.
            </p>
        </div>
    </ng-container>
    <div *ngIf="!busyPublish && exercisesToPublish && exercisesToPublish.length > 0">
        <br />
        The following exercises will be published:
        <br />
        <scroll-container>
            <div class="row" *ngFor="let exercise of exercisesToPublish" #exercisesToPublishContainer>
                <div class="col-xs-6">
                    <span [ngClass]="exercise.iconClass"></span> <a
                        [routerLink]="'/edit/'+exercise.id">{{exercise.name}}</a>
                </div>
            </div>
        </scroll-container>
        <br />
        <div class="row" [hidden]="!busyCheck">
            <br />
            <div class="col-sm-6">
                <busy-gears></busy-gears>
                Checking for errors...
            </div>
        </div>
        <div *ngIf="selectedSubject && !busyPublish && !busyCheck && !hasWarnings() && !hasErrors()" class="row">
            <div class="col-xs-12">
                <div class="alert alert-success">
                    <i class="fa fa-check-circle" aria-hidden="true"></i> No errors or warnings for this course.
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <verify-results *ngIf="sortedCheckResults && !busyCheck" [results]="sortedCheckResults">
                </verify-results>
            </div>
        </div>
        <br />
        <div *ngIf="!canPublish() && hasErrors()" class="row">
            <div class="col-xs-12">
                <div class="alert alert-danger">
                    <i class="fa fa-times-circle" aria-hidden="true"></i> Please fix any errors before publishing.
                </div>
            </div>
        </div>
        <br />
    </div>
    <div *ngIf="exercisesToPublish && exercisesToPublish.length == 0">
        The selected folder does not contain any exercises.
    </div>
    <br />
    <div class="row">
        <div class="col-xs-2" *ngIf="!busyPublish && exercisesToPublish && exercisesToPublish.length > 0">
            <button [disabled]="!canPublish()" type="button" (click)="publish()"
                class="btn btn-md btn-primary btn-block">
                <span class="fa fa-book"></span>
                <span>Publish</span>
            </button>
        </div>
        <div class="col-xs-2" *ngIf="!busyPublish && exercisesToPublish && exercisesToPublish.length > 0">
            <button type="button" (click)="close()" class="btn btn-md btn-default btn-block">
                <i class="fa fa-ban"></i>
                <span>Close</span>
            </button>
        </div>
    </div>
</div>