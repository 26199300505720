
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/Rx';

import {
    CmsSubject, CmsSubjectRef, CmsContainerType, CmsExerciseRef, GeneralUpdateRequest, UpdateRequest
} from '../types/navigator.types';
import { CmsExerciseType } from '../types/cms-metadata.types';
import { AuthoringService } from './authoring.service';
import { ToastrService } from 'ngx-toastr';
import { ConcurrencyService } from '../../app/services/concurrency.service';
import { HttpClient } from '@angular/common/http';
import { AkitVersionsService } from '../../algebrakit/services/akit-versions.service';

const URL_BASE_SUBJECTS = "api/subjects";

@Injectable()
export class SubjectService extends AuthoringService {

    constructor(
        protected http: HttpClient,
        protected toastr: ToastrService,
        protected concurrencyService: ConcurrencyService,
        protected akitVersionsService: AkitVersionsService
    ) {
        super(http, toastr, concurrencyService, akitVersionsService);
    }

    getRootSubject(courseId: string, versionId?: string): Observable<CmsSubject> {
        let url: string = versionId
            ? `${URL_BASE_SUBJECTS}/published-root-instance/${courseId}/${versionId}`
            : `${URL_BASE_SUBJECTS}/instance/root/${courseId}`;

        return this.get(url).pipe(
            map(obj => this._getSubjectFromJson(obj)),
            catchError(error => this._serverError(error)),);
    }

    getSubject(id: string, publishId?: string, courseId?: string): Observable<CmsSubject> {
        let url: string = publishId && courseId
            ? `${URL_BASE_SUBJECTS}/published-instance/${courseId}/${id}/${publishId}`
            : `${URL_BASE_SUBJECTS}/instance/${id}`;

        return this.get(url).pipe(
            map(obj => this._getSubjectFromJson(obj)),
            catchError(error => this._serverError(error)),);
    }

    getSubjectForFilterSpec(id: string): Observable<CmsSubject> {
        let url = `${URL_BASE_SUBJECTS}/instance/for-filter-spec/${id}`;
        return this.get(url).pipe(
            map(obj => this._getSubjectFromJson(obj)),
            catchError(error => this._serverError(error)),);
    }

    getRootSubjectForFilterSpec(id: string): Observable<CmsSubject> {
        let url = `${URL_BASE_SUBJECTS}/instance/root/for-filter-spec/${id}`;
        return this.get(url).pipe(
            map(obj => this._getSubjectFromJson(obj)),
            catchError(error => this._serverError(error)),);
    }

    getChildSubjects(parent: CmsSubject): Observable<CmsSubject[]> {
        return this.get(URL_BASE_SUBJECTS + '/childs/' + parent.course.id + '/' + parent.id).pipe(
            map((arr: any[]) => arr.map(obj => this._getSubjectFromJson(obj))),
            catchError(error => this._serverError(error)),);
    }

    getParentSubject(id: string): Observable<CmsSubject> {
        return this.get<CmsSubject>(URL_BASE_SUBJECTS + '/parent/' + id).pipe(
            catchError(error => this._serverError(error)));
    }

    createSubject(parent: CmsSubject): CmsSubject {
        let parentRef: CmsSubjectRef = { id: parent.id, name: parent.name, containerType: CmsContainerType.FOLDER };
        let result: CmsSubject = {
            id: null,
            course: parent.course,
            name: 'new folder',
            parents: parent.parents ? [...parent.parents, parentRef] : [parentRef],
            childFolders: null,
            childLeafs: null,
            defaultQuestionMode: parent.defaultQuestionMode,
            defaultEditor: parent.defaultEditor,
            defaultAudience: parent.defaultAudience,
        }
        return result;
    }

    copySubject(id: string) {
        return this.post(`${URL_BASE_SUBJECTS}/copy/${id}`, {}, { responseType: 'text' }).toPromise();
    }

    storeSubject(subject: CmsSubject, params?: GeneralUpdateRequest): Promise<string | any[]> {
        let updateRequest = new UpdateRequest(subject, params);
        updateRequest.defaultEditor = subject.defaultEditor;
        updateRequest.defaultQuestionMode = subject.defaultQuestionMode;
        updateRequest.defaultAudience = subject.defaultAudience;
        return this.post<string | any[]>(URL_BASE_SUBJECTS + '/instance', updateRequest, { responseType: 'text' })
            .toPromise()
            .catch(this._serverError);
    }

    checkDeleteSubject(id: string): Promise<any> {
        return this.get(`${URL_BASE_SUBJECTS}/instance/check-delete/${id}`)
            .toPromise();
    }

    deleteSubject(id: string): Promise<boolean | any[]> {
        return this.delete(`${URL_BASE_SUBJECTS}/instance/${id}`).pipe(
            map(() => true)).toPromise()
            .catch(this._serverError);
    }

    moveSubject(subjectId: string, targetSubjectId: string, audience?: string) {
        console.log("move ", subjectId, " to folder ", targetSubjectId, " with audience ", audience);
        return this.post(URL_BASE_SUBJECTS + "/move", {
            subjectId: subjectId,
            targetSubjectId: targetSubjectId,
            audience: audience
        }).toPromise();
    }

    private _getSubjectFromJson(obj: any): CmsSubject {
        let vstr: string = obj.versionId;
        let versionId: string = null;
        if (vstr) {
            let ind = vstr.indexOf('__');
            //courseId = vstr.substring(0, ind);
            ind = ind == -1 ? -2 : ind;
            versionId = vstr.substring(ind + 2);
        }
        let subject: CmsSubject = {
            ...obj,
            childLeafs: obj.childExercises ? this._getExerciceRefsFromJson(obj.childExercises) : this._getExerciceRefsFromJson(obj.childLeafs),
            rootFolder: obj.rootFolder ? obj.rootFolder : null,
            trash: obj.trash ? obj.trash : null,
            metadata: obj.metadata ? obj.metadata : [],
        }
        if (versionId) subject.versionId = versionId;
        return subject;
    }

    private _getExerciceRefsFromJson(objs: any): CmsExerciseRef[] {
        let refs: CmsExerciseRef[] = [];
        for (let obj of objs) {
            refs.push({
                id: obj.id,
                name: obj.name,
                title: obj.title,
                containerType: CmsContainerType.FILE,
                exerciseType: obj.exerciseType as CmsExerciseType,
                audience: obj.audience ? obj.audience : null,
                specType: obj.specType ? obj.specType : null,
                interactionType: obj.interactionType ? obj.interactionType : null,
                state: obj.state,
                tested: obj.tested,
                multiplePreviews: obj.multiplePreviews,
                contentVersion: obj.contentVersion,
                validationReport: obj.validationReport
            });
        }
        return refs;
    }

}