import { Injectable }   from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import {UserService} from '../services/user.service';
import {User} from '../types/user.type';

@Injectable()
export class UserResolverService implements Resolve<User> {
    constructor(private userService: UserService) {}
    
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<User> {
        let username = route.params['username'];
        return this.userService.getUserData(username);
    }
}
