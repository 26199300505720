import { CmsExercise, CmsExerciseFilter, CmsExerciseReference, CmsFolder, CmsTopic, CmsContainerType, CmsExerciseScriptType, CmsExerciseScript, CmsItem, isExerciseFilter, isExerciseReference, CmsCourse } from "../types/navigator.types";
import { CmsExerciseType } from "../types/cms-metadata.types";
import { ICMS2ExerciseSpec, ElementType, ICMS2InteractionSpec, isInlineInteraction, isInteraction, isInteractionReference } from '../types/cms2-metadata.types';
var cloneDeep = require('clone-deep');
const equal = require('./deepEqual/index');

export class TypeHelpers {

    static itemsEqual(a: any, b: any): boolean {
        //return JSON.stringify(a, this.undefinedToEmptyStringReplacer) === JSON.stringify(b, this.undefinedToEmptyStringReplacer);
        TypeHelpers.cleanObject(a);
        TypeHelpers.cleanObject(b);
        return equal(a, b);
    }

    static undefinedToEmptyStringReplacer(key: any, value: any) {
        if (value == undefined || value === null) {
            return "";
        }
        return value;
    }

    static cleanObject(obj: any) {
        for (let key of Object.keys(obj)) {
            if (obj[key] instanceof Object) {
                TypeHelpers.cleanObject(obj[key]);
                continue;
            }
            obj[key] = TypeHelpers.emptyStringToNullReplacer(obj[key]);
        }
    }

    static emptyStringToNullReplacer(value: any) {
        if (value === "") {
            return null;
        }
        return value;
    }

    static isExerciseScript(item: any): item is CmsExerciseScript {
        return item.definition != null && item.topic != null && item.attributeAssignments != null;
    }

    static isExerciseFilter(item: CmsExercise): item is CmsExerciseFilter {
        let exercise: CmsExerciseFilter = <CmsExerciseFilter>item;
        return exercise.type === CmsExerciseType.FILTER || exercise.topicFilters !== undefined
            && exercise.numberOfLevels !== undefined;
    }

    static isExerciseReference(item: CmsExercise): item is CmsExerciseReference {
        let exercise: CmsExerciseReference = <CmsExerciseReference>item;
        return exercise.type === CmsExerciseType.REFERENCE || exercise.exerciseScript !== undefined;
    }

    static isCmsTopic<T, V>(folder: CmsFolder<any, any>): folder is CmsTopic {
        return (<CmsTopic>folder).attributeAssignments !== undefined;
    }

    static clone<T>(obj: T) {
        return cloneDeep(obj);
    }

    static getIconClass(item: any) {
        if (item.trash) {
            return 'fa fa-trash';
        }
        if (item.containerType && item.containerType === CmsContainerType.FOLDER) {
            return 'fa fa-folder';
        }
        if (item.exerciseType) {
            switch (item.exerciseType) {
                case CmsExerciseType.FILTER:
                    return 'fa fa-glass';
                case CmsExerciseType.REFERENCE:
                    return 'fa fa-link';
            }
        }
        else if (isExerciseFilter(item)) {
            return 'fa fa-glass';
        }
        else if (isExerciseReference(item)) {
            return 'fa fa-link';
        }

        let scriptType = item.scriptType

        let def = <ICMS2ExerciseSpec>item.definition;
        if (def && def.elements) {

            //Check multipart
            if (def.elements.filter(elm => elm.type == ElementType.QUESTION).length > 1) {
                return 'fa fa-list-ol';
            }

            let elm = def.elements.find(elm => elm.type == ElementType.QUESTION);

            if (elm && elm.interactions && elm.interactions.length > 0) {
                let inter = elm.interactions[0];
                if (isInlineInteraction(inter)) {
                    return 'fa fa-ellipsis-h';
                }
                inter = (inter as ICMS2InteractionSpec);
                let typeStr = inter.ans ? inter.ans.type : null;
                if (typeStr) {
                    switch (typeStr) {
                        case "ALGEBRA":
                            return 'fa fa-indent';
                        case "CHOICE":
                            return 'fa fa-check-square';
                        case "TABLE":
                        case "MATH_TABLE":
                            return 'fa fa-th';
                        case "ARITHMETIC":
                            return 'fa fa-sticky-note';
                    }
                }
            }
        }
        scriptType = item.scriptType || (item.definition ? item.definition.type : null) || item.interactionType || item.specType;
        if (scriptType) {
            switch (scriptType) {
                case CmsExerciseScriptType.MULTIPART:
                    return 'fa fa-list-ol';
                case CmsExerciseScriptType.ALGEBRA:
                    return 'fa fa-indent';
                case CmsExerciseScriptType.CHOICE:
                    return 'fa fa-check-square';
                case CmsExerciseScriptType.COMPOUND:
                    return 'fa fa-file-text';
                case CmsExerciseScriptType.TABLE:
                    return 'fa fa-th';
                case CmsExerciseScriptType.WORDPROBLEM:
                    return 'fa fa-th-list';
                case CmsExerciseScriptType.ARITHMETIC:
                    return 'fa fa-sticky-note';
            }
        }
        return 'fa fa-file-o';
    }

    static getExerciseComplexity(ex: CmsExerciseScript, goal: CmsTopic) {
        let score = 0;
        for (let attribute of ex.attributeAssignments) {
            let attr = goal.attributeAssignments.find(item => item.id === attribute.id);
            score += attr ? attr.difficulty.complexity * attr.difficulty.complexity : 0;
        }
        return Math.sqrt(score) / ex.attributeAssignments.length
    }

    static removeMetaDataIds(item: CmsItem) {
        if (!item.metadata) {
            return item;
        }
        for (let meta of item.metadata) {
            meta.id = null;
        }
        return item;
    }

    static getDifferences(a: any, b: any) {
        let result: any = {};
        let keysAll = [...Object.keys(a), ...Object.keys(b)];
        for (var i = 0; i < keysAll.length; ++i) {
            for (var j = i + 1; j < keysAll.length; ++j) {
                if (keysAll[i] === keysAll[j])
                    keysAll.splice(j--, 1);
            }
        }

        for (let key of keysAll) {
            if ((a[key] == null && b[key] != null) || (a[key] != null && b[key] == null)) {
                result[key] = 'does not exist in one of the objects';
                continue;
            }
            if (a[key] instanceof Object && b[key] instanceof Object) {
                let subResult = TypeHelpers.getDifferences(a[key], b[key])
                if (Object.keys(subResult).length > 0) {
                    result[key] = subResult;
                }
                continue;
            }
            if (a[key] != b[key]) {
                result[key] = 'values are not equal';
            }
        }
        return result;
    }

    /**
     * Returns:
     * if A < B: -1
     * if A = B: 0
     * if A > B: 1
     * @param majorVersionA 
     * @param minorVersionA 
     * @param majorVersionB 
     * @param minorVersionB 
     */
    static compareVersionNumbers(majorVersionA: number, minorVersionA: number, majorVersionB: number, minorVersionB: number) {
        if (majorVersionA !== majorVersionB) {
            return majorVersionA - majorVersionB;
        }
        return minorVersionA - minorVersionB;
    }

    public static generateDummyListCourse(): CmsCourse {
        return {
            id: '',
            name: 'Select a course...',
            audiences: [],
            namespace: ''
        }
    }

    //remove empty strings
    public static _sanitizeJsonForAws(obj: any): any {
        if (typeof (obj) === 'string') {
            if (obj && !obj.trim()) return null;
        }
        if (typeof (obj) === 'object') {
            for (let key in obj) {
                let value = obj[key];
                if (typeof (value) === 'string' && !value) obj[key] = null;
                else if (typeof (value) === 'object') this._sanitizeJsonForAws(value);
            }
            return obj;
        }
        return obj;
    }

    public static isSingleInteraction(spec: ICMS2ExerciseSpec): string {
        if (spec.elements
            && spec.elements.length == 1) {
            let elm = spec.elements[0];
            if (elm.interactions && elm.interactions.length == 1) {
                let inter = elm.interactions[0];
                if (isInteraction(inter)) return (inter as ICMS2InteractionSpec).ans.type;
            }
        }
    }
}