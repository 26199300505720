
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/Rx';

import {
    CmsTopic, CmsAkitResourceRef, CmsContainerType, CmsTopicRef, ReferencedBy, Difficulty, CmsTopicAttributeAssignment, CmsCourseRef
} from '../types/navigator.types';
import { AuthoringService } from './authoring.service';
import { ToastrService } from 'ngx-toastr';
import { Sorting } from '../util/sorting';
import { ConcurrencyService } from '../../app/services/concurrency.service';
import { HttpClient } from '@angular/common/http';
import { AkitVersionsService } from '../../algebrakit/services/akit-versions.service';

const URL_TOPIC_TRASH = "api/topic/trash";
const URL_TOPIC_ROOT = "api/topic/root";
const URL_BASE_TOPC_REF = "api/topic/getRef";
const URL_BASE_TOPIC = "api/topic";

//interpolate between green and red
function getColor(value: number) {
    //value from 0 to 1
    if (value === 0) {
        return "rgb(210,210,210)";
    }
    var hue = ((1 - value) * 120).toString(10);
    return ["hsl(", hue, ",100%,50%)"].join("");
}

@Injectable()
export class TopicService extends AuthoringService {
    exerciseTemplates: { [type: string]: any }

    constructor(
        protected http: HttpClient,
        protected toastr: ToastrService,
        protected concurrencyService: ConcurrencyService,
        protected akitVersionsService: AkitVersionsService
    ) {
        super(http, toastr, concurrencyService, akitVersionsService);
    }

    getRootTopic(): Observable<CmsTopic> {
        return this.getTopic('root');
    }

    getTrashTopic(): Observable<CmsTopic> {
        return this.getTopic('trash');
    }

    getTopic(id: string, publishedOnly?: boolean): Observable<CmsTopic> {
        let url = publishedOnly
            ? URL_BASE_TOPIC + '/instance/' + id + '/published-scripts'
            : URL_BASE_TOPIC + '/instance/' + id;
        if (id === 'root') {
            url = URL_TOPIC_ROOT;
        }
        else if (id === 'trash') {
            url = URL_TOPIC_TRASH;
        }

        return this.get(url).pipe(
            map((obj: any) => {
                return this._getTopicFromObject(obj)
            }),
            catchError(error => this._serverError(error)),);
    }

    getParentTopic(id: string): Observable<CmsTopic> {
        return this.get<CmsTopic>(URL_BASE_TOPIC + '/parent/' + id).pipe(
            catchError(error => this._serverError(error)));
    }

    getTopics(ids: string[]): Observable<CmsTopic[]> {
        let url = URL_BASE_TOPIC + '/retrieve-all';
        return this.post<CmsTopic[]>(url, ids).pipe(
            map((list) => {
                return list.map(obj => this._getTopicFromObject(obj));
            }),
            catchError(error => this._serverError(error)),);
    }

    getTopicRef(id: string): Observable<CmsTopicRef> {
        let url = URL_BASE_TOPC_REF + '/' + id;

        return this.get(url).pipe(
            map((obj: any) => {
                let folder: CmsTopicRef = {
                    name: obj.name,
                    id: obj.id,
                    trash: obj.trash ? obj.trash : false,
                    containerType: CmsContainerType.FOLDER
                };
                return folder;
            }),
            catchError(error => this._serverError(error)),);
    }

    storeTopic(topic: CmsTopic): Promise<string | any[]> {
        return this.post<string | any[]>(`${URL_BASE_TOPIC}/instance`, topic, { responseType: 'text' })
            .toPromise()
            .catch(this._serverError.bind(this));
    }

    checkDeleteTopic(id: string): Promise<any> {
        return this.get(`${URL_BASE_TOPIC}/instance/check-delete/${id}`)
            .toPromise();
    }

    deleteTopic(id: string): Promise<boolean | any[]> {
        return this.delete(`${URL_BASE_TOPIC}/instance/${id}`).pipe(
            map(() => true)).toPromise();
    }

    restoreTopic(id: string): Promise<boolean | any[]> {
        return this.post(`${URL_BASE_TOPIC}/instance/${id}/restore`, {}).pipe(
            map(() => true)).toPromise()
            .catch(this._serverError.bind(this));
    }

    createTopic(parent: CmsTopic, course?: CmsCourseRef): CmsTopic {
        let parentRef: CmsAkitResourceRef = { id: parent.id, name: parent.name, containerType: CmsContainerType.FOLDER };
        if (parent.course !== null && parent.course.id !== course.id) {
            console.error('A new topic\'s course must be null or equal to the parent topic\'s course');
            return;
        }
        let result: CmsTopic = {
            id: null,
            course: course,
            name: 'new topic',
            attributeAssignments: [],
            parents: parent.parents ? [...parent.parents, parentRef] : [parentRef],
            childFolders: [],
            childLeafs: []
        }
        return result;
    }

    moveTopic(topicId: string, targetTopicId: string) {
        console.log("move ", topicId, " to topic ", targetTopicId);
        return this.post(URL_BASE_TOPIC + "/move", {
            topicId: topicId,
            targetTopicId: targetTopicId
        }).toPromise();
    }

    checkAttributeDelete(id: string): Observable<ReferencedBy[]> {
        return this.get(`${URL_BASE_TOPIC}/check-attribute/${id}`).pipe(
            map((result: any[]) => {
                let references: ReferencedBy[] = [];
                for (let item of result) {
                    references.push({ item: item.objectA, type: item.objectB });
                }
                return references;
            }),
            catchError(error => this._serverError(error)),);
    }

    getAttributeColorBg(difficulty: Difficulty) {
        var result: { color?: string, 'background-color': string }
            = { "background-color": getColor(difficulty.complexity / 8.0) };
        if (difficulty.complexity > 4) result['color'] = 'white';
        return result;
    };

    getAttributeColorDeleted(attribute: CmsTopicAttributeAssignment) {
        let value = getColor(attribute.difficulty.complexity / 8.0);
        var result = { "color": value, 'text-decoration-color': value };
        if (attribute.difficulty.complexity > 4) result['color'] = 'black';
        return result;
    };

    _getTopicFromObject(obj: any): CmsTopic {
        let folder: CmsTopic = {
            name: obj.name,
            id: obj.id,
            course: obj.course,
            childFolders: obj.childFolders,
            childLeafs: obj.childExerciseScripts,
            parents: obj.parents,
            attributeAssignments: obj.attributeAssignments,
            rootFolder: obj.rootFolder ? obj.rootFolder : false,
            trash: obj.trash ? obj.trash : false,
            metadata: obj.metadata ? obj.metadata : [],
            algebrakitOnly: obj.algebrakitOnly
        };
        if (folder.attributeAssignments) {
            folder.attributeAssignments = Sorting.sortAttributeAssignments(folder.attributeAssignments);
        }
        return folder;
    }

}

