
import { empty as observableEmpty, BehaviorSubject, Subscription, Observable, Subject } from 'rxjs';

import { catchError, map, switchMap, filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input, Output, ViewChild, ElementRef, EventEmitter, NgZone, ViewEncapsulation } from '@angular/core';
import { SessionService } from '../../../algebrakit/services/session.service';
import { APP_ID, } from '../../../algebrakit/services/metadata.service';
import { IDebugInformation } from '../../types/cms-metadata.types';
import { ICMS2ExerciseSpec } from '../../types/cms2-metadata.types';
import { ViewSpecWrapper, } from '../../../algebrakit/types/metadata.type';
import { ExerciseRef } from '../../types/cms-metadata.types';
import { ISessionId, IErrorData } from '../../../algebrakit/types/algebrakit.type';
import { IAlgebraKIT } from '../../../algebrakit/types/algebrakit.type';
import { ExerciseScriptService } from '../../services/exercise-script.service';
import { ToastrService } from 'ngx-toastr';
import { IValidationReport } from '../../types/ValidationReport';

const DEFAULT_NR_GENERATE = 5;
declare let AlgebraKIT: IAlgebraKIT;

let refSubscription: Subscription;
let specSubscription: Subscription;

interface SpecPreview {
    viewSpec: ViewSpecWrapper;
    instruction?: string;
    solution?: string;
}

@Component({
    selector: 'randomized-algebra-preview',
    templateUrl: './randomized-algebra-preview.component.html',
    styleUrls: ['./randomized-algebra-preview.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class RandomizedAlgebraPreviewComponent implements OnInit, OnDestroy {

    _exerciseSpec = new BehaviorSubject<ICMS2ExerciseSpec>(null);
    _exerciseRef = new BehaviorSubject<ExerciseRef>(null);

    specPreviews: Subject<SpecPreview[]> = new BehaviorSubject(null);
    sessionId: string; //when generating widgets on a single spec
    debug: IDebugInformation;
    interactionHTML: string;

    busy: boolean = false;

    @ViewChild('previewTable', { static: false }) previewTable: ElementRef;
    @Input() nr: number = DEFAULT_NR_GENERATE;
    @Input() showAudienceSelector: boolean = true;
    @Input() set exerciseSpec(spec: ICMS2ExerciseSpec) {
        if (spec) {
            this._exerciseSpec.next(spec);
        }
    }
    @Input() set exerciseRef(ref: ExerciseRef) {
        if (ref) {
            this._exerciseRef.next(ref);
        }
    }

    @Input() onlyEmitErrors: boolean = false;
    @Input() scoringModel: string;

    @Input() contentVersion: string = "0.0";

    // publish session-id when session is created
    @Output() newSessionId: EventEmitter<ISessionId> = new EventEmitter<ISessionId>();

    @Output() onErrorDetails: EventEmitter<string> = new EventEmitter<string>();

    @Output() finished: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() debugInfo: EventEmitter<IDebugInformation> = new EventEmitter<IDebugInformation>();
    @Output() error: EventEmitter<IErrorData> = new EventEmitter<IErrorData>();
    @Output() validationReport: EventEmitter<IValidationReport> = new EventEmitter<IValidationReport>();


    constructor(
        private sessionService: SessionService,
        private exerciseScriptService: ExerciseScriptService,
        private toastr: ToastrService,
        private _ngZone: NgZone
    ) { }

    public clearDebug() {
        this.debug = null;
    }

    insertWidgets(spec: ViewSpecWrapper) {
        this.busy = true;
        this.debug = spec.debugInformation;
        this.sessionService.createSession(spec.exerciseSpec, { 'start-active': true, 'show-progress-and-score': !!this.scoringModel }, true, this.scoringModel, spec.contentVersion).toPromise()
            .then(sessionData => {
                this.busy = false;
                this.interactionHTML = sessionData.html;
                this.sessionId = sessionData.sessionId;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    // generate again
    generate() {
        this._exerciseSpec.next(this._exerciseSpec.value);
    }

    renderString(str: string): Promise<string> {
        return this._ngZone.runOutsideAngular(function () {
            return AlgebraKIT.elements2html(str);
        });
    }

    renderElement(assignment: any, instruction: any): Promise<string> {
        if (assignment) return this.renderString(assignment);
        else if (instruction) return this.renderString(instruction);
        else return Promise.resolve('no instruction defined');
    }

    setRunSessionId(sessionId: ISessionId) {
        this.newSessionId.emit(sessionId);
    }

    emitError(message: string) {
        this.onErrorDetails.emit(message);
    }

    onToastClick = (toast: any) => {
        if (toast.data && (<any>toast.data).message) {
            this.emitError((<any>toast.data).message);
        }
    }

    ngOnInit() {
        refSubscription = this._exerciseRef.asObservable().pipe(
            filter(ref => ref != null),
            switchMap(ref => this.exerciseScriptService.getExercise(ref.exerciseId)),
            map(exercise => exercise.definition))
            .subscribe(spec => {
                return this._exerciseSpec.next(<ICMS2ExerciseSpec>spec);
            });

        let specStream = this._exerciseSpec.asObservable().pipe(
            filter(spec => spec != null),
            map(spec => {
                this.sessionId = null;
                if (!spec.audience) spec.audience = 'uk_KS5';
                return spec;
            }),
            switchMap(spec => {
                this.busy = true;
                this.clearDebug();
                return this.sessionService.generateFromSpec(spec, this.nr, true, null, true, this.contentVersion).pipe(
                    catchError((error) => {
                        console.error(error);
                        this.handleError(error, 'Cannot run the specified script');
                        this.busy = false;
                        this.finished.emit(false);
                        return observableEmpty();
                    }));
            }), catchError((error) => {
                this.busy = false;
                this.exerciseSpec = null;
                this.exerciseRef = null;
                this.finished.emit(false);
                this.handleError(error, 'Cannot run the specified script');
                return observableEmpty();
            }));


        specSubscription = specStream.subscribe(response => {
            if (!response) {
                return;
            }
            let viewSpecs = <ViewSpecWrapper[]>response.instances;
            this.busy = true;
            this.emitError(null);
            let viewspecPreviews: SpecPreview[] = [];
            let todoCounter = 2 * viewSpecs.length;

            if (response.validationReport) {
                this.validationReport.emit(response.validationReport);
            }

            //set debug information of the 1st item only. Used for interpretations of symbols
            if (viewSpecs.length > 0 && viewSpecs[0].debugInformation) {
                this.debugInfo.emit(viewSpecs[0].debugInformation);
            }

            let post = () => {
                this._ngZone.run(() => {
                    this.specPreviews.next(viewspecPreviews);
                    this.busy = false;
                    this.finished.emit(false);
                })
            }

            for (let ii = 0; ii < viewSpecs.length; ii++) {
                let obj = viewSpecs[ii] as any;
                let elm = obj.view.elements[0];
                let inter = elm.interactions[0];
                let expr: any;
                if (elm.resources) {
                    let resource = elm.resources.find((r: any) => r.id == 'Q1-expr');
                    if (resource) expr = resource.expr;
                }
                if (!expr && elm.expr) expr = elm.expr;
                if (!expr) expr = elm.content;
                viewspecPreviews[ii] = {
                    viewSpec: obj
                }

                this.renderElement(expr, null)
                    .then(async str => {
                        //Replace akit-resource blocks with rendered resources
                        if (elm.resources && elm.resources.length > 0) {
                            let dummyElm = document.createElement('div');
                            dummyElm.innerHTML = str;
                            for (let resource of elm.resources) {
                                let resElm = dummyElm.querySelector(`akit-resource[ref-id=${resource.id}]`);
                                if (resElm != null) {
                                    let renderContainer = document.createElement('span');
                                    renderContainer.innerHTML = await AlgebraKIT.elements2html(resource.expr);
                                    resElm.parentNode.replaceChild(renderContainer, resElm);
                                }
                            }
                            str = dummyElm.innerHTML;
                        }
                        viewspecPreviews[ii].instruction = str;
                        todoCounter--;
                        if (todoCounter == 0) post();
                    });
                this.renderElement(inter.solution, null).then(str => {
                    viewspecPreviews[ii].solution = str;
                    todoCounter--;
                    if (todoCounter == 0) post();
                });
            }

        });
    }

    handleError(errorObj: any, fallBackMessage: string = "An error occurred.") {
        let info;
        let error = errorObj.error?errorObj.error:errorObj;
        let message = fallBackMessage;
        if(error){
            if(error.message) message = error.message;
            if(error.additionalInfo) info = error.additionalInfo;
        }
        this.emitError(message);

        if (info) {
            if (info.validationReport) {
                this.validationReport.emit(JSON.parse(info.validationReport));
                delete info.validationReport;
            }
            this.error.emit({
                msg: message,
                data: info
            })
        }

        if (!this.onlyEmitErrors) {
            this.toastr.error(message, 'Error').onTap.subscribe(this.onToastClick);
        }
    }

    ngOnDestroy() {
        if (refSubscription) refSubscription.unsubscribe();
        if (specSubscription) specSubscription.unsubscribe();
    }
}
