import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {CmsFolder, CmsAkitResourceRef, CmsDiffAnomaly, CmsDiffSeverityLevel, CmsDiffAnomalyType, ReferencedBy} from '../../types/navigator.types';
import { Observable } from 'rxjs';

/**
 * A topic-leaf is a 'file' in the navigation system, which corresponds to an
 * exercise. The model is defined by class of type CmsLeaf. Classes for the various 
 * types of exercises are derived from CmsLeaf
 */

@Component({
    selector: 'reference-check',
    templateUrl: './reference-check.component.html',
    styleUrls: ['./reference-check.component.css']
})
export class ReferenceCheckComponent {

    _filterReferences: ReferencedBy[] = [];
    _directReferences: ReferencedBy[] = [];

    @Input() set referencedBy(value: ReferencedBy[]) {
        this._filterReferences = value.filter(v => v.type == 'ExerciseFilter');
        this._directReferences = value.filter(v => v.type == 'ExerciseReference');
    }

    reset() {
        this.referencedBy = [];
    }
}



