import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { CmsSubject } from '../types/navigator.types';
import { SubjectService } from './subject.service';
import { SetTitleService } from '../../app/services/set-title.service';
import { Title } from '@angular/platform-browser';
import { AkitVersionsService } from '../../algebrakit/services/akit-versions.service';

@Injectable()
export class SubjectResolverService extends SetTitleService implements Resolve<CmsSubject> {
    constructor(
        private subjectService: SubjectService, 
        titleService: Title,
        private akitVersionsService: AkitVersionsService
    ) { super(titleService) }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<CmsSubject> {
        let id = route.params['id'];
        let courseId = route.params['courseId'];
        let publishId = route.params['publishId'];
        return this.subjectService.getSubject(id, publishId, courseId).toPromise().then(async (subject) => {
            let name = subject.rootFolder ? subject.course.name : subject.name;
            this.setTitle(name);

            if (route.data.matchVersion && subject && subject.course && subject.course.options) {
                await this.akitVersionsService.changeVersionIfRequired(subject.course.options.akitVersion, route);
            }

            return subject;
        });
    }
}

