import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { IdNamePair } from '../../types/cms2-metadata.types';
import { EditorAssetResolveResult } from '../../../algebrakit/types/metadata.type';

@Component({
    selector: 'course-fe-asset-resolve-editor',
    templateUrl: './course-fe-asset-resolve-editor.component.html',
    styleUrls: ['./course-fe-asset-resolve-editor.component.css']
})
export class CourseEditorAssetResolveComponent {

    @Input() editorAssetResolve: EditorAssetResolveResult;
    @Input() duplicateProps: string[];
    @Output() onChanged: EventEmitter<EditorAssetResolveResult> = new EventEmitter<EditorAssetResolveResult>();

    form: FormGroup;

    formulaEditorIdCtrl = new FormControl('');
    assetIdCtrl = new FormControl('');

    editors: IdNamePair[];

    constructor(
        private formBuilder: FormBuilder,
    ) {
        this.form = this.formBuilder.group({
            formulaEditorId: this.formulaEditorIdCtrl,
            assetId: this.assetIdCtrl,
        });

        let self = this;

        this.formulaEditorIdCtrl.setValidators([Validators.required, (control: FormControl) => {
            if (!self || !self.duplicateProps) {
                return null;
            }
            return self.duplicateProps.indexOf('formulaEditorId') === -1 ? null : { duplicate: true };
        }])

        this.formulaEditorIdCtrl.registerOnChange(() => {
            this.changed();
        })
        this.assetIdCtrl.registerOnChange(() => {
            this.changed();
        })
    }

    changed() {
        this.onChanged.emit(this.editorAssetResolve);
    }

    ngOnChanges() {
        if (this.editorAssetResolve) {
            this.formulaEditorIdCtrl.setValue(this.editorAssetResolve.formulaEditorId);
            this.assetIdCtrl.setValue(this.editorAssetResolve.assetId);
        }
    }
}




