import { Component, OnInit, Input } from '@angular/core';
import { CmsFolder, CmsAkitResourceRef } from '../../types/navigator.types';
import { UserService } from '../../../security/services/user.service';

/**
 * A topic-leaf is a 'file' in the navigation system, which corresponds to an
 * exercise. The model is defined by class of type CmsLeaf. Classes for the various 
 * types of exercises are derived from CmsLeaf
 */
@Component({
    selector: 'folder',
    templateUrl: './folder.component.html',
    styleUrls: ['./folder.component.css','../../../../assets/shared.less']
})
export class FolderComponent {
    @Input() folder: CmsFolder<CmsAkitResourceRef, CmsAkitResourceRef>;

    @Input() searchHighlight: string;
    @Input() readOnly = false;

    constructor(private userService: UserService) {}

    onFolderClick(): void {
        if (this.folder.onClick) this.folder.onClick(this.folder);
    }

    getFolderName() {
        let name = this.folder.name;
        if (this.searchHighlight) {
            let regex = new RegExp(`${this.searchHighlight}`, 'i');
            name = name.replace(regex, `<strong>$&</strong>`);
        }
        return ` ${name}`;
    }

    @Input() userIsAdmin: boolean = false;

    getActions() {
        if (this.readOnly) {
            return [];
        }
        let canEdit: boolean = this.userService.getUser() && this.userService.getUser().canEditLibrary();
        return canEdit
            ? this.folder.actions
            : this.folder.actions.filter(a => !a.adminOnly);
    }
}



