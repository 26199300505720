<ul class="list-group verify-results-list" *ngFor="let result of filterResults()">
    <li *ngFor="let checkedResource of getFindings(result.severity)">
        <div *ngIf="checkedResource.anomalies && checkedResource.anomalies.length > 0" [ngClass]="getStatusClass(result)">
            {{getResourceTypeTranslation(checkedResource)}}
            <span *ngIf="
                    !getLink(checkedResource)">
                {{checkedResource.resource.name && checkedResource.resource.name.length > 0 ?
                checkedResource.resource.name :
                checkedResource.resource.id }}
            </span>
            <a *ngIf="getLink(checkedResource)" target="_blank" [routerLink]="getLink(checkedResource)">
                {{checkedResource.resource.name && checkedResource.resource.name.length > 0 ?
                checkedResource.resource.name :
                checkedResource.resource.id }}
            </a>

            <ul>
                <li *ngFor="let anomaly of checkedResource.anomalies">
                    {{getAnomalyTranslation(anomaly, checkedResource)}}
                </li>
            </ul>
        </div>
    </li>
</ul>