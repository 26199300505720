<div class='label-editor' *ngIf="label">
    <h2>Edit label</h2>
    <form (ngSubmit)="onSubmit()">
        <div class='row row-spacing'>
            <div class='col-xs-3 col-sm-2'>
                <label>Label identifier</label>
            </div>
            <div class="col-xs-7 col-sm-5">
                <input name='id' [(ngModel)]="label.id" class='form-control' [ngClass]="getInputClass(label.id, true)">
            </div>
            <div class="col-xs-2 col-sm-5" *ngIf="checkIdExists()">
                <span style="color: red">This ID already exists</span>
            </div>
        </div>
    </form>

    <h3>Translations</h3>
    <div *ngFor="let region of regions">
        <div>
            <div class="row row-spacing">
                <div class="col-sm-2">
                    <span>{{region.name}}</span>
                </div>
                <div class="col-sm-9">
                    <input type="text" [(ngModel)]="label.items[region.id].text" class="form-control" [ngClass]="getInputClass(label.items[region.id].text, false, region.id)"
                    />
                </div>
            </div>
        </div>
        <br/>
    </div>

    <div class="row">
        <div class="col-xs-3">
            <button class="btn btn-md btn-primary btn-block" type="button" title="OK" (click)="onSubmit()" [disabled]="!valid()">
                <i class="fa fa-save"></i>
                <span>OK</span>
            </button>
        </div>
        <div class="col-xs-3">
            <button class="btn btn-md btn-default btn-block" type="button" (click)="cancel()" title="Cancel">
                <i class="fa fa-ban"></i>
                <span translate>Close</span>
            </button>
        </div>
    </div>
</div>