import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { CmsFolder, CmsAkitResourceRef, NameIdPair, CmsContainerType, CmsLeaf, CmsExerciseScriptRef, SortBy, CmsTopicRef, CmsExercise, CmsExerciseScript, isExerciseScript, CmsExerciseRef } from '../../types/navigator.types';
import { ExerciseScriptService } from '../../services/exercise-script.service';
import { compareVersion, Sorting } from '../../util/sorting';
import { TypeHelpers } from '../../util/type-helpers';
import { UserService } from '../../../security/services/user.service';

@Component({
    selector: 'breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.less']
})
export class BreadcrumbComponent {

    // if true, child folders are shown and can be selected. If false, it is only possible to 
    // select a parent folder.
    @Input() showChilds: boolean;

    // if true, child leafs are shown and can be selected.
    @Input() showLeafs: boolean;
    @Input() leafFilter: (obj: any) => boolean;
    @Input() hideTrashLeafs: boolean = true;
    @Input() courseName: string = null; //if set, shows the course name instead of the home icon
    @Input() homeLinkHref: string = null; //if set, shows the course name instead of the home icon

    // called after selection of a parent or after commit
    @Output() parentChange: EventEmitter<string> = new EventEmitter<string>();

    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() confirm: EventEmitter<void> = new EventEmitter<void>();

    //Called when a leaf is clicked
    @Output() leafSelect: EventEmitter<string> = new EventEmitter<string>();

    @Output() onActivated: EventEmitter<void> = new EventEmitter<void>();

    // 'true', 'false' (default) or 'force' (not closeable) 
    @Input() activated: string;

    @Input() readOnly: boolean = false;

    @Input() confirmAndClose: boolean = false;

    @Input() invalid: boolean = false;

    @Input() currentVersion: string;

    childs: NameIdPair[];
    _folder: CmsFolder<CmsAkitResourceRef, CmsAkitResourceRef>;

    constructor(private exerciseScriptService: ExerciseScriptService, private userService: UserService) { }

    @Input() set folder(f: CmsFolder<CmsAkitResourceRef, CmsAkitResourceRef>) {
        this._folder = f;
        if (f && f.childFolders) {
            let childFolders = !this.userService.canEditLibrary()
                ? f.childFolders.map(f => f as CmsTopicRef).filter(f => !f.algebrakitOnly)
                : f.childFolders;

            this.childs = Sorting.sortItemsByOtherProperty(childFolders, SortBy.NAME, 1);
        } else {
            this.childs = [];
        }
        if (this.showLeafs && f && f.childLeafs && f.childLeafs.length > 0) {
            if (TypeHelpers.isCmsTopic(f)) {
                let scriptIds = f.childLeafs.map(e => e.id);
                this.exerciseScriptService.getAttributeAssignmentsForScripts(scriptIds)
                    .toPromise()
                    .then(attributeMap => {
                        let leafList: CmsExerciseScriptRef[] = [];
                        for (let script of f.childLeafs) {
                            script.attributeAssignments = attributeMap[script.id] ? attributeMap[script.id] : [];
                            leafList.push(script);
                        }
                        leafList = Sorting.sortItemsByDifficulty(leafList, f.attributeAssignments, 1);
                        this.childs = this.childs.concat(leafList);
                    });
            }
            else {
                let childLeafs = f.childLeafs;

                if (childLeafs && this.leafFilter) {
                    childLeafs = childLeafs.filter(child => this.leafFilter(child));
                }
                this.childs = this.childs.concat(childLeafs);

            }
        }
        if (this._folder && this._folder.parents && this._folder.parents[0] && !this._folder.parents[0].trash) {
            this._folder.parents[0].root = true;
        }
    };

    @Input() leaf: CmsLeaf;

    close() {
        if (this.activated === 'force') return;
        this.activated = 'false';
        this.confirm.emit();
    }

    onCancel() {
        if (this.activated === 'force') return;
        this.activated = 'false';
        this.cancel.emit();
    }

    activate() {
        if (this.readOnly) {
            return;
        }
        if (this.activated === 'force') return;
        this.activated = 'true';
        this.onActivated.emit();
    }

    setParentFromRef(obj: CmsAkitResourceRef): void {
        if (!this.activated || this.activated == 'false') {
            this.activate();
        }
        if (this.readOnly) {
            return;
        }
        if (typeof obj === 'string') {
            this.parentChange.emit(obj);
        }
        else if (obj.containerType === CmsContainerType.FOLDER) {
            this.parentChange.emit(obj.id);
        }
        else if (obj.containerType === CmsContainerType.FILE) {
            if (this.isVersionMismatch(obj as CmsExerciseScriptRef | CmsExerciseRef)) {
                return;
            }
            this.leafSelect.emit(obj.id);
            this.close();
        }
    }

    folderIsRoot(f: any) {
        return !!f.rootFolder || !!f.root || f.id == 'root';
    }

    isVersionMismatch(exercise: CmsExerciseScriptRef | CmsExerciseRef) {
        return exercise.containerType == CmsContainerType.FILE
            && this.currentVersion != null
            && compareVersion(this.currentVersion, exercise.contentVersion) < 0
    }

}


