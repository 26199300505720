<div class="exercisefilter-attr-label-wrapper" [attr.title]="getTitle()">
    <div class="exercisefilter-attr-label" [ngStyle]='getColorComplexity(attributeAssignment)'>{{attributeAssignment.attribute.name}}</div>                      
    <div class="exercisefilter-attr-button" [ngStyle]='getColorComplexity(attributeAssignment)' 
         *ngIf="attributeAssignment.attributeAssignmentType!==cmsAttributeSetting.NONE" (click)="select()">
         <span class="fa"
            [ngClass]="{
                'fa-check':attributeAssignment.attributeAssignmentType===cmsAttributeSetting.ALLOW, 
                'fa-exclamation':attributeAssignment.attributeAssignmentType===cmsAttributeSetting.FORCE || attributeAssignment.attributeAssignmentType===cmsAttributeSetting.SUPER_FORCE}
            "></span>
            <span *ngIf="attributeAssignment.attributeAssignmentType===cmsAttributeSetting.SUPER_FORCE" class="fa fa-exclamation"></span>
    </div>                        
    <div class="exercisefilter-attr-button" *ngIf="attributeAssignment.attributeAssignmentType===cmsAttributeSetting.NONE" (click)="select()">&nbsp;</div>                        
</div>
