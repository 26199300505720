import { HostListener } from "@angular/core";
import { Title } from "@angular/platform-browser";

export abstract class SetTitleService {

    constructor(private title: Title) { }

    public setTitle(newTitle: string) {
        var titleSuffix = "Algebrakit CMS";
        if (!newTitle || newTitle.length === 0) {
            this.title.setTitle(titleSuffix);
        }
        else {
            this.title.setTitle(`${newTitle} - ${titleSuffix}`);
        }
    }
}