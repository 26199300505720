import { Directive, ElementRef, HostListener, Renderer2, Input, OnInit } from '@angular/core';
import { UserService } from '../../../security/services/user.service';
import {Router, NavigationEnd, ActivatedRoute  }   from '@angular/router';

@Directive({
  selector: '[helpitem-listitem]'
})

export class HelpItemLIDirective implements OnInit {
    @Input('id') help_id : string;
    @Input('sid') help_sid : string;
    @Input('section') help_section : string;
    @Input('ssection') help_ssection : string;
//    @Input('component') help_component : string;
    @Input('title') help_title : string;

    constructor(
        private hostElement: ElementRef,
        private renderer: Renderer2,
        private router : Router,
        private route: ActivatedRoute,
        private userService: UserService
    ) { 
    }
    
    showId(){
        if (!this.help_section) {
            return this.help_id == this.help_sid;
        }
        else {
            return this.help_section == this.help_ssection && this.help_id == this.help_sid;
        }
    }

    ngOnInit (){
        this.hostElement.nativeElement.append(this.help_title);
    }

    ngOnChanges () {
        if(this.showId()) {
            this.renderer.removeClass(this.hostElement.nativeElement, 'helpQ');
            this.hostElement.nativeElement.removeAttribute('ng-click');
            this.renderer.addClass(this.hostElement.nativeElement, 'selected');
            this.renderer.addClass(this.hostElement.nativeElement, 'helpQS');
        }
        else{
            this.renderer.removeClass(this.hostElement.nativeElement, 'selected');
//            this.hostElement.nativeElement.setAttribute('click', this.showQuestion('quickguide','intro'));
//            this.renderer.setAttribute(this.hostElement.nativeElement,'click', 'showQuestion(' + this.help_component + ',' + this.help_id + ')');
            this.renderer.removeClass(this.hostElement.nativeElement, 'helpQS');
            this.renderer.addClass(this.hostElement.nativeElement, 'helpQ');
        }

    }
            
}