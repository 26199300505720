export type ProfileKey = 'staging' | 'dev' | 'staging_detached' | 'local' | 'local_prod_db' | 'production' | 'test';

/**
 * These override the default settings defined in the ProfileSettings class
 */
const PROFILE_SETTINGS: {[key in ProfileKey]: IProfileSettings} = {
    staging: {
        publishingWarning: true,
        refArrangementWarning: true, 
        managementUrl: 'management.algebrakit.com/console'
    },
    dev: {
        publishingWarning: true,
        refArrangementWarning: true, 
        managementUrl: 'management.algebrakit.com/console'
    },
    staging_detached: {
        publishingWarning: true,
        refArrangementWarning: true, 
        managementUrl: 'management.algebrakit.com/console'
    },
    local: {
        publishingWarning: true,
        refArrangementWarning: true, 
        managementUrl: 'localhost:8091/console'
    },
    local_prod_db: {
        managementUrl: 'localhost:8091/console'
    },
    production: {
        managementUrl: 'management.algebrakit.com/console'
    },
    test: {
        publishingWarning: true,
        refArrangementWarning: true, 
        managementUrl: 'management.algebrakit.com/console'
    }
}

export interface IProfileSettings {
    publishingWarning?: boolean;
    refArrangementWarning?: boolean;
    managementUrl: string;
}

export class ProfileSettings implements IProfileSettings {

    //Default settings
    public publishingWarning: boolean = false;       //Show a warning when publishing
    public refArrangementWarning: boolean = false;   //Show a warning when editing references and arrangements
    public managementUrl: string;

    constructor(profileKey: ProfileKey) {
        let obj = PROFILE_SETTINGS[profileKey];
        if (!obj) {
            return;
        }
        this.publishingWarning = obj.publishingWarning !== undefined 
            ? obj.publishingWarning 
            : this.publishingWarning;
        this.refArrangementWarning = obj.refArrangementWarning !== undefined
            ? obj.refArrangementWarning
            : this.refArrangementWarning;
        this.managementUrl = obj.managementUrl;
    }
}