<div class='attribute-editor' *ngIf="attribute">
    <h2>Edit Attribute</h2>
    <div *ngIf="ready()">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class='row row-spacing'>
                <div class='col-xs-3 col-sm-2'>
                    <label>Attribute name</label>
                </div>
                <div class="col-xs-7 col-sm-5">
                    <input name='id' formControlName='name' class='form-control'> 
                    <span *ngIf="nameCtrl?.errors?.nameExists" class="error-message">An attribute with this name already exists</span>
                </div>
            </div>
            <div class='row row-spacing'>
                <div class='col-xs-3 col-sm-2'>
                    <label>Description</label>
                </div>
                <div class="col-xs-7 col-sm-5">
                    <input name='description' formControlName='description' class='form-control'>
                </div>
            </div>
        </form>
        <br />
        <div class="row">
            <div class="col-xs-3">
                <button class="btn btn-md btn-primary btn-block" type="button" title="OK" (click)="onSubmit()" [disabled]="!form.valid">
                    <i class="fa fa-save"></i>
                    <span>OK</span>
                </button>
            </div>
            <div class="col-xs-3">
                <button class="btn btn-md btn-default btn-block" type="button" (click)="cancel()" title="Cancel">
                    <i class="fa fa-ban"></i>
                    <span translate>Close</span>
                </button>
            </div>
        </div>
    </div>
</div>