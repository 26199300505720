<div class='topic-leaf row cms-navigator-item'>
    <div class='col-sm-6 col-xs-12'>
        <div class="dropdown">
            <input *ngIf="selectable" class="clickable" type="checkbox" [checked]="selected"
                (change)="checked($event)" />
            &nbsp;
            <button class="dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="true">
                <span [ngClass]="leaf.iconClass"></span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenu2" *ngIf="leaf.actions && getActions().length > 0">
                <li *ngFor="let action of getActions()" (click)="action.callback(leaf)" class='dropdown-entry'>
                    <a>
                        <span [ngClass]="action.iconClass"></span>
                        <span class='dropdown-entry-label'>{{action.name}}</span>
                    </a>
                </li>
            </ul>
        </div>
        &nbsp;
        <span [ngClass]="{'clickable': enabled, 'disabled': !enabled}" (click)="onLeafClick()"><span
                [innerHTML]="getLeafName()"></span> <i *ngIf="errorBadgeTitle" [tooltip]="errorBadgeTitle"
                class="error-badge fa fa-exclamation-circle"></i></span>
    </div>
    <div class="col-sm-6 label-container-sm" *ngIf='topic.attributeAssignments'>
        <div *ngFor='let attr of leaf.attributeAssignments' class='label-wrapper-xs'
            [ngStyle]="getColorForAttribute(attr)">
            <span class='attr-label-xs'>{{attr.attribute.name}}</span>
        </div>
    </div>
    <div class="col-xs-12 label-container-xs" *ngIf='topic.attributeAssignments'>
        <div *ngFor='let attr of leaf.attributeAssignments' class='label-wrapper-xs'>
            <div class='attr-label-xs' [ngStyle]="getColorForLeafAttribute(attr)">{{attr.attribute.name}}</div>
        </div>
    </div>
</div>