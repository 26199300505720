import { Injectable } from '@angular/core';
import {
	CanActivate, 
	ActivatedRouteSnapshot,
	RouterStateSnapshot} from '@angular/router';
import { AkitVersionsService } from '../../algebrakit/services/akit-versions.service';

@Injectable()
export class ResetAkitVersionGuard implements CanActivate {
	constructor(private akitVersionsService: AkitVersionsService) { }

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		await this.akitVersionsService.changeVersionIfRequired("default", route);
		return true
	}
}