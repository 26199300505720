import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { UserLoginComponent } from './components/user-login/user-login.component';
import { InviteRegisterComponent } from './components/invite-register/invite-register.component';

const routes = [
  { path: 'invited/register/:email/:token', component: InviteRegisterComponent, data: {title: 'Register'} },
  { path: 'login', component: UserLoginComponent, data: {title: 'Login'} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SecurityRoutingModule { }


