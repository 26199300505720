import { User } from "../types/user.type";

/**
 * Returns true if user has at least 1 of the specified permissions
 * @param user 
 * @param permissions 
 */
export function checkPermissions(user: User, permissions: string[]): boolean {
	if (!permissions) {
		return true;
	}
    return user.roles && permissions.some(pCheck => user.roles.some(pUser => checkPermission(pUser, pCheck)));
}

/*
 * 
 * @param user Returns true only if user has all of the specified permissions
 * @param permissions 
 */
export function checkPermissionsAll(user: User, permissions: string[]): boolean {
	if (!permissions) {
		return true;
	}
    return user.roles && permissions.every(pCheck => user.roles.some(pUser => checkPermission(pUser, pCheck)));
}

export function checkPermission(userPermission: string, checkPermission: string) {
    let permissionRegex = getPermissionRegExp(checkPermission);
    let match = userPermission.match(permissionRegex);
    return match && match.length > 0
}

function getPermissionRegExp(permission: string) {
    return permission
        .replace(new RegExp('\\.', 'g'), '\\.')
        .replace(new RegExp('\\*', 'g'), '\.\*');
}