import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { CmsExerciseFilter } from '../types/navigator.types';
import { ExerciseService } from './exercise.service';
import { ToastrService } from 'ngx-toastr';
import { SetTitleService } from '../../app/services/set-title.service';
import { Title } from '@angular/platform-browser';
import { VersioningService } from './versioning.service';
import { AkitVersionsService } from '../../algebrakit/services/akit-versions.service';

@Injectable()
export class ExerciseFilterResolverService extends SetTitleService implements Resolve<CmsExerciseFilter> {
    constructor(
        private exerciseFilterService: ExerciseService,
        private versioningService: VersioningService,
        private toastr: ToastrService,
        titleService: Title,
        private akitVersionsService: AkitVersionsService
    ) { super(titleService) }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<CmsExerciseFilter> {
        let id = route.params['id'];
        return this.exerciseFilterService.lockResource(id).then(() => {
            return this.exerciseFilterService.getExerciseFilter(id).toPromise();
        })
            .then(async (ex) => {
                this.setTitle(ex.name)
                if (route.data.matchVersion && ex && ex.course && ex.course.options) {
                    await this.akitVersionsService.changeVersionIfRequired(ex.course.options.akitVersion, route);
                }
                return ex;
            })
            .catch((error: any) => {
                this.versioningService.handleVersionMisMatch(error);
                this.exerciseFilterService.showLockErrorMessage(error, true);
                return null;
            });
    }
}

