import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRouteSnapshot } from '@angular/router';
import { HttpErrorHandler } from '../../app/services/http-error-handler';
import { AkitVersion, AkitVersionsResult, testEnvs } from '../types/metadata.type';
import { ContentVersionService } from '../../app/services/content-version-service';
import { off } from 'process';

declare let AKIT_CMS_PROFILE: string;

/**
 * This service can be used to switch the version of Algebrakit that the CMS uses to author and run exercises.
 * Changing a version will reload the page with the new version of the algebrakit widgets.
 * 
 * Checking whther we need to change version should be done on every page that either:
 * - Uses any authoring or student-facing stencil compoenent
 * - Calls functions on the AlgebraKIT object
 * - Does API calls to the sessionmanager (directly or indirectly)
 */
@Injectable({
    providedIn: 'root',
})
export class AkitVersionsService extends HttpErrorHandler {

    /**
     * (prefix for available (LTS) version) => contentVersion
     * e.g. "2024-jan" or "testvision-56"
     */
    private _akitVersions: { [versionName: string]: string } = null;

    /**
     * Promise to prevent multiple requests to the server
     */
    private getPromise: Promise<{ [versionName: string]: string }> = null;

    /**
     * Try to determine the default version based on the hostname of the CMS and the profile as fallback
     * default versions are always prod, staging local or a test namespaces. Never an LTS version.
     */
    private get defaultVersion() {
        const host = window.location.hostname;
        if (host.includes('localhost')) {
            return 'local';
        }
        else if (host.includes('staging.algebrakit.com')) {
            return 'staging';
        }
        else if (host === 'cms.algebrakit.com') {
            return 'prod';
        }
        else {
            const hostParts = host.split('.');
            const prefixParts = hostParts[0].split('-');
            if (prefixParts.length > 1 && testEnvs.includes(prefixParts[0])) {
                return prefixParts[0];
            }
        }

        switch (AKIT_CMS_PROFILE) {
            case 'production':
            case 'prod':
                return 'prod';
            default:
                return AKIT_CMS_PROFILE
        }
    }

    /**
     * Determine the currently loaded version by checking the script tag that loaded the widgets.
     * This reduces risk of desync between the version of the widgets and the version of the sessionmanager.
     * First part of the hostname indicates the version. If this part is not present in the list of
     * available versions, the version is considered "auto" => no specific LTS version is loaded and the 
     * selected version is based on the course version. If no vourse version is present, use the default version for
     * the current environment.
     */
    public get currentVersion(): Promise<string> {
        return this.getAkitVersions(false, true).then((versions) => {
            //Determine the current version based on the widgets script tag that is loaded
            const widgetsScriptTag = document.querySelector(`script[data-akit-widgets]`);
            const scriptSrc = widgetsScriptTag ? widgetsScriptTag.getAttribute('src') : "";
            const hostParts = /https?:\/\/([0-9A-z-]+)/.exec(scriptSrc);
            if (hostParts && hostParts.length > 1) {
                const hostPrefix = hostParts[1];
                const versionParts = hostPrefix.split('-');
                let version
                if (versionParts.length > 1 && testEnvs.includes(versionParts[0])) {
                    //Test namespaces use hyphens instead of dots to separate version parts (due to SSL certification limitations)
                    version = versionParts[0];
                }
                else {
                    version = hostPrefix;
                }
                if (versions[version] != undefined) {
                    return version;
                }
                else if (scriptSrc.includes('localhost')) {
                    return 'local';
                }
                else if (scriptSrc.includes('staging.algebrakit.com')) {
                    return 'staging';
                }
                else if (scriptSrc.includes('://widgets.algebrakit.com')) {
                    return 'prod';
                }
            }
            return "unknown";
        });
    }

    constructor(
        protected http: HttpClient,
        protected toastr: ToastrService
    ) {
        super(toastr);
    }

    /**
     * Get the available versions of the Algebrakit widgets.
     * @param forceReload if true, clears the cache and reloads the versions from the server.
     * @returns 
     */
    public async getAkitVersions(forceReload?: boolean, includingTestNamespaces?: boolean): Promise<{ [versionName: string]: string }> {
        if (forceReload) {
            this.getPromise = null
            this._akitVersions = null;
        }
        if (this._akitVersions === null) {
            if (this.getPromise === null) {
                this.getPromise = this.http.get<{ [versionName: string]: string }>(`info/akit-versions${includingTestNamespaces ? '?includingTestNamespaces=true' : ''}`)
                    .toPromise();
            }
            this._akitVersions = await this.getPromise
                .catch(this._serverError.bind(this));
        }
        return this._akitVersions;
    }

    async switchToAutoVersion(route: ActivatedRouteSnapshot) {
        window.sessionStorage.setItem('autoAkitVersion', 'true');
        window.sessionStorage.removeItem('akitEndpoint');

        const savedTargetVersion = window.sessionStorage.getItem('savedTargetVersion');
        const queryParams = route.queryParams ? { ...route.queryParams } : {};
        if (savedTargetVersion) {
            queryParams.akitEndpoint = savedTargetVersion;
        }
        else {
            delete queryParams.akitEndpoint;
        }
        this.refresh(route, queryParams, true);
    }

    /**
     * Check wether the target version is different from the current version and reload the page if necessary.
     * 
     * If a previous version is stored in session storage, it will be used to override the target version.
     * @param targetVersion 
     * @param route 
     * @param storeForSession if true, stores the target version in the current window's session so we "keep" the CMS in this version until manually changed.
     * @param saveTargetVersion if true, "remembers" the target version that should be used if we switch back to "auto" mode.
     */
    async changeVersionIfRequired(targetVersion: string, route: ActivatedRouteSnapshot, storeForSession?: boolean, saveTargetVersion: boolean = true): Promise<void> {
        if (this._akitVersions === null) {
            await this.getAkitVersions();
        }

        if (saveTargetVersion && targetVersion != null) {
            //Used to switch to when we go back to "auto". To prevent double refreshes
            window.sessionStorage.setItem('savedTargetVersion', targetVersion);
        }
        else {
            window.sessionStorage.removeItem('savedTargetVersion')
        }

        if (storeForSession) {
            //Clear the auto version flag to ensure no auto-switching takes place and store the target in session storage
            window.sessionStorage.setItem('autoAkitVersion', 'false');
            window.sessionStorage.setItem('akitEndpoint', targetVersion);
        }
        else if (window.sessionStorage.getItem('akitEndpoint')) {
            //If we are not going to change the version for this session, we need to check if we already have a version stored and use that
            targetVersion = window.sessionStorage.getItem('akitEndpoint');
        }

        if (targetVersion) {
            let courseVersion = targetVersion || 'default';
            if (courseVersion === 'default') { //default to the profile if no version is specified
                courseVersion = this.defaultVersion;
            }
            const currentVersion = await this.currentVersion;
            if (currentVersion !== courseVersion) {
                //Switch version
                console.log(`Switching to akit version ${courseVersion}`);
                const queryParams = route.queryParams ? { ...route.queryParams } : {};
                if (courseVersion == 'local' || Object.keys(this._akitVersions).includes(courseVersion)) {
                    queryParams.akitEndpoint = courseVersion;
                }
                else {
                    delete queryParams.akitEndpoint;
                }
                this.refresh(route, queryParams, true);
            }
        }
    }

    /**
     * Refreshesh the page with the given Route and query parameters.
     * @param route snapshot of the angular route, usued to determine the current url
     * @param queryParams additional query parameters to add to the URL
     * @param removeOnCloseUrl if true, remose the "onCloseUrl" from the query parameters to prevent redirection errors after the page is reloaded.
     */
    refresh(route: ActivatedRouteSnapshot, queryParams: { [x: string]: any } = {}, removeOnCloseUrl?: boolean) {
        const url = (route.firstChild || route).pathFromRoot
            .map(v => v.url.map(segment => segment.toString()).join('/'))
            .join('/');
        let fullUrl = url;
        if (queryParams && Object.keys(queryParams).length > 0) {
            if (removeOnCloseUrl && queryParams.onCloseUrl) {
                delete queryParams.onCloseUrl;
            }
            const queryStr = Object.keys(queryParams).map(k => `${k}=${queryParams[k]}`).join('&');
            fullUrl += `?${queryStr}`;
        }
        const baseElm = document.querySelector('base');
        const baseHref = baseElm ? baseElm.getAttribute('href') : '';
        if (baseHref) {
            fullUrl = baseHref + fullUrl;
        }
        window.location.href = fullUrl;
    }
}
