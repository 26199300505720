<div class='label-navigator'>
    <h1>Labels</h1>
    <div class="row" *ngIf="canEditTopicsScripts()">
        <div class="col-sm-12">
            <button class="btn btn-primary create-button" (click)="createLabel()">
                <span translate>Create new label...</span>
            </button>
        </div>
    </div>
    <br/>
    <busy-gears *ngIf="loading"></busy-gears>
    <div *ngIf="labels">
        <div class="row header-row">
            <div class="col-xs-3">Label ID</div>
            <div class="col-xs-5">
                Translation:
                <select [(ngModel)]="selectedRegion">
                    <option *ngFor="let region of regions" [ngValue]="region.id">{{region.name}}</option>
                </select>
            </div>
            <div *ngIf="canEditTopicsScripts()" class="col-xs-2">Last modified by</div>
            <div *ngIf="canEditTopicsScripts()" class="col-xs-2">Last modified on</div>
        </div>

        <div *ngFor="let label of labels" class='label-item row'>
            <div class='col-xs-3'>
                <div class="dropdown">
                    <button class="dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        <span class="fa fa-bookmark-o"></span>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenu2" *ngIf="canEditTopicsScripts() && actions">
                        <li *ngFor="let action of actions" (click)="action.callback(label)" class='dropdown-entry'>
                            <a>
                                <span [ngClass]="action.iconClass"></span>
                                <span class='dropdown-entry-label'>{{action.name}}</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <span>{{label.id}}</span>
            </div>
            <div class="col-xs-5">{{getTranslation(label)}}</div>
            <div *ngIf="canEditTopicsScripts() && label.modifiedBy" class="col-xs-2">{{label.modifiedBy}}</div>
            <div *ngIf="canEditTopicsScripts() && label.modifiedDate" class="col-xs-2">{{label.modifiedDate | date:'dd-MM-y HH:mm'}}</div>
        </div>
    </div>
</div>