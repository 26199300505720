import { Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";

@Component({
    selector: 'pagination-controller',
    templateUrl: './pagination-controller.component.html',
    styleUrls: ['./pagination-controller.component.less']
})
export class PaginationControllerComponent {

    @Input() pageSize: number = 25;
    @Input() searchFilter: string;
    @Input() hasNext: boolean;
    @Input() hasPrevious: boolean;
    @Input() buttonsOnly: boolean = false;

    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();
    @Output() pageSizeChanged: EventEmitter<number> = new EventEmitter<number>();
    @Output() search: EventEmitter<string> = new EventEmitter<string>();

    onPageSizeChanged() {
        this.pageSizeChanged.emit(this.pageSize);
    }

    onSearch() {
        this.search.emit(this.searchFilter);
    }

    searchFilterKeyDown(e: KeyboardEvent) {
        if (e.key == 'Enter') {
            this.onSearch();
        }
    }
}