<div class='help-navigator cms-navigator'>
    <h1>Algebrakit CMS - Reference Guide</h1>
    <div class="row  flex-row">
        <div class='col-xs-6 col-sm-4 col-md-2 col-md-offset-1 col-lg-2 col-lg-offset-1'
            (click)="showQuestions('interactions')">
            <helpitem-img
                [ngClass]="{'refblock': !showComponent('interactions'), 'refblockSelected': showComponent('interactions')}"
                [itemClass]="'fa-file-text'" [selectedClass]="showComponent('interactions')">
                <div class="subject">Interactions</div>
                <div class="itemdescription">
                    Detailed specification of interaction types
                </div>
            </helpitem-img>
        </div>
        <div class='col-xs-6 col-sm-4 col-md-2 col-lg-2' (click)="showQuestions('syntax')">
            <helpitem-img
                [ngClass]="{'refblock': !showComponent('syntax'), 'refblockSelected': showComponent('syntax')}"
                [itemClass]="'fa-code'" [selectedClass]="showComponent('syntax')">
                <div class="subject">Syntax Guide</div>
                <div class="itemdescription">
                    Detailed syntax description
                </div>
            </helpitem-img>
        </div>
        <div class='col-xs-6 col-sm-4 col-md-2 col-lg-2' (click)="showQuestions('modes')">
            <helpitem-img [ngClass]="{'refblock': !showComponent('modes'), 'refblockSelected': showComponent('modes')}"
                [itemClass]="'fa-map-signs'" [selectedClass]="showComponent('modes')">
                <div class="subject">Modes</div>
                <div class="itemdescription">
                    The list of available modes
                </div>
            </helpitem-img>
        </div>
        <div class='col-xs-6 col-sm-4 col-md-2 col-lg-2' (click)="showQuestions('attributes')">
            <helpitem-img
                [ngClass]="{'refblock': !showComponent('attributes'), 'refblockSelected': showComponent('attributes')}"
                [itemClass]="'fa-bar-chart'" [selectedClass]="showComponent('attributes')">
                <div class="subject">Attributes</div>
                <div class="itemdescription">
                    The list of default library attributes
                </div>
            </helpitem-img>
        </div>
        <div class='col-xs-6 col-sm-4 col-md-2 col-lg-2' (click)="showQuestions('labels')">
            <helpitem-img
                [ngClass]="{'refblock': !showComponent('labels'), 'refblockSelected': showComponent('labels')}"
                [itemClass]="'fa-tags'" [selectedClass]="showComponent('labels')">
                <div class="subject">Labels</div>
                <div class="itemdescription">
                    The list of available labels
                </div>
            </helpitem-img>
        </div>
    </div>

    <div class="row flex-row">

        <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1">
            <div *ngIf="showComponent('interactions')">
                <div class="referenceblock">
                    <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">

                    </div>
                    <div class="col-xs-6 col-sm-8 col-md-8 col-lg-8">
                        This feature will be added in the future.
                    </div>
                </div>
            </div>

            <div *ngIf="showComponent('syntax')">
                <div class="referenceblock">
                    <syntax-overview></syntax-overview>
                </div>
            </div>

            <div *ngIf="showComponent('modes')">
                <div class="referenceblock">
                    <modes-overview></modes-overview>
                </div>
            </div>

            <div *ngIf="showComponent('attributes')">
                <div class="referenceblock">
                    <attributes-overview></attributes-overview>
                </div>
            </div>

            <div *ngIf="showComponent('labels')">
                <div class="referenceblock">
                    <labels-overview></labels-overview>
                </div>
            </div>

            <div *ngIf="showComponent('other')">
                <div class="referenceblock">
                    <div>
                        <p>Please select one of the topics above.</p>
                    </div>
                </div>
            </div>

        </div> <!-- bootstrap 10 col div -->

    </div> <!-- flex row -->


</div>