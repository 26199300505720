import {
    Component, Input, Output, 
    OnDestroy, EventEmitter,
    OnChanges,
    SimpleChanges} from '@angular/core';
import { IAlgebraKIT, IErrorData } from '../../types/algebrakit.type';
import 'rxjs/Rx';

declare let AlgebraKIT: IAlgebraKIT;

// Angular wrapper for various widgets types (show solution only)
// parameters:
// - app-id
// - session-id

@Component({
    selector: 'cms-solution',
    template: '<div><akit-exercise *ngIf="_sessionId" solution-mode [attr.session-id]="_sessionId"></akit-exercise></div>',
    //styleUrls: ['./cms-interaction.component.css']
})
export class CmsSolutionComponent implements OnDestroy {
    _sessionId: string;

    @Input() set sessionId(value: string) {
        // if session id is changed, remove the widget and reload it
        if(this._sessionId !== value) {
            if(this._sessionId) {
                this._sessionId = null;
                setTimeout(() => {
                    this._sessionId = value;
                }, 0);
            } else {
                this._sessionId = value;
            }
        }
    }

    @Output() error: EventEmitter<IErrorData> = new EventEmitter<IErrorData>();

    constructor(
    ) { }

    public command(name: string, args: any) {
        if (!this._sessionId) return;
        return AlgebraKIT.exerciseCommand(this._sessionId, name, args);
    }

    ngOnDestroy() {
        if (this._sessionId) {
            AlgebraKIT.removeWidgetBySessionId(this._sessionId, false);
        }
    }
}