import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CmsExerciseScript, CmsTopicAttribute, CmsTopic, CheckedEvent, CmsTopicAttributeAssignment, CmsItemState } from '../../types/navigator.types';
import { UserService } from '../../../security/services/user.service';
import { TopicService } from '../../services/topic.service';

//interpolate between green and red
function getColor(value: number) {
    //value from 0 to 1
    var hue = ((1 - value) * 120).toString(10);
    return ["hsl(", hue, ",100%,50%)"].join("");
}

/**
 * A topic-leaf is a 'file' in the navigation system, which corresponds to an
 * exercise. The model is defined by class of type CmsLeaf. Classes for the various 
 * types of exercises are derived from CmsLeaf
 */
@Component({
    selector: 'topic-leaf',
    templateUrl: './topic-leaf.component.html',
    styleUrls: ['./topic-leaf.component.less', '../../../../assets/shared.less']
})
export class TopicLeafComponent {
    @Input() leaf: CmsExerciseScript;
    @Input() topic: CmsTopic; //required for attribute definitions

    @Input() selectable: boolean = false;
    @Input() selected: boolean = false;
    @Input() readOnly = false;
    @Input() hideState: boolean = false;

    @Input() errorBadgeTitle: string;
    @Input() enabled = true;

    @Output() onChecked: EventEmitter<CheckedEvent<CmsExerciseScript>> = new EventEmitter<CheckedEvent<CmsExerciseScript>>();

    constructor(private userService: UserService, private topicService: TopicService) { }

    get isPublished() {
        if (!this.leaf.latestVersionNumbers) {
            return false;
        }
        return this.leaf.latestVersionNumbers.publishedMajorVersion && this.leaf.latestVersionNumbers.publishedMinorVersion != null
    }

    checked(e: any) {
        let value: boolean = e.target.checked;
        this.onChecked.emit({ item: this.leaf, checked: value });
    }

    getColorForAttribute(attribute: CmsTopicAttributeAssignment): { [key: string]: string } {
        let result = {};
        if (this.leaf.attributeAssignments.find(attr => attr.id === attribute.id)) {
            result = this.topicService.getAttributeColorBg(attribute.difficulty);
            if (attribute.difficulty.complexity > 4) result['color'] = 'white';
        }
        return result;
    };

    getColorForLeafAttribute(attribute: CmsTopicAttributeAssignment): { [key: string]: string } {
        let result = {};
        if (!this.topic.attributeAssignments) return result;
        if (!attribute) return result;

        result["background-color"] = getColor(attribute.difficulty.complexity / 8.0);
        if (attribute.difficulty.complexity > 4) result['color'] = 'white';
        return result;
    };

    onLeafClick(): void {
        if (this.enabled && this.leaf.onClick) this.leaf.onClick(this.leaf);
    }

    getActions() {
        if (this.readOnly) {
            return [];
        }
        let canEdit: boolean = this.userService.getUser() && this.userService.getUser().canEditLibrary();
        return canEdit
            ? this.leaf.actions
            : this.leaf.actions.filter(a => !a.adminOnly);
    }

    getExportPossibilities() {
        let results = [];
        if (this.leaf.state == CmsItemState.APPROVED) {
            results.push({ label: 'Editable Copy', class: 'label-info' });
        }
        if (this.isPublished) {
            results.push({ label: 'Reference', class: 'label-success' });
        }
        return results;
    }

    getApprovedVersionNumbers(leaf: CmsExerciseScript) {
        if (leaf.latestVersionNumbers.majorVersion === 0 && leaf.latestVersionNumbers.minorVersion === 0) {
            return '';
        }
        return `${leaf.latestVersionNumbers.majorVersion}.${leaf.latestVersionNumbers.minorVersion}`;
    }
}



