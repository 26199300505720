import { Component, OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { BehaviorSubject ,  Observable ,  Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { CmsItemAction, CmsCourse, CmsItem, CmsTopicAttribute } from '../../types/navigator.types';
import { UserService } from '../../../security/services/user.service';
import { User } from '../../../security/types/user.type';
import { CourseService } from '../../services/course.service';
import { I18nLabelService } from '../../services/i18n-label.service';
import { I18nLabel, REGIONS, NameIdPair } from '../../types/cms-metadata.types';
import { TopicAttributeService } from '../../services/topic-attribute.service.';

declare let $: any;

@Component({
    selector: 'attributes-overview',
    templateUrl: './attributes-overview.component.html',
    styleUrls: ['./attributes-overview.component.css']
})
export class AttributesOverviewComponent implements OnInit {

    attributes: CmsTopicAttribute[];

    regions = REGIONS;
    selectedRegion = 'en';

    modifyCheckResult: NameIdPair[] = [];
    attrToDelete: CmsTopicAttribute;

    actions: CmsItemAction[] = [{
        name: 'edit',
        iconClass: 'fa fa-lg fa-pencil-square',
        callback: ((attribute: CmsTopicAttribute) => this.editAttribute(attribute.id))
    }, {
        name: 'delete',
        iconClass: 'fa fa-lg fa-times',
        callback: ((attribute: CmsTopicAttribute) => this.removeAttribute(attribute.id))
    }
    ];

    loading: boolean = false;
    modifyChecking: boolean = false;

    subscription: Subscription;
    user: User;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private attributeService: TopicAttributeService,
        private userService: UserService
    ) {
    }

    editAttribute(id: string): void {
        this.router.navigate(['edit/attribute', id]);
    }

    canEditTopicsScripts() {
        return this.userService.canEditLibrary();
    }

    createAttribute(): void {
        this.router.navigate(['create/attribute']);
    }

    refresh() {
        this.attributeService.getAllAttributes().then(attributes => this.attributes = attributes);
    }

    removeAttribute(id: string): Promise<boolean> {
        this.attrToDelete = this.attributes.find(a => a.id === id);
        this.modifyCheckResult = [];
        this.modifyChecking = true;
        return this.attributeService.getTopicReferences(id).then(result => {
            this.modifyCheckResult = result;
            this.modifyChecking = false;
            if (this.modifyCheckResult.length === 0) {
                return this.removeAttributeConfirm(id);
            }
            else {
                return false;
            }
        }).catch(err => {
            this.toastr.error("Could not delete the topic", 'Error');
            this.modifyChecking = false;
            console.log(err);
            return false;
        });
        
    }

    removeAttributeConfirm(id: string) {
        if (confirm('Are you sure you want to remove this attribute?')) {
            return this.attributeService.deleteAttribute(id).then(() => {
                this.toastr.success('Label succesfully removed.', 'Success');
                this.attributeService.setDirty();
                this.refresh();
                this.attrToDelete = null;
                return true;
            }).catch(err => {
                this.toastr.error("Could not delete the topic", 'Error');
                console.log(err);
                return false;
            });
        }
    }

    ngOnInit() {
        this.user = this.userService.getUser();
        this.refresh();
    }
}