export function isArguments(object:any) {
    return supportsArgumentsClass ? supported(object) : unsupported(object);
}

var supportsArgumentsClass = (function(){
  return Object.prototype.toString.call(arguments)
})() == '[object Arguments]';

//exports = module.exports = supportsArgumentsClass ? supported : unsupported;

function supported(object:any) {
  return Object.prototype.toString.call(object) == '[object Arguments]';
};

function unsupported(object:any){
  return object &&
    typeof object == 'object' &&
    typeof object.length == 'number' &&
    Object.prototype.hasOwnProperty.call(object, 'callee') &&
    !Object.prototype.propertyIsEnumerable.call(object, 'callee') ||
    false;
};
