
import {timer as observableTimer,  BehaviorSubject ,  Observable } from 'rxjs';

import {switchMap, filter} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';

import { CmsCourse } from '../../types/navigator.types';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../../security/services/user.service';
import { PublishService } from '../../services/publish.service';
import { PublishedSnapshot, PublishProgress } from '../../types/published.types';
import { VerifyService } from '../../services/verify.service';
import { SortedCheckResults } from '../../types/verify.types';
import { AppProfileService } from '../../../app/services/app-profile.service';

declare let $: any;

interface Action {
    name: string,
    callback: { (item: PublishedSnapshot): void }
    iconClass: string
}

@Component({
    templateUrl: './publish-snapshot.component.html',
    styleUrls: ['./publish-snapshot.component.css']
})
export class PublishSnapshotComponent implements OnInit {
    courseId$ = new BehaviorSubject<string>(null);
    courses: CmsCourse[];
    snapshots: PublishedSnapshot[] = [];
    courseCtrl = new FormControl('');
    newVersionCtrl = new FormControl('', []);
    selectedCourse: CmsCourse;

    busyCheck = false;
    busyPublish = false;
    publisherInUse = false;

    sortedCheckResults: SortedCheckResults[];

    public progress: number;

    publishingWarning = false;

    actions: Action[] = [
        {
            //        name: 'repeat',
            //        iconClass: 'fa fa-lg fa-repeat',
            //        callback: ((version: CmsVersion) => this.repeatPublish(version.id))
            //    },{
            name: 'delete',
            iconClass: 'fa fa-lg fa-times',
            callback: ((snapshot: PublishedSnapshot) => this.deleteSnapshot(snapshot.course, snapshot.snapshotId, snapshot.name))
        },
        {
            name: 'export metadata',
            iconClass: 'fa fa-lg fa-download',
            callback: ((snapshot: PublishedSnapshot) => this.exportMetadata(snapshot.snapshotId))
        }
    ];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private publishService: PublishService,
        private courseService: CourseService,
        private verifyService: VerifyService,
        private userService: UserService,
        private appProfileService: AppProfileService
    ) {
    }

    hasErrors() {
        return this.sortedCheckResults
            && this.sortedCheckResults[3]
            && this.sortedCheckResults[3].checkedResources
            && Object.keys(this.sortedCheckResults[3].checkedResources).length > 0;
    }

    hasWarnings() {
        return this.sortedCheckResults
            && (
                (this.sortedCheckResults[1]
                    && this.sortedCheckResults[1].checkedResources
                    && Object.keys(this.sortedCheckResults[1].checkedResources).length > 0)
                || (this.sortedCheckResults[2]
                    && this.sortedCheckResults[2].checkedResources
                    && Object.keys(this.sortedCheckResults[2].checkedResources).length > 0)
            );
    }

    refresh(): void {
        this.busyPublish = false;
        this.publishService.getSnapshots(this.selectedCourse.id)
            .toPromise()
            .then(snapshots => {
                this.snapshots = snapshots.sort((s1, s2) => {
                    return s2.created.getTime() - s1.created.getTime();
                });
            })
    }

    repeatPublish(versionId: string) {

    }

    publish() {
        this.progress = 0;
        this.busyPublish = true;
        let versionName: string = this.newVersionCtrl.value;
        if (versionName && versionName.trim().length > 0) {
            this.publishService.publishSnapshot(this.courseCtrl.value, versionName)
                .toPromise()
                .then(() => {
                    this.toastr.success('Publishinging in progress...', 'Info');
                    this.setProgressTimer();
                }).catch(error => {
                    this.toastr.error(error.message, 'Error');
                    this.busyPublish = false;
                    this.refresh();
                })

        }
    }

    setProgressTimer() {
        let timer = observableTimer(0, 500)
            .subscribe((t) => {
                this.publishService.publishSnapshotProgress()
                    .toPromise()
                    .then((progress: PublishProgress) => {
                        this.progress = progress.progress;
                        if (progress.finished) {
                            this.toastr.success('Publishinging succesful', 'Success');
                            this.refresh();
                            timer.unsubscribe();
                        }
                    }).catch((err) => {
                        let message = err && err.message ? err.message : err;
                        this.toastr.error(message, 'Error');
                        timer.unsubscribe();
                    });
            });
    }

    deleteSnapshot(courseId: string, snapshotId: string, snapshotName: string) {
        if (confirm('Are you sure you want to delete this snapshot?')) {
            this.progress = 0;
            this.busyPublish = true;
            this.publishService.deleteSnapshot(courseId, snapshotId)
                .then(() => {
                    this.progress = 1;
                    this.busyPublish = false;
                    this.refresh();
                    this.toastr.success(`Snapshot ${snapshotName} is deleted successfully`, 'Success');
                }).catch(err => {
                    console.log(err);
                    this.toastr.error('Could not delete this version', 'Error');
                    this.busyPublish = false;
                    this.refresh();
                })

        }
    }

    countExercises(snapshot: PublishedSnapshot) {
        return Object.keys(snapshot.exercises).length;
    }

    exportMetadata(snapshotId: string) {
        this.publishService.exportSnapshotMetadata(snapshotId);
    }

    canPublish() {
        return this.selectedCourse && !this.busyCheck && !this.hasErrors();
    }

    verify() {
        this.busyCheck = true;
        this.verifyService.verifyCourseExtended(this.courseId$.value)
            .toPromise().then((result) => {
                this.sortedCheckResults = result;
                this.busyCheck = false;
            }).catch(error => {
                console.error(error);
                this.busyCheck = false;
                this.toastr.error('Could not verify the course', 'Error');
            });
    }

    checkIfProgressShouldShow() {
        this.publishService.publishInProgress().then((res) => {
            if (res.inProgress && res.showProgress) {
                this.busyPublish = true;
                this.setProgressTimer();
            }
        })
    }

    ngOnInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (event.url == "/snapshots") {
                    this.checkIfProgressShouldShow();
                    if (this.courseCtrl.value === null || this.courseCtrl.value === "0" || this.courseCtrl.value.length === 0) {
                        this.snapshots = [];
                        this.selectedCourse = null;
                    } else {
                        this.courseId$.next(null);
                    }
                }
            }
        });
        this.appProfileService.getSetting('publishingWarning').then((value) => {
            this.publishingWarning = value;
        });
        this.userService.user$.pipe(
            filter(user => user !== null))
            .subscribe(() => {
                this.progress = 0;
                this.checkIfProgressShouldShow();
                this.courseService.getCoursesList()
                    .toPromise()
                    .then((arr: CmsCourse[]) => {
                        this.courses = arr;
                        this.courseCtrl.setValue("0")
                    }).catch((err: any) => {
                        console.error(err);
                        this.toastr.error('Could not get your courses from the server', 'Error');
                    });

                this.courseCtrl.valueChanges.pipe(
                    filter(value => value))
                    .subscribe(value => this.courseId$.next(value));

                this.courseId$.asObservable().pipe(
                    filter(value => value !== null && value !== "0" && value.length > 0),
                    switchMap((courseId) => {
                        this.sortedCheckResults = null;
                        this.busyCheck = true;
                        this.busyPublish = false;
                        this.selectedCourse = this.courses.find(c => c.id === courseId);
                        return this.publishService.getSnapshots(courseId)
                    }),)
                    .subscribe(snapshots => {
                        this.snapshots = snapshots.sort((s1, s2) => {
                            return s2.created.getTime() - s1.created.getTime();
                        });
                        if (this.courseId$.value && this.courseId$.value !== "0") {
                            this.verifyService.verifyCourseForPublish(this.courseId$.value)
                                .toPromise().then((result) => {
                                    this.sortedCheckResults = result;
                                    this.busyCheck = false;
                                }).catch(err => {
                                    console.error(err);
                                    this.busyCheck = false;
                                    this.toastr.error('Could not verify the course', 'Error');
                                });
                        }
                    });
            })
    }

}

