import { Component, OnInit, ViewContainerRef, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {Location} from '@angular/common';

import { ToastrService } from 'ngx-toastr';

import {CmsSubject, NameIdPair, CmsSubjectRef, CmsContainerType, CmsMetadata, CmsItem } from '../../types/navigator.types';
import { TopicService } from '../../services/topic.service';
import { SubjectService } from '../../services/subject.service';

const ERROR_MSG_DUPLICATE = "Metadata cannot contain duplicate names.";
const ERROR_MSG_EMPTY = "Metadata fields cannot be empty. Please fill in all empty fields or delete them.";

@Component({
  selector: 'metadata-collection-editor',
  templateUrl: './metadata-collection-editor.component.html',
  styleUrls: ['./metadata-collection-editor.component.css']
})
export class MetadataCollectionEditorComponent {
    
    @Input() forItem: CmsItem;

    @Output() afterValidate: EventEmitter<boolean> = new EventEmitter<boolean>();

    metadataValid = true;
    metadataErrors: string[] = [];
    
    constructor(
        private toastr: ToastrService,
    ) { 
    }

    addMetadata(index?:number) {
        let entry: CmsMetadata = {
            id: null,
            name: "",
            value: ""
        }
        if (index !== 0 && !index) {
            this.forItem.metadata.push(entry);
        }
        else {
            this.forItem.metadata.splice(index+1,0,entry);
        }
        this.checkMetadataFields();
    }

    removeMetadata(index: number) {
        if(confirm('Are you sure to delete this entry?')){
            this.forItem.metadata.splice(index,1);
            this.checkMetadataFields();
        }
    }

    checkMetadataFields(event?: FormControl) {
        this.metadataErrors = [];
        let keys: string[] = [];
        this.metadataValid = true;
        for (let entry of this.forItem.metadata) {
            if (keys.indexOf(entry.name) === -1) {
                keys.push(entry.name);
            }
            else {
                this.metadataValid = false;
                if (this.metadataErrors.indexOf(ERROR_MSG_DUPLICATE) === -1) {
                    this.metadataErrors.push(ERROR_MSG_DUPLICATE);
                }
            }
            if (!(entry.name && entry.name.trim()) 
            || !(entry.value && entry.value.trim())) {
                this.metadataValid = false;
                this.metadataErrors.push(ERROR_MSG_EMPTY);
                break;
            }
        }
        this.afterValidate.emit(this.metadataValid);
    }
}

