import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';


import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { UserService } from '../../../security/services/user.service';
import { validatePasswordCtrls } from '../../services/util';

const DEFAULT_REDIRECT = '/home';

@Component({
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

    form: FormGroup;
    newPasswordCtrl = new FormControl('', Validators.required);
    repeatNewPasswordCtrl = new FormControl('', Validators.required);

    token: string;

    constructor(
        private userService: UserService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.form = this.formBuilder.group({
            newPassword: this.newPasswordCtrl,
            repeatNewPassword: this.repeatNewPasswordCtrl
        })
    }

    passWordValidator(formgroup: FormGroup) {
        let passwordCtrl = formgroup.controls['newPassword'];
        let repeatPasswordCtrl = formgroup.controls['repeatNewPassword'];
        return validatePasswordCtrls(passwordCtrl, repeatPasswordCtrl);
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.token = params['token'];
        });
        this.form.setValidators(this.passWordValidator);

        this.toastr.info('Please enter your new password', 'Reset Password');
    }

    submit(): void {
        this.userService.resetPassword(this.token, this.newPasswordCtrl.value)
            .then((result) => {
                this.toastr.success('Password reset was successful. You can now login using your new password', 'Success');
                
                this.router.navigate(['/login']);
            })
    }
}

