<div class='topic-editor' *ngIf="topic">
    <h2>Edit topic</h2>

    <div>
        <breadcrumb [folder]='topic' [readOnly]="true"></breadcrumb>
    </div>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class='row row-spacing'>
            <div class='col-xs-3 col-sm-2'>
                <label>Name</label>
            </div>
            <div class="col-xs-7 col-sm-5">
                <input name='name' formControlName='name' class='form-control'>
            </div>
        </div>
        <div class='row row-spacing' *ngIf="userService.canEditLibrary()">
            <div class='col-xs-3 col-sm-2'></div>
            <div class="col-xs-7 col-sm-5">
                <label for="algebrakitOnly"><input type="checkbox" name='algebrakitOnly' formControlName='algebrakitOnly' /> Hide for external authors in library</label>
            </div>
        </div>
    </form>

    <div class="attributes-container">
        <div class="row" *ngFor="let spec of attributeSpecs; let i = index">
            <div class="col-xs-10 col-sm-7 col-md-4">
                <attribute-assignment-editor [attributeAssignment]="spec.attributeAssignment" [deleted]="spec.deleted" [usedAttributes]="usedAttributes(spec.attributeAssignment?.attribute?.id)"></attribute-assignment-editor>
            </div>
            <div class="col-xs-2 col-sm-1 form-text">
                <a class="button" (click)="addAttribute(i)" title="Add an attribute">
                    <span class="fa fa-lg fa-plus"></span>
                </a>
                <a *ngIf="!spec.deleted" class="button" (click)="deleteAttribute(i)" title="Delete this attribute">
                    <span class="fa fa-lg fa-times"></span>
                </a>
                <a *ngIf="spec.deleted" class="button" (click)="restoreAttribute(i)" title="Restore this attribute">
                    <span class="fa fa-lg fa-undo"></span>
                </a>
            </div>
        </div>
    </div>
    <div *ngIf="!attributeSpecs || attributeSpecs.length === 0">
        <span>No attributes defined.</span>
        <button (click)="createAttributeAssignment()">
            <span class="fa fa-lg fa-plus"></span>
            <span>Create attribute</span>
        </button>
    </div>

    <busy-gears *ngIf="modifyChecking"></busy-gears>
    <div *ngFor="let attrId of keys(modifyCheckResult)">
        <attr-delete-check *ngIf="modifyCheckResult[attrId].referencedBy && modifyCheckResult[attrId].referencedBy.length > 0" [referencedBy]="modifyCheckResult[attrId].referencedBy"
            [id]="attrId" [name]="modifyCheckResult[attrId].attrName"></attr-delete-check>
    </div>

    <h4>Additional metadata</h4>
    <metadata-collection-editor [forItem]="topic" (afterValidate)="setMetadataValid($event)"></metadata-collection-editor>

    <div class="row">
        <div class="col-xs-3">
            <button class="btn btn-md btn-primary btn-block" type="button" title="OK" (click)="onSubmit()" [disabled]="!form.valid || !metadataValid || !canSubmit() || !attributesValid()">
                <i class="fa fa-save"></i>
                <span>OK</span>
            </button>
        </div>
        <div class="col-xs-3">
            <button class="btn btn-md btn-default btn-block" type="button" (click)="cancel()" title="Cancel">
                <i class="fa fa-ban"></i>
                <span translate>Close</span>
            </button>
        </div>

    </div>
</div>