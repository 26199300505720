<div class='audience-selector'>
    <form [formGroup]="form" class="form-inline">
        <div class='row'>
            <div class="col-sm-2 col-xs-6">
                <label>Audience Group</label>
            </div>
            <div class="col-sm-4 col-xs-6">
                <ng-select *ngIf="categories" [items]="categories" bindValue="id" bindLabel="name"
                    placeholder="enter an audience group" formControlName="category" groupBy="type"
                    (change)="categoryChanged($event)">
                    <ng-template ng-optgroup-tmp let-item="item">
                        <strong>{{item.type}}</strong>
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-sm-2 col-xs-6">
                <label>Audience</label>
            </div>
            <div class="col-sm-4 col-xs-6">
                <select name="audience" formControlName="audience" class='form-control' *ngIf="audiences">
                    <option *ngFor="let audience of audiences" value="{{audience.audienceID}}">{{audience.name || audience.audienceID}}
                    </option>
                </select>
            </div>
        </div>
    </form>
</div>