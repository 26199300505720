import { CmsAkitResourceRef } from "./navigator.types";

export interface CheckedResource {
    resource: CmsAkitResourceRef;
    anomalies: Anomaly[];
    resourceType: string;
}

export interface Anomaly {
    type: AnomalyType,
    severity: Severity,
    details: {[key: string]: any};
}

export enum AnomalyType {
    EXERCISE_AUDIENCE_EMPTY = "EXERCISE_AUDIENCE_EMPTY", //Exercise has an empty audience
    EXERCISE_FILTER_INVALID = "EXERCISE_FILTER_INVALID", //Exercise filter is invalid (unspecified reason)
    EXERCISE_FILTER_NO_LEVELS = "EXERCISE_FILTER_NO_LEVELS", //Exercise filter has no levels,
    EXERCISE_FILTER_EMPTY_LEVEL = "EXERCISE_FILTER_EMPTY_LEVEL", //Exercise filter has one or more empty levels
    EXERCISE_VERSION_MISMATCH = "EXERCISE_VERSION_MISMATCH", //Exercise's saved application versions (cms, gateway, api) do not match the versions of the current applications
    EXERCISE_REFERENCE_NO_SCRIPT = "EXERCISE_REFERENCE_NO_SCRIPT", //Exercise reference has no script specified
    EXERCISE_SPECIFICATION_NO_DEF = "EXERCISE_SPECIFICATION_NO_DEF", //Exercise specification has no definition
    EXERCISE_SCRIPT_NO_DEF = "EXERCISE_SCRIPT_NO_DEF", //Exercise script is invalid (unspecified reason)

    //Verify only
    EXERCISE_IS_DRAFT = "EXERCISE_IS_DRAFT", //Exercise is still draft
    MAJOR_UPDATE = "MAJOR_UPDATE", //Exercise has a major update when compared to the last published version
    MINOR_UPDATE = "MINOR_UPDATE", //Exercise has a minor update when compared to the last published version
    VERSION_NUMBER_ERROR = "VERSION_NUMBER_ERROR" //The version numbe has been stored incorrectly or the current versions number is lower than the published version number
}

export enum Severity {
    INFO = 0,
    WARNING_LOW_PRIO = 1,
    WARNING_HIGH_PRIO = 2,
    ERROR = 3
}

export interface SortedCheckResults {
    severity: Severity;
    checkedResources: {[id: string]: CheckedResource};
}

export function partitionBySeverity(items: CheckedResource[]): SortedCheckResults[] {
    let results: SortedCheckResults[] = [];
    for (let i = 0; i < 4; i++) {
        results[i] = {
            severity: i,
            checkedResources: {}
        };
        for (let item of items) {
            results[i].checkedResources[item.resource.id] = {
                ...item,
                anomalies: []
            };
        }
    }

    for (let item of items) {
        for (let anomaly of item.anomalies) {
            results[anomaly.severity].checkedResources[item.resource.id].anomalies.push(anomaly);
        }
    }

    for (let result of results) {
        for(let id of Object.keys(result.checkedResources)) {
            let item = result.checkedResources[id];
            if (item.anomalies.length === 0) {
                delete result.checkedResources[id];
            }
        }
    }

    return results;
}