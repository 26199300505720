import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from '../../../security/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { PasswordValidatorService } from '../../../security/services/password-validator.service';

@Component({
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css', '../../../../assets/shared.less']
})
export class ChangePasswordComponent implements OnInit {

    form: FormGroup;
    currentPasswordCtrl = new FormControl('', Validators.required);
    newPasswordCtrl = new FormControl('', Validators.required);
    repeatNewPasswordCtrl = new FormControl('', Validators.required);

    constructor(
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private location: Location,
        private router: Router,
        private userService: UserService,
        private passwordValidatorService: PasswordValidatorService
    ) {
        this.form = this.formBuilder.group({
            currentPassword: this.currentPasswordCtrl,
            passwords: this.formBuilder.group({
                newPassword: this.newPasswordCtrl,
                repeatNewPassword: this.repeatNewPasswordCtrl,
            }, { validator: this.passwordsMatchValidator.bind(this) }),
        });
    }

    passwordsMatchValidator(formgroup: FormGroup): null {
        const passwordCtrl = formgroup.controls['newPassword'];
        const repeatPasswordCtrl = formgroup.controls['repeatNewPassword'];
        const errors = this.passwordValidatorService.validate(passwordCtrl.value);
        if (errors.length) {
            passwordCtrl.setErrors({ invalidPassword: errors });
        } else {
            passwordCtrl.setErrors(null);
        }
        if (passwordCtrl.value !== repeatPasswordCtrl.value) {
            repeatPasswordCtrl.setErrors({ passwordsDontMatch: true });
        } else {
            repeatPasswordCtrl.setErrors(null);
        }
        return null;
    }

    cancel() {
        this.location.back();
    }

    onSubmit() {
        const values = { ...this.form.value };
        this.userService.updatePassword(values.currentPassword, values.passwords.newPassword)
            .then(() => {
                this.toastr.success('Password was updated successfully', 'Success');
                this.router.navigate(['/home']);
            })
            .catch((msg: any) => {
                console.log(msg);
                this.toastr.error(msg.message, 'Error');
            });
    }

    ngOnInit(): void { }
}