<div class="debug-view">
    <div class="bucket-wrapper" *ngFor="let b of visibleBuckets()">
        <div class="bucket-description">{{b.description}}</div>
        <div class="item-wrapper">
            <div class="bucket-item" *ngFor="let item of b.items">
                <div *ngIf="item.key;else no_key">
                    <div class="row" *ngIf="item.key">
                       <div class="col-xs-6" [innerHTML]="item.key | latex | async"></div>
                       <div class="col-xs-6" [innerHTML]="item.value | latex | async"></div>
                    </div>
                </div>
                <ng-template #no_key>
                    <div class="row">
                       <div class="col-xs-12">{{item.value}}</div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>