import { Component, Input } from '@angular/core';

@Component({
  selector: 'syntax-associative-example',
  templateUrl: './syntax-associative-example.component.html',
  styleUrls: ['./syntax-associative-example.component.css']
})
export class SyntaxAssociativeComponent {
    @Input() associative:any;

    ttOptions = {
        'placement': 'left',
        'show-delay': 500
    }

    constructor(
    ){  
    }
}