<div class='help-navigator'>
    <h1 class="center">Algebrakit CMS - Help Center</h1>
    <div class="row  flex-row">
        <div class='col-xs-6 col-sm-4 col-md-2 col-md-offset-1 col-lg-2 col-lg-offset-1'
            (click)="showQuestions('quickguide')">
            <helpitem-img
                [ngClass]="{'guideblock': !showComponent('quickguide'), 'guideblockSelected': showComponent('quickguide')}"
                [itemClass]="'fa-fast-forward'" [selectedClass]="showComponent('quickguide')">
                <div class="subject">Quick Guide</div>
                <div class="itemdescription">
                    Step-by-Step introduction
                </div>
            </helpitem-img>
        </div>

        <div class='col-xs-6 col-sm-4 col-md-2 col-lg-2' (click)="showQuestions('library')">
            <helpitem-img
                [ngClass]="{'helpblock': !showComponent('library'), 'helpblockSelected': showComponent('library')}"
                [itemClass]="'fa-archive'" [selectedClass]="showComponent('library')" [faq]=true>
                <div class="subject">Library</div>
                <div class="itemdescription">
                    Pre-defined exercises by Algebrakit
                </div>
            </helpitem-img>
        </div>

        <div class='col-xs-6 col-sm-4 col-md-2 col-lg-2' (click)="showQuestions('courses')">
            <helpitem-img
                [ngClass]="{'helpblock': !showComponent('courses'), 'helpblockSelected': showComponent('courses')}"
                [itemClass]="'fa-book'" [selectedClass]="showComponent('courses')" [faq]=true>
                <div class="subject">Courses</div>
                <div class="itemdescription">Managing your courses</div>
            </helpitem-img>
        </div>

        <div class='col-xs-6 col-sm-4 col-md-2 col-lg-2' (click)="showQuestions('publishing')">
            <helpitem-img
                [ngClass]="{'helpblock': !showComponent('publishing'), 'helpblockSelected': showComponent('publishing')}"
                [itemClass]="'fa-leanpub'" [selectedClass]="showComponent('publishing')" [faq]=true>
                <div class="subject">Publishing</div>
                <div class="itemdescription">Export a course to your own CMS</div>
            </helpitem-img>
        </div>

        <div class='col-xs-6 col-sm-4 col-md-2 col-lg-2' (click)="showQuestions('contact')">
            <helpitem-img
                [ngClass]="{'guideblock': !showComponent('contact'), 'guideblockSelected': showComponent('contact')}"
                [itemClass]="'fa-comments'" [selectedClass]="showComponent('contact')">
                <div class="subject">Contact</div>
                <div class="itemdescription">Contact the Algebrakit team</div>
            </helpitem-img>
        </div>
    </div>

    <div class="row flex-row">
        <div class="col-xs-6 col-sm-4 col-md-2 col-md-offset-1 col-lg-2 col-lg-offset-1">

            <div class="helpblockQ">
                <div *ngIf="showComponent('quickguide')">
                    <div class="help-menu help-menu-guide">
                        <ol>
                            <li *ngFor="let qg of quickguideJSON" helpitem-listitem [id]='qg.id' [sid]='id'
                                [title]='qg.title' (click)="showQuestion('quickguide',qg.id)">
                            </li>
                        </ol>
                    </div>
                </div>
                <div *ngIf="showComponent('library')">
                    <div class="help-menu">
                        <ul>
                            <li *ngFor="let lib of libraryJSON" helpitem-listitem [id]='lib.id' [sid]='id'
                                [title]='lib.title' (click)="showQuestion('library',lib.id)">
                            </li>
                        </ul>
                    </div>
                </div>
                <div *ngIf="showComponent('courses')">
                    <div class="help-menu">
                        <div *ngFor="let section of coursesJSON">
                            <span class="help-section-title" helpitem-listitem [title]='section.section'></span>
                            <ul>
                                <li *ngFor="let course of section.faqs" helpitem-listitem [id]='course.id' [sid]='id'
                                    [section]='section.id' [ssection]='sectionid' [title]='course.title'
                                    (click)="showSectionQuestion('courses',section.id,course.id)">
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
                <div *ngIf="showComponent('publishing')">
                    <div class="help-menu">
                        <ul>
                            <li *ngFor="let pub of publishingJSON" helpitem-listitem [id]='pub.id' [sid]='id'
                                [title]='pub.title' (click)="showQuestion('publishing',pub.id)">
                            </li>
                        </ul>
                    </div>
                </div>
                <div *ngIf="showComponent('contact')">
                    <div class="help-menu help-menu-guide">
                        <div class='contact'>
                            <ol>
                                <li [ngClass]="{'selected': showId('intro'), 'helpQ': !showId('intro'), 'helpQS': showId('intro')}"
                                    (click)="showQuestion('contact','intro')">Contact us</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xs-6 col-sm-8 col-md-8 col-lg-8">
            <div hidden="">
                <helpitem-note [image]="'fa-fast-forward'">
                    <div class="title">Note</div>
                    <div class="description">THIS IS A HIDDEN NOTE
                    </div>
                </helpitem-note>
            </div>

            <div class="helpblockA">
                <div *ngIf="showComponent('quickguide')">
                    <div class="help-expand help-expand-guide">
                        <ng-container *ngFor="let qg of quickguideJSON">
                            <div *ngIf="qg.id == id">
                                <h3 class="help-expand-title">{{qg.title}}</h3>
                                <div *ngIf="hasImage(qg.image)" class="help-expand-title-img">
                                    <img src="{{getUrlImage(qg.image)}}" />
                                </div>
                                <div [innerHTML]="getHTML(qg.content)"></div>
                                <hr>
                                <div
                                    class="navigate-buttons col-xs-6 col-sm-4 col-lg-4 col-xs-offset-3 col-sm-offset-4 col-lg-offset-4">
                                    <div class="prev-button"><a *ngIf="previousId(id)"
                                            [routerLink]="'/help/quickguide/'+previousId(id)"><i
                                                class="fa fa-chevron-circle-left"></i></a></div>
                                    <div class="next-button"><a *ngIf="nextId(id)"
                                            [routerLink]="'/help/quickguide/'+nextId(id)"><i
                                                class="fa fa-chevron-circle-right"></i></a></div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div *ngIf="showComponent('library')">
                    <div class="help-expand">
                        <ng-container *ngFor="let lib of libraryJSON">
                            <div *ngIf="lib.id == id">
                                <h3 class="help-expand-title">{{lib.title}}</h3>
                                <div [innerHTML]="getHTML(lib.content)"></div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div *ngIf="showComponent('courses')">
                    <div class="help-expand">
                        <div *ngFor="let section of coursesJSON">
                            <div *ngIf="section.id == sectionid">
                                <ng-container *ngFor="let course of section.faqs">
                                    <div *ngIf="course.id == id">
                                        <h3 class="help-expand-title">{{course.title}}</h3>
                                        <div [innerHTML]="getHTML(course.content)"></div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="showComponent('publishing')">
                    <div class="help-expand">
                        <ng-container *ngFor="let pub of publishingJSON">
                            <div *ngIf="pub.id == id">
                                <h3 class="help-expand-title">{{pub.title}}</h3>
                                <div [innerHTML]="getHTML(pub.content)"></div>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div *ngIf="showComponent('contact')">
                    <div class="help-expand help-expand-guide">
                        <div *ngIf="showId('intro')">
                            <h3 class="help-expand-title">Contact us</h3>
                            <div class="help-expand-title-img">
                                <img src="/19441f283440a3ad98046f71f8b79943.png" />
                            </div>
                            <helpitem-contactform></helpitem-contactform>
                        </div>
                    </div>
                </div>
                <div *ngIf="showComponent('other')">
                    <div class="help-expand">
                        <div>
                            <p>Please select one of the topics above.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>


</div>