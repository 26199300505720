import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {CmsFolder, CmsAkitResourceRef, CmsDiffAnomaly, CmsDiffSeverityLevel, CmsDiffAnomalyType, ReferencedBy} from '../../types/navigator.types';
import { Observable } from 'rxjs';

/**
 * A topic-leaf is a 'file' in the navigation system, which corresponds to an
 * exercise. The model is defined by class of type CmsLeaf. Classes for the various 
 * types of exercises are derived from CmsLeaf
 */

@Component({
    selector: 'reference-list',
    templateUrl: './reference-list.component.html'
})
export class ReferenceListComponent {

    @Input() references: ReferencedBy[];
    @Input() urlPrefix: string;

    getUrl(ref: ReferencedBy) {
        if (!this.urlPrefix.endsWith('/')) {
            this.urlPrefix += '/';
        }
        return this.urlPrefix+ref.item.id
    }
}



