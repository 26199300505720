import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { TopicService } from '../../services/topic.service';

import { CmsTopicAttribute, CmsTopicAttributeAssignment, Difficulty } from '../../types/navigator.types';
import { TopicAttributeService } from '../../services/topic-attribute.service.';
import { Sorting } from '../../util/sorting';


@Component({
    selector: 'attribute-assignment-editor',
    templateUrl: './attribute-assignment-editor.component.html',
    styleUrls: ['./attribute-assignment-editor.component.css']
})
export class AttributeEditorComponent implements OnInit {
    _attributeAssignment: CmsTopicAttributeAssignment;
    _usedAttributes: string[];

    form: FormGroup;
    attributeCtrl = new FormControl('', Validators.required);
    complexityCtrl = new FormControl('', Validators.required);

    @Input() set attributeAssignment(value: CmsTopicAttributeAssignment) {
        this._attributeAssignment = value;
        this.makeAttributeMap();
        this._attributeAssignment.attribute = value.attribute || this.attributeMap['-1'];
        this._attributeAssignment.difficulty = value.difficulty || this.difficulties()[0];
    }

    @Input() deleted: boolean;

    @Input() set usedAttributes(value: string[]) {
        this._usedAttributes = value;
        this.makeAttributeMap();
    };

    attributeMap: { [id: string]: CmsTopicAttribute };
    difficultyMap: { [id: string]: Difficulty };

    attributes() {
        if (!this.attributeMap) {
            this.makeAttributeMap();
        }
        let attrArray: CmsTopicAttribute[] = Object.keys(this.attributeMap)
            .map(id => this.attributeMap[id]);
        return attrArray;
    }

    difficulties() {
        if (!this.difficultyMap) {
            this.makeDifficultyMap();
        }
        let diffArray: Difficulty[] = Object.keys(this.difficultyMap)
            .map(id => this.difficultyMap[id]);
        return diffArray;
    }

    constructor(
        private formBuilder: FormBuilder,
        private topicService: TopicService,
        private attributeService: TopicAttributeService
    ) {
        this.form = this.formBuilder.group({
            attribute: this.attributeCtrl,
            complexity: this.complexityCtrl
        })
    }

    getColorForGoalAttribute(attribute: CmsTopicAttributeAssignment): { [key: string]: string } {
        return this.topicService.getAttributeColorBg(attribute.difficulty);
    };

    setCtrlValues() {
        if (this._attributeAssignment) {
            this.attributeCtrl.setValue(this._attributeAssignment.attribute ?
                this._attributeAssignment.attribute.id
                : '-1');
            this.complexityCtrl.setValue(this._attributeAssignment.difficulty.id);
        }
    }

    ngOnChanges() {
        this.setCtrlValues();
    }

    makeAttributeMap() {
        this.attributeMap = {};
        if (!this._attributeAssignment.attribute || this._attributeAssignment.attribute.id == '-1') {
            this.attributeMap['-1'] = {
                id: '-1',
                name: 'select an attribute...'
            }
        }
        this.attributeService.allAttributes
            .filter(attr => !this._usedAttributes || this._usedAttributes.indexOf(attr.id) === -1)
            .map(attr => {
                this.attributeMap[attr.id] = attr;
            })
    }

    makeDifficultyMap() {
        this.difficultyMap = {};
        this.attributeService.difficulties.map(diff => {
            this.difficultyMap[diff.id] = diff;
        })
    }

    getSelectClass() {
        return this.attributeCtrl.value == '-1'
            ? 'input-invalid'
            : '';
    }

    ngOnInit() {

        this.setCtrlValues();

        this.attributeCtrl.valueChanges.subscribe(value => {
            if (this._attributeAssignment) {
                this._attributeAssignment.attribute = this.attributeMap[value];
            }
        });
        this.complexityCtrl.valueChanges.subscribe(value => {
            if (this._attributeAssignment) {
                this._attributeAssignment.difficulty = this.difficultyMap[value];
            }
        });
    }
}




