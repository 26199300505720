import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';



import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { MetadataService } from '../../../algebrakit/services/metadata.service';
import { AppProfileService } from '../../../app/services/app-profile.service';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';

const DEFAULT_REDIRECT = '/home';

@Component({
    templateUrl: './user-login.component.html',
    styleUrls: ['./user-login.component.css']
})
export class UserLoginComponent implements OnInit {

    redirect: string; //navigate to this route after succes
    usernameCtrl = new FormControl('');
    passwordCtrl = new FormControl('');

    form: FormGroup;
    

    mgmtConsoleUrl: string;

    constructor(
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private metadataService: MetadataService,
        private appProfileService: AppProfileService,
        private formBuilder: FormBuilder,
    ) { 
        this.form = this.formBuilder.group({
            username: this.usernameCtrl,
            password: this.passwordCtrl
        });
    }


    login(): void {
        if (this.usernameCtrl.value == "" || this.passwordCtrl.value == "") {
            this.toastr.error("Please enter your email address and password.");
            return;
        }

        if (Validators.email(this.usernameCtrl)) {
            this.toastr.error("Please enter a valid email address.");
            return;
        }

        let email = this.usernameCtrl.value;
        let password = this.passwordCtrl.value;
        this.userService.login(email, password)
            .then(() => {
                this.metadataService.initAudiences();
                if (this.redirect) {
                    this.router.navigate([this.redirect]);
                }
            })
            .catch((error) => {
                if (error.status === 401) {
                    alert('Email address and/or password incorrect');
                }
                else {
                    this.toastr.error("An error occurred while trying to log you in. Please try again later", "Error");
                }
            });

    }

    getForgotPasswordUrl() {
        return `/user/forgot-password`;
    }

    ngOnInit() {
        this.appProfileService.getManagementConsoleUrl().then((value) => {
            this.mgmtConsoleUrl = value;
        });
        this.userService.checkSession()
            .then(user => {
                if (user !== null) {
                    this.router.navigate([DEFAULT_REDIRECT]);
                }
            }).catch(error => {
                if (error.status !== 401) {
                    console.log("Not logged in");
                }
            });
        this.route.queryParams
            .subscribe((params: Params) => {
                this.redirect = params['redirect'] ? params['redirect'] : DEFAULT_REDIRECT;
            });
    }
}

