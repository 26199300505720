import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * Class to define img HELP item selector in overview help page for authoring environment
 */

    @Component({
  selector: 'helpitem-note',
  templateUrl: './helpitem-note.component.html',
  styleUrls: ['./helpitem-note.component.css']
}) 
export class HelpItemNoteComponent {
    @Input() image: string;
}

