<div class="welcome-container">

    <div *ngIf="isTest">
        <div class="attention">
            <h1>Attention</h1>
            <p>This is not a production environment. Any changes you make will not be visible from the production
                version of Algebrakit.</p>
        </div>
    </div>

    <div class="actions-container">
        <h1 class="">Welcome! </h1>
        <h3 class="actions-container__sub-title"> What do you want to do?</h3>
        <div class="row">
            <div class="col-md-4 col-xs-12">

                <div class="list-group">
                    <a routerLink="/courses" class="list-group-item">
                        <i class="fa fa-book"></i>
                        Go to my Courses
                    </a>
                    <a routerLink="/topics" class="list-group-item">
                        <i class="fa fa-archive"></i>
                        Go to the Library
                    </a>
                    <div class="list-group-item">
                        Find a specific Exercise ID
                        <i class="fa fa-search"></i>

                        <form [formGroup]="form">
                            <div class="action">
                                <div class="search-bar">
                                    <input name='exerciseID' formControlName='exerciseID' placeholder="Exercise ID"
                                        class='form-control'>
                                    <button class="btn btn-primary" (click)="search()">Find</button>
                                </div>
                            </div>

                        </form>
                    </div>

                </div>


            </div>
            <div class="col-md-8"></div>

        </div>

    </div>

    <div *ngIf="showAdminComp()">
        <div class="whatsnew">
        </div>
    </div>

</div>
<div class="footer">
    <p>Please send any questions or remarks to <a href='mailto:info@algebrakit.nl'>info@algebrakit.nl</a> | <a
            href="http://public.algebrakit.nl/privacyverklaring-algebrakit-v1.0" target="_blank">Privacy Policy</a>.</p>
</div>