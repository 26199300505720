<div class="attribute-selector" *ngIf="attributes && _selectedAttributes">
    <div *ngIf="attributes && attributes.length>0">
        <span *ngFor="let attr of attributes" (click)="select(attr)" class="attr-item clickable" [ngStyle]='getAttributeColor(attr)' [ngClass]="{'selected':isSelected(attr)}">
            {{attr.attribute.name}}
        </span>
        <span *ngFor="let attr of _unknownAttributes" class="attr-item-unknown clickable">
            <span class="attr-name">{{attr.attribute.name}}</span>
            <span class="fa fa-times" (click)="removeAttribute(attr.id)"></span>
        </span>
    </div>
    <span *ngIf="attributes==null || attributes.length===0" class="no-attributes">No attributes available</span>
    
</div>
