<div>
    <div class="panel-wrapper">
        <h3 class="panel-title">Manual selection</h3>
        <br />
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link" [href]="'#favourites-'+level" data-toggle="tab">Selected Topics</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [href]="'#library-'+level" data-toggle="tab">Library</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [href]="'#course-'+level" data-toggle="tab">Course</a>
            </li>
        </ul>

        <ng-template #showDeprecatedBox>
            <div class="row show-deprecated-box" *ngIf="canEditLibrary()">
                <div class="col-xs-12">
                    <label class="font-weight-normal"><input [(ngModel)]="showDeprecated"
                        type="checkbox"> Show deprecated
                    </label>
                </div>
            </div>
        </ng-template>


        <div class="tab-content clearfix row-spacing">
            <div class="tab-pane" [id]="'favourites-'+level">
                <ng-container *ngIf="!_favouriteTopics || _favouriteTopics.length == 0 else showDeprecatedBox">
                    <div>
                        <br />
                        No topics selected. <button (click)="selectTopics.emit()" class="btn btn-default">Select
                            topics</button>
                    </div>
                </ng-container>
                <div *ngFor="let topicData of _favouriteTopics">
                    <div class="row topic-row">
                        <div class='col-sm-7 col-xs-12'>
                            <h5>{{topicData.topic?.name}}</h5>
                        </div>
                        <div class='col-sm-5 col-xs-12'>
                            <div class="goal-attr-label-container display-sm"
                                *ngIf="topicData?.topic?.attributeAssignments && topicData.topic.attributeAssignments.length > 0">
                                <div *ngFor='let attr of topicData.topic.attributeAssignments'
                                    class='goal-attr-label-wrapper'>
                                    <div class='goal-attr-label' [ngStyle]='getColorForTopicAttribute(attr)'
                                        [title]="attr.attribute.name">{{attr.attribute.name
                                        | truncate:[15]}}</div>
                                </div>
                                <div class='goal-attr-label-bar'></div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!topicData.exercisesAKIT || topicData.exercisesAKIT.length == 0">
                        This topic does not contain any exercises
                    </div>
                    <ng-container *ngFor="let script of topicData.exercisesAKIT; let i = index">
                        <div class="row exercise-list-row" *ngIf="showScript(script)">
                            <div class='col-sm-7 col-xs-12 exercise-list-entry'>
                                <input *ngIf="!isVersionMismatch(script)" type="checkbox" (change)="selectedTopicExerciseChanged(script, $event)" />
                                <span (click)="topicScriptClicked(script)" [ngClass]="{ 'incompatible': isVersionMismatch(script), 'clickable': !isVersionMismatch(script), 'deprecated': script.state == 'DEPRECATED' }">
                                    {{script.name}} <i *ngIf="isVersionMismatch(script)" [tooltip]="getVersionMismatchError(script)" class="error-badge fa fa-exclamation-circle"></i>
                                </span> 
                                <span class="label label-danger" title="Deprecated" *ngIf="script.state == 'DEPRECATED'">
                                    <i class="fa fa-minus-circle"></i>
                                    <span>Deprecated</span>
                                </span>
                            </div>
                            <div class="col-sm-5 label-container-sm display-sm">
                                <div *ngFor='let attr of topicData?.topic?.attributeAssignments' class='label-wrapper'>
                                    <div class='attr-label' [ngStyle]="getColorForScriptAttribute(attr, script)">&nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="tab-pane" [id]="'library-'+level">
                <ng-container *ngTemplateOutlet="showDeprecatedBox"></ng-container>
                <topic-navigator [incompatibleExercisesSelectable]="false" [browseMode]="'VIEW'" [showDeprecated]="showDeprecated" [useAsComponent]="true"
                    (selectedChanged)="selectedLibraryExerciseChanged($event)" [interactionBlacklist]="interactionBlacklist"></topic-navigator>
            </div>

            <div class="tab-pane" [id]="'course-'+level">
                <course-navigator [incompatibleExercisesSelectable]="false" [browseMode]="'VIEW'" [useAsComponent]="true"
                    (selectedChanged)="selectedCourseExerciseChanged($event)" [courseRef]="courseRef" [forFilterSpecs]="true"></course-navigator>
            </div>
        </div>
    </div>
    <br />
    <div class="exercise-filter-spec-controls">
        <button class="btn btn-md btn-default" type="button" (click)="cancel.emit()">
            <i class="fa fa-ban"></i>
            <span>Cancel</span>
        </button>
        <button *ngIf="exercisesAreSelected()" class="btn btn-md btn-default" type="button" (click)="addSelection()">
            <i class="fa fa-plus"></i>
            <span>Add Exercises</span>
        </button>
    </div>
</div>