import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CmsLeaf, CheckedEvent, CmsItemState } from '../../types/navigator.types';
import { UserService } from '../../../security/services/user.service';
import { share } from 'rxjs-compat/operator/share';
import { ValidationReport, ValidationStatus, ValidationUserReportEntry } from '../../types/ValidationReport';

/**
 * A leaf is a 'file' in a navigation system, where parents are folders.
 */
@Component({
    selector: 'leaf',
    templateUrl: './leaf.component.html',
    styleUrls: ['./leaf.component.less', '../../../../assets/shared.less']
})
export class LeafComponent implements OnInit {

    @Input() leaf: CmsLeaf
    @Input() selectable: boolean = false;
    @Input() selected: boolean = false;
    @Input() browsingPublished = false;
    @Output() onChecked: EventEmitter<CheckedEvent<CmsLeaf>> = new EventEmitter<CheckedEvent<CmsLeaf>>();
    @Input() readOnly = false;

    @Input() errorBadgeTitle: string;
    @Input() enabled = true;

    @Input() isShowValidationInfo: boolean = false;

    validationReport: ValidationReport;

    get isPublished() {
        if (!this.leaf.latestVersionNumbers) {
            return false;
        }
        return this.leaf.latestVersionNumbers.publishedMajorVersion && this.leaf.latestVersionNumbers.publishedMinorVersion != null
    }

    get itemValidationStatus(): ValidationStatus {
        return this.validationReport ? this.validationReport.itemValidationStatus : null;
    }

    get validationReportForUser(): ValidationUserReportEntry[] {
        return this.validationReport ? this.validationReport.getValidationReportForUser('short') : [];
    }

    constructor(private userService: UserService) { }

    ngOnInit() {
        if (this.leaf.validationReport) {
            this.validationReport = new ValidationReport(this.leaf.validationReport);
        }
    }

    checked(e: any) {
        let value: boolean = e.target.checked;
        this.onChecked.emit({ item: this.leaf, checked: value });
    }

    onLeafClick(): void {
        if (this.enabled && this.leaf.onClick) this.leaf.onClick(this.leaf);
    }

    getActions() {
        if (this.readOnly) {
            return [];
        }
        let canEdit: boolean = this.userService.getUser() && this.userService.getUser().canEditLibrary();
        return canEdit
            ? this.leaf.actions
            : this.leaf.actions.filter(a => !a.adminOnly);
    }

    getState() {
        return this.leaf.state == CmsItemState.NOT_APPLICABLE ? "N/A" : this.leaf.state;
    }

    getTested() {
        return this.leaf.state == CmsItemState.NOT_APPLICABLE ? "N/A" : this.leaf.tested;
    }

    getApprovedVersionNumbers(leaf: CmsLeaf) {
        if (leaf.latestVersionNumbers.majorVersion === 0 && leaf.latestVersionNumbers.minorVersion === 0) {
            return '';
        }
        return `${leaf.latestVersionNumbers.majorVersion}.${leaf.latestVersionNumbers.minorVersion}`;
    }

    toggleValidationInfo() {
        this.isShowValidationInfo = !this.isShowValidationInfo;
    }

    closeValidationInfo() {
        this.isShowValidationInfo = false;
    }


}
