<div>
    <div class='row'>
        <div class="col-xs-12">
            <h3>Version History</h3>
        </div>
    </div>

    <div class="list-group">
        <a style="cursor: pointer" (click)="setSelectedVersion(obj)" *ngFor="let obj of objects" class="list-group-item">
            {{obj.majorVersion}}.{{obj.minorVersion}} - {{obj.created}}
        </a>
    </div>

    <version-info-panel *ngIf="currentVersion" [state]="'APPROVED'" [versionInfo]="currentVersion" [collapseable]="false"></version-info-panel>

    <div class="panel panel-default" *ngIf="data">
        <div class="panel-heading">
            <h3 class="panel-title">
                <span>Data</span>
                <button title="copy to clipboard as JSON" ngxClipboard [cbContent]="getJSON()">
                    <span class='fa fa-clipboard'></span>
                </button>
            </h3>
        </div>
        <div class="panel-body">
            <ngx-json-viewer [json]="data"></ngx-json-viewer>
        </div>
    </div>

</div>