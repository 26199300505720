<div id="wrap">
    <div id="main" [ngClass]="{ 'has-version-mismatch': versionMismatch }">
        <navigation-bar></navigation-bar>
        <akit-error></akit-error>
        <div class="container">
            <div id='main-content' *ngIf="!busy">
                <router-outlet></router-outlet>
            </div>
            <div id='app-busy' *ngIf="busy">
                Logging out...
            </div>
        </div>
    </div>
</div>