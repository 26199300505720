import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, enableProdMode } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { getDefaultToastOption } from './options/options';
import { RouteReuseStrategy } from '@angular/router';

import { SecurityModule } from '../security/security.module';
import { AuthoringModule } from '../authoring/authoring.module';
import { AlgebraKITModule } from '../algebrakit/algebrakit.module';

import { AppRoutingModule } from './app.routing';
import { AppComponent } from './containers/app.component';
import { AppHomeComponent } from './components/app-home/app-home.component';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AuthUserGuard } from './services/auth-user-guard.service';
import { CustomReuseStrategy } from './services/reuse-route.service';


import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { AppProfileService } from './services/app-profile.service';
import { ConcurrencyService } from './services/concurrency.service';
import { ContentVersionService } from './services/content-version-service';
import { TooltipModule } from './components/common/ui/tooltip/tooltip.module';
import { TooltipComponent } from './components/common/ui/tooltip/tooltip.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

enableProdMode();

@NgModule({
  imports: [CommonModule,AlgebraKITModule, AuthoringModule, BrowserModule, FormsModule, SecurityModule, AppRoutingModule,
    ReactiveFormsModule, BrowserAnimationsModule, ToastrModule.forRoot(getDefaultToastOption()), TooltipModule],
  declarations: [AppComponent, AppHomeComponent, NavigationBarComponent, PageNotFoundComponent, UserProfileComponent, ChangePasswordComponent, TooltipComponent,ForgotPasswordComponent,ResetPasswordComponent],
  bootstrap: [AppComponent],
  providers: [AuthUserGuard, { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    AppProfileService, ConcurrencyService, ContentVersionService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] //required to allow custom tags, such as <akit-exercise>
})
export class AppModule { }


