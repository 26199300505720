<div class='cms-breadcrumb subject-navigation' [ngClass]="readOnly ? 'read-only' : null">


    <div *ngIf="_folder">
        <!-- shown if NOT activated -->
        <div class="path-selector" [hidden]='activated==="true" || activated==="force"' (click)='activate()'>
            <span *ngFor="let parent of _folder.parents" (click)='setParentFromRef(parent.id)'>
                <span class="breadcrumb-element" *ngIf="!folderIsRoot(parent)">{{parent.name}}</span>
                <ng-container *ngIf="folderIsRoot(parent)" class="display-flex-center">
                    <ng-container *ngTemplateOutlet=" "></ng-container>
                </ng-container>
                <span> / </span>
            </span>
            <span class="breadcrumb-element" *ngIf="_folder && !folderIsRoot(_folder)">{{_folder.name}}</span>
            <ng-container *ngIf="folderIsRoot(_folder)">
                <ng-container *ngTemplateOutlet="rootElement">
                </ng-container>
            </ng-container>
            <span *ngIf="leaf"> / </span>
            <span class="breadcrumb-element" *ngIf="leaf"><strong>{{leaf.name}}</strong></span>
        </div>

        <ng-template #rootElement>
            <a *ngIf="homeLinkHref" class="breadcrumb-element" [routerLink]="homeLinkHref">
                <span class="fa fa-lg fa-home"></span>
            </a>
            <span *ngIf="!homeLinkHref" class="breadcrumb-element fa fa-lg fa-home"></span>
            <span *ngIf="courseName || (courseName && courseName.length > 0)"
                class="breadcrumb-element"><strong>{{courseName}}</strong></span>
        </ng-template>


        <!-- show if ACTIVATED -->
        <div class="path-selector active" [ngClass]="invalid ? ' invalid' : null"
            [hidden]='!activated || activated==="false"'>
            <ng-container *ngIf="confirmAndClose">
                <button class="btn btn-primary close-button" *ngIf='activated!=="force"' (click)="close()"><span
                        class='fa fa-check'></span> Confirm</button>
                <button class="btn btn-default close-button" *ngIf='activated!=="force"' (click)="onCancel()"><span
                        class='fa fa-times'></span> Cancel</button>
            </ng-container>
            <ng-container *ngIf="!confirmAndClose">
                <a class="close-button" *ngIf='activated!=="force"' (click)="close()"><span
                        class='fa fa-times'></span></a>
            </ng-container>
            <span *ngFor="let parent of _folder.parents" (click)='setParentFromRef(parent.id)'>
                <span class="breadcrumb-element" *ngIf="!folderIsRoot(parent)">{{parent.name}}</span>
                <ng-container *ngIf="folderIsRoot(parent)">
                    <ng-container *ngTemplateOutlet="rootElement">
                    </ng-container>
                </ng-container>
                <span> / </span>
            </span>
            <span class="breadcrumb-element" *ngIf="_folder && !folderIsRoot(_folder)">{{_folder.name}}</span>
            <ng-container *ngIf="folderIsRoot(_folder)">
                <ng-container *ngTemplateOutlet="rootElement">
                </ng-container>
            </ng-container>
        </div>

        <!-- show CHILDS, only if ACTIVATED -->
        <div class="child-selector" *ngIf="showChilds && childs" [hidden]='!activated || activated==="false"'>
            <div class='child-wrapper' *ngIf='!hideTrashLeafs && folderIsRoot(_folder)'>
                <div class='child' (click)='setParentFromRef("trash")'>
                    <span class='fa fa-trash'></span>
                    <span>trash</span>
                </div>
            </div>
            <div class='child-wrapper' *ngIf='_folder.trash'>
                <div class='child' (click)='setParentFromRef("root")'>
                    <span class='fa fa-home'></span>
                    <span>home</span>
                </div>
            </div>
            <div class='child-wrapper' *ngFor='let child of childs'>
                <div class='child' [ngClass]="{'incompatible': isVersionMismatch(child)}"
                    (click)='setParentFromRef(child)'>
                    <span *ngIf="child.containerType == 'FOLDER'" class='fa fa-folder'></span>
                    <span *ngIf="child.containerType == 'FILE'" class='fa fa-list'></span>
                    <span class="child-name">{{child.name}}</span>
                </div>
            </div>
        </div>
    </div>

</div>