<div class='topic-leaf row cms-navigator-item' *ngIf="folder">
    <div class='col-xs-10 display-flex-center'>
        <div class="dropdown">
            <ng-container *ngIf="!readOnly">
                <button [ngClass]="'dropdown-toggle' + (getActions()?.length == 0 ? ' no-actions' : '')" type="button"
                    id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <span [ngClass]="folder.iconClass"></span> &nbsp;<i class="fa fa-chevron-down"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenu2"
                    *ngIf="folder.actions && getActions().length > 0">
                    <li *ngFor="let action of getActions()" (click)="action.callback(folder)"
                        class='dropdown-entry clickable'><a>
                            <span [ngClass]="action.iconClass"></span>
                            <span class='dropdown-entry-label'>{{action.name}}</span>
                        </a></li>
                </ul>
            </ng-container>
            <div *ngIf="readOnly" class="icon-container">
                <span [ngClass]="folder.iconClass"></span>
            </div>
        </div>
        &nbsp;
        <span class="clickable" (click)="onFolderClick()" [innerHTML]="' ' + getFolderName()"></span>
    </div>
    <div class="col-xs-1">

    </div>
</div>