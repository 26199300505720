import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * Class to define help tooltip selector to use in editor to reference to help center.
 */

@Component({
  selector: 'help-tooltip',
  templateUrl: './help-tooltip.component.html',
  styleUrls: ['./help-tooltip.component.css']
}) 
export class HelpToolTipComponent {
    @Input() ttip: string;
    @Input() rlink: string;

    ttOptions = {
        'placement': 'top',
        'show-delay': 500
    }

}

