<form [formGroup]="form">
    <div class="row" *ngIf="baseAudiences && courseSpecificAudiences">
        <div class="col-xs-3">
            <input type="text" name="name" formControlName="name" class="form-control" title="name" placeholder="name"
                [(ngModel)]="audience.name" />
        </div>
        <ng-container>
            <div class="col-xs-3">
                <ng-select [items]="baseAudiences" bindValue="audienceID" bindLabel="audienceID" (change)="changed()"
                    placeholder="enter base audience" formControlName="baseAudienceId" groupBy="country"
                    [(ngModel)]="audience.baseAudienceId">
                    <ng-template ng-optgroup-tmp let-item="item">
                        <strong>{{item.country}}</strong>
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-xs-3">
                <ng-select [items]="courseSpecificAudiences" bindValue="audienceID" bindLabel="audienceID"
                    (change)="changed()" placeholder="enter audience to resolve to" formControlName="resolvedAudience"
                    groupBy="country" [(ngModel)]="audience.resolvedAudience">
                    <ng-template ng-optgroup-tmp let-item="item">
                        <strong>{{item.country}}</strong>
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-xs-3">
                <ng-select [items]="editors" bindValue="id" bindLabel="name" (change)="changed()"
                    placeholder="editor for this audience" formControlName="editor" [(ngModel)]="audience.editor">
                </ng-select>
            </div>
        </ng-container>
    </div>
</form>