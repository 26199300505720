import { Component, Input, SimpleChanges } from '@angular/core';
import { PasswordValidatorService } from '../../services/password-validator.service';

@Component({
  selector: 'password-errors',
  templateUrl: './password-errors.component.html',
  styleUrls: ['./password-errors.component.less', '../../../../assets/shared.less']
})
export class PasswordErrorsComponent {

  @Input() userPassword: string;

  userPasswordErrors: string[] = [];

  constructor(private passwordValidatorService: PasswordValidatorService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['userPassword']) {
      this.detectUserPasswordErrors(changes['userPassword'].currentValue);
    }
  }

  detectUserPasswordErrors(value: string) {
    this.userPasswordErrors = this.passwordValidatorService.validate(value);
  }
}
