import { Component, OnInit, ViewContainerRef, ViewChild, HostListener, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { UUID } from 'angular2-uuid';

import { CmsFolder, CmsTopic, CmsExerciseScript, CmsItem, CmsItemAction, CmsTopicAttribute, CmsAkitResourceRef, NameIdPair, CmsContainerType, CheckedEvent, CmsItemState, ReferencedBy, SortBy, CmsTopicAttributeAssignment, LibrarySearchResult, IdSearchResult, IdSearchResultList, CmsExerciseFilter, CmsExerciseReference, CmsExerciseClientSpec, IdSearchResultType, isExerciseFilter, isExerciseReference, isExerciseClientSpec, CmsExercise } from '../../types/navigator.types';
import { TopicService } from '../../services/topic.service';
import { ExerciseScriptService } from '../../services/exercise-script.service';
import { UserService } from '../../../security/services/user.service';
import { PlatformLocation } from '@angular/common';
import { ExerciseScriptPreviewComponent } from '../../components/exercise-script-preview/exercise-script-preview.component';
import { compareVersion, Sorting } from '../../util/sorting';
import { TypeHelpers } from '../../util/type-helpers';
import { SearchService } from '../../services/search.service';
import { SearchFieldComponent } from '../../components/search-field/search-field.component';
import { AppProfileService } from '../../../app/services/app-profile.service';
import { ContentVersionService } from '../../../app/services/content-version-service';

declare let $: any;

@Component({
    selector: 'id-search',
    templateUrl: './id-search.component.html',
    styleUrls: ['./id-search.component.css', '../../../../assets/shared.less']
})
export class IdSearchComponent implements OnInit {

    busy: boolean;
    searchResults: IdSearchResult[]
    lastSearchToken: string;
    searchString: string;
    exportScripts: CmsExerciseScript[];
    referenceExporterVisible = false;
    specExporterVisible = false;

    publishedMap: { [id: string]: boolean } = {};

    currentVersion: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
        private toastr: ToastrService,
        private searchService: SearchService,
        private contentVersionService: ContentVersionService
    ) {

    }

    ngOnInit(): void {
        this.contentVersionService.getContentVersion().then(contentVersion => this.currentVersion = contentVersion);
        this.route.params
            .subscribe((params) => {
                if (params.ids) {
                    this.search(params.ids);
                }
            });
    }

    getVersionMismatchError(exercise: CmsExercise | CmsExerciseScript) {
        return this.isVersionMismatch(exercise)
            ? "This exercise was edited in a newer version and cannot be opened"
            : null;
    }

    isVersionMismatch(exercise: CmsExercise | CmsExerciseScript) {
        return compareVersion(this.currentVersion, exercise.contentVersion) < 0
    }

    onLeafClick(item: IdSearchResult) {
        let exercise = item.item;
        let id = exercise.id;
        if (item.type === IdSearchResultType.COURSE) {
            if (isExerciseFilter(exercise)) {
                this.editExerciseArrangement(id);
            }
            else if (isExerciseReference(exercise)) {
                this.editExerciseReference(id);
            }
            else if (isExerciseClientSpec(exercise)) {
                this.editExerciseClientSpec(id);
            }
        }
        else if (item.type === IdSearchResultType.LIBRARY) {
            this.editExerciseScript(id);
        }
    }

    onSearchTextChanged(searchString: string) {
        this.router.navigate(['/id-search', searchString]);
    }

    search(searchString: string) {
        this.exporterClose();
        if (!searchString || searchString.length === 0) {
            this.busy = false;
            this.searchResults = [];
            return;
        }
        this.busy = true;
        this.lastSearchToken = UUID.UUID();
        this.searchString = searchString;
        let ids = searchString
            .split(new RegExp('[,;]+'))
            .filter(s => !!s && s.length > 0)
            .map(s => s.trim());
        this.searchService.idSearch(ids, this.lastSearchToken).toPromise()
            .then((result: IdSearchResultList) => {
                if (result.token !== this.lastSearchToken) {
                    return;
                }

                this.publishedMap = {};
                for (let resultItem of result.results) {
                    this.publishedMap[resultItem.item.id] = resultItem.published;
                }

                this.searchResults = result.results;

                this.busy = false;
            });
    }

    editExerciseArrangement(id: string) {
        this.router.navigate(['edit/exercise-filter/' + id], { queryParams: { onCloseUrl: '_BACK' } });
    }

    editExerciseReference(id: string) {
        this.router.navigate(['edit/exercise-reference/' + id], { queryParams: { onCloseUrl: '_BACK' } });
    }

    editExerciseClientSpec(id: string) {
        this.router.navigate(['edit/exercise-spec-client/' + id], { queryParams: { onCloseUrl: '_BACK' } });
    }

    editExerciseScript(id: string): void {
        this.router.navigate(['edit/exercise/' + id], { queryParams: { onCloseUrl: '_BACK' } });
    }

    canEditTopicsScripts(): boolean {
        return this.userService.getUser() && this.userService.getUser().canEditLibrary();
    }

    checkIfExportIsAllowed(exerciseScript: CmsExerciseScript) {
        let allowed = this.publishedMap[exerciseScript.id];
        return !!allowed;
    }

    exporterClose() {
        this.referenceExporterVisible = false;
        this.specExporterVisible = false;
    }

    createReferences(exerciseScript: CmsExerciseScript) {
        this.exporterClose();
        if (!this.checkIfExportIsAllowed(exerciseScript)) {
            this.toastr.warning("You can only export exercises that are published", "Warning");
            return;
        }
        this.exportScripts = [exerciseScript];
        this.referenceExporterVisible = true;
        window.scrollTo(0, 0)
    }

    createClientSpecs(exerciseScript: CmsExerciseScript) {
        this.exporterClose();
        if (!this.checkIfExportIsAllowed(exerciseScript)) {
            this.toastr.warning("You can only export exercises that are published", "Warning");
            return;
        }
        this.exportScripts = [exerciseScript];
        this.specExporterVisible = true;
        window.scrollTo(0, 0)
    }

    getActions(item: IdSearchResult) {
        let actions: CmsItemAction[];
        let canEdit: boolean = this.userService.getUser() && this.userService.getUser().canEditLibrary();
        if (item.type === IdSearchResultType.COURSE) {
            let exercise = item.item;
            if (isExerciseFilter(exercise)) {
                actions = this.courseActions.FILTER;
            }
            else if (isExerciseReference(exercise)) {
                actions = this.courseActions.REFERENCE;
            }
            else if (isExerciseClientSpec(exercise)) {
                actions = this.courseActions.SPECIFICATION;
            }
        }
        else if (item.type === IdSearchResultType.LIBRARY) {
            actions = this.libraryActions;
        }
        return canEdit
            ? actions
            : actions.filter(a => !a.adminOnly);
    }

    courseActions = {
        'FILTER': [
            {
                name: 'edit',
                iconClass: 'fa fa-lg fa-pencil-square',
                callback: ((leaf: CmsExerciseFilter) => this.editExerciseArrangement(leaf.id))
            }],
        'REFERENCE': [
            {
                name: 'edit',
                iconClass: 'fa fa-lg fa-pencil-square',
                callback: ((leaf: CmsExerciseReference) => this.editExerciseReference(leaf.id))
            }],
        'SPECIFICATION': [
            {
                name: 'edit',
                iconClass: 'fa fa-lg fa-pencil-square',
                callback: ((leaf: CmsExerciseClientSpec) => this.editExerciseClientSpec(leaf.id))
            }]
    }

    libraryActions = [
        {
            name: (this.canEditTopicsScripts() ? 'edit' : 'view'),
            iconClass: (this.canEditTopicsScripts() ? 'fa fa-lg fa-pencil-square' : 'fa fa-lg fa-eye'),
            callback: ((leaf: CmsExerciseScript) => this.editExerciseScript(leaf.id)),
        },
        {
            name: 'create single reference',
            iconClass: 'fa fa-lg fa-link',
            callback: ((leaf: CmsExerciseScript) => this.createReferences(leaf))
        },
        {
            name: 'create editable copy',
            iconClass: 'fa fa-lg fa-share',
            callback: ((leaf: CmsExerciseScript) => this.createClientSpecs(leaf))
        }]

}

