import { Component, Input } from '@angular/core';

@Component({
  selector: 'syntax-usage-example',
  templateUrl: './syntax-usage-example.component.html',
  styleUrls: ['./syntax-usage-example.component.css']
})
export class SyntaxUsageComponent {
    @Input() usage:any;

    constructor(
    ){  
    }
}