<div class='row'>
    <div class='col-xs-12 col-md-10'>
        <hr />
    </div>
</div>
<div class='col-xs-12'>
    <div class='col-xs-12 col-md-9 assoc-block'>

        <div class='col-xs-2 assoc-arg'><span class='assoc-name'>{{associative['name']}}</span></div>
        <div class="col-xs-12 assoc-info">
            <div class='col-xs-12 assoc-syntax'  [tooltip]="'Syntax of the associative argument'" [innerHtml]="associative['syntax']"></div>
            <div class='col-xs-12 assoc-text' [tooltip]="'Description of the associative argument'"  [innerHtml]="associative['description']"></div>
            <div  *ngIf="associative['example'] !== ''" class='col-xs-12'>
                <div class='col-xs-2'>Example:</div>
                <div class='assoc-example col-xs-10' [innerHtml]="associative['example']"></div>
            </div>
        </div>
        
    </div>
</div>