<div>
    <h3 class="panel-title">Wizard</h3>
    <div *ngIf="!_topicData || _topicData.length == 0">
        <br />
        No topics selected. <button (click)="selectTopics.emit()" class="btn btn-default">Select topics</button>
    </div>
    <div *ngFor="let topicSpec of levelSpec.topicSpecs; let topicIndex = index">
        <div class="row">
            <div class="col-sm-12">
                <span class="exerciseFilter-goal-heading">{{topicSpec.topic.name}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-xs-12">
                <attribute-filter-toggle [attributeAssignment]="attrSpec" *ngFor="let attrSpec of topicSpec.attributeSpecs"></attribute-filter-toggle>
            </div>
            <!-- list of exercises for each topic-->
            <div class="col-sm-6 col-xs-12">
                <div>
                    <div *ngFor="let ex of _topicData[topicIndex].exercisesAKIT" [ngClass]="{'incompatible': isVersionMismatch(ex)}">
                        <span *ngIf="getScriptEntryClass(ex, levelSpec.levelIndex, topicIndex, topicSpec) == 'existing-exercise-filter newly selected'"
                            class="fa fa-exclamation-circle"></span>
                        <span class="exercisefilter-exercise" [ngClass]="getScriptEntryClass(ex, levelSpec.levelIndex, topicIndex, topicSpec)"
                            (click)="scriptClicked(ex)">{{ex.name}}
                            <span *ngIf="ex.state == 'DRAFT'" class="label label-warning">Draft</span>
                        </span>
                    </div>
                </div>
                <div *ngFor="let ex of _topicData[topicIndex].exercisesCourse">{{ex.name}}
                    <span *ngIf="ex.state == 'DRAFT'" class="label label-warning">Draft</span>
                </div>
            </div>
        </div>
        <hr />
    </div>
    <br />
    <div class="exercise-filter-spec-controls">
        <button class="btn btn-md btn-default" type="button" (click)="cancel.emit()">
            <i class="fa fa-ban"></i>
            <span>Cancel</span>
        </button>
        <button class="btn btn-md btn-default" type="button" (click)="commit('ADD')">
            <i class="fa fa-plus"></i>
            <span>Add to existing selection</span>
        </button>
        <button class="btn btn-md btn-default" type="button" (click)="commit('REPLACE')">
            <i class="fa fa-exchange"></i>
            <span>Replace selection</span>
        </button>
    </div>
</div>