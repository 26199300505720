<div class='syntax-row-expand'>
    <hr />
    <div *ngIf="existsTags()" class='row'>
        <div class="tags col-xs-12">
            <span *ngIf="isDeprecated()" class="deprecated-tag tag">deprecated</span>
            <span *ngIf="isInternal()" class="internal-tag tag">internal</span>
        </div>
    </div>
    <div class='detailed-syntax-table row'>
        <div *ngIf="modes['insteadofdeprecated'] !== ''">
            <div class='col-xs-12'>
                <div class='col-xs-12 col-md-9 usage-example'>
                    <div class='usage-deprecated'>Deprecated! Use <span class="code">{{modes['insteadofdeprecated']}}</span> instead.</div>
                </div>
            </div>
        </div>
        <div *ngIf="modes['example-in'] !== ''">
            <div class='row'>
                <div class='col-xs-12 col-md-10'>
                    <hr />
                </div>
            </div>
            <div class='col-xs-12'>
                <div class='col-xs-12 col-md-9 usage-example'>
                    <div class='example-text' [innerHtml]="modes['example-text']"></div>
                    <div class='col-xs-12'>
                        <div class='col-xs-2 usage-io'>In:</div>
                        <div class='col-xs-10 usage-mono' [innerHtml]="modes['example-in']"></div>
                    </div>
                    <div *ngIf="modes['example-out-1'] !== '' && modes['example-out-1'] !== modes['example-in']" class='col-xs-12'>
                        <div class='col-xs-2 usage-io'>Step:</div>
                        <div class='col-xs-10 usage-mono' [innerHtml]="modes['example-out-1']"></div>
                    </div>
                    <div *ngIf="modes['example-out-2'] !== ''" class='col-xs-12'>
                        <div class='col-xs-2 usage-io'>Out:</div>
                        <div class='col-xs-10 usage-mono' [innerHtml]="modes['example-out-2']"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>