import { Component, Input } from '@angular/core';
import { UserService } from '../../../security/services/user.service';

@Component({
  selector: 'syntax-item-details',
  templateUrl: './syntax-row.component.html',
  styleUrls: ['./syntax-row.component.css']
})
export class SyntaxRowComponent {
    @Input() syntax:any;
    
    ttOptions = {
        'placement': 'left',
        'show-delay': 500
    }

    currentUsage: any;
    
    public getTags(tags:any) {
        return tags.split(',');
    }
  
    public existsTags() {
        var bool = false;
        if (this.syntax.properties !== '') {
            bool = this.syntax.properties['ops-transparant']=='true' || 
               this.syntax.properties['ops-commass']=='true' || 
               this.syntax.properties.deprecated=='true' ||
               this.syntax.properties.internal=='internal';
        }
        return this.syntax.tags!=='' || bool;
    }
    
    public isDeprecated() {
        return this.syntax.properties !== '' && this.syntax.properties.deprecated=='true';
    }
    
    public isInternal() {
        return this.syntax.properties !== '' && this.syntax.properties.internal=='internal';
    }
    
    public hasEvaluationTemplate() {
        return this.syntax.properties !== '' && this.syntax.properties['ops-evaltemplate']=='True';
    }
    
    public showMatchingFilter() {
        return this.canEditTopicsScripts() && this.syntax.properties !== '' && this.syntax.properties['ops-matchingfilter']!=='';
    }
    
    public showAssocArg(assocarg:any) {
        if (this.canEditTopicsScripts()) {
            return true;
        }
        else {
            return assocarg !== 'internal';
        }
    }
    
    public visibleAssocArgs(assocargs:any) {
        if (this.canEditTopicsScripts()) {
            return assocargs !== '';
        }
        else {
            for(let assocarg of assocargs) {
                if (assocarg.internal !== 'internal'){
                    return true;
                }
            }
            return false;
        }
    }
    
    canEditTopicsScripts() {
        return this.userService.canEditLibrary();
    }
  
    constructor(
        private userService: UserService
    ){  
        this.currentUsage = '';
    }
    
}