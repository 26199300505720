<div class="exercise-filter-editor">
    <form [formGroup]="form">
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3 class="panel-title">Create exercise specification</h3>
            </div>
            <div class="panel-body">
                <div class="row row-spacing" *ngIf="selectedExerciseScripts && selectedExerciseScripts.length > 0">
                    <div class="col-sm-2">
                        <label>Selected Exercises</label>
                    </div>
                    <div class="col-sm-10">
                        <ul>
                            <li *ngFor="let script of selectedExerciseScripts">
                                {{script.name}}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row row-spacing">
                    <div class="col-sm-2">
                        <label>Course</label>
                    </div>
                    <div class="col-sm-10">
                        <span *ngIf="retrievingCourses" class='form-control'>Retrieving courses...</span>
                        <select *ngIf="!retrievingCourses" class='form-control' [formControl]="courseCtrl">
                            <option *ngFor="let course of courses" value="{{course.id}}">{{course.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="row row-spacing">
                    <div class="col-sm-2">
                        <label>Subject</label>
                    </div>
                    <div class="col-sm-10">
                        <breadcrumb [folder]="subject" (parentChange)='setSubject($event)' [showChilds]="true"></breadcrumb>
                    </div>
                </div>
                <div class="row row-spacing" *ngIf="course$.value">
                    <div class="col-sm-2">
                        <label>Audience</label>
                    </div>
                    <div class="col-sm-4">
                        <select [formControl]="audienceCtrl" class='form-control'>
                            <option *ngFor="let audience of audiences" value="{{audience.audienceID}}">{{audience.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="row row-spacing" *ngIf="selectedExerciseScripts && selectedExerciseScripts.length == 1">
                    <div class="col-sm-2">
                        <label>Name (leave empty to keep original name)</label>
                    </div>
                    <div class="col-sm-4">
                        <input [formControl]="nameCtrl" class='form-control' />
                    </div>
                </div>
                <div class="row row-spacing" *ngIf="selectedExerciseScripts && selectedExerciseScripts.length == 1">
                    <div class="col-sm-2">
                        <label>Title</label>
                    </div>
                    <div class="col-sm-4">
                        <input [formControl]="titleCtrl" class='form-control' />
                    </div>
                </div>
                <!--<div class="row row-spacing" *ngIf="canKeepIds()">
                    <div class="col-sm-2">
                        <label>Keep script IDs. WARNING: this will delete the original exercise!</label>
                    </div>
                    <div class="col-sm-4">
                        <input type="checkbox" (change)="test()" [formControl]="keepIdsCtrl" [ngModel]="keepIds" />
                    </div>
                </div>-->
            </div>
        </div>
    </form>
    <div class="row row-spacing">
        <div class="col-xs-3">
            <button class="btn btn-md btn-primary btn-block" type="button" (click)="commit()" [disabled]="!form.valid">
                <i class="fa fa-save"></i>
                <span>Create Copy</span>
            </button>
        </div>
        <div class="col-xs-3">
            <button class="btn btn-md btn-default btn-block" type="button" (click)="close()">
                <i class="fa fa-ban"></i>
                <span translate>Close</span>
            </button>
        </div>
    </div>

</div>