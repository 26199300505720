export function getDefaultToastOption() {
  return {
    animate: 'fade',
    positionClass: 'toast-top-full-width',
    showCloseButton: true,
    timeOut: 6000,
    extendedTimeOut: 2000
  };
}

export function getExtendedToastOption() {
  return {
    enableHtml: true,
    animate: 'fade',
    positionClass: 'toast-top-full-width',
    showCloseButton: true,
    dismiss: 'click',
    timeOut: 10000,
    extendedTimeOut: 2000
  };
}