import {Component, OnInit, Input, OnChanges, forwardRef, ViewChild, ElementRef, AfterViewInit} from '@angular/core';

declare const $: any;

@Component({
    selector: 'scroll-container',
    templateUrl: './scroll-container.component.html',
    styleUrls: ['./scroll-container.component.css']
})
export class ScrollContainerComponent implements OnInit{
    
    @Input() maxHeight: number = 400;

    @ViewChild('scrollContainer', {static: false}) scrollContainer: ElementRef;
    @ViewChild('scrollContent', {static: false}) scrollContent: ElementRef;

    style: any = {};
    scrollDelta: number;

    headerVisible: boolean;

    ngOnInit(): void {
        this.style = { "max-height": this.maxHeight+'px'}
        this.scrollDelta = this.maxHeight * 0.9;
    }

    footerVisible() {
        return this.scrollContainer.nativeElement.scrollTop < this.scrollContainer.nativeElement.scrollHeight-this.scrollContainer.nativeElement.offsetHeight;
    }

    onScroll(e: any) {
        this.headerVisible = this.scrollContainer.nativeElement.scrollTop > 0;
    }

    scrollUp() {
        let scrollPos = this.scrollContainer.nativeElement.scrollTop;
        if (scrollPos < this.scrollDelta) {
            scrollPos = 0;
        } else {
            scrollPos -= this.scrollDelta;
        }
        this.scrollTo(scrollPos);
    }

    scrollDown() {
        let scrollPos = this.scrollContainer.nativeElement.scrollTop;
        let height = this.scrollContainer.nativeElement.scrollHeight;
        if (scrollPos + this.scrollDelta > height) {
            scrollPos = height;
        } else {
            scrollPos += this.scrollDelta;
        }
        this.scrollTo(scrollPos);
    }

    scrollTo(scrollPos: number) {
        $(this.scrollContainer.nativeElement).animate({scrollTop: scrollPos});
    }
}

