
import {distinctUntilChanged} from 'rxjs/operators';
import { Component, OnInit, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MetadataService, COUNTRIES } from '../../../algebrakit/services/metadata.service';
import { NameIdPair, AudienceSpec } from '../../../algebrakit/types/metadata.type';
import { BehaviorSubject } from 'rxjs';
import { CmsCourseAudience } from '../../types/navigator.types';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../../security/services/user.service';

interface AudienceCategory {
    id: string;
    name: string;
    type: string;
}

@Component({
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => AudienceSelectorComponent),
        multi: true
    }],
    selector: 'audience-selector',
    templateUrl: './audience-selector.component.html',
    styleUrls: ['./audience-selector.component.css']
})
export class AudienceSelectorComponent implements ControlValueAccessor, OnInit {
    countries = COUNTRIES;

    audiences: AudienceSpec[];
    courses: NameIdPair[];
    _audienceId: BehaviorSubject<string> = new BehaviorSubject(null);
    _category: BehaviorSubject<AudienceCategory> = new BehaviorSubject(null);

    categories: AudienceCategory[];

    form: FormGroup;
    categoriesCtrl = new FormControl("", Validators.required);
    audienceCtrl = new FormControl("", Validators.required);

    init: boolean = false;

    private propagateChange: { (_: any): void } = null;

    get invalid() {
        return !this.categoriesCtrl.value || !this.audienceCtrl.value;
    }

    get audience() { return this._audienceId.value; }
    @Input() set audience(id: string) {
        this._audienceId.next(id)
    }

    get course() {
        if (!this._category.value) {
            return null;
        }
        return this._category.value.type == "Courses" ? this._category.value.id : null;
    }
    set course(id: string) {
        let course = this.categories.find(c => c.id == id && c.type == "Courses");
        if (!course) {
            return;
        }
        this._category.next(course);
    }

    get country() {
        if (!this._category.value) {
            return null;
        }
        return this._category.value.type == "Regions" ? this._category.value.id : null;
    }
    set country(id: string) {
        let country = this.categories.find(c => c.id == id && c.type == "Region");
        if (!country) {
            return;
        }
        this._category.next(country);
    }

    @Output() onAudienceSelected: EventEmitter<CmsCourseAudience> = new EventEmitter<CmsCourseAudience>();

    constructor(
        private metadataService: MetadataService,
        private courseService: CourseService,
        private userService: UserService,
        private formBuilder: FormBuilder
    ) { }

    setAudiencesFromCountry(country: string): void {
        if (country) {
            this.metadataService.getAudiences(country).then((audiences) => {
                this.audiences = audiences
                if (!this.audiences.find(aud => aud.audienceID === this.audience)) {
                    this.audience = this.audiences[0].audienceID;
                }
            });
        }
    }

    getCourseId() {
        return this.course ? this.course : null;
    }

    categoryChanged(e: AudienceCategory) {
        this._category.next(e);
    }

    setInitialCategory() {
        if (this._category.value != null) {
            this.setInitialAudience();
            return;
        }
        this.init = true;
        let initCategoryId = this.courses[0].id;
        if (this.userService.canEditLibrary()) {
            initCategoryId = 'en';
        }
        let initCategory = this.categories.find(c => c.id == initCategoryId);
        this.categoriesCtrl.setValue(initCategoryId);
        this._category.next(initCategory);
        return;
    }

    setInitialAudience() {
        let initAudienceId = this.audiences[0].audienceID;
        if (this.userService.canEditLibrary() && this.init) {
            initAudienceId = 'uk_KS5';
        }
        this.audienceCtrl.setValue(initAudienceId);
        this._audienceId.next(initAudienceId);
        this.init = false;
    }

    ngOnInit() {
        //careful: wait until onChange listener is registered.
        //        this.countries = this.metadataService.getCountries();
        this.form = this.formBuilder.group({
            category: this.categoriesCtrl,
            audience: this.audienceCtrl,
        });
        this.audienceCtrl.valueChanges.subscribe(id => {
            this.audience = id;
            this.propagateChanges(id);
        });
        this._category.pipe(
            distinctUntilChanged())
            .subscribe((category) => {
                if (category == null) {
                    this.audiences = [];
                    this.audienceCtrl.setValue(null);
                    return;
                }
                let promise;
                if (category.type == "Regions") {
                    promise = this.metadataService.getPreselectedAudiences(category.id);
                }
                else if (category.type == "Courses") {
                    promise = this.metadataService.getForCourseId(category.id);
                }
                if (promise) {
                    promise.then((result) => {
                        this.audiences = result;
                        this.setInitialAudience();
                    })
                }
            })
        this._audienceId.pipe(
            distinctUntilChanged())
            .subscribe(id => {
                this.audienceCtrl.setValue(id, { emitEvent: false });
                this.propagateChanges(id);
            });

        this.courseService.getCourseNameList().toPromise().then(result => {
            this.courses = result;
            this.courses.sort((a: NameIdPair, b: NameIdPair) => a.name.localeCompare(b.name));
            this.categories = [];
            if (this.userService.canEditLibrary()) {
                this.countries.map((c) => {
                    return {
                        id: c.id,
                        name: c.name,
                        type: 'Regions'
                    }
                }).forEach((c) => this.categories.push(c));
            }
            this.courses.map((c) => {
                return {
                    id: c.id,
                    name: c.name,
                    type: 'Courses'
                }
            }).forEach((c) => this.categories.push(c));

            if (!this.audience) {
                this.setInitialCategory();
            }
        })
    }

    //implementing ControlValueAccessor
    writeValue(value: string) {
        if (!value) return;
        this._audienceId.next(value);
    }

    public emitAudience() {
       this.propagateChanges(this.audience);
    }

    propagateChanges(id: string) {
        let obj = this._category.value && this._audienceId.value 
            ? { baseAudienceId: id, courseId: this.getCourseId() }
            : null;
        if (this.propagateChange) {
            this.propagateChange(obj)
        }; //for forms
        if (this.onAudienceSelected) this.onAudienceSelected.emit(obj); //for standalone usage
    }

    registerOnChange(fn: { (_: any): void }) {
        this.propagateChange = fn;
        //setting default audience has already happened in ngOnInit, so resend
        this.propagateChanges(this.audience);
    }
    registerOnTouched() { }

}
