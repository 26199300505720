<div *ngIf="debug" class="interaction">
    <button type="button" class="debug-button fa fa-cogs" (click)="showDebug=true" [hidden]="showDebug"
        title="Show debug information"></button>
    <div class="debug-wrapper" *ngIf="showDebug">
        <button class="close-button fa fa-times" (click)="showDebug=false"></button>
        <debug-view [debug]="debug"></debug-view>
    </div>
</div>
<div *ngIf="errorMessage">
    <div class="alert alert-danger" [innerHTML]="errorMessage | nl2br : true"></div>
</div>
<div #interactionWrapper></div>