import { IAlgebraKIT } from "../../algebrakit/types/algebrakit.type";

declare const AlgebraKIT: IAlgebraKIT;

let LATEX_START = '$$';
let LATEX_RESOURCE_START = '$$$';
let LATEX_END = '$$';
let regExp = new RegExp('^\\$\\$(.*)\\$\\$$');
let verbatimExp = new RegExp('^\\\\verb\\|(.*)\\|$');

/** transform expression LATEX_START<latex>LATEX_END to <latex> */
export function akitToLatex(exp: string): string {
    let result = exp.trim().replace(regExp, function (match: string, latex: string) {
        return latex;
    });
    //handle extra $ in case of resource
    if (result.startsWith('$')) {
        result = result.substring(1).replace(/&lt;/g, '<');
    }
    return result;
}

/** transform latex expression such that it can be embedded into an Algebrakit expression 
 * @param replaceVerbatimToAkit If true, expressions like \verb|A| are translated into A, not $LATEX_START\verb|A|$LATEX_END
 * @param isResource If true, the delimiter for resources will be used, such that the latex will not be interpreted by AlgebaKiT. 
*/
export function latexToAkit(latex: string, isResource?: boolean, replaceVerbatimToAkit?: boolean): string {
    if (latex == null) latex = '';
    if (replaceVerbatimToAkit && latex.match(verbatimExp)) {
        return latex.trim().replace(verbatimExp, function (match: string, latex: string) {
            return latex;
        });
    } else if (isResource) {
        latex = latex.replace(/</g, '&lt;');
        return LATEX_RESOURCE_START + latex + LATEX_END;
    } else {
        return LATEX_START + latex + LATEX_END;
    }
}

export function renderLatex(latex: string, displayMode?: boolean): string {
    const input = displayMode ? latex : `$${latex}$`
    return AlgebraKIT.latex2HtmlSync(input);
}

export function isLatexExpression(expr: string): boolean {
    if (!expr) return false;
    expr = expr.trim();
    return !!expr.match(regExp);
}

export function containsLatexExpression(expr: string): boolean {
    if (!expr) return false;
    return expr.indexOf("$$") >= 0;
}

/**
 * @deprecated
 * !!!! Avoid using this function as much as possible !!!!
 * 
 * Only use this function when you need to transform a string that contains latex expressions to a string that contains html.
 * In all other cases: use the <akit-latex> stencil component.
 * For example when you can only provide an html string to a component.
 * 
 * Use case examples:
 * - Toastr messages where text and latex are mixed: str="An error occurred: $$...$$ is not a valid expression"
 * - Messages received from the backend where the text can contain latex expressions inline.
 * 
 * In the future we might want to remove this function and replace it with a stencil component that can render mixed latex/plain-text strings.
 * 
 * This function replaces all subexpressions LATEX_START<latex>LATEX_END by rendered html
 * @param str 
 * @param displayMode 
 * @returns 
 */
export function renderMixedLatexStringToHTML(str: string, displayMode?: boolean): string {
    if (!str) return '';

    let html = '';
    let ii = str.indexOf(LATEX_START);
    while (ii >= 0) {
        html += str.substr(0, ii);
        str = str.substr(ii + LATEX_START.length);
        ii = str.indexOf(LATEX_END);
        if (ii >= 0) {
            let latex = str.substr(0, ii);
            if (latex.startsWith('$')) latex = latex.substring(1); //handle resource case
            str = str.substr(ii + LATEX_END.length);
            try {
                html += renderLatex(latex, displayMode);
            } catch (error) {
                html += '<span style="color:red">invalid expression</span>';
            }

        }
        ii = str.indexOf(LATEX_START);
    }
    html += str;
    html = html.replace(/`/g, '');
    return html;
}

/** 
 * Refers to the same function in the widgets.
 */
export function getSymbolAsLatex(akitStr: string) {
    return AlgebraKIT._api.Util.getSymbolAsLatex(akitStr);
}

//Removed all old render functiuons since they were no longer used anywhere