
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { Component, Output, EventEmitter, ViewChild, ElementRef, Input, OnInit } from "@angular/core";
import { CmsItem } from "../../types/navigator.types";
import { BehaviorSubject, Subject } from "rxjs";
import { CANCELLED } from "dns";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";



@Component({
    selector: 'search-field',
    templateUrl: './search-field.component.html',
    styleUrls: ['./search-field.component.css']
})

export class SearchFieldComponent implements OnInit {

    @Output() onSearchTextChanged: EventEmitter<string> = new EventEmitter<string>();
    @Input() manualSubmit: boolean = false;
    @Input() placeholder: string = "search"

    searchInputText: BehaviorSubject<string> = new BehaviorSubject<string>("");

    searchFieldGroup: FormGroup;
    searchInputCtrl = new FormControl('');

    public clear() {
        this.searchInputCtrl.setValue("");
    }

    update(event: any) {
        let value: string = event.target.value;
        if (value === this.searchInputText.value) {
            return;
        }
        this.searchInputText.next(event.target.value);
    }

    constructor(private formBuilder: FormBuilder) {
        this.searchFieldGroup = this.formBuilder.group({
            searchInput: this.searchInputCtrl
        });
    }

    submit() {
        let value = this.searchInputCtrl.value;
        this.onSearchTextChanged.emit(value);
    }

    ngOnInit(): void {
        if (!this.manualSubmit) {
            this.searchInputText.asObservable().pipe(
                debounceTime(500),
                distinctUntilChanged(),)
                .subscribe(value => {
                    this.onSearchTextChanged.emit(value);
                })
        }
    }

}