<div class='topic-navigator cms-navigator'>
    <h1>

        <span *ngIf="courseRef.name">
            {{courseRef.name}}
        </span>


    </h1>
    <div *ngIf='currentFolder | async'>

        <breadcrumb [folder]='currentFolder | async' (parentChange)='setParent($event)' [courseName]="courseRef.name"
            [homeLinkHref]="'/courses'" [activated]='"force"'>
        </breadcrumb>

        <div class="button-container">
            <div class='actions-button navigator-button' *ngIf="canEditCourse() && !inTrash() && browseMode === 'EDIT'">
                <div class="dropdown ">
                    <button class="btn btn-default dropdown-toggle create-button" type="button" id="dropdownMenu1"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        <span>Actions</span>
                        <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">
                        <li>
                            <a (click)="copyExercises()">
                                <i class="fa fa-copy"></i>
                                <span>Copy exercises</span>
                            </a>
                        </li>
                        <li>
                            <a (click)="deleteExercises()">
                                <i class="fa fa-trash"></i>
                                <span>Delete exercises</span>
                            </a>
                        </li>
                        <li>
                            <a (click)="changeExerciseCourse()" [ngClass]="{'clickable':browseMode=='EDIT'}">
                                <i class="fa fa-share"></i>
                                <span>Move</span>
                            </a>
                        </li>
                        <li>
                            <a *ngIf="canApprove()" (click)="approveSelected()"
                                [ngClass]="{'clickable':browseMode=='EDIT'}">
                                <i class="fa fa-check"></i>
                                <span>Approve selected exercises</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class='create-button create-button navigator-button'
                *ngIf="canEditCourse() && !inTrash() && browseMode === 'EDIT'">
                <div class="dropdown ">
                    <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        <span>New...</span>
                        <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">
                        <li>
                            <a (click)="createExerciseFilter()">
                                <i class="fa fa-glass"></i>
                                <span>Exercise Arrangement</span>
                            </a>
                        </li>
                        <li>
                            <a (click)="createExerciseReference()">
                                <i class="fa fa-link"></i>
                                <span>Single Reference</span>
                            </a>
                        </li>
                        <li role="separator" class="divider"></li>
                        <li>
                            <a (click)="createExerciseSpecification()">
                                <i class="fa fa-file-text"></i>
                                <span>Exercise</span>
                            </a>
                        </li>
                        <li>
                            <a (click)="createSubject()">
                                <i class="fa fa-folder"></i>
                                <span>Folder</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <br *ngIf="changeCourseDialogVisible || changeCourseSubjectDialogVisible" />
        <move-exercise [selectedExercises]="exercisesToMove" (onClose)="exportersClose()" (onSuccess)="refresh()"
            [currentCourseId]="this.courseRef.id" *ngIf="changeCourseDialogVisible">
        </move-exercise>
        <move-folder [selectedFolder]="subjectToMove" [folderType]="'SUBJECT'" (onClose)="exportersClose()"
            (onSuccess)="refresh()" *ngIf="changeCourseSubjectDialogVisible">
        </move-folder>

        <div *ngIf="approveFailures && approveFailures.length > 0" class="alert alert-danger">
            <strong>Could not approve the following exercises:</strong>
            <br />
            <br />
            <ul>
                <li *ngFor="let ref of approveFailures">
                    <a [routerLink]="'/edit/'+ref.id" target="_blank">{{ref.name}}</a>
                </li>
            </ul>
            <br />
            Please check if these exercises can be run without errors before approving them.
        </div>

        <div *ngIf="childSubjects">
            <folder *ngFor='let item of childSubjects' [folder]="item" [readOnly]="browseMode=='VIEW'"></folder>
        </div>
        <busy-gears *ngIf="loading"></busy-gears>
        <br>
        <h3 *ngIf="exercises && exercises.length > 0" class="course-navigator__exercises">
            Exercises
        </h3>

        <div class="row header topic-check-all" *ngIf="exercises && exercises.length > 0">
            <div class='col-sm-5 col-xs-12 '>
                <input type="checkbox" [checked]="allSelected()" (change)="checkAll($event)" />
            </div>
            <div class='col-sm-1 hidden-xs'>
                <strong>State</strong>
            </div>
            <div class='col-sm-1 hidden-xs'>
                <strong>Published</strong>
            </div>
            <div class='col-sm-1 hidden-xs'>
                <strong>Tested</strong>
            </div>
            <div class='col-sm-1 hidden-xs'>
                <strong>Validated</strong>
            </div>
        </div>

        <div *ngIf="exercises && currentVersion">
            <leaf (onChecked)="exerciseSelectedChanged($event)"
                [selectable]="(incompatibleExercisesSelectable || !isVersionMismatch(item)) && !inTrash()"
                [selected]="selectedExercises[item.id]" *ngFor='let item of exercises' [leaf]="item"
                [readOnly]="browseMode=='VIEW'" [errorBadgeTitle]="getVersionMismatchError(item)"
                [enabled]="!isVersionMismatch(item)"></leaf>
        </div>
    </div>

    <div class="modal fade item-history" *ngIf="historyItem">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="modal-title">History:
                        <span>{{historyItem.name}} ({{historyItem.id}})</span>
                    </h4>
                </div>
                <div class="modal-body">
                    <version-viewer [id]="historyItem.id"></version-viewer>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>

    <!-- modal to show demo of exerise -->
    <div class="modal fade exercise-demo" *ngIf="!useAsComponent">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="modal-title">Preview: {{getName(testObj)}}</h4>
                </div>
                <div class="modal-body">
                    <div class="button-row">
                        <button class="edit-button" type="button" (click)="refreshPreview()">
                            <span class="fa fa-refresh"></span>
                        </button>
                        <button class="edit-button" type="button" (click)="editExercise()" *ngIf="canEditCourse()">
                            edit
                        </button>
                        <div style="clear:both"></div>
                    </div>
                    <exercise-preview #exercisePreview [showEditViewButtenForScripts]="testObj.type != 'SPECIFICATION'"
                        [exerciseRef]="testObj" *ngIf="testObj" (error)="onInteractionError($event)"
                        [scoringModel]="courseRef?.options?.scoringModel"></exercise-preview>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->

</div>