import { Component, OnInit, OnDestroy, ViewContainerRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

import { BehaviorSubject, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import {
    CmsExerciseScript, CmsCourse, CmsExerciseFilter, CmsSubject, CmsTopic, CmsTopicFilter,
    CmsTopicFilterLevelAttributeAssignement, CmsTopicFilterLevel, CmsAttributeAssignmentType,
    CmsLeaf, CmsTopicAttribute, CmsContainerType, RefConverter, CmsVersioningInfo, CmsItemState,
    CmsExerciseRef, GenerateExerciseFilterPreview, CmsUpdateType,
    CmsTopicAttributeAssignment,
    CmsExerciseFilterLevelSpec,
    CmsExerciseFilterScriptSpec,
    CmsCourseAudience,
    CmsExerciseClientSpec
} from '../../types/navigator.types';
import { TopicService } from '../../services/topic.service';
import { CourseService } from '../../services/course.service';
import { MetadataService } from '../../../algebrakit/services/metadata.service';
import { AudienceSpec } from '../../../algebrakit/types/metadata.type';
import { ExerciseRef, CmsExerciseType, ExerciseAttrRef, TopicData, LevelSpec, TopicSpec } from '../../types/cms-metadata.types';
import { SubjectService } from '../../services/subject.service';
import { ExerciseScriptService } from '../../services/exercise-script.service';
import { ExerciseService } from '../../services/exercise.service';
import { ExerciseScriptPreviewComponent } from '../../components/exercise-script-preview/exercise-script-preview.component';
import { VersioningService } from '../../services/versioning.service';
import { Sorting } from '../../util/sorting';
import { UserService } from '../../../security/services/user.service';
import { ExercisePreviewComponent } from '../../components/exercise-preview/exercise-preview.component';
import { FilterUtils } from '../../util/filter-utils';
import { TypeHelpers } from '../../util/type-helpers';
import { ComponentCanDeactivate } from '../../../app/services/component-can-deactivate';
import { PublishedExerciseFilter, ExerciseFilterLevelSpecType } from '../../types/published.types';
import { StackedTabOption } from '../../components/stacked-tabs/stacked-tabs.component';
import { UUID } from 'angular2-uuid';
import { AppProfileService } from '../../../app/services/app-profile.service';
import { AkitShortcutComponent } from '../../components/akit-shortcuts/akit-shortcuts.component';
import { ContentVersionService } from '../../../app/services/content-version-service';
import { AkitVersionsService } from '../../../algebrakit/services/akit-versions.service';
import { akitVersionMismatch } from '../../util/akit-version-utils';

declare let $: any;
let subscriptions: Subscription[] = [];
declare let AlgebraKIT: any

function getExerciseComplexity(ex: CmsExerciseScript, goal: CmsTopic) {
    let score = 0;
    for (let attributeAssignment of ex.attributeAssignments) {
        let attr = goal.attributeAssignments.find(item => item.id === attributeAssignment.id);
        score += attr ? attr.difficulty.complexity * attr.difficulty.complexity : 0;
    }
    return Math.sqrt(score) / ex.attributeAssignments.length

}

enum FilterEditMode {
    WIZARD = 'WIZARD',
    BROWSE = 'BROWSE',
    LIST = 'LIST'
}

@Component({
    templateUrl: './exercise-filter-editor.component.html',
    styleUrls: ['./exercise-filter-editor.component.css', '../../../../assets/shared.less']
})
export class ExerciseFilterEditorComponent extends ComponentCanDeactivate implements OnInit, OnDestroy, AfterViewInit {
    form: FormGroup;
    nameCtrl = new FormControl('', Validators.required);
    titleCtrl = new FormControl('');
    audienceCtrl = new FormControl('', Validators.required);
    descriptionCtrl = new FormControl('');
    commentCtrl = new FormControl('');
    audiences: CmsCourseAudience[];

    updateTypeControl = new FormControl();
    commitMessageControl = new FormControl('', [this.commitMessageValidator]);

    exercise: CmsExerciseFilter;
    oldExercise: CmsExerciseFilter;
    exerciseReady: boolean = false;
    viewReady: boolean = false;
    topicsReady: boolean = false;

    demoExerciseScript: ExerciseAttrRef;
    demoExerciseFilter: CmsExerciseFilter;

    course: CmsCourse;
    subject: CmsSubject;
    topicData: TopicData[]
    attrPerTopicPerLevel: LevelSpec[];     //reorganized structure from this.goalFilters. Toggles in GUI interact with this.

    oldAttrPerTopicPerLevel: LevelSpec[];

    refreshTimer: any;

    metadataValid = true;

    versionInfo: CmsVersioningInfo;
    lastPublishedVersion: PublishedExerciseFilter;
    lastPublishedRetrieved: boolean = false;

    //Modes per level
    modes: FilterEditMode[] = [];
    currentMode = FilterEditMode.LIST;

    // bool to show title information in general settings tab
    generalExtend = false;

    changed: boolean;

    showEditingWarning = false;
    publishingWarning = false;

    confirmPublish = false;

    currentActiveLevel = 0;

    activeTab = "general";
    @ViewChild("topics", { static: false }) topicsPane: ElementRef;

    @ViewChild('scriptPreviewComponent', { static: false }) scriptPreviewComponent: ExerciseScriptPreviewComponent;
    @ViewChild('exercisePreviewComponent', { static: false }) exercisePreviewComponent: ExercisePreviewComponent;

    onCloseUrl: string;

    currentVersion: string;
    akitVersion: string; //akit-version of the currently chosen API
    akitVersionsPromise: Promise<void>;

    readOnly: boolean;

    //To prevent "are you sure...." popup box from appearing twice
    leaveMessageAlreadyGiven: boolean;

    get interactionBlacklist() {
        if (!this.course || !this.course.options) {
            return [];
        }
        return this.course.options.interactionBlacklist;
    }

    get saveTitle() {
        return `Save this exercise (${AkitShortcutComponent.getSaveShortcut()})`
    }

    get runTitle() {
        return `Run this exercise (${AkitShortcutComponent.getRunShortCut()})`
    }

    get akitVersionMismatch() {
        return akitVersionMismatch(this.course, this.akitVersion);
    }

    get courseAkitVersion() {
        if (!this.course || !this.course.options) {
            return null;
        }
        return this.course.options.akitVersion;
    }

    constructor(
        private toastr: ToastrService,
        private router: Router,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private location: Location,
        private topicService: TopicService,
        private exerciseService: ExerciseService,
        private courseService: CourseService,
        private subjectService: SubjectService,
        private exerciseScriptService: ExerciseScriptService,
        private metadataService: MetadataService,
        private versioningService: VersioningService,
        private userService: UserService,
        private appProfileService: AppProfileService,
        private contentVersionService: ContentVersionService,
        private akitVersionsService: AkitVersionsService
    ) {
        super();
        this.form = this.formBuilder.group({
            name: this.nameCtrl,
            title: this.titleCtrl,
            audience: this.audienceCtrl,
            commitMessage: this.commitMessageControl,
            updateType: this.updateTypeControl,
            description: this.descriptionCtrl,
            comment: this.commentCtrl,
        })
    }

    showErrorMessage(err: any, fallBackMessage: string) {
        let message = err.message || err.message || fallBackMessage;
        this.toastr.error(message, 'Error');
    }

    commitMessageValidator() {
        return (control: AbstractControl): { [key: string]: any } => {
            return this.exercise.state == CmsItemState.APPROVED
                && (!control.value || ("" + control.value).trim() == "")
                ? { 'emptyCommitMessage': { value: control.value } }
                : null;
        };
    }

    setTested() {
        this.versioningService.setTestedTrue(this.exercise.id)
            .then(() => {
                this.exercise.tested = true;
                this.oldExercise = TypeHelpers.clone(this.exercise);
                this.toastr.success("Exercise marked as tested", 'Success')
            }).catch((err) => {
                console.log(err);
                this.showErrorMessage(err, "Error occurred while marking the exercise as tested");
            });
    }

    canApprove(): boolean {
        return this.userService.canApproveCourseExercises(this.course);
    }

    approve() {
        if (confirm("You are about to move this exercise from the DRAFT to the APPROVED state and enable versioning for this exercise. Are you sure?")) {
            this.versioningService.approve(this.exercise.id)
                .then((exercise: CmsExerciseFilter) => {
                    this.exercise.state = CmsItemState.APPROVED;
                    this.getVersioningData();
                    this.oldExercise = TypeHelpers.clone(this.exercise);
                    this.toastr.success("Exercise is now approved.", 'Success');
                })
                .catch((err: any) => {
                    console.log(err);
                    this.showErrorMessage(err, "Error occurred while approving the exercise");
                })
        }
    }

    getVersioningData() {
        if (this.exercise.state == CmsItemState.APPROVED) {
            this.versioningService.getLatestVersionInfo(this.exercise.id)
                .then((versionInfo: CmsVersioningInfo) => {
                    this.versionInfo = versionInfo
                });
        }
    }

    getLastPublish() {
        this.lastPublishedRetrieved = false;
        if (this.exercise.state == CmsItemState.DRAFT) {
            this.lastPublishedRetrieved = true;
            return Promise.resolve(null);
        }
        return this.exerciseService.getPublishedFilter(this.exercise.id, this.exercise.publishedMajorVersion).then((pubEx) => {
            this.lastPublishedVersion = pubEx as PublishedExerciseFilter;
            this.lastPublishedRetrieved = true;
        });
    }

    publish() {
        if (this.publishingWarning) {
            this.confirmPublish = true;
        }
        else {
            this.doPublish();
        }
    }

    cancelPublish() {
        this.confirmPublish = false;
    }

    doPublish() {
        this.exerciseService.publish(this.exercise, this.versionInfo, this.lastPublishedVersion)
            .then((pubEx) => {
                this.confirmPublish = false;
                if (!pubEx) {
                    //Aborted, do nothing.
                    return;
                }
                this.confirmPublish = false;
                this.lastPublishedVersion = pubEx as PublishedExerciseFilter;
                this.getVersioningData();
            })
    }

    canPublish(): boolean {
        return this.userService.canPublishInCourse(this.exercise.course) && this.exercise.state == 'APPROVED';
    }

    cancel() {
        if (!this.canDeactivate()) {
            if (confirm("You have unsaved changes in this exercise. Are you sure you want to close the editor?")) {
                this.leaveMessageAlreadyGiven = true;
                this.leave();
            }
        }
        else {
            this.leave();
        }
    }

    leave() {
        this.releaseLock();
        if (this.onCloseUrl == '_BACK') {
            this.location.back();
        }
        else if (this.onCloseUrl) {
            this.router.navigate([this.onCloseUrl]);
        }
        else {
            this.router.navigate(['subject', this.exercise.subject.id]);
        }
    }

    releaseLock(): void {
        if (this.exercise) {
            this.exerciseService.releaseLock(this.exercise.id);
        }
    }

    addTopic() {
        //set structure for attributes for each level. 
        //the attributes will be set when a goal is chosen
        let attributeListPerLevel = [];
        for (let ii = 0; ii < this.exercise.numberOfLevels; ii++) {
            attributeListPerLevel.push({
                levelNumber: ii + 1,
                attributeAssignments: []
            });
        }
        let newFilter: CmsTopicFilter = {
            levels: attributeListPerLevel,
            topic: null
        }

        this.exercise.topicFilters.push(newFilter);
        this.topicData.push({
            topic: null,
            exercisesAKIT: [],
            exercisesCourse: []
        });
        this.updateTopic('root', this.topicData.length - 1);
    }

    addLevel() {
        let newLevelNumber = this.exercise.exercisesPerLevel.length + 1;
        //Add the level to the exercisePerLevel list
        this.exercise.exercisesPerLevel.push({
            level: newLevelNumber,
            scripts: [],
            courseExercises: []
        })

        //Add the level to the objects used by the wizard
        for (let ii = 0; ii < this.exercise.topicFilters.length; ii++) {
            let topicFilter = this.exercise.topicFilters[ii];
            let topicAttributeAssignments: CmsTopicFilterLevelAttributeAssignement[] = [];
            for (let topicAttribute of this.topicData[ii].topic.attributeAssignments) {
                topicAttributeAssignments.push({
                    ...topicAttribute,
                    attributeAssignmentType: CmsAttributeAssignmentType.NONE
                });
            }
            let levelSpec: CmsTopicFilterLevel = {
                levelNumber: newLevelNumber,
                attributeAssignments: topicAttributeAssignments
            };
            topicFilter.levels.push(levelSpec);
        }
        this.attrPerTopicPerLevel.push(FilterUtils.getLevelSpec(this.exercise.numberOfLevels, this.exercise, this.topicData))
        this.exercise.numberOfLevels++;
    }

    deleteLevel(index: number) {
        for (let goalFilter of this.exercise.topicFilters) {
            goalFilter.levels.splice(index, 1);
        }
        for (let goalFilter of this.exercise.topicFilters) {
            for (let i = 0; i < goalFilter.levels.length; i++) {
                goalFilter.levels[i].levelNumber = i + 1;
            }
        }
        this.attrPerTopicPerLevel.splice(index, 1);
        this.exercise.numberOfLevels--;
        this.exercise.exercisesPerLevel.splice(index, 1);
    }

    deleteTopic(index: number) {
        this.topicData.splice(index, 1);
        this.exercise.topicFilters.splice(index, 1);
        this.attrPerTopicPerLevel = FilterUtils.setAttrPerGoalPerLevel(this.exercise, this.topicData);
    }

    commit() {
        if (this.exercise.state == 'APPROVED') {
            let commitMsg: string = this.form.value.commitMessage;
            if (commitMsg == null || commitMsg.trim() == "") {
                alert('You need to provide a commit message when saving approved exercises.');
                return;
            }
        }
        this._getFormValue();
        this.exerciseService.storeExerciseFilter(this.exercise)
            .then(() => {
                this.toastr.success("Exercise was stored successfully.", 'Success')
                this.exercise.tested = false;
                this.init(this.exercise);
                this.commitMessageControl.setValue("");
            })
            .catch(err => {
                console.log(err);
                this.showErrorMessage(err, "Error occurred while storing the exercise");
            });
    }

    setSubject(id: string): void {
        this.subjectService.getSubject(id).toPromise()
            .then(subject => {
                this.subject = subject;
                this.exercise.subject = {
                    id: subject.id, name: subject.name, containerType: CmsContainerType.FOLDER
                }
            })
            .catch(err => {
                console.log(err);
                this.showErrorMessage(err, "Error occurred while retrieving subject");
            });
    }

    async previewScript(ex: CmsExerciseScript) {
        return this.previewExerciseScriptOrSpec({
            type: CmsExerciseType.FILTER,
            exerciseId: ex.id,
            courseId: "",
            attributes: ex.attributeAssignments.map(attr => attr.attribute.name),
            script: ex.definition,
            contentVersion: ex.contentVersion
        });
    }

    async previewCourseExercise(ex: CmsExerciseClientSpec) {
        return this.previewExerciseScriptOrSpec({
            type: CmsExerciseType.FILTER,
            exerciseId: ex.id,
            courseId: "",
            attributes: [],
            script: ex.definition,
            contentVersion: ex.contentVersion
        });
    }

    async previewExerciseScriptOrSpec(demoExerciseScript: ExerciseAttrRef) {
        this.demoExerciseFilter = null;
        let theme = this.course.options && this.course.options.theme
            ? this.course.options.theme
            : 'akit';
        await AlgebraKIT._api.changeTheme(theme);
        this.demoExerciseScript = demoExerciseScript;
        var modal = $('.exercise-demo').first();
        let self = this;
        modal.modal('show').on('hide.bs.modal', function (e: any) {
            self.demoExerciseScript = null;//trigger destroy of widgets
        });
    }

    /*
    {
      "audience": "uk_KS5",
      "exercisesPerLevel": [
        [
          ...
        ]
      ],
      "id": "3c12a362-6642-4b0b-b294-ebd4812ffdce",
      "metadata": {},
      "name": "diff3",
      "numberOfLevels": 1,
      "subjectId": "b0e062d6-b162-4036-ab50-afccfac6ad9c",
      "type": "FILTER",
      "versionId": "01b94941-5afb-4031-82a9-a08b2c715a7f"
    }
    */

    canRun() {
        return !!this.audienceCtrl.value;
    }

    hasEmptyLevels(showMessage: boolean) {
        let emptyLevels = [];
        if (!this.exercise.exercisesPerLevel || this.exercise.exercisesPerLevel.length === 0) {
            return true;
        }
        for (let spec of this.exercise.exercisesPerLevel) {
            if ((!spec.scripts || spec.scripts.length === 0) && (!spec.courseExercises || spec.courseExercises.length === 0)) {
                emptyLevels.push(spec.level);
            }
        }
        if (emptyLevels.length > 0) {
            if (showMessage) {
                let msg = emptyLevels.length === 1
                    ? `Level ${emptyLevels[0]} has no exercises.`
                    : `Levels ${emptyLevels.join(',')} have no exercises.`;
                this.toastr.error(msg, 'Error');
            }
            return true;
        }
        return false;
    }

    async run(lastPublishedVersion?: any) {
        if (this.hasEmptyLevels(true)) {
            return;
        }
        this.demoExerciseScript = null;
        let self = this;
        let theme = this.course.options && this.course.options.theme
            ? this.course.options.theme
            : 'akit';
        await AlgebraKIT._api.changeTheme(theme);
        return new Promise<void>((resolve, reject) => {
            let demoEx = { ...self.exercise }
            if (lastPublishedVersion) {
                demoEx.exercisesPerLevel = [];
                for (let i = 0; i < self.lastPublishedVersion.exercisesPerLevel.length; i++) {
                    let level = self.lastPublishedVersion.exercisesPerLevel[i];
                    let scripts: CmsExerciseFilterScriptSpec[] = Object.keys(level).map(l => {
                        return {
                            id: l,
                            topicId: "",
                            majorVersion: level[l]
                        }
                    })
                    let libraryScripts: CmsExerciseFilterScriptSpec[] = [];
                    let courseExercises: CmsExerciseFilterScriptSpec[] = []
                    if (self.lastPublishedVersion.exerciseToTypeMap
                        && Object.keys(self.lastPublishedVersion.exerciseToTypeMap).length > 0) {
                        for (let script of scripts) {
                            if (self.lastPublishedVersion.exerciseToTypeMap[script.id] == ExerciseFilterLevelSpecType.CLIENT_SPEC) {
                                courseExercises.push(script);
                            }
                            else {
                                libraryScripts.push(script);
                            }
                        }
                    }
                    else {
                        libraryScripts = scripts;
                    }
                    let levelSpec: CmsExerciseFilterLevelSpec = {
                        level: i,
                        scripts: libraryScripts,
                        courseExercises: courseExercises
                    };
                    demoEx.exercisesPerLevel.push(levelSpec);
                    demoEx.audience = self.lastPublishedVersion.audience;
                }
            }
            self.demoExerciseFilter = demoEx;
            resolve();
        }).then(() => {
            var modal = $('.exercise-demo').first();
            modal.modal('show').on('hide.bs.modal', function (e: any) {
                self.demoExerciseFilter = null;//trigger destroy of widgets
            });
        })
    }

    editExerciseScript(id: string): void {
        var modal = $('.exercise-demo').first();
        if (modal) modal.modal('hide');

        this.router.navigate(['edit/exercise', id]);
    }

    updateTopic(id: string, goalIndex: number, updateLevelSpec: boolean = false): Promise<boolean> {
        return this.topicService.getTopic(id).toPromise()
            .then(goal => {
                this.topicData[goalIndex].topic = goal;
                if (this.attrPerTopicPerLevel) {
                    this.exercise = FilterUtils.setGoalFiltersInExercise(this.exercise, this.attrPerTopicPerLevel);
                }
                this.exercise.topicFilters[goalIndex].topic = { id: goal.id, name: goal.name, containerType: CmsContainerType.FOLDER };
                if (updateLevelSpec) {
                    let newAttrPerTopicPerLevel = FilterUtils.setAttrPerGoalPerLevel(this.exercise, this.topicData);
                    for (let levelIndex = 0; levelIndex < this.attrPerTopicPerLevel.length; levelIndex++) {
                        let levelSpec = this.attrPerTopicPerLevel[levelIndex];
                        //Assign the ids for the wizard
                        newAttrPerTopicPerLevel[levelIndex].id = levelSpec.id;
                        for (let topicIndex = 0; topicIndex < levelSpec.topicSpecs.length; topicIndex++) {
                            let topicSpec = levelSpec.topicSpecs[topicIndex];
                            newAttrPerTopicPerLevel[levelIndex].topicSpecs[topicIndex].id = topicSpec.id;
                            if (topicIndex === goalIndex - 1) {
                                topicIndex++; //Skip the new topic
                            }
                        }
                    }
                    this.attrPerTopicPerLevel = newAttrPerTopicPerLevel;
                }
                return this.exerciseScriptService.getExerciseScriptsForTopic(goal, true).toPromise();
            })
            .then(exercises => {
                //Get current versioning information
                return this.exerciseScriptService.getLatestVersionNumbers(exercises)
                    .then(versions => {
                        return exercises.map(ex => {
                            ex.latestVersionNumbers = versions[ex.id];
                            return ex;
                        });
                    });
            })
            .then(exercises => {
                let showDeprecated = this.userService.canEditLibrary();
                exercises = exercises.filter(exercise => exercise.state !== "DRAFT" //remove draft exercises
                    && (showDeprecated || exercise.state !== "DEPRECATED") //remove deprecated exercises (if applicable)
                );
                let goal = this.topicData[goalIndex].topic;
                this.topicData[goalIndex].exercisesAKIT = Sorting.sortItemsByDifficulty(exercises, goal.attributeAssignments, 1);
                return true;
            })
            .catch(err => {
                console.log(err);
                this.showErrorMessage(err, "Error occurred while retrieving learning goal " + id);
            }) as Promise<boolean>;

    }

    isExerciseSelected(ex: CmsExerciseScript, goalSpec: TopicSpec, level: number) {
        //check if all attributes of exercise are allowed
        for (var ii = 0; ii < ex.attributeAssignments.length; ii++) {
            let exAttrAssignment = ex.attributeAssignments[ii];
            let attrSpec = goalSpec.attributeSpecs.find((attrAssignment: CmsTopicFilterLevelAttributeAssignement) => {
                return attrAssignment.id === exAttrAssignment.id
            });
            if (attrSpec && attrSpec.attributeAssignmentType === CmsAttributeAssignmentType.NONE) {
                return false;
            }
        }
        //check if at least one 'FORCE' attribute is present in the exercise
        let forcedAttrs = goalSpec.attributeSpecs.filter(attr => attr.attributeAssignmentType === CmsAttributeAssignmentType.FORCE);
        if (forcedAttrs.length > 0) {
            for (let forcedAttr of forcedAttrs) {
                if (ex.attributeAssignments.some(attrAssignment => attrAssignment.id === forcedAttr.id)) {
                    return true;
                }
            }
            return false;
        }
        return true;
    }

    //required to prevent that breadcrumb is destroyed and created whenever a new parent is chosen
    indexTrackId(index: number, goal: CmsTopic) {
        return index;
    }

    setMetadataValid(valid: boolean) {
        this.metadataValid = valid;
    }

    refreshExerciseScriptTest() {
        this.scriptPreviewComponent.refresh();
    }

    refreshExerciseTest() {
        this.exercisePreviewComponent.generate();
    }

    tryGetOldValue() {
        if (this.ready()) {
            this._getFormValue();
            this.oldExercise = TypeHelpers.clone(this.exercise);
        }
    }

    canDeactivate(): boolean {
        if (this.readOnly) {
            return true;
        }
        this._getFormValue();
        let oldEx = { ...this.oldExercise };
        let newEx = { ...this.exercise };
        delete oldEx.commitMessage;
        delete oldEx.updateType;
        delete newEx.commitMessage;
        delete newEx.updateType;
        this.changed = !TypeHelpers.itemsEqual(oldEx, newEx);
        return this.leaveMessageAlreadyGiven || !this.changed;
    }

    approveButtonVisible() {
        return this.canApprove() && this.exercise.state == 'DRAFT' && this.canDeactivate() && this.form.valid && this.metadataValid;
    }

    ready() {
        return this.exerciseReady && this.viewReady && this.topicsReady;
    }

    compareVersionNumbers() {
        if (!this.versionInfo || !this.lastPublishedRetrieved) {
            return -1;
        }
        if (!this.lastPublishedVersion) {
            return 1;
        }
        let compareResult = TypeHelpers.compareVersionNumbers(
            this.versionInfo.majorVersion,
            this.versionInfo.minorVersion,
            this.lastPublishedVersion.majorVersion,
            this.lastPublishedVersion.minorVersion
        );
        return compareResult;
    }

    getPublishButtonTitle() {
        if (!this.canPublish()) {
            return 'Please save the exercise first';
        }
        if (this.compareVersionNumbers() === 0) {
            return 'This version is already published';
        }
        if (this.compareVersionNumbers() === -1) {
            return 'A newer version of this exercise has already been published';
        }
        return 'Publish this exercise';
    }

    init(exercise: CmsExerciseFilter, scheduleRefesh?: boolean) {
        this.leaveMessageAlreadyGiven = false;
        this.exerciseReady = false;
        this.topicsReady = false;

        if (scheduleRefesh) {
            this.refreshTimer = this.exerciseService.scheduleRefresh(exercise.id);
        }

        this.exercise = exercise;
        this.getLastPublish();
        const coursePromise = this.courseService.getCourse(exercise.course.id).toPromise().then(course => {
            this.course = course;
            this.audiences = course.audiences;
            this.form.patchValue({
                name: exercise.name,
                title: exercise.title,
                audience: exercise.audience,
                description: exercise.description,
                comment: exercise.comment
            })
            this.getVersioningData();
            this.exerciseReady = true;
            this.tryGetOldValue();
        });

        Promise.all([this.akitVersionsPromise, coursePromise]).then(() => {
            this.readOnly = this.akitVersionMismatch;
        })

        for (let levelSpec of exercise.exercisesPerLevel) {
            if (levelSpec.level == null) {
                levelSpec.level = exercise.exercisesPerLevel.indexOf(levelSpec) + 1;
            }
            if (!levelSpec.courseExercises) {
                levelSpec.courseExercises = [];
            }
            if (!levelSpec.scripts) {
                levelSpec.scripts = [];
            }
        }

        this.subjectService.getSubject(this.exercise.subject.id)
            .toPromise().then(subject => this.subject = subject);

        this.topicData = [];
        let updatePromises: Promise<boolean>[] = this.exercise.topicFilters.map((filter, index) => {
            this.topicData.push({
                topic: null,
                exercisesAKIT: [],
                exercisesCourse: []
            });
            return this.updateTopic(filter.topic.id, index);
        })
        Promise.all(updatePromises).then(() => {
            this.attrPerTopicPerLevel = FilterUtils.setAttrPerGoalPerLevel(this.exercise, this.topicData)
            this.topicsReady = true;
            this.tryGetOldValue();
        });

        // show extended settings if they are present
        if (this.exercise) {
            this.generalExtend = (this.exercise.comment != null && this.exercise.comment.length > 0)
                || (this.exercise.description != null && this.exercise.description.length > 0)
                || (this.exercise.title != null && this.exercise.title.length > 0);
        }
    }

    isEmptyMetadata() {
        return !this.exercise || !this.exercise.metadata || this.exercise.metadata.length == 0;
    }

    getEditorIcon() {
        return `<span class="fa fa-edit"></span>`;
    }

    getOptionsForTab(elmIndex: number): StackedTabOption[] {
        let result = <StackedTabOption[]>[];
        result.push(new StackedTabOption('remove', 'Remove', 'fa-times'));
        return result;
    }

    onOptionEvent(elmIndex: number, optionId: string) {
        switch (optionId) {
            case 'remove':
                this.deleteLevel(elmIndex);
                break;
        }
    }

    getMode(level: number) {
        return this.modes[level] || FilterEditMode.LIST;
    }

    setMode(level: number, mode: FilterEditMode) {
        this.modes[level] = mode;
        this.currentMode = this.getMode(level);
    }

    levelChanged(level: number) {
        this.currentActiveLevel = level;
        this.currentMode = this.getMode(level);
    }

    useBrowser(level: number) {
        this.setMode(level, FilterEditMode.BROWSE);
    }

    browserCancelled(level: number) {
        this.setMode(level, FilterEditMode.LIST);
    }

    useWizard(level: number) {
        this.setWizardIds();
        this.oldAttrPerTopicPerLevel = TypeHelpers.clone(this.attrPerTopicPerLevel);
        this.setMode(level, FilterEditMode.WIZARD);
    }

    wizardCancelled(level: number) {
        this.restoreOldAttributeAssignments();
        this.setMode(level, FilterEditMode.LIST);
        this.unsetWizardIds();
    }

    exercisesAdded(e: CmsExerciseFilterLevelSpec) {
        for (let script of this.exercise.exercisesPerLevel[e.level].scripts) {
            if (e.scripts.find(s => s.id === script.id)) {
                continue;
            }
            e.scripts.push(script);
        }
        for (let courseEx of this.exercise.exercisesPerLevel[e.level].courseExercises) {
            if (e.courseExercises.find(s => s.id === courseEx.id)) {
                continue;
            }
            e.courseExercises.push(courseEx);
        }
        this.exercise.exercisesPerLevel[e.level] = e;
        this.setMode(e.level, FilterEditMode.LIST);
    }

    wizardReplace(e: CmsExerciseFilterLevelSpec) {
        this.exercise.exercisesPerLevel[e.level] = e;
        this.setMode(e.level, FilterEditMode.LIST);
    }

    goToTopics() {
        this.activeTab = "topics";
        this.topicsPane.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    setActiveTab(tab: string) {
        this.activeTab = tab;
    }

    restoreOldAttributeAssignments() {
        for (let oldLevelSpec of this.oldAttrPerTopicPerLevel) {
            let currentLevelSpec = this.attrPerTopicPerLevel.find(l => l.id === oldLevelSpec.id);
            if (!currentLevelSpec) {
                continue;
            }
            for (let oldTopicSpec of oldLevelSpec.topicSpecs) {
                let currentTopicSpec = currentLevelSpec.topicSpecs.find(t => t.id === oldTopicSpec.id);
                if (!currentTopicSpec) {
                    continue;
                }
                currentTopicSpec.attributeSpecs = oldTopicSpec.attributeSpecs;
            }
        }
        //All topicSpecs without ids have been created after the wizard was started 
        //so all their attribute assignments can be cleared
        for (let levelSpec of this.attrPerTopicPerLevel) {
            for (let topicSpec of levelSpec.topicSpecs) {
                if (topicSpec.id) {
                    continue;
                }
                for (let attr of topicSpec.attributeSpecs) {
                    attr.attributeAssignmentType = CmsAttributeAssignmentType.NONE;
                }
            }
        }
    }

    setWizardIds() {
        for (let levelSpec of this.attrPerTopicPerLevel) {
            levelSpec.id = UUID.UUID();
            for (let topicSpec of levelSpec.topicSpecs) {
                topicSpec.id = UUID.UUID();
            }
        }
    }

    unsetWizardIds() {
        for (let levelSpec of this.attrPerTopicPerLevel) {
            delete levelSpec.id;
            for (let topicSpec of levelSpec.topicSpecs) {
                delete topicSpec.id;
            }
        }
    }

    ngOnInit() {
        this.readOnly = true;
        this.contentVersionService.getContentVersion().then(contentVersion => this.currentVersion = contentVersion);
        this.appProfileService.getSetting('refArrangementWarning').then((value) => {
            this.showEditingWarning = value;
        });
        this.appProfileService.getSetting('publishingWarning').then((value) => {
            this.publishingWarning = value;
        });

        this.akitVersionsPromise = this.akitVersionsService.currentVersion.then(version => {
            this.akitVersion = version
        });

        this.route.queryParams
            .subscribe((params: Params) => {
                this.onCloseUrl = params['onCloseUrl'];
                console.log(this.onCloseUrl);
            });

        this.route.data
            .subscribe((data: { exercise: CmsExerciseFilter, course: CmsCourse }) => {
                this.readOnly = true;
                if (data.exercise) {
                    this.contentVersionService.showVersionAlert();
                    this.init(data.exercise as CmsExerciseFilter, true);
                }
            });

        subscriptions.push(
            this.audienceCtrl.valueChanges
                .subscribe(id => { this.exercise.audience = id; })
        );

    }

    ngAfterViewInit(): void {
        this.viewReady = true;
        this.tryGetOldValue();
    }

    ngOnDestroy() {
        for (let s of subscriptions) s.unsubscribe();
        this.exerciseService.descheduleRefresh(this.refreshTimer);
    }

    _setGoalFiltersInExercise(): void {
        for (let level = 0; level < this.exercise.numberOfLevels; level++) {
            let levelSpec = this.attrPerTopicPerLevel[level];
            for (let goalIndex = 0; goalIndex < levelSpec.topicSpecs.length; goalIndex++) {
                let goalSpec = levelSpec.topicSpecs[goalIndex];
                this.exercise.topicFilters[goalIndex].levels[level].attributeAssignments = goalSpec.attributeSpecs;
            }
        }
    }

    _getFormValue(): void {
        let obj = this.form.value;
        this.exercise.title = obj.title;
        this.exercise.name = obj.name;
        this.exercise.comment = obj.comment;
        this.exercise.description = obj.description;
        this.exercise = FilterUtils.setGoalFiltersInExercise(this.exercise, this.attrPerTopicPerLevel);
    }

    //precondition: goals loaded for all goalFilters
    _setAttrPerGoalPerLevel() {
        this.attrPerTopicPerLevel = [];
        for (var ll = 0; ll < this.exercise.numberOfLevels; ll++) {
            this.attrPerTopicPerLevel.push(this._getLevelSpec(ll));
        }
    }

    _getLevelSpec(level: number): LevelSpec {
        let levelSpec: LevelSpec = { topicSpecs: [], levelIndex: level };
        for (let ii = 0; ii < this.exercise.topicFilters.length; ii++) {
            let topicFilter = this.exercise.topicFilters[ii];
            let topic = this.topicData[ii].topic;
            if (topic.attributeAssignments == null) topic.attributeAssignments = [];
            let topicSpec: TopicSpec = {
                topic: topic,
                attributeSpecs: [] as CmsTopicFilterLevelAttributeAssignement[]
            };
            levelSpec.topicSpecs.push(topicSpec);

            let selectedAttrs: CmsTopicFilterLevel = topicFilter.levels[level];
            for (let attr of topic.attributeAssignments) {
                topicSpec.attributeSpecs.push({
                    id: attr.id,
                    attribute: attr.attribute,
                    topic: RefConverter.toTopicRef(topic),
                    difficulty: attr.difficulty,
                    attributeAssignmentType: this._getAttributeType(attr.id, selectedAttrs)
                });
            }
        }
        return levelSpec;
    }

    _getAttributeType(attrId: string, selectedAttr: CmsTopicFilterLevel): CmsAttributeAssignmentType {
        for (var ii = 0; ii < selectedAttr.attributeAssignments.length; ii++) {
            let attr: CmsTopicFilterLevelAttributeAssignement = selectedAttr.attributeAssignments[ii];
            if (attr.id === attrId) return attr.attributeAssignmentType;
        }
        return CmsAttributeAssignmentType.NONE;
    }


}
