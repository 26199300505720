<div class='syntax-row-expand'>
    <hr />
    <div *ngIf="existsTags()" class='row'>
        <div class="tags col-xs-12">
            <span *ngIf="isDeprecated()" class="deprecated-tag tag">deprecated</span>
            <span *ngIf="isInternal()" class="internal-tag tag">internal</span>
            <span *ngIf="hasEvaluationTemplate()" class="evaltemplate-tag tag">evaluation</span>
            <div *ngIf="syntax.tags!==''">
                <div *ngFor="let tag of getTags(syntax.tags)">
                    <span class="tag regular-tag">{{tag}}</span>
                </div>
            </div>
            <span *ngIf="canEditTopicsScripts() && syntax.properties['ops-transparant']=='true'"
                class="transparant-tag tag">transparant</span>
            <span *ngIf="syntax.properties['ops-commass']=='true'" class="commass-tag tag">commass</span>
        </div>
    </div>
    <div class='detailed-syntax-table row'>
        <div *ngIf="syntax['infix-syntax']!==''" class='col-xs-12 syntax-notations'>
            <div class='col-xs-12'>
                <h4>Notation</h4>
            </div>
            <div *ngIf="'a'=='b'" class="col-xs-12">
                <div class='syntax-def col-xs-2' [tooltip]="'Prefix syntax'">Syntax:</div>
                <div class='syntax-decl col-xs-10' [innerHtml]="syntax.syntax"></div>
            </div>
            <div *ngIf="syntax['infix-syntax']!==''" class="col-xs-12">
                <div class='syntax-def col-xs-2' [tooltip]="'Infix syntax'" >Infix:</div>
                <div class='syntax-decl syntax-infix col-xs-10' [innerHtml]="syntax['infix-syntax']"></div>
            </div>
        </div>

        <div *ngIf="syntax.usages !== ''" class='col-xs-12 syntax-usages'>
            <div class='col-xs-12'>
                <h4>Usages</h4>
            </div>
            <div *ngFor="let usage of syntax.usages" class="col-xs-12">

                <div [ngClass]="{'col-xs-12': true, 'new-usage': (usage['new-usage'] == 'true')}">
                    <div class="col-xs-12">
                        <div *ngIf="usage['new-usage'] == 'true'" class='new-usage-descr'>
                            <span class='usage' [tooltip]="'Usage description'" [innerHtml]="usage['usage']"></span>
                            <span class='usage-text' [innerHtml]="usage['usage-text']"></span>
                        </div>
                        <syntax-usage-example [usage]=usage></syntax-usage-example>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="showMatchingFilter()" class='col-xs-12 syntax-properties'>
            <div class='col-xs-12'>
                <h4>Properties</h4>
            </div>
            <div class="col-xs-12">
                <div class='syntax-def  col-xs-2' [tooltip]="'Matching filter'">Matching filter:</div>
                <div class='syntax-decl col-xs-10'>{{syntax.properties['ops-matchingfilter']}}</div>
            </div>
        </div>
        <div *ngIf="visibleAssocArgs(syntax['associative-args'])" class='col-xs-12 syntax-associative'>
            <div class='col-xs-12'>
                <h4>Associative arguments</h4>
            </div>
            <div *ngFor="let assocargs of syntax['associative-args']" class="col-xs-12">
                <syntax-associative-example *ngIf="showAssocArg(assocargs.internal)"
                    [associative]=assocargs></syntax-associative-example>
            </div>
        </div>

    </div>
</div>