<div *ngIf="usage['example-in'] !== ''">
    <div class='row'>
        <div class='col-xs-12 col-md-10'>
            <hr />
        </div>
    </div>
    <div class='col-xs-12'>
        <div class='col-xs-12 col-md-9 usage-example'>
            <div class='example-text' [innerHtml]="usage['example-text']"></div>
            <div class='col-xs-12'>
                <div class='col-xs-2 usage-io'>In:</div>
                <div class='col-xs-10 usage-mono' [innerHtml]="usage['example-in']"></div>
            </div>
            <div *ngIf="usage['example-out'] !== ''" class='col-xs-12'><!--&& usage['example-out'] !== usage['example-in']-->
                <div class='col-xs-2 usage-io'>Out:</div>
                <div class='col-xs-10 usage-mono' [innerHtml]="usage['example-out']"></div>
            </div>
            <div *ngIf="usage['reduced-out'] !== ''" class='col-xs-12'>
                <div class='col-xs-2 usage-io'>Reduced:</div>
                <div class='col-xs-10 usage-mono' [innerHtml]="usage['reduced-out']"></div>
            </div>
            <div *ngIf="usage['rendered-out'] !== ''" class='col-xs-12'>
                <div class='col-xs-2 usage-io'>Rendered:</div>
                <div class='col-xs-10 usage-mono' [innerHtml]="usage['rendered-out']"></div>
            </div>
        </div>
    </div>
</div>