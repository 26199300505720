
import {filter} from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CmsFolder, CmsAkitResourceRef, CmsExerciseReference, CmsExerciseScript, CmsSubject, CmsCourse } from '../../types/navigator.types';
import { UserService } from '../../../security/services/user.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AudienceSpec, NameIdPair } from '../../types/cms-metadata.types';
import { ToastrService } from 'ngx-toastr';
import { ExerciseService } from '../../services/exercise.service';
import { CourseService } from '../../services/course.service';
import { SubjectService } from '../../services/subject.service';
import { MetadataService } from '../../../algebrakit/services/metadata.service';
import { BehaviorSubject } from 'rxjs';
import { getExtendedToastOption } from '../../../app/options/options';
import { TypeHelpers } from '../../util/type-helpers';

declare let $: any;

/**
 * A topic-leaf is a 'file' in the navigation system, which corresponds to an
 * exercise. The model is defined by class of type CmsLeaf. Classes for the various 
 * types of exercises are derived from CmsLeaf
 */
@Component({
    selector: 'exercise-client-spec-exporter',
    templateUrl: './exercise-client-spec-exporter.component.html',
    styleUrls: ['./exercise-client-spec-exporter.component.css']
})
export class ExerciseClientSpecExporter {

    @Input() selectedExerciseScripts: NameIdPair[];

    @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

    form: FormGroup;

    courseCtrl = new FormControl('', Validators.required);
    audienceCtrl = new FormControl('', Validators.required);
    keepIdsCtrl = new FormControl('');
    titleCtrl = new FormControl('');
    nameCtrl = new FormControl('');

    audiences: AudienceSpec[];

    courses: CmsCourse[];
    course$: BehaviorSubject<CmsCourse> = new BehaviorSubject(null);
    subject: CmsSubject;

    refreshTimer: any;

    retrievingCourses: boolean = false;

    constructor(
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private exerciseService: ExerciseService,
        private courseService: CourseService,
        private subjectService: SubjectService,
        private metadataService: MetadataService,
        private userService: UserService
    ) {
        this.form = this.formBuilder.group({
            audience: this.audienceCtrl,
            keepIds: this.keepIdsCtrl,
            title: this.titleCtrl,
            name: this.nameCtrl
        })
    }

    close() {
        this.onClose.emit();
    }

    canKeepIds() {
        return this.userService.getUser().canEditLibrary();
    }

    test() {
        console.log(this.keepIdsCtrl.value);
    }

    commit() {
        let title = this.titleCtrl.value || "";
        let name = this.nameCtrl.value || "";
        let selectedExerciseScriptIds = this.selectedExerciseScripts.map(s => s.id);
        this.exerciseService.exportClientSpecs(selectedExerciseScriptIds, this.subject, this.audienceCtrl.value, title, name, this.keepIdsCtrl.value)
            .then(() => {
                let course = this.course$.value;
                let messageBody = 'Exercise specification(s) created successfully and saved in course ';
                let baseUrl = $('base').attr('href');
                messageBody += this.subject.id !== course.rootSubject.id
                    ? `<i>${this.subject.course.name}</i>, subject <a style="text-decoration: underline;" target="_blank" href="${baseUrl}/subject/${this.subject.id}"><i><strong>${this.subject.name || this.subject.id}</strong></i></a>.`
                    : `<a style="text-decoration: underline;" target="_blank" href="${baseUrl}/subject/${this.subject.id}"><i><strong>${this.subject.course.name}</strong></i></a>.`;
                this.toastr.success(messageBody, 'Success', getExtendedToastOption());
                this.close();
            })
            .catch((err: any) => {
                console.log(err);
                this.toastr.error("Error occurred while creating the exercise specification(s)" + (err.message ? ": " + err.message : ""), 'Error');
            });
    }

    setSubject(id: string): void {
        this.subjectService.getSubject(id).toPromise()
            .then((subject: CmsSubject) => {
                this.subject = subject;
            })
            .catch(err => {
                console.log(err);
                this.toastr.error("Error occurred while retrieving subject", 'Error');
            });
    }

    ngOnInit() {
        this.retrievingCourses = true;
        this.courseService.getCoursesList()
            .toPromise()
            .then((arr: CmsCourse[]) => {
                this.courses = arr.filter(course => course.id !== 'algebrakit');
                this.courses.unshift(TypeHelpers.generateDummyListCourse());
                this.retrievingCourses = false;
                this.courseCtrl.setValue(this.courses[0].id);
            }).catch((err: any) => {
                console.log(err);
                this.toastr.error('Could not get your courses from the server', 'Error');
                this.retrievingCourses = false;
            });

        this.courseCtrl.valueChanges.pipe(
            filter(value => !!value))
            .subscribe(value => {
                this.courseService.getCourse(value)
                    .toPromise()
                    .then(course => {
                        this.course$.next(course);
                        this.subjectService.getRootSubject(value)
                            .toPromise()
                            .then(subject => this.subject = subject);
                        this.metadataService.getForCourse(course).then((audiences) => {
                            this.audiences = audiences
                        });
                    });
            });
    }

}



