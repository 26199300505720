<div class="helpitem-note">
    <div class="note-icon-container hidden-xs col-sm-1 col-lg-1">
        <span *ngIf="image != 'noimage'" class="icon">
            <i [ngClass]="'fa ' + image" aria-hidden="true"></i>
        </span>
    </div>

    <div class="note-text-container col-xs-12 col-sm-11 col-lg-11">
        <div [ngClass]="{'helpnote-title': true}"><ng-content select=".title"></ng-content></div>
        <div [ngClass]="{'helpnote-description': true}"><ng-content select=".description"></ng-content></div>
    </div>
</div>