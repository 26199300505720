<div>
    <p *ngIf="noExercises()">
        No exercises.
    </p>
    <ng-container *ngIf="scriptMap && getAllScripts().length > 0">
        <h4>Library Exercises</h4>
        <div *ngFor="let topic of topics; let i = index;">
            <div class="row">
                <div class='col-sm-7 col-xs-12'>
                    <h5>{{topic.name}}</h5>
                </div>
                <div class='col-sm-5 col-xs-12'>
                    <div class="goal-attr-label-container display-sm"
                        *ngIf="this.attributeAssignmentMap[topic.id] && this.attributeAssignmentMap[topic.id].length > 0">
                        <div *ngFor='let attr of this.attributeAssignmentMap[topic.id]' class='goal-attr-label-wrapper'>
                            <div class='goal-attr-label' [ngStyle]='getColorForTopicAttribute(attr)'
                                [title]="attr.attribute.name">{{attr.attribute.name
                            | truncate:[15]}}</div>
                        </div>
                        <div class='goal-attr-label-bar'></div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="ready">
                <div class="row select-all-row">
                    <div class='col-sm-7 col-xs-12 exercise-list-entry'>
                        <input type="checkbox" (change)="selectAll(topic.id, $event)" />
                    </div>
                </div>
                <div class="row exercise-list-row" *ngFor="let script of scriptsPerTopic[topic.id]; let i = index">
                    <div class='col-sm-7 col-xs-12 exercise-list-entry'>
                        <input type="checkbox" [checked]="isSelected(topic.id, i)"
                            (change)="selectedExerciseChanged(topic.id, i, $event)" />
                        <span class="clickable"
                            (click)="onExerciseClick.emit(scriptMap[script.id])">{{scriptMap[script.id].name}}</span>
                        <span class="label label-danger" title="Deprecated"
                            *ngIf="scriptMap[script.id].state == 'DEPRECATED'">
                            <i class="fa fa-minus-circle"></i>
                            <span>Deprecated</span>
                        </span>
                    </div>
                    <div class="col-sm-5 label-container-sm display-sm" *ngIf='ready'>
                        <div *ngFor='let attr of this.attributeAssignmentMap[topic.id]' class='label-wrapper'>
                            <div class='attr-label' [ngStyle]="getColorForScriptAttribute(attr, scriptMap[script.id])">
                                &nbsp;</div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <br />
            <br />
        </div>
    </ng-container>
    <ng-container *ngIf="ready && clientSpecMap && getAllClientSpecs().length > 0">
        <h4>Course Exercises</h4>
        <div class="row select-all-row">
            <div class='col-sm-7 col-xs-6 exercise-list-entry'>
                <input type="checkbox" (change)="selectAllClientSpecs($event)" />
            </div>
        </div>
        <ng-container *ngFor="let subject of getSubjectsClientSpecs()">
            <div class="row">
                <div class='col-xs-12'>
                    <h5>{{subject.name}}</h5>
                </div>
            </div>
            <div class="row exercise-list-row" *ngFor="let exercise of subject.exercises">
                <div class='col-xs-6 exercise-list-entry'>
                    <input type="checkbox" [checked]="isClientSpecSelected(exercise.id)"
                        (change)="selectedClientSpecsChanged(exercise.id, $event)" />
                    <span class="clickable"
                        (click)="onExerciseClientSpecClick.emit(clientSpecMap[exercise.id])">{{exercise.name}}</span>
                </div>
                <div class="col-xs-6">
                    {{getSubjectName(exercise.subject)}}
                </div>
            </div>
        </ng-container>
    </ng-container>
    <div class="row">
        <hr class="filter-list-thick-divider" />
    </div>
    <div class="exercise-filter-spec-controls" *ngIf="showButtons">
        <button class="btn btn-md btn-default" type="button" (click)="switchToBrowser.emit()">
            <i class="fa fa-plus"></i>
            <span>Add Exercises</span>
        </button>
        <button *ngIf="canRemove" class="btn btn-md btn-default" type="button" (click)="removeSelected()">
            <i class="fa fa-times"></i>
            <span>Remove selected</span>
        </button>
        <button class="btn btn-md btn-default" type="button" (click)="switchToWizard.emit()">
            <i class="fa fa-magic"></i>
            <span>Wizard</span>
        </button>
    </div>
</div>