import {Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter} from '@angular/core';

interface Entry {
    name: string;
    langMap: {[lang:string]:string};
}

@Component({
    selector: 'spec-collapsable',
    templateUrl: './spec-collapsable.component.html',
    styleUrls: ['./spec-collapsable.component.css']
})
export class SpecCollapsableComponent {
    @Input() showDeleteButton: boolean;
    @Input() showAddButton: boolean;
    @Input() showMoveUpButton: boolean;
    @Input() showMoveDownButton: boolean;
    @Input() ref: string;  //an optional reference to allow querying the right component by the parent element
    
    @Input() startExpanded: boolean = false;

    @Output() onRemove: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onAdd: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onMove: EventEmitter<-1 | 1> = new EventEmitter<-1 | 1>();
    @Output() onShowContent: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() bgColor: string = 'rgb(240,256,256)';
    @Input() borderColor: string = 'lightgray';
    @Input() dividerColor: string = 'lightblue';

    @ViewChild('content', {static: false}) contentChild: ElementRef;

    expanded : boolean;

    ngOnInit() {
        this.expanded = !!this.startExpanded;
    }

    removeClicked() {
        this.onRemove.emit(true);
    }
    
    addClicked() {
        this.onAdd.emit(true);
    }

    moveUpClicked() {
        this.onMove.emit(1);
    }

    moveDownClicked() {
        this.onMove.emit(-1);
    }

    public getRef() {
        return this.ref;
    }
    
    public setCollapsed(b:boolean) {
        this.expanded = !b;
        if(!b) this.onShowContent.emit(true);

        if(b) this.collapseAnimation();
        else this.expandAnimation();
    }

    collapseAnimation() {
        let element = this.contentChild.nativeElement;

        // get the height of the element's inner content, regardless of its actual size
        let sectionHeight = element.scrollHeight;
        if(sectionHeight>0) {
            // temporarily disable all css transitions
            var elementTransition = element.style.transition;
            element.style.transition = '';
            
            // on the next frame (as soon as the previous style change has taken effect),
            // explicitly set the element's height to its current pixel height, so we 
            // aren't transitioning out of 'auto'
            requestAnimationFrame(function() {
                element.style.height = sectionHeight + 'px';
                element.style.transition = elementTransition;
                
                // on the next frame (as soon as the previous style change has taken effect),
                // have the element transition to height: 0
                requestAnimationFrame(function() {
                    element.classList.add('collapsed');
                    // element.style.height = 0 + 'px';
                    element.style.height = '';
                });
            });
        } else {
            // no transition possible
            element.classList.add('collapsed');
        }
    }

    expandAnimation() {
        let element = this.contentChild.nativeElement;
        
        // get the height of the element's inner content, regardless of its actual size
        let sectionHeight = element.scrollHeight;
        if(sectionHeight>0) {
            // css transition is not possible when height=auto
            // so explicitly set the height and remove it again when the transition has finished 

            // have the element transition to the height of its inner content
            element.style.height = sectionHeight + 'px';

            let func = function(e:any) {
                // remove this event listener so it only gets triggered once
                element.removeEventListener('transitionend', func);
                
                // remove "height" from the element's inline styles, so it can return to its initial value
                element.style.height = null;
                element.classList.remove('collapsed');
            };

            // when the next css transition finishes (which should be the one we just triggered)
            element.addEventListener('transitionend', func);
        } else {
            //no transition possible
            element.classList.remove('collapsed');
        }
    }

}

