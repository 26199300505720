import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UserService } from './services/user.service';
import { UserResolverService } from './services/user-resolver.service';
import { UserLoginComponent } from './components/user-login/user-login.component';
import { SecurityRoutingModule } from './security.routing';
import { InviteRegisterComponent } from './components/invite-register/invite-register.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MetadataService } from '../algebrakit/services/metadata.service';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { TooltipModule } from '../app/components/common/ui/tooltip/tooltip.module';
import { ActivateUserComponent } from './components/activate-user/activate-user.component';
import { PasswordErrorsComponent } from './components/password-errors/password-errors.component';

@NgModule({
  imports: [CommonModule, BrowserModule, FormsModule, ReactiveFormsModule, SecurityRoutingModule, HttpClientModule, HttpClientJsonpModule,
    TooltipModule],
  declarations: [UserLoginComponent, InviteRegisterComponent, ActivateUserComponent,PasswordErrorsComponent],
  bootstrap: [],
  providers: [UserService, UserResolverService, MetadataService],
  exports: [UserLoginComponent]
})
export class SecurityModule { }


