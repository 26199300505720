<div class="collapsable" [ngClass]="{open:visible}" [ngStyle]="getOpenStyle(visible)">
    <div class="header" (click)="visible=!visible" [ngStyle]="getDividerStyle()">
        <span class="fa fa-eye" [hidden]="visible"></span>
        <span class="fa fa-eye-slash" [hidden]="!visible"></span>
        <span class="title">{{heading}}</span>
    </div>
    <div class="content" [hidden]="!visible">
        <ng-content></ng-content>
    </div>
</div>
