<div class='topic-navigator cms-navigator'>
    <h1>Library</h1>
    <div *ngIf='currentFolder | async'>
        <breadcrumb [folder]='currentFolder | async' (parentChange)='setParent($event)' [activated]='"force"'
            [homeLinkHref]="'/topics'">
        </breadcrumb>

        <div class="button-container">
            <div class='actions-button navigator-button' *ngIf="!inTrash() && browseMode === 'EDIT'">
                <div class="dropdown ">
                    <button class="btn btn-default dropdown-toggle create-button" type="button" id="dropdownMenu1"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        <span>Actions</span>
                        <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">
                        <li>
                            <a (click)="copyExercises()">
                                <i class="fa fa-copy"></i>
                                <span>Copy exercises</span>
                            </a>
                        </li>
                        <li>
                            <a (click)="moveExerciseScripts()" [ngClass]="{'clickable':browseMode=='EDIT'}">
                                <i class="fa fa-share"></i>
                                <span>Move</span>
                            </a>
                        </li>
                        <li>
                            <a (click)="createReferences()" [ngClass]="{'clickable':browseMode=='EDIT'}">
                                <i class="fa fa-link"></i>
                                <span>Create reference exercise</span>
                            </a>
                        </li>
                        <li>
                            <a (click)="createClientSpecs()" [ngClass]="{'clickable':browseMode=='EDIT'}">
                                <i class="fa fa-share"></i>
                                <span>Create editable copy</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class='create-button navigator-button'
                *ngIf="canEditTopicsScripts() && !inTrash() && browseMode === 'EDIT'">
                <div class="dropdown">
                    <button class="btn btn-default dropdown-toggle create-button" type="button" id="dropdownMenu1"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        <span>New...</span>
                        <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">
                        <li>
                            <a (click)="createExercise()" class="clickable">
                                <i class="fa fa-list-ul"></i>
                                <span>Exercise</span>
                            </a>
                        </li>
                        <li>
                            <a (click)="createTopic()" class="clickable">
                                <i class="fa fa-folder"></i>
                                <span>Topic</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <move-exercise-scripts [selectedExerciseScripts]="scriptsToMove" (onClose)="exportersClose()"
            (onSuccess)="refresh()" *ngIf="moveScriptsDialogVisible">
        </move-exercise-scripts>
        <move-folder [selectedFolder]="topicToMove" [folderType]="'TOPIC'" (onClose)="exportersClose()"
            (onSuccess)="refresh()" *ngIf="moveTopicDialogVisible">
        </move-folder>

        <exercise-reference-exporter [selectedExerciseScripts]="exportScripts" (onClose)="exporterClose()"
            *ngIf="referenceExporterVisible"></exercise-reference-exporter>
        <exercise-client-spec-exporter [selectedExerciseScripts]="exportScripts" (onClose)="exporterClose()"
            *ngIf="specExporterVisible"></exercise-client-spec-exporter>

        <!-- toggle trash or root -->
        <div *ngIf="!referenceExporterVisible && !specExporterVisible">
            <search-field #librarySearchField (onSearchTextChanged)="search($event)" *ngIf="!inTrash()"></search-field>

            <busy-gears *ngIf="busy"></busy-gears>

            <div *ngIf="searching">
                <div *ngIf="!busy && searchExercises.length == 0">
                    No results.
                </div>

                <folder *ngFor='let item of searchTopics' [folder]="item" [searchHighlight]="searchString"
                    [readOnly]="browseMode=='VIEW'"></folder>
                <br>
                <h4 *ngIf="searchExercises?.length > 0">Exercises found</h4>
                <div class='row label-container search-header' *ngIf='searchExercises?.length > 0'>
                    <div class="topic-check-all">
                        <input class="clickable" type="checkbox" [checked]="allSelected()"
                            (change)="checkAll($event)" />
                    </div>
                </div>

                <ng-container *ngFor='let item of searchExercises'>
                    <topic-leaf-search *ngIf="showDeprecated || item.state !== 'DEPRECATED'"
                        (onChecked)="exerciseSelectedChanged($event)"
                        [selectable]="incompatibleExercisesSelectable || !isVersionMismatch(item)"
                        [selected]="selectedExercises[item.id]" [leaf]="item" [topic]="currentFolder | async"
                        [searchHighlight]="searchString" [readOnly]="browseMode=='VIEW'"
                        [hideState]="!canEditTopicsScripts()" [errorBadgeTitle]="getVersionMismatchError(item)"
                        [enabled]="!isVersionMismatch(item)"></topic-leaf-search>
                </ng-container>
            </div>

            <busy-gears *ngIf="deleteChecking"></busy-gears>
            <delete-check *ngIf="exerciseToDelete && deleteCheckResult && deleteCheckResult.length > 0"
                [referencedBy]="deleteCheckResult" [id]="exerciseToDelete.id" [name]="exerciseToDelete.name"
                (confirm)="deleteConfirm($event)"></delete-check>

            <div *ngIf="!searching && !busy">
                <folder *ngIf='canEditTopicsScripts() && (currentFolder | async)?.rootFolder===true'
                    [folder]="trashFolder" class='trash-folder' [readOnly]="browseMode=='VIEW'"></folder>
                <folder *ngIf='canEditTopicsScripts() && (currentFolder | async)?.trash===true' [folder]="rootFolder"
                    class='root-folder' [readOnly]="browseMode=='VIEW'"></folder>

                <folder *ngFor='let item of childFolders' [folder]="item" [readOnly]="browseMode=='VIEW'"></folder>

                <!-- attribute labels -->
                <div class='row label-container' *ngIf='exercises?.length>0'>
                    <div class='col-sm-7 col-xs-12'>
                        Sort exercises by:
                        <select class="sortby-dropdown" (change)="onSortDropDownChange($event)"
                            [(ngModel)]="currentSortBy">
                            <option value="DIFFICULTY">Difficulty</option>
                            <option value="NAME">Name</option>
                            <option value="TYPE">Exercise type</option>
                            <option *ngIf="canEditTopicsScripts()" value="STATE">State</option>
                            <option *ngIf="canEditTopicsScripts()" value="PUBLISHED">Published</option>
                            <option *ngIf="canEditTopicsScripts()" value="TESTED">Tested</option>
                        </select>
                        <select class="sortby-dropdown" (change)="onSortDropDownChange($event)"
                            [(ngModel)]="currentSortOrder">
                            <option value="1">Ascending</option>
                            <option value="-1">Descending</option>
                        </select>
                    </div>
                    <div class='col-sm-5 col-xs-12'>
                        <div class="goal-attr-label-container">
                            <div *ngFor='let attr of (currentFolder | async).attributeAssignments'
                                class='goal-attr-label-wrapper'>
                                <div class='goal-attr-label' [ngStyle]='getColorForAttribute(attr)'
                                    [title]="attr.attribute.name">{{attr.attribute.name
                                    | truncate:[15]}}</div>
                            </div>
                            <div class='goal-attr-label-bar'></div>
                        </div>
                    </div>
                </div>

                <div class="row header" *ngIf="exercises && exercises.length > 0">
                    <div class='col-xs-12 col-sm-4'>
                        <input *ngIf="!inTrash()" class="clickable" type="checkbox" [checked]="allSelected()"
                            (change)="checkAll($event)" />
                    </div>
                    <ng-container *ngIf="canEditTopicsScripts() else viewerHeader">
                        <div class='col-sm-1'>
                            <strong>State</strong>
                        </div>
                        <div class='col-sm-1'>
                            <strong>Published</strong>
                        </div>
                        <div class='col-sm-1'>
                            <strong>Tested</strong>
                        </div>
                    </ng-container>
                    <ng-template #viewerHeader>
                        <div class='col-sm-3'>
                            <strong>Export possibilities</strong>
                        </div>
                    </ng-template>
                </div>
                <ng-container *ngFor='let item of exercises'>
                    <topic-leaf *ngIf="showDeprecated || item.state !== 'DEPRECATED'"
                        (onChecked)="exerciseSelectedChanged($event)"
                        [selectable]="(incompatibleExercisesSelectable || !isVersionMismatch(item)) && !inTrash()"
                        [selected]="selectedExercises[item.id]" [leaf]="item" [topic]="currentFolder | async"
                        [readOnly]="browseMode=='VIEW'" [hideState]="!canEditTopicsScripts()"
                        [errorBadgeTitle]="getVersionMismatchError(item)"
                        [enabled]="!isVersionMismatch(item)"></topic-leaf>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="modal fade item-history" *ngIf="historyItem">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="modal-title">History:
                        <span>{{historyItem.name}} ({{historyItem.id}})</span>
                    </h4>
                </div>
                <div class="modal-body">
                    <version-viewer [id]="historyItem.id"></version-viewer>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>

    <!-- modal to show demo of exercise -->
    <div class="modal fade exercise-demo" *ngIf="testExerciseScript">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="modal-title">Preview:
                        <span>{{testExerciseScript.name}}</span>
                    </h4>
                </div>
                <div class="modal-body">
                    <div class="button-row">
                        <div class="label-container-xs" *ngIf='testExerciseScript.attributeAssignments'>
                            <div *ngFor='let attr of testExerciseScript.attributeAssignments' class='label-wrapper-xs'
                                [ngStyle]="getColorForAttribute(attr)">
                                <span class='attr-label-xs'>{{attr.attribute.name}}</span>
                            </div>
                        </div>
                        <button class="edit-button" type="button" (click)="refreshTest()">
                            <span class="fa fa-refresh"></span>
                        </button>
                        <button class="edit-button" type="button" (click)="editExercise(testExerciseScript.id)">
                            <span *ngIf="canEditTopicsScripts()">edit</span>
                            <span *ngIf="!canEditTopicsScripts()">view</span>
                        </button>
                        <div style="clear:both"></div>
                    </div>
                    <exercise-script-preview #previewComponent [exercise]="testExerciseScript?.definition"
                        [contentVersion]="testExerciseScript?.contentVersion">
                    </exercise-script-preview>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
</div>