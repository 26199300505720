import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {CmsFolder, CmsAkitResourceRef, CmsDiffAnomaly, CmsDiffSeverityLevel, CmsDiffAnomalyType, ReferencedBy} from '../../types/navigator.types';
import { Observable } from 'rxjs';

/**
 * A topic-leaf is a 'file' in the navigation system, which corresponds to an
 * exercise. The model is defined by class of type CmsLeaf. Classes for the various 
 * types of exercises are derived from CmsLeaf
 */

@Component({
    selector: 'publish-confirm',
    templateUrl: './publish-confirm.component.html',
    styleUrls: ['./publish-confirm.component.css']
})
export class PublishConfrim {

    @Output() confirm: EventEmitter<String> = new EventEmitter();
    @Output() cancel: EventEmitter<String> = new EventEmitter();

    onConfirm() {
        this.confirm.emit();
    }

    onCancel() {
        this.cancel.emit();
    }
}



