import {Component, OnInit, Input, OnChanges, forwardRef} from '@angular/core';

interface Entry {
    name: string;
    langMap: {[lang:string]:string};
}

@Component({
    selector: 'collapsable-panel',
    templateUrl: './collapsable-panel.component.html',
    styleUrls: ['./collapsable-panel.component.css']
})
export class CollapsablePanelComponent {
    @Input() heading: string;

    visible: boolean;
}

