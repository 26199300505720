<div class="panel panel-default panel-wrapper">
    <div class="panel-heading clickable" (click)="visible = !visible">
        <h3 class="panel-title">
            <span class="fa fa-eye" [hidden]="visible"></span>
            <span class="fa fa-eye-slash" [hidden]="!visible"></span>
            <span class="title">{{heading}}</span>
        </h3>
    </div>
    <div class="panel-body" *ngIf="visible">
        <ng-content></ng-content>
    </div>
</div>