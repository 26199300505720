<div class='topic-leaf row cms-navigator-item'>
    <div class='col-sm-4 col-xs-12 '>
        <div class="row display-flex-center">
            <div class="dropdown col-xs-4">
                <input *ngIf="selectable" class="clickable" type="checkbox" [checked]="selected"
                    (change)="checked($event)" />
                &nbsp;
                <ng-container *ngIf="!readOnly">
                    <button class="dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="true">
                        <span [ngClass]="leaf.iconClass"></span>
                        &nbsp;
                        <span> <i class="fa fa-chevron-down"></i></span>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenu2"
                        *ngIf="leaf.actions && getActions().length > 0">
                        <li *ngFor="let action of getActions()" (click)="action.callback(leaf)" class='dropdown-entry'>
                            <a class="clickable">
                                <span [ngClass]="action.iconClass"></span>
                                <span class='dropdown-entry-label'>{{action.name}}</span>
                            </a>
                        </li>
                        <li (click)="onLeafClick()" class='dropdown-entry'>
                            <a class="clickable">
                                <span> <i class="fa fa-eye"></i></span>
                                <span class='dropdown-entry-label'> Preview</span>
                            </a>
                        </li>
                    </ul>
                </ng-container>
                <div *ngIf="readOnly" class="icon-container">
                    <span [ngClass]="leaf.iconClass"></span>
                </div>
            </div>
            <div class="col-xs-8">
                <a [href]="'cms/edit/exercise/'+leaf.id">

                    <span
                        [ngClass]="{ 'deprecated': leaf.state=='DEPRECATED', 'clickable': enabled, 'disabled': !enabled }">
                        {{leaf.name}} <i *ngIf="errorBadgeTitle" [tooltip]="errorBadgeTitle"
                            class="error-badge fa fa-exclamation-circle"></i></span>
                </a>
            </div>
        </div>


        <span class="hidden-sm hidden-lg hidden-md cms-state-label">
            <ng-container [ngSwitch]="leaf.state" *ngIf="!hideState else exportPossibilities">
                <span class="label label-default" *ngSwitchCase="'DRAFT'" title="Draft">
                    <i class="fa fa-flask"></i>
                </span>
                <span class="label label-info" *ngSwitchCase="'APPROVED'" title="Approved">
                    <i class="fa fa-handshake-o"></i> <span *ngIf="leaf.latestVersionNumbers"
                        class="hidden-sm">{{getApprovedVersionNumbers(leaf)}}</span>
                </span>
                <span class="label label-danger" title="Deprecated" *ngSwitchCase="'DEPRECATED'">
                    <i class="fa fa-minus-circle"></i>
                    <span>Deprecated</span>
                </span>
                <span class="label label-warning" title="Published Version" *ngIf='isPublished'>
                    <i class="fa fa-leanpub"></i>
                    {{leaf.latestVersionNumbers.publishedMajorVersion}}.{{leaf.latestVersionNumbers.publishedMinorVersion}}
                </span>
                <span class="label label-success" *ngIf="leaf.tested == true" title="The current version is tested">
                    <i class="fa fa-check"></i>
                </span>
            </ng-container>
        </span>
    </div>

    <ng-template #exportPossibilities>
        <span *ngFor="let label of getExportPossibilities()" class="label" style="margin-right: 0.5em"
            [ngClass]="label.class">{{label.label}}</span>
    </ng-template>

    <ng-template #exportPossibilitiesWrapper>
        <div class="col-sm-3 label-container-sm" *ngIf='topic.attributeAssignments'>
            <ng-container *ngTemplateOutlet="exportPossibilities"></ng-container>
        </div>
    </ng-template>

    <ng-container *ngIf="!hideState else exportPossibilitiesWrapper">
        <div class="col-sm-1 label-container-sm" *ngIf='topic.attributeAssignments'>
            <span class="cms-state-label cms-state-label-lg">
                <ng-container [ngSwitch]="leaf.state">
                    <span class="label label-default" *ngSwitchCase="'DRAFT'" title="Draft">
                        <i class="fa fa-flask"></i>
                    </span>
                    <span class="label label-info" *ngSwitchCase="'APPROVED'" title="Approved">
                        <i class="fa fa-handshake-o"></i> <span *ngIf="leaf.latestVersionNumbers"
                            class="hidden-sm">{{getApprovedVersionNumbers(leaf)}}</span>
                    </span>
                    <span class="label label-danger" title="Deprecated" *ngSwitchCase="'DEPRECATED'">
                        <i class="fa fa-minus-circle"></i>
                        <span class="hidden-sm">Deprecated</span>
                    </span>
                </ng-container>
            </span>
        </div>
        <div class="col-sm-1 label-container-sm">
            <span class="cms-state-label cms-state-label-lg" *ngIf='isPublished'>
                <span class="label label-warning" title="Published Version">
                    <i class="fa fa-leanpub"></i>
                    {{leaf.latestVersionNumbers.publishedMajorVersion}}.{{leaf.latestVersionNumbers.publishedMinorVersion}}
                </span>
            </span>
        </div>
        <div class="col-sm-1 label-container-sm" *ngIf='topic.attributeAssignments'>
            <span class="cms-state-label">
                <span class="label label-success" *ngIf="leaf.tested == true">
                    <i class="fa fa-check" title="The current version is tested"></i>
                </span>
            </span>
        </div>
    </ng-container>
    <div class="col-sm-5 label-container-sm" *ngIf='topic.attributeAssignments'>
        <div *ngFor='let attr of topic.attributeAssignments' class='label-wrapper' [tooltip]="attr.attribute.name">

            <div class='attr-label' [ngStyle]="getColorForAttribute(attr)">&nbsp;</div>
        </div>
    </div>
</div>