<div class="change-password-component cms-navigator">
    <h2>Change password</h2>
    <br>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class=' row row-spacing'>
            <div class='col-xs-3 col-sm-2'>
                <label>Current password</label>
            </div>
            <div class="col-xs-7 col-sm-5">
                <input formControlName='currentPassword' class='form-control' type="password">
            </div>
        </div>
        <br />
        <div formGroupName="passwords">
            <div class='row row-spacing'>
                <div class='col-xs-3 col-sm-2'>
                    <label>New password</label>
                </div>
                <div class="col-xs-7 col-sm-5">
                    <input formControlName='newPassword' class='form-control' type="password">
                    <div class="alert alert-danger" *ngIf="newPasswordCtrl.errors?.invalidPassword" role="alert">
                        {{ newPasswordCtrl.errors.invalidPassword }}
                    </div>
                </div>
            </div>
            <br />
            <div class='row row-spacing'>
                <div class='col-xs-3 col-sm-2'>
                    <label>Repeat new password</label>
                </div>
                <div class="col-xs-7 col-sm-5">
                    <input type="password" class="form-control" placeholder="confirm password" title="confirm password"
                        formControlName="repeatNewPassword">
                    <div class="alert alert-danger" role="alert"
                        *ngIf="repeatNewPasswordCtrl.errors?.passwordsDontMatch">
                        The two passwords must match
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div class='row row-spacing'>
            <div class="col-xs-4 col-sm-2">
                <button class="btn btn-primary btn-block" type="submit" [disabled]="!form.valid">
                    <i class="fa fa-sign-in"></i>
                    <span>Save</span>
                </button>
            </div>
            <div class="col-xs-4 col-sm-2">
                <button class="btn btn-default btn-block" title="Cancel" (click)="cancel()">
                    <i class="fa fa-ban"></i>
                    <span>Cancel</span>
                </button>
            </div>
        </div>
    </form>
</div>