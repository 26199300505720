import { AfterViewInit, Component, ElementRef, NgZone, OnInit } from '@angular/core';

@Component({
    templateUrl: './test-algebra.component.html',
    styleUrls: ['./test-algebra.component.less', '../../../../assets/shared.less']
})
export class TestAlgebraComponent implements OnInit {
    ngOnInit() {

    }
}

