import { Component, OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { CmsItemAction, CmsCourse, CmsItem } from '../../types/navigator.types';
import { UserService } from '../../../security/services/user.service';
import { User } from '../../../security/types/user.type';
import { CourseService } from '../../services/course.service';

declare let $: any;

const STORAGE_KEY_FILTER = "akit_cms_storage_filter";

class FilterCache {
    selectedNamespaces: string[];
    excludeFree: boolean;

    constructor(selectedNamespaces: string[], excludeFree: boolean) {
        this.selectedNamespaces = selectedNamespaces;
        this.excludeFree = excludeFree;
    }
}

@Component({
    templateUrl: './course-overview.component.html',
    styleUrls: ['./course-overview.component.less','../../../../assets/shared.less']
})
export class CourseOverviewComponent implements OnInit {
    courses: CmsCourse[];

    loading: boolean = false;

    namespaces: string[];
    excludeFree: boolean;

    cachedFilter: FilterCache;

    canManageCourse = (course: CmsCourse) => {
        return this.userService.canManageCourse(course);
    }

    canEditCourse = (course: CmsCourse) => {
        return this.userService.canEditCourse(course);
    }

    actions: CmsItemAction[] = [{
        name: 'edit',
        iconClass: 'fa fa-lg fa-pencil-square',
        callback: ((course: CmsCourse) => this.editCourse(course.id)),
        preCond: this.canManageCourse
    }, {
        name: 'delete',
        iconClass: 'fa fa-lg fa-times',
        callback: ((course: CmsCourse) => this.removeCourse(course)),
        preCond: this.canManageCourse
    }];

    showCreateButton: boolean;
    subscription: Subscription;
    user: User;

    pageSize = 25;
    currentPage = 0;
    searchFilter: string;

    get hasNext() {
        return this.courses && this.courses.length == this.pageSize;
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private courseService: CourseService,
        private userService: UserService,
        private formBuilder: FormBuilder
    ) {
    }

    editCourse(id: string): void {
        this.router.navigate(['edit/course', id], { queryParams: { onCloseUrl: '_BACK' } });
    }

    onCourseClick(course: CmsCourse) {
        this.router.navigate(['subject', course.rootSubject.id]);
    }

    excludeFreeChanged(e: any) {
        if (!this.cachedFilter) {
            this.cachedFilter = {
                excludeFree: this.excludeFree,
                selectedNamespaces: []
            }
        }
        else {
            this.cachedFilter.excludeFree = this.excludeFree;
        }
        window.localStorage.setItem(STORAGE_KEY_FILTER, JSON.stringify(this.cachedFilter));
        this.currentPage = 0;
        this.refreshNamespaces(false);
    }

    hasGlobalNamespace() {
        return this.namespaces && this.namespaces.includes('global');
    }

    createCourse(): void {
        let course: CmsCourse = this.courseService.createCourse("New Course");

        this.courseService.storeCourse(course)
            .then((newCourseId) => {
                let roles: string[] = [...this.user.roles, newCourseId];
                course.id = newCourseId;
            })
            .then(() => {
                this.editCourse(course.id);
            })
            .catch(err => {
                console.log(err);
                this.toastr.error('Could not create the new course', 'Error');
            });
    }

    removeCourse(course: CmsCourse): Promise<boolean> {
        if (!this.user.canManageCourse(course)) {
            this.toastr.error("You are not authorized to remove courses", "Error");
            return;
        }
        this.courseService.deleteCourse(course.id)
            .then(() => {
                this.toastr.success("Course was successfully deleted", "Success");
                this.refresh()
            })
            .catch(err => {
                console.log(err);
                this.toastr.error(err.message, 'Error');
            });
    }

    refresh(): Promise<CmsCourse[]> {
        this.loading = true;

        return this.courseService.getCoursesList(null, this.excludeFree, this.currentPage, this.pageSize, this.searchFilter)
            .toPromise()
            .then((arr: CmsCourse[]) => {
                this.courses = arr;
                this.loading = false;
                return arr;
            })
            .catch(err => {
                console.log(err);
                this.toastr.error('Could not get your courses from the server', 'Error');
            }) as Promise<CmsCourse[]>;
    }

    refreshNamespaces(onInit: boolean) {
        let namespaces = this.userService.getCurrentUserNamespaces([".course.manage", ".course.edit", ".course.read"]);
        let promise: Promise<any>;
        if (namespaces.includes('global')) {
            promise = this.courseService.getCourseNamespaces(!this.excludeFree).toPromise().then(nsArr => {
                this.namespaces = Array.from(new Set(namespaces.concat(nsArr)))
                    .filter(ns => ns != null && ns !== "");
            })
        }
        else {
            this.namespaces = namespaces;
            promise = Promise.resolve();
        }
        promise.then(() => {
            if (onInit && !this.cachedFilter) {
                this.excludeFree = this.hasGlobalNamespace();
            }
            this.refresh();
        })

    }

    assignDropDownEvents() {
        $('.dropdown-menu .keep-open').on('click', function (e: any) {
            e.stopPropagation();
        });
    }

    nextPage() {
        if (this.loading) {
            return;
        }
        this.currentPage++;
        this.updateQueryParams();
    }

    previousPage() {
        if (this.loading) {
            return;
        }
        if (this.currentPage > 0) {
            this.currentPage--;
            this.updateQueryParams();
        }
    }

    pageSizeChanged(pageSize: number) {
        this.pageSize = pageSize;
        this.updateQueryParams();
    }

    setSearchFilter(searchFilter: string) {
        if (this.searchFilter != searchFilter) {
            this.searchFilter = searchFilter;
            this.currentPage = 0;
            this.updateQueryParams();
        }
    }

    updateQueryParams() {
        //Update query params in url so navigating "back" in the browser will display the same courses
        this.router.navigate(['.'], { relativeTo: this.route, queryParams: { search: this.searchFilter, page: this.currentPage, size: this.pageSize } });
    }

    ngOnInit() {
        let cachedFilterStr = window.localStorage.getItem(STORAGE_KEY_FILTER);
        if (cachedFilterStr) {
            this.cachedFilter = JSON.parse(cachedFilterStr) as FilterCache;
            this.excludeFree = this.cachedFilter.excludeFree;
        }

        this.user = this.userService.getUser();
        this.showCreateButton = this.user && this.user.isCourseManager()

        this.route.queryParams
            .subscribe((params) => {
                if (params.search) {
                    this.searchFilter = params.search;
                }
                if (params.page) {
                    this.currentPage = parseInt(params.page);
                }
                if (params.size) {
                    this.pageSize = parseInt(params.size);
                }
                this.refreshNamespaces(true);
            });
    }
}