import { AbstractControl } from "@angular/forms";
import * as PasswordValidator from 'password-validator';

export function validatePasswordCtrls(passwordCtrl: AbstractControl, repeatPasswordCtrl: AbstractControl) {
    let errors: any = {};
    let passSchema = new PasswordValidator();
    passSchema
        .is().min(8)
        .is().max(255)
        .has().uppercase()
        .has().lowercase()
        .has().digits()
        .has().symbols();
    if (!passSchema.validate(passwordCtrl.value)) {
        let pwErr = { invalidPassword: true };
        errors = { ...errors, ...pwErr };
        passwordCtrl.setErrors(pwErr);
    }
    if (passwordCtrl.value !== repeatPasswordCtrl.value) {
        let pwmatchErr = { passwordsDontMatch: true };
        errors = { ...errors, ...pwmatchErr };
        repeatPasswordCtrl.setErrors(pwmatchErr);
    }
    if (Object.keys(errors).length > 0) {
        return errors;
    }
    return null;
}
