// CMS metadata mirrors Algebrakit metadata more or less. We do not reuse the Algebrakit types as those types can evolve 
// independent from the CMS content.
// So: algebrakit.IAlgebraExerciseSpec is similar to cms.IAlgebraExercise, but not the same

import {
    AudienceSpec, NameIdPair, EvaluationMode, PaletteType, RenderMode,
    TableTaskSpec, ArithmeticTemplate, QuestionMode,
    InstructionType, StepActionType, ElementType, IExerciseSpec
} from '../../algebrakit/types/metadata.type';
import { CmsTopic, CmsExerciseScript, CmsTopicFilterLevelAttributeAssignement } from './navigator.types';

import { IDebugInformation } from '../../algebrakit/types/algebrakit.type';

// re-export types that are exactly the same
export {
    AudienceSpec, NameIdPair, EvaluationMode, PaletteType, QuestionMode, RenderMode,
    TableTaskSpec, InstructionType, ArithmeticTemplate, StepActionType,
    IDebugInformation, ElementType
}

export interface ICMS18NContent {
    [key: string]: string;
}
export interface ICMS18NContentMap {
    [lang: string]: ICMS18NContent;
}

export class I18nLabel {
    id: string;
    items: { [lang: string]: I18nLabelValue }
    modifiedBy?: string;
    modifiedDate?: Date;
}

export class I18nLabelValue {
    lang: string;
    text: string;
}

export enum CmsExerciseType {
    FILTER = "FILTER",
    REFERENCE = "REFERENCE",
    SPECIFICATION = "SPECIFICATION"
}

export interface ExerciseRef {
    type: CmsExerciseType,
    contentVersion: string,
    publishId?: string,
    courseId: string,
    exerciseId: string,
    level?: number,
    name?: string,
    title?: string,
    multiplePreviews?: boolean;
}

export interface TopicSpec {
    topic: CmsTopic,
    attributeSpecs: CmsTopicFilterLevelAttributeAssignement[],
    id?: string;
}
export interface LevelSpec {
    topicSpecs: TopicSpec[] //iterate over learning goals
    levelIndex: number;
    id?: string;
}

export interface TopicData {
    topic: CmsTopic,
    exercisesAKIT: CmsExerciseScript[],
    exercisesCourse: CmsExerciseScript[]
}

export interface ExerciseAttrRef extends ExerciseRef {
    attributes: string[],
    script: IExerciseSpec
}

export const REGIONS: NameIdPair[] = [
    { name: 'Nederlands (NL)', id: 'nl' },
    { name: 'Nederlands (BE)', id: 'nl-BE' },
    { name: 'English', id: 'en' },
    { name: 'Francais', id: 'fr' },
    { name: 'Francais (BE)', id: 'fr-BE' },
    { name: 'Deutsch', id: 'de' },
    { name: 'Spanish', id: 'es' }
];

export const QUESTION_TYPES: any[] = [
    { name: 'Algebra', id: 'ALGEBRA', icon: 'fa-list-ul' },
    { name: 'Math Entry', id: 'MATH_ENTRY', icon: 'fa-terminal' },
    { name: 'Geometry', id: 'GEOMETRY', icon: 'fa-connectdevelop' },
    { name: 'Multiple Choice', id: 'CHOICE', icon: 'fa-check-square' },
    { name: 'Math Table', id: 'MATH_TABLE', icon: 'fa-th' },
    { name: 'Math Table (old)', id: 'TABLE', icon: 'fa-th' },
    { name: 'Long Arithmetic', id: 'ARITHMETIC', icon: 'fa-sticky-note' },
    { name: 'Open Answer', id: 'OPEN_ANSWER', icon: 'fa-pencil-square-o' },
    { name: 'Statistics View', id: 'STAT_SINGLE_VIEW', icon: 'fa-pencil-square-o' },
    { name: 'Statistics Multiview', id: 'STAT_MULTI_VIEW', icon: 'fa-pencil-square-o' },
];

export interface TopicSpec {
    topic: CmsTopic,
    attributeSpecs: CmsTopicFilterLevelAttributeAssignement[],
}
export interface LevelSpec {
    topicSpecs: TopicSpec[] //iterate over learning goals
}

export interface TopicData {
    topic: CmsTopic,
    exercisesAKIT: CmsExerciseScript[],
    exercisesCourse: CmsExerciseScript[]
}