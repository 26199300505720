import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CmsExerciseScript, CmsExerciseFilterLevelSpec } from '../../types/navigator.types';
import { LevelSpec, TopicData, TopicSpec } from '../../types/cms-metadata.types';
import { FilterUtils } from '../../util/filter-utils';
import { compareVersion } from '../../util/sorting';

/**
 * A topic-leaf is a 'file' in the navigation system, which corresponds to an
 * exercise. The model is defined by class of type CmsLeaf. Classes for the various 
 * types of exercises are derived from CmsLeaf
 */
@Component({
    selector: 'exercise-filter-wizard',
    templateUrl: './exercise-filter-wizard.component.html',
    styleUrls: ['./exercise-filter-wizard.component.less']
})
export class ExerciseFilterWizardComponent {

    @Input() levelSpec: LevelSpec;
    @Input() interactionBlacklist: string[] = [];
    @Input() currentVersion: string;

    @Input() set topicData(value: TopicData[]) {
        this._topicData = value;
        //Remove exercises with blacklisted interactions
        for (let topic of this._topicData) {
            topic.exercisesAKIT = topic.exercisesAKIT.filter(ex =>
                !ex.interactionTypes || !this.interactionBlacklist || ex.interactionTypes.every(type => this.interactionBlacklist.indexOf(type) === -1)
            );
        }
    }

    _topicData: TopicData[]

    @Output() previewScript: EventEmitter<CmsExerciseScript> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();
    @Output() replace: EventEmitter<CmsExerciseFilterLevelSpec> = new EventEmitter();
    @Output() add: EventEmitter<CmsExerciseFilterLevelSpec> = new EventEmitter();
    @Output() selectTopics: EventEmitter<void> = new EventEmitter();

    getScriptEntryClass(ex: CmsExerciseScript,
        level: number,
        topicIndex: number,
        topicSpec: TopicSpec) {
        let compatible = !this.isVersionMismatch(ex);
        return FilterUtils.getScriptEntryClass(ex, level, topicIndex, topicSpec, this._topicData, this.currentVersion);
    }

    isVersionMismatch(exercise: CmsExerciseScript) {
        return compareVersion(this.currentVersion, exercise.contentVersion) < 0
    }

    scriptClicked(ex: CmsExerciseScript) {
        if (this.isVersionMismatch(ex)) {
            return;
        }
        this.previewScript.emit(ex)
    }

    commit(type: string) {
        let event: CmsExerciseFilterLevelSpec = {
            level: this.levelSpec.levelIndex,
            scripts: []
        }

        for (let topicIndex = 0; topicIndex < this.levelSpec.topicSpecs.length; topicIndex++) {

            let topicSpec = this.levelSpec.topicSpecs[topicIndex];
            let scriptsForTopic = this._topicData[topicIndex].exercisesAKIT
                .filter(ex => FilterUtils.isExerciseSelected(ex, topicSpec, this.levelSpec.levelIndex, this.currentVersion));
            for (let script of scriptsForTopic) {
                let majorVersion = script.latestVersionNumbers
                    ? script.latestVersionNumbers.publishedMajorVersion
                    : script.publishedMajorVersion;
                event.scripts.push({
                    id: script.id,
                    topicId: script.topic.id,
                    majorVersion: majorVersion
                })
            }
        }

        switch (type) {
            case 'ADD':
                this.add.emit(event);
                return;
            case 'REPLACE':
                this.replace.emit(event);
                return;
        }
    }

}



