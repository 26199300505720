import { Component, EventEmitter, Input, Output } from '@angular/core';
import {CmsFolder, CmsTopicAttribute, CmsTopicAttributeAssignment } from '../../types/navigator.types';
import { TopicService } from '../../services/topic.service';

@Component({
  selector: 'attribute-selector',
  templateUrl: './attribute-selector.component.html',
  styleUrls: ['./attribute-selector.component.css']
})
export class AttributeSelectorComponent {
    @Input() attributes: CmsTopicAttributeAssignment[];
    
     _selectedAttributes: CmsTopicAttributeAssignment[];
    //attributes that don't exist in the topic. Probably left over from copying.
     _unknownAttributes: CmsTopicAttributeAssignment[]; 
    
    @Input() set selectedAttributes(attrs: CmsTopicAttributeAssignment[]) {
        if(attrs) {
            this._selectedAttributes = attrs;
            this.setUnknownAttributes();
        }
    }

    @Output() onChange: EventEmitter<CmsTopicAttributeAssignment[]> = new EventEmitter<CmsTopicAttributeAssignment[]>();
    
    setUnknownAttributes() {
        this._unknownAttributes = this._selectedAttributes
            .filter(attrStr => !this.attributes.some(attr => attr.id === attrStr.id));
    }
    
    constructor(private topicService: TopicService) {}
    
    isSelected(attr: CmsTopicAttributeAssignment) {
        if (!this._selectedAttributes) return false;
        return this._selectedAttributes.find(elm => elm.id === attr.id);
    } 
    
    select(attr: CmsTopicAttributeAssignment) {
        let ind = this._selectedAttributes.findIndex(elm => elm.id === attr.id);
        if (ind>=0) this._selectedAttributes.splice(ind,1);
        else this._selectedAttributes.push(attr);
        this.onChange.emit(this._selectedAttributes.concat(this._unknownAttributes || []));
    }
    
    removeAttribute(id: string) {
        let ind = this._selectedAttributes.findIndex(attr => attr.id===id);
        if (ind >=0) this._selectedAttributes.splice(ind,1);
        this.setUnknownAttributes();
        this.onChange.emit(this._selectedAttributes.concat(this._unknownAttributes || []));
    }
    
    
    getAttributeColor(attribute: CmsTopicAttributeAssignment) {
        if(this.isSelected(attribute))  return this.topicService.getAttributeColorBg(attribute.difficulty);
        else return {};
    }
}
