import { Component, Input } from '@angular/core';
import { UserService } from '../../../security/services/user.service';

@Component({
  selector: 'modes-item-details',
  templateUrl: './modes-row.component.html',
  styleUrls: ['./modes-row.component.css']
})
export class ModesRowComponent {
    @Input() modes:any;

    currentUsage: any;
    
    public getTags(tags:any) {
        return tags.split(',');
    }
  
    public existsTags() {
        return this.modes.deprecated=='true' ||
               this.modes.internal=='internal';
    }
    
    public isDeprecated() {
        return this.modes.deprecated=='true';
    }
    
    public isInternal() {
        return this.modes.internal=='internal';
    }
    
    canEditTopicsScripts() {
        return this.userService.canEditLibrary();
    }
  
    constructor(
        private userService: UserService
    ){  
        this.currentUsage = '';
    }
    
}