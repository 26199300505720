
import { switchMap, filter } from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';

import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { UserService } from '../../../security/services/user.service';
import { CmsExerciseScript, CmsTopic, SessionInfo, CmsContainerType, CmsItemState, CmsUpdateType, CmsVersioningInfo, CmsTopicAttributeAssignment, ReferencedBy, CmsCourseAudience, Asset, CmsUnitTestRef } from '../../types/navigator.types';
import { ICMS2ExerciseSpec, WidgetType, ICMS2InteractionSpec, isInteraction, ICMS2SymbolProperty, SymbolSpec } from '../../types/cms2-metadata.types';
import { ISessionId, IErrorData } from '../../../algebrakit/types/algebrakit.type';
import { IDebugInformation } from '../../../algebrakit/types/algebrakit.type';
import { TopicService } from '../../services/topic.service';
import { SessionService } from '../../../algebrakit/services/session.service';
import { ExerciseScriptService } from '../../services/exercise-script.service';
import { VersioningService } from '../../services/versioning.service';
import { TypeHelpers } from '../../util/type-helpers';
import { ComponentCanDeactivate } from '../../../app/services/component-can-deactivate';
import { I18nLabel, QuestionMode } from '../../types/cms-metadata.types';
import { PublishedExerciseScript } from '../../types/published.types';
import { AppProfileService } from '../../../app/services/app-profile.service';
import { AudienceSelectorComponent } from '../../components/audience-selector/audience-selector.component';
import { addModeToInteraction } from '../exercise-client-spec-editor/exercise-client-spec-editor.component';
import { generateId } from '../../util/id-generator';
import { I18nLabelService } from '../../services/i18n-label.service';
import { getSymbolAsLatex } from '../../util/render-utils';
import { AkitShortcutComponent } from '../../components/akit-shortcuts/akit-shortcuts.component';
import { ContentVersionService } from '../../../app/services/content-version-service';

declare let $: any;
declare let AlgebraKIT: any;


type SymbolMap = { [name: string]: ICMS2SymbolProperty[] };

// the symbolMap (from WKContext) has the author symbols as keys as these are uniqye
// in the author tool we want the student symbol as key, with the different synonyms
// as childs. This function converts to that format.
export function getSymbolSpecsFromWKSymbolMap(symbolMap: SymbolMap): SymbolSpec[] {
    let result: SymbolSpec[] = [];

    //collect symbols according to student symbol
    let map: { [studentName: string]: { [name: string]: ICMS2SymbolProperty } } = {};

    Object.keys(symbolMap).forEach(key => {
        let s = symbolMap[key][0];
        let studentName = s.synonym ? s.synonym : key;
        let synonymMap = map[studentName];
        if (!synonymMap) {
            synonymMap = {};
            map[studentName] = synonymMap;
        }
        synonymMap[key] = s;
    });

    Object.keys(map).forEach(key => {
        let synonymMap = map[key];
        let synonyms = Object.keys(synonymMap);
        let symbol: SymbolSpec;
        if (synonyms.length == 1) {
            let wkSymbol = synonymMap[synonyms[0]];
            symbol = {
                name: key,
                type: wkSymbol.type,
                latex: getSymbolAsLatex(key),
                id: generateId(),
                args: (wkSymbol.type == 'FUNCTION') ? wkSymbol.args : null
            }
        } else {
            symbol = {
                name: key,
                type: 'MULTIPLE',
                latex: getSymbolAsLatex(key),
                id: generateId(),
                synonyms: Object.keys(synonymMap).map(_skey => {
                    let _sSpec = synonymMap[_skey];
                    return {
                        type: _sSpec.type,
                        name: _skey,
                        latex: getSymbolAsLatex(_skey),
                        args: (_sSpec.type == 'FUNCTION') ? _sSpec.args : null
                    }
                })
            }
        }

        result.push(symbol);
    })

    return result;
}

export function getWKSymbolMapFromSymbolSpecs(symbols: SymbolSpec[]): SymbolMap {
    let newMap: SymbolMap = {};

    // we need to use the author-symbols as key
    symbols.forEach(s => {
        if (s.synonyms && s.synonyms.length > 1) {
            s.synonyms.forEach(_synonym => {
                let newS = {
                    ..._synonym,
                    synonym: s.name,
                    latex: s.latex
                };
                newMap[_synonym.name] = [newS];
            });
        } else {
            let newS = { ...s };
            delete newS.id;
            newMap[s.name] = [newS];
        }

    });

    return newMap;
}


@Component({
    templateUrl: './exercise-editor.component.html',
    styleUrls: ['./exercise-editor.component.css', '../../../../assets/shared.less']
})
export class ExerciseEditorComponent extends ComponentCanDeactivate implements OnInit, AfterViewInit {
    //Form controls
    form: FormGroup;
    testNameControl = new FormControl();
    isTestCaseCtrl = new FormControl();
    updateTypeControl = new FormControl('');
    commitMessageControl = new FormControl('', [this.commitMessageValidator]);

    //Data Objects
    exercise: CmsExerciseScript;
    oldExercise: CmsExerciseScript;
    updatedExerciseSpec: any;

    topicId: BehaviorSubject<string> = new BehaviorSubject(null);
    attributes: BehaviorSubject<CmsTopicAttributeAssignment[]> = new BehaviorSubject(null);
    topic: CmsTopic;
    testSpec: ICMS2ExerciseSpec;
    wordSession: string; //used to replay a test session
    isDefinitionValid: boolean = true;
    changeCounter: number = 0;
    runSessionId: ISessionId;   //session id of test run. Used to store session as unit-test
    sessionName: string;
    isTestCase: boolean = false;
    testSessions: CmsUnitTestRef[];
    testAudience: string;
    errorMessage: string;
    audience: CmsCourseAudience;
    versionInfo: CmsVersioningInfo;
    interpretations: SymbolSpec[];

    nextTopicId: BehaviorSubject<string> = new BehaviorSubject(null);
    nextTopic: CmsTopic;
    newAttributeAssignments: CmsTopicAttributeAssignment[];
    topicSelectorActive = false;

    references: ReferencedBy[];
    showUpdateReferences: boolean;

    @ViewChild('specEditor', { static: false }) exerciseEditor: ElementRef;
    @ViewChild(AudienceSelectorComponent, { static: false }) private audienceSelector: AudienceSelectorComponent

    refreshTimer: any;

    exerciseLoaded: boolean;
    exerciseInitialised: boolean;
    viewsReady: boolean;

    moving: boolean = false;
    attributesBeforeMove: CmsTopicAttributeAssignment[];
    missingAttributesInNewTopic: { [attrId: string]: CmsTopicAttributeAssignment };

    lastPublishedVersion: PublishedExerciseScript;
    lastPublishedRetrieved = false;

    publishingWarning = false;
    confirmPublish = false;

    scriptInit: boolean;

    assets: Asset[];
    scriptNames: string[];

    questionModes = [
        { id: QuestionMode.ONE_BY_ONE, name: 'One by one' },
        { id: QuestionMode.ALL_AT_ONCE, name: 'All at once' }
    ]

    // bool to show title information in general settings tab
    generalExtend = false;

    JSON = JSON;
    widgetsEventsSet: boolean;
    showVersionInfo = false;

    labelSubscription: any;
    i18nLabels: I18nLabel[];
    onCloseUrl: string;

    //To prevent "are you sure...." popup box from appearing twice
    leaveMessageAlreadyGiven: boolean;

    contentVersion: string;

    get saveTitle() {
        if(this.exercise.state!='DRAFT' && !!this.commitMessageValidator()) {
            return 'You must add a commit message to update an approved exercise.'
        }
        return `Save this exercise (${AkitShortcutComponent.getSaveShortcut()})`
    }

    get runTitle() {
        return `Run this exercise (${AkitShortcutComponent.getRunShortCut()})`
    }

    get allowExperimental() {
        return this.userService.getUser().canUseExperimentalWidgets();
    }

    constructor(
        private userService: UserService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private location: Location,
        private topicService: TopicService,
        private exerciseScriptService: ExerciseScriptService,
        private sessionService: SessionService,
        private versioningService: VersioningService,
        private appProfileService: AppProfileService,
        private changeDetector: ChangeDetectorRef,
        private i18nLabelService: I18nLabelService,
        private zone: NgZone,
        private router: Router,
        private contentVersionService: ContentVersionService
    ) {
        super();
        this.form = this.formBuilder.group({
            commitMessage: this.commitMessageControl,
            updateType: this.updateTypeControl,
            questionMode: ['']
        });
    }

    commitMessageValidator() {
        return (control: AbstractControl): { [key: string]: any } => {
            return (this.exercise.state == CmsItemState.APPROVED || this.exercise.state == CmsItemState.DEPRECATED)
                && (!control.value || ("" + control.value).trim() == "")
                ? { 'emptyCommitMessage': { value: control.value } }
                : null;
        };
    }

    cancel() {
        if (!this.canDeactivate()) {
            if (confirm("You have unsaved changes in this exercise. Are you sure you want to close the editor?")) {
                this.leaveMessageAlreadyGiven = true;
                this.leave();
            }
        }
        else {
            this.leave();
        }
    }

    leave() {
        this.releaseLock();
        if (this.onCloseUrl == '_BACK') {
            this.location.back();
        }
        else if (this.onCloseUrl) {
            this.router.navigate([this.onCloseUrl]);
        }
        else {
            this.router.navigate(['topic', this.exercise.topic.id]);
        }
    }

    releaseLock(): void {
        if (this.exercise) {
            this.exerciseScriptService.releaseLock(this.exercise.id);
        }
    }

    setTested() {
        this.versioningService.setTestedTrue(this.exercise.id)
            .then(() => {
                this.exercise.tested = true;
                this.oldExercise = TypeHelpers.clone(this._getFormValue());
                this.toastr.success("Exercise marked as tested", 'Success')
            }).catch((err) => {
                console.log(err);
                this.toastr.error("Error occurred while marking the exercise as tested", 'Error');
            });
    }

    approve() {
        if (confirm("You are about to move this exercise from the DRAFT to the APPROVED state and enable versioning for this exercise. Are you sure?")) {
            this.versioningService.approve(this.exercise.id)
                .then(() => {
                    this.exercise.state = CmsItemState.APPROVED;
                    this.getVersioningData();
                    this.oldExercise = TypeHelpers.clone(this._getFormValue());
                    this.toastr.success("Exercise is now approved.", 'Success')
                })
                .catch((err: any) => {
                    console.log(err);
                    this.toastr.error("Error occurred while approving the exercise", 'Error');
                })
        }
    }

    makeDeprecated() {
        if (confirm("You are about to mark this exercise as deprecated. After this point, you can no longer use this exercise for new arrangements and references. Existing references to this exercise will keep working.")) {
            this.versioningService.setDeprecated(this.exercise.id)
                .then(() => {
                    this.exercise.state = CmsItemState.DEPRECATED;
                    this.oldExercise = TypeHelpers.clone(this._getFormValue());
                    this.toastr.success("Exercise is now deprecated.", 'Success')
                })
                .catch((err: any) => {
                    console.log(err);
                    this.toastr.error("Error occurred while marking the exercise as deprecated", 'Error');
                })
        }
    }

    saveExercise() {

        if (this.exercise.state == 'APPROVED') {
            let commitMsg: string = this.form.value.commitMessage;
            if (commitMsg == null || commitMsg.trim() == "") {
                alert('You need to provide a commit message when saving approved exercises.');
                return;
            }
        }

        if (this.exercise.state === CmsItemState.DRAFT) {
            this.commitExercise();
        }
        else {
            this.exerciseScriptService.checkPotentialFilterChanges(this.exercise)
                .then((result: boolean) => {
                    if (result) {
                        if (confirm('Saving this exercise could change a number of existing exercise arrangements. Are you sure?')) {
                            this.commitExercise();
                        }
                    }
                    else {
                        this.commitExercise();
                    }
                })
        }
    }

    commitExercise() {
        let obj = this._getFormValue();
        if (obj.topic.id !== this.topicId.value) {
            this.topicService.getTopic(this.topicId.value).toPromise().then((topic: CmsTopic) => {
                obj.topic = { id: topic.id, name: topic.name, containerType: CmsContainerType.FOLDER };
                this.storeExercise(obj);
            });
        }
        else {
            this.storeExercise(obj);
        }
        this.changeCounter = 0;
    }

    publish() {
        if (this.publishingWarning) {
            this.confirmPublish = true;
        }
        else {
            this.doPublish();
        }
    }

    cancelPublish() {
        this.confirmPublish = false;
    }

    doPublish() {
        if (this.exercise.state !== CmsItemState.APPROVED) {
            this.toastr.error('Exercise is not approved.');
            return;
        }
        let currentMajor = this.versionInfo ? this.versionInfo.majorVersion : 1;
        if (currentMajor == null) {
            this.toastr.error('An error occurred when trying to get the current major version number.', 'Error');
            return;
        }
        let publishedMajor = this.lastPublishedVersion ? (this.lastPublishedVersion.majorVersion || 0) : 0;
        let msg = 'You are about to publish this exercise'
        if (publishedMajor === 0) {
            msg += ` for the first time. Are you sure?`;
        } else if (publishedMajor === currentMajor) {
            msg += `. Only minor updates have been performed on this exercise since the last publish. The exercise will be published under the same version number as last publish`;
        } else if (publishedMajor < currentMajor) {
            msg += `. One or more major updates have been performed on this exercise since the last publish. The exercise will be published under version ${currentMajor}`;
        } else {
            this.toastr.error('The current version number and published version number have incorrect values.');
            return;
        }
        if (confirm(msg)) {
            this.exerciseScriptService.publish(this.exercise.id)
                .then((pubScript) => {
                    this.toastr.success(`Published exercise with id=[${this.exercise.id}] under major version ${currentMajor}.`);
                    this.lastPublishedVersion = pubScript;
                    this.getVersioningData();
                })
                .catch(error => {
                    let message = error.message || (error ? error.message : null) || "Error occurred while publishing the exercise";
                    this.toastr.error(message, 'Error');
                })
        }
    }

    updateTypeChanged() {
        let value = this.updateTypeControl.value;
        if (value === CmsUpdateType.MAJOR) {
            this.showUpdateReferences = true;
            this.checkReferences();
        }
        else {
            this.showUpdateReferences = false;
        }
    }

    checkReferences() {
        if (this.references) {
            return;
        }
        this.exerciseScriptService.getReferencedExercises(this.exercise.id)
            .toPromise()
            .then(result => {
                this.references = result;
            })
    }

    storeExercise(formValue: CmsExerciseScript) {
        this.exerciseScriptService.storeExercise(formValue)
            .then(() => {
                this.toastr.success("Exercise was stored successfully.", 'Success')
                this.exercise.tested = false;
                this.oldExercise = TypeHelpers.clone(this._getFormValue());
                this.getVersioningData();
                this.commitMessageControl.setValue("");
            })
            .catch(err => {
                console.log(err);
                let message = err.message || "Error occurred while storing the exercise";
                this.toastr.error(message, 'Error');
            });
    }

    checkMissingAttributes() {
        if (!this.attributesBeforeMove) {
            return;
        }
        let newAttrIds = this.nextTopic.attributeAssignments.map(assignment => assignment.attribute.id);
        for (let attrAssignment of this.attributesBeforeMove) {
            if (newAttrIds.indexOf(attrAssignment.attribute.id) === -1) {
                this.missingAttributesInNewTopic[attrAssignment.attribute.id] = attrAssignment;
            }
        }
    }

    convertAssignments() {
        if (!this.attributesBeforeMove) {
            return;
        }
        let attrToAssignmentMap: { [attrId: string]: CmsTopicAttributeAssignment } = {};
        let newAttrAssignments = [];
        for (let assignment of this.nextTopic.attributeAssignments) {
            attrToAssignmentMap[assignment.attribute.id] = assignment;
        }
        for (let exAssignment of this.attributesBeforeMove) {
            let topicAssignment: CmsTopicAttributeAssignment = attrToAssignmentMap[exAssignment.attribute.id];
            if (topicAssignment) {
                if (topicAssignment.id === exAssignment.id) {
                    newAttrAssignments.push(exAssignment);
                }
                else {
                    newAttrAssignments.push(topicAssignment);
                }
            }
        }
        this.newAttributeAssignments = newAttrAssignments;
    }

    missingAttributeAssignmentArray(): CmsTopicAttributeAssignment[] {
        return Object.keys(this.missingAttributesInNewTopic)
            .filter(attrId => !this.newAttributeAssignments.find(assignment => assignment.attribute.id === attrId))
            .map(attrId => this.missingAttributesInNewTopic[attrId]);
    }

    setTopic(id: string) {
        if (!this.moving) {
            this.attributesBeforeMove = [...this.exercise.attributeAssignments];
        }
        this.moving = true;
        if (!this.missingAttributesInNewTopic) {
            this.missingAttributesInNewTopic = {};
        }
        this.nextTopicId.next(id);
    }

    confirmTopicMove() {
        this.topicId.next(this.nextTopic.id);
        this.clearNextTopic();
    }

    clearNextTopic() {
        this.nextTopicId.next(null);
        this.nextTopic = null;
        this.newAttributeAssignments = null;
        this.moving = false;
        this.missingAttributesInNewTopic = null;
        this.attributesBeforeMove = null;
        this.setTopicSelectorActive(false);
    }

    canEditTopicsScripts(): boolean {
        return this.userService.canEditLibrary();
    }

    canPublish(): boolean {
        return this.userService.canPublishInLibrary() && this.exercise.state == 'APPROVED';
    }

    showPublishButton() {
        return this.canPublish() && this.lastPublishedRetrieved;
    }

    isSimpleExercise(ex: ICMS2ExerciseSpec) {
        if (TypeHelpers.isSingleInteraction(ex) == 'ALGEBRA') {
            let inter = ex.elements[0].interactions[0];
            if (isInteraction(inter)) {
                if ((inter as ICMS2InteractionSpec).widgetType != WidgetType.CALCBOOK) {
                    return true;
                }
            }
        }
        return false;
    }

    showSolution(ex: ICMS2ExerciseSpec) {
        const singleInteractionType = TypeHelpers.isSingleInteraction(ex);
        if (singleInteractionType == 'ALGEBRA') {
            let inter = ex.elements[0].interactions[0];
            if (isInteraction(inter)) {
                if ((inter as ICMS2InteractionSpec).widgetType == WidgetType.CALCBOOK) {
                    return false;
                }
            }
        } else if (singleInteractionType === 'STATISTICS') {
            return false;
        }

        return true;
    }

    async run(spec?: ICMS2ExerciseSpec) {
        return this.generate(true, spec);
    }

    onSessionReplay() {
        if (AlgebraKIT._api.errorViewerComponent) AlgebraKIT._api.errorViewerComponent.clearErrorMessages();
        this.testSpec = null;
    }

    tryGetOldValue() {
        if (this.exerciseLoaded && this.viewsReady) {
            this.oldExercise = TypeHelpers.clone(this._getFormValue());
            if (this.oldExercise) this.exerciseInitialised = true;
        }
    }

    setRunSessionId(sessionId: ISessionId) {
        this.runSessionId = sessionId;
        this.changeDetector.detectChanges();
    }

    showStoreTestPanel() {
        return this.runSessionId && this.userService.canEditLibrary();
    }

    storeSession(event: Event) {
        (event.target as HTMLButtonElement).disabled = true;
        if (!this.runSessionId) {
            console.log("Cannot store session: no session id available.");
            return;
        }
        console.log(this.runSessionId.sessionId + ' (appId=' + this.runSessionId.appId);
        this.sessionService
            .storeTestSession(this.runSessionId.sessionId, this.sessionName, this.exercise.id, this.isTestCase)
            .then((sessionInfo: CmsUnitTestRef) => {
                if (!this.exercise.testSessions) this.exercise.testSessions = [];
                this.testSessions.push(sessionInfo);
                this.setLocalTestSessionArray();
                this.testNameControl.setValue("");
                this.sessionName = null;
                this.toastr.success(`Test session '${sessionInfo.description}' was stored succesfully`, 'Success');
            });

    }

    inspectSession(session: SessionInfo) {
        session.active = !session.active;
    }

    referenceCheckIsValid() {
        return !this.showUpdateReferences || this.references
    }

    setLocalTestSessionArray() {
        this.testSessions = [];
        this.sessionService.getTestSessionRefs(this.exercise.id).then((result) => {
            this.testSessions = result;
        })
    }

    setErrorMessage(message: string) {
        this.errorMessage = message;
    }

    testHasError() {
        return this.errorMessage && this.errorMessage.length > 0;
    }

    previewLoaded() {
        window.scrollTo(0, document.body.scrollHeight);
    }

    onDebugInfo(debugInfo: IDebugInformation) {
        if (!debugInfo) return;
        let bucket = debugInfo.buckets.find(b => b.description == 'Interpretations');
        if (bucket) {
            this.interpretations = bucket.items.map(item => {
                let obj = JSON.parse(item.value);
                let latex = getSymbolAsLatex(item.key);
                return {
                    name: item.key,
                    type: obj.type,
                    latex: latex
                }
            });
            var modal = $('.interpretations-modal').first();
            modal.modal('show');
        }
    }

    onInteractionError(error: IErrorData) {
        this.exerciseEditor.nativeElement.setErrorData(error);
    }

    confirmInterpretation() {
        let symbols: SymbolSpec[] = [];
        this.interpretations.forEach(inter => {
            if (!symbols.some(_s => _s.name == inter.name)) {
                symbols.push(inter);
            }
            symbols = [...symbols];
        });
        this.exerciseEditor.nativeElement.appendSymbols(symbols);
        this.interpretations = null;
    }

    setAudience(audience: CmsCourseAudience) {
        if (!this.testSpec) return;

        if (!audience) {
            this.testSpec.audience = null;
            this.testSpec.course = null;
            return;
        }
        this.audience = audience;
        this.testSpec.audience = audience.baseAudienceId;
        this.testSpec.course = audience.courseId != "none"
            ? audience.courseId
            : null;
    }

    async generate(force?: boolean, spec?: ICMS2ExerciseSpec) {
        this.testSpec = null;
        this.errorMessage = null;
        this.wordSession = null;
        this.runSessionId = null;
        if (!spec) {
            let formValue = this._getFormValue();
            spec = formValue.definition;
        }
        await AlgebraKIT._api.changeTheme('akit');
        this.testSpec = spec;
        if (this.audienceSelector) {
            spec.audience = this.audienceSelector.audience;
        }

        let dum = force
            ? {
                ...this.testSpec
            }
            : this.testSpec;
        let self = this;
        setTimeout(function () {
            self.testSpec = dum;
        }, 0);
    }

    getVersioningData() {
        if (this.exercise.state == CmsItemState.APPROVED || this.exercise.state === CmsItemState.DEPRECATED) {
            this.versioningService.getLatestVersionInfo(this.exercise.id)
                .then((versionInfo: CmsVersioningInfo) => {
                    this.versionInfo = versionInfo
                });
        }
    }

    getLastPublish() {
        this.lastPublishedRetrieved = false;
        if (this.exercise.state == CmsItemState.DRAFT) {
            this.lastPublishedRetrieved = true;
            return Promise.resolve(null);
        }
        this.exerciseScriptService.getPublished(this.exercise.id).then((pubEx) => {
            this.lastPublishedVersion = pubEx;
            this.lastPublishedRetrieved = true;
        });
    }

    isEmptyMathData() {
        return !this.exercise || !this.exercise.definition.definitions || this.exercise.definition.definitions.length === 0;
    }

    isEmptyAssets() {
        return !this.exercise
            ||
            (
                (this.exercise.state === CmsItemState.APPROVED || this.exercise.state === CmsItemState.DEPRECATED) && !this.versionInfo
            );
    }

    assetsChanged(assets: Asset[]) {
        this.assets = assets;
    }

    getMajorVersion() {
        return this.exercise.state === CmsItemState.APPROVED || this.exercise.state === CmsItemState.DEPRECATED
            ? (this.versionInfo
                ? this.versionInfo.majorVersion
                : null
            )
            : 0;
    }

    ngOnInit() {
        this.viewsReady = false;
        this.labelSubscription = this.i18nLabelService.labels$.subscribe(
            labels => {
                this.i18nLabels = labels;
                if (this.exerciseEditor && this.exerciseEditor.nativeElement) {
                    this.exerciseEditor.nativeElement.updateI18nLabels(labels);
                }
            }
        )
        this.appProfileService.getSetting('publishingWarning').then((value) => {
            this.publishingWarning = value;
        });

        this.route.queryParams
            .subscribe((params: Params) => {
                this.onCloseUrl = params['onCloseUrl'];
            });

        this.route.data
            .subscribe((data: { exercise: CmsExerciseScript }) => {
                //Show alert for version mismatch if not in read-mode
                if (this.canEditTopicsScripts()) {
                    this.contentVersionService.showVersionAlert();
                }
                this.contentVersionService.getContentVersion().then(v => {
                    this.contentVersion = v;
                })
                this.leaveMessageAlreadyGiven = false;
                this.lastPublishedRetrieved = false;
                this.exerciseLoaded = false;
                this.exerciseInitialised = false;
                this.changeCounter = 0;
                if (data.exercise) {
                    this.refreshTimer = this.canEditTopicsScripts() ?
                        this.exerciseScriptService.scheduleRefresh(data.exercise.id)
                        : null;

                    this.exercise = data.exercise;

                    this.getLastPublish();
                    this.checkReferences();

                    this.topicId.next(data.exercise.topic.id);
                    this.attributes.next(data.exercise.attributeAssignments);

                    if (data.exercise.definition.modes) {
                        let marr: string[] = typeof (data.exercise.definition.modes) == 'string'
                            ? data.exercise.definition.modes.split(',').map(m => m.trim())
                            : data.exercise.definition.modes;
                        let delegateModes = ['units_are_optional', 'units_explicit'];
                        delegateModes
                            .filter(mode => marr.some(_m => _m == mode))
                            .forEach(m => {
                                data.exercise.definition.elements
                                    .filter(elm => elm.interactions)
                                    .forEach(elm => {
                                        elm.interactions
                                            .forEach(inter => addModeToInteraction(m, inter));
                                    });
                                marr = marr.filter(_m => _m != m);
                            });
                        data.exercise.definition.modes = marr.join(',');
                    }


                    this.form.patchValue({
                        name: data.exercise.name,
                        description: data.exercise.description,
                        comment: data.exercise.comment,
                        script: this.exercise.definition.script,
                        questionMode: data.exercise.definition.questionMode,
                        modes: data.exercise.definition.modes
                    });
                    if (this.userService.canEditLibrary()) {
                        this.getVersioningData();
                        this.setLocalTestSessionArray();
                    }
                    this.exerciseLoaded = true;
                    this.tryGetOldValue();
                }
            });
        this.topicId.pipe(
            filter(id => id !== null),
            switchMap(id => this.topicService.getTopic(id)))
            .subscribe(topic => {
                this.topic = topic;
            });

        this.nextTopicId.pipe(
            filter(id => id !== null),
            switchMap(id => this.topicService.getTopic(id)))
            .subscribe(topic => {
                this.nextTopic = topic;
                if (this.moving) {
                    this.convertAssignments();
                    this.checkMissingAttributes();
                }
            });

        this.testNameControl.valueChanges.subscribe(value => {
            this.sessionName = value;
        });
        this.isTestCaseCtrl.valueChanges.subscribe(value => {
            this.isTestCase = value;
        });
        // show extended settings if they are present
        this.generalExtend = (this.exercise.comment != null && this.exercise.comment.length > 0)
            || (this.exercise.description != null && this.exercise.description.length > 0);

    }

    ngAfterViewInit(): void {
        this.tryGetOldValue();
    }

    ngAfterViewChecked() {
        if (this.widgetsEventsSet) {
            return;
        }
        else if (this.exerciseEditor) {
            this.widgetsEventsSet = true;
            const getFunc = async () => {
                await AlgebraKIT.waitUntilReady(this.exerciseEditor.nativeElement);
                this.updatedExerciseSpec = { ...this.exercise };
                this.updatedExerciseSpec.definition = await this.exerciseEditor.nativeElement.getSpec();
                this.isDefinitionValid = await this.exerciseEditor.nativeElement.getValid();
                this.exerciseEditor.nativeElement.removeWidgetListener('exerciseChanged', this.onExerciseSpecChanged);
                this.exerciseEditor.nativeElement.addWidgetListener('exerciseChanged', this.onExerciseSpecChanged);
                this.viewsReady = true;
                this.tryGetOldValue();
            };
            getFunc();
        }
    }

    onExerciseSpecChanged = async (e: { spec: any, valid: boolean }) => {
        this.updatedExerciseSpec = e.spec;
        this.isDefinitionValid = e.valid;
        this.changeCounter++;
        this.changeDetector.detectChanges();
    }

    attributesChanged() {
        this.changeCounter++;
    }

    ngOnDestroy() {
        this.exerciseScriptService.descheduleRefresh(this.refreshTimer);
        if (this.exercise) {
            this.exerciseScriptService.releaseLock(this.exercise.id);
        }
        this.labelSubscription.unsubscribe();
    }

    canApprove() {
        return this.userService.canEditLibrary();
    }

    canCompareTestsessions() {
        return this.userService.canCompareTestSessions();
    }

    canUpdateSession() {
        return this.userService.canUpdateTestSessions();
    }

    canSetIsTestCase() {
        return this.userService.canSetIsTestCase();
    }

    canDeactivate(): boolean {
        return this.leaveMessageAlreadyGiven || this.changeCounter === 0;
    }

    approveButtonVisible() {
        return this.canApprove() && this.exercise.state == 'DRAFT' && this.changeCounter == 0 && this.form.valid;
    }

    setTopicSelectorActive(value: boolean) {
        this.topicSelectorActive = value;
    }

    isEmptyTranslation(trans: { [key: string]: any }) {
        for (let lang in trans) {
            let obj = trans[lang];
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) return false;
            }
        }
        return true;
    }

    _getFormValue(): CmsExerciseScript {
        let obj = this.form.value;

        let audience = this.updatedExerciseSpec && this.updatedExerciseSpec.definition
            ? this.updatedExerciseSpec.definition.audience
            : null;

        let result: CmsExerciseScript = {
            ...this.exercise,//default: original values
            ...obj,           //override with settings in form
            ... this.updatedExerciseSpec,  //Get properties from exercise-editor,
            state: this.exercise.state, //updatedExerciseSpec may have an old state (e.g. after approving)
            audience: audience,
            folderId: this.topicId.value,
            attributeAssignments: this.attributes.value,
            testSessions: this.exercise.testSessions,
            updateType: CmsUpdateType[this.updateTypeControl.value]
        }

        return result;
    }
}
