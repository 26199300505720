<div class='modes-navigator modes-table'>
    <div class="row">
        <div class="col-md-4">
            <input placeholder="Search..." class="form-control" (keyup)="filterModes($event)" />
        </div>
    </div>
    <br>
    <div *ngFor="let item of filteredItems">
        <div *ngIf="showFilterComp(item.internal)" class='col-xs-12 syntax-row'>
            <div [ngClass]="{'row': true, 'expand-hand': !isItemEmpty(item)}"  (click)="toggleItemExpansion(item.id, isItemEmpty(item))" >
                <div class='syntax-quickview'>
                    <div class="syntax-quickview-table col-xs-12">
                        <div class="row">
                            <div class='syntax-name col-xs-10'>
                                
                                <div *ngIf="!isItemEmpty(item)" class='syntax-expand-icon'>
                                    <i [ngClass]="{'fa-icon-class fa': true, 'fa-plus-circle': !showExpandedItem(item.id), 'fa-minus-circle' : showExpandedItem(item.id)}"></i>
                                </div>
                                <div *ngIf="isItemEmpty(item)" class='syntax-expand-icon syntax-empty-expand'>
                                    <i class="fa-icon-class fa fa-plus-circle"></i>
                                </div>
                                
                                <span [ngClass]="{'syntax-deprecated': item.deprecated=='true'}">{{item.name}}</span>
                            </div>
                            <div class='syntax-type-icon col-xs-2'>
                                <span>mode</span>
                            </div>
                        </div>
                        <div *ngIf="item.description !== ''" class='row'>
                            <div class="syntax-description col-xs-12" [innerHtml]="item.description"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div *ngIf="showExpandedItem(item.id)" class='col-xs-12 syntax-expand'>
                    <modes-item-details [modes]=item></modes-item-details>
                </div>
            </div>
        </div>
    </div>
</div>