<div class="attribute-editor-component">
    <form [formGroup]="form" [ngClass]="{'deleted': deleted}">
        <div *ngIf="attributes()" class="attr-name-wrapper attr-wrapper">
            <select [attr.disabled]="deleted ? '' : null" class='form-control' [ngClass]='getSelectClass()' name="attribute" formControlName="attribute">
                <option *ngFor="let attribute of attributes()" [ngValue]="attribute.id">{{attribute.name}}</option>
            </select>
        </div>
        <div *ngIf="difficulties()" class="attr-complexity-wrapper attr-wrapper">
            <select [attr.disabled]="deleted ? '' : null" name="complexity" formControlName="complexity" class='form-control' [ngStyle]="getColorForGoalAttribute(_attributeAssignment)">
                <option *ngFor="let level of difficulties()" [ngValue]="level.id">{{level.name}}</option>
            </select>
        </div>
    </form>
</div>