import { Component, OnInit, Input } from '@angular/core';
import { CmsFolder, CmsAkitResourceRef, CmsDiffAnomaly, CmsDiffSeverityLevel } from '../../types/navigator.types';
import { Observable } from 'rxjs';
import { SortedCheckResults, AnomalyType, Anomaly, CheckedResource, Severity } from '../../types/verify.types';
import { ValidationReport, ValidationUserReportEntry } from '../../types/ValidationReport';



@Component({
    selector: 'validation-report',
    templateUrl: './validation-report.html',
    styleUrls: ['./validation-report.less']
})
export class ValidationReportComponent {
    @Input() validationReport: ValidationReport;

    get validationReportItems(): ValidationUserReportEntry[] {
        return this.validationReport ? this.validationReport.getValidationReportForUser('long') : [];
    }

    getItemClass(item: ValidationUserReportEntry) {
        let baseClass = 'list-group-item'
        switch (item.status) {
            case 'PASSED':
                return baseClass + ' list-group-item-success';
            case 'FAILED':
                return baseClass + ' list-group-item-danger';
            case 'UNAVAILABLE':
                return baseClass + ' list-group-item-warning';
            default:
                return baseClass;
        }
    }
}



