import { Component, OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { UserService } from '../../../security/services/user.service';
import { User } from '../../../security/types/user.type';

var synjson = require('../../../../assets/json/syntax.json');

declare let $: any;

@Component({
    selector: 'syntax-overview',
    templateUrl: './syntax-overview.component.html',
    styleUrls: ['./syntax-overview.component.css']
})
export class SyntaxOverviewComponent implements OnInit {

    public expandedItemIndex: Array<number> = [];
    public showLegend = false;

    subscription: Subscription;
    user: User;

    public syntaxitems: Array<any>;
    public filteredItems: Array<any>;

    loading: boolean = false;

    canEditTopicsScripts() {
        return this.userService.canEditLibrary();
    }

    public showFilterComp(internal: Boolean) {
        if (internal && this.canEditTopicsScripts()) {
            return true;
        }
        return !internal;
    }

    private helpLegend: Array<any> = [
        {
            name: 'expr',
            description: 'Algebrakit expressions (e.g. 2+3 is plus[2,3], the list {2,3} is List[2,3])'
        },
        {
            name: 'func',
            description: 'Algebrakit functions'
        },
        {
            name: 'b',
            description: 'Boolean values'
        },
        {
            name: 'n',
            description: 'Natural number'
        },
        {
            name: 'z',
            description: 'Whole numbers'
        },
        {
            name: 'q',
            description: 'Whole (negative) numbers and fractions'
        },
        {
            name: 's',
            description: 'Numbers in R'
        },
        {
            name: 'f',
            description: 'Fractions'
        },
        {
            name: 'var',
            description: 'Variables'
        },
        {
            name: 'key',
            description: 'String that is used in an option'
        },
        {
            name: 'value',
            description: 'Value of a key'
        },
        {
            name: 'cond',
            description: 'Condition'
        },
        {
            name: 'equa',
            description: 'Equation'
        },
        {
            name: 'unit',
            description: 'Unit'
        },
        {
            name: 'text',
            description: 'Text'
        },
        {
            name: 'options',
            description: 'Options'
        }
    ];

    public allcolumns: Array<any> = [
        { name: 'name' },
        { name: 'syntax' },
        { name: 'infix-syntax' },
        { name: 'description' },
        { name: 'tags' },
        { name: 'properties' },
        { name: 'associative-args' },
        { name: 'usages' }
    ];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private userService: UserService
    ) {
    }

    ngOnInit() {
        this.user = this.userService.getUser();
        this.syntaxitems = synjson.sort(function (syntax1: any, syntax2: any) {
            if (syntax1.name < syntax2.name) {
                return -1;
            } else if (syntax1.name > syntax2.name) {
                return 1;
            } else {
                return 0;
            }
        });
        this.filteredItems = synjson.sort(function (syntax1: any, syntax2: any) {
            if (syntax1.name < syntax2.name) {
                return -1;
            } else if (syntax1.name > syntax2.name) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    public filterSyntax(event: any) {
        let filteredJSON: Array<any> = [];
        this.syntaxitems.forEach((item: any) => {
            let flag = false;
            this.allcolumns.forEach((column: any) => {
                if (this.findValue(item[column.name], event.target.value)) {
                    flag = true;
                }
            });
            if (flag) {
                filteredJSON.push(item);
            }
        });
        this.filteredItems = filteredJSON;
    }

    public findValue(item: any, value: any): boolean {
        let bool = false;
        if (typeof item === 'object') {
            for (let key of Object.keys(item)) {
                bool = bool || this.findValue(item[key], value);
            }
        }
        else {
            if (item.toString().toLowerCase().match(this.escapeRegExp(value.toLowerCase()))) {
                bool = true;
            }
        }
        return bool;
    }

    public escapeRegExp(str: string) {
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    }

    public showExpandedItem(itemNum: number) {
        let index = this.expandedItemIndex.indexOf(itemNum);
        if (index > -1) {
            return true;
        }
        else {
            return false;
        }
    }

    public showLegendItem() {
        return this.showLegend;
    }

    public toggleLegendExpansion() {
        this.showLegend = !this.showLegend;
    }

    public isItemEmpty(item: any) {
        let bool = false;
        if (item.properties !== '') {
            bool = item.properties['ops-transparant'] !== 'true' &&
                item.properties['ops-commass'] !== 'true' &&
                item.properties.deprecated !== 'true' &&
                item.properties.internal !== 'internal';
        }
        if (item.usages !== '') {
            bool = false;
        }
        if (item["associative-args"] !== '') {
            bool = false;
        }
        return bool;
    }

    public toggleItemExpansion(itemNum: number, isEmpty: boolean) {

        if (!isEmpty) {
            let index = this.expandedItemIndex.indexOf(itemNum);
            if (index > -1) {
                this.expandedItemIndex.splice(index, 1);
            }
            else {
                this.expandedItemIndex.push(itemNum);
            }
        }
    };

}