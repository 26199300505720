<form [formGroup]="form">
    <div class="row" *ngIf="editorAssetResolve">
        <div class="col-xs-6">
            <input type="text" name="formulaEditorId" formControlName="formulaEditorId" class="form-control" title="name" placeholder="Formula editor ID"
                [(ngModel)]="editorAssetResolve.formulaEditorId" />
        </div>
        <div class="col-xs-6">
            <input type="text" name="assetId" formControlName="assetId" class="form-control" title="name" placeholder="Asset ID"
                [(ngModel)]="editorAssetResolve.assetId" />
        </div>
    </div>
</form>