
import {filter} from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CmsCourseAudience } from "../../types/navigator.types";
import { BehaviorSubject } from "rxjs";
import { ICMS2ExerciseSpec } from '../../types/cms2-metadata.types';
import { CmsInteractionComponent } from "../../../algebrakit/components/cms-interaction/cms-interaction.component";
import { RandomizedAlgebraPreviewComponent } from "../randomized-algebra-preview/randomized-algebra-preview.component";
import { TypeHelpers } from '../../util/type-helpers';

@Component({
    selector: 'exercise-script-preview',
    templateUrl: './exercise-script-preview.component.html',
    styleUrls: ['./exercise-script-preview.component.css']
})
export class ExerciseScriptPreviewComponent implements OnInit {

    interactionSpec: ICMS2ExerciseSpec;
    testSpec: ICMS2ExerciseSpec;

    _exerciseSpec: BehaviorSubject<ICMS2ExerciseSpec> = new BehaviorSubject(null);

    @ViewChild('cmsInteractionComponent', {static: false}) cmsInteractionComponent: CmsInteractionComponent;
    @ViewChild('algebraPreviewComponent', {static: false}) algebraPreviewComponent: RandomizedAlgebraPreviewComponent;

    @Input()
    set exercise(exercise: ICMS2ExerciseSpec) {
        this._exerciseSpec.next(exercise);
    }

    @Input() scoringModel: string;

    @Input() contentVersion: string;

    refresh() {
        if (this.algebraPreviewComponent) {
            this.algebraPreviewComponent.clearDebug();
        }
        let exercise = this._exerciseSpec.value;
        if (TypeHelpers.isSingleInteraction(exercise) === 'ALGEBRA' && exercise.script) {
            this.algebraPreviewComponent.generate();
        } else {
            this.cmsInteractionComponent.generate();
        }
    }

    @Input()
    audience: CmsCourseAudience;

    @Input()
    showAudienceSelector: boolean = true;

    ngOnInit(): void {
        this._exerciseSpec.pipe(
            filter(exercise => exercise !== null))
            .subscribe(exercise => {
                this.interactionSpec = null;
                this.testSpec = null;
                if (TypeHelpers.isSingleInteraction(exercise) === 'ALGEBRA' && exercise.script) {
                    //use CMS format, that is what randomized-algebra-preview expects
                    this.testSpec = { ...exercise };
                    if (this.audience) {
                        this.testSpec.audience = this.audience.baseAudienceId;
                        this.testSpec.course = this.audience.courseId;
                    }
                } else {
                    this.interactionSpec = { ...exercise };
                    if (this.audience) {
                        this.interactionSpec.audience = this.audience.baseAudienceId;
                        this.interactionSpec.course = this.audience.courseId;
                    }
                }
            });
    }

}