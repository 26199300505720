
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { VersioningService } from '../../services/versioning.service';

declare let $: any;

const pageSize: number = 20;

@Component({
    templateUrl: './version-viewer-container.component.html',
    styleUrls: ['./version-viewer-container.component.css']
})
export class VersionViewerContainer implements OnInit {

    id: string = null;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private versioningService: VersioningService,
    ) {
    }

    ngOnInit(): void {
        this.route.params
            .subscribe((params: Params) => {
                this.id = params['id']
            });
    }
    
}

