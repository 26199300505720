<div class="akit-stacked-tabs-wrapper">
    <div class="akit-stacked-inner-wrapper">
        <div *ngFor="let tab of tabs; let i = index">
            <div class="btn-group" [ngClass]='{active: tab.active, inactive: !tab.active, invalid: !isValid(tab)}' (click)="selectTab(tab, i)">
                <button type="button" class="btn" style='max-width:34px;'>
                    <span class='tab-title' [innerHTML]="tab.tabTitle"></span>
                    <!-- 'invalid' symbol
                    <span [ngClass]="{'invalid':!isValid(tab)}" class="fa fa-exclamation-circle"></span> -->
                </button>
                <!-- dropdown button -->
                <button type="button" class="btn dropdown-toggle dropdown-toggle-split" *ngIf="tab.tabOptions && tab.tabOptions.length>0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="fa fa-caret-down"></span>
                </button>
                <!-- transparent dropdown button, to make sure width is the same -->
                <button type="button" class="btn dropdown-toggle dropdown-toggle-split" *ngIf="!tab.tabOptions || tab.tabOptions.length==0">
                    <span class="fa fa-caret-down" style="color:transparent"></span>
                </button>
                <div class="dropdown-menu">
                    <a class='dropdown-item' (click)='tab.onOption(option.id)' *ngFor='let option of tab.tabOptions'>
                       <div><span class='fa' [ngClass]='option.icon'></span> {{option.name}}</div></a>
                </div>
            </div>
        </div>
        
        <div class="btn-group btn-blank" *ngIf="allowAdd">
            <button type="button" class="btn" (click)="createTab()" style='max-width:34px;'>
                <span class="fa fa-plus"></span>
            </button>
            <button type="button" class="btn dropdown-toggle dropdown-toggle-split">
                <span class="fa fa-caret-down" style="color:transparent"></span>
            </button>
            <div class="dropdown-menu">

            </div>
        </div>
    </div>
    <div class="akit-stacked-content-wrapper">
        <ng-content></ng-content>
    </div>
    <div style="clear:both"></div>
</div>

