// @ts-nocheck
import { platformBrowser } from '@angular/platform-browser';
import { enableProdMode } from '@angular/core';

import { BootService } from './app/security/services/boot-service';
import { AppModuleNgFactory } from './app/app/app.module.ngfactory';
import { IAlgebraKIT } from './app/algebrakit/types/algebrakit.type';
import { addWidgetsScript } from './Helpers';

let faviconProd = require("./assets/images/favicon-production.png");
let faviconTest = require("./assets/images/favicon-test.png");
let faviconStaging = require("./assets/images/favicon-staging.png");

declare const AlgebraKIT: IAlgebraKIT;

declare let AKIT_CMS_PROFILE: string;
declare let $: any;

const iconProfiles = {
  'test': faviconTest,
  'production': faviconProd,
  'staging': faviconStaging,
  'staging_detached': faviconTest,
}

function getIconUrl() {
  if (AKIT_CMS_PROFILE == undefined) {
    AKIT_CMS_PROFILE = "";
  }
  let iconUrl = iconProfiles[AKIT_CMS_PROFILE];
  return iconUrl ? iconUrl : faviconProd;
}

$('document').ready(function () {
  $('link[rel="icon"]').attr('href', getIconUrl());
})

require("./styles.css");

enableProdMode();

const init = async () => {
  await addWidgetsScript(AKIT_CMS_PROFILE);
  await AlgebraKIT.initializedPromise;

  platformBrowser().bootstrapModuleFactory(AppModuleNgFactory);
}

// Init on first load
init();

// Init on reboot request
const boot = BootService.getbootControl().watchReboot().subscribe(() => init());
