<div class="metadata-container" *ngIf="forItem.metadata && forItem.metadata.length > 0">
    <div class="row" *ngFor="let entry of forItem.metadata; let index=index;">
        <div class="col-xs-10 col-sm-7">
            <metadata-editor [metadata]="entry" (onMetadataChanged)="checkMetadataFields($event)"></metadata-editor>
        </div>
        <div class="col-xs-2 col-sm-2 form-text">
            <a class="button" (click)="addMetadata(index)" title="Add an attribute">
                <span class="fa fa-lg fa-plus"></span>
            </a>
            <a class="button" (click)="removeMetadata(index)" title="Delete this attribute">
                <span class="fa fa-lg fa-times"></span>
            </a>
        </div>
    </div>
</div>
<div *ngIf="!forItem.metadata || forItem.metadata.length===0">
    <p><span>No additional metadata defined.</span> <help-tooltip ttip="You can add any metadata here. Note that you can also add metadata to folders. These will be automatically included in the containing exercises." rlink="/help/quickguide/authoring_concepts"></help-tooltip></p>
    <button (click)="addMetadata()">
        <span class="fa fa-lg fa-plus"></span>
        <span>Add entry</span>
    </button>
</div>

<div class="row" *ngFor="let mdError of metadataErrors">
    <div class="col-xs-12">
        <div class="alert alert-danger">
            <i class="fa fa-times-circle" aria-hidden="true"></i> {{mdError}}
        </div>
    </div>
</div>