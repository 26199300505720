import { Component, OnInit } from '@angular/core';

import { User } from '../../../security/types/user.type';
import { UserService } from '../../../security/services/user.service';
import { AppProfileService } from '../../services/app-profile.service';
import { compareVersion } from '../../../authoring/util/sorting';
import { ContentVersionService } from '../../services/content-version-service';
import { AkitVersion } from '../../../algebrakit/types/metadata.type';
import { AkitVersionsService } from '../../../algebrakit/services/akit-versions.service';
import { ActivatedRoute } from '@angular/router';

declare let AKIT_CMS_PROFILE: string;

@Component({
    selector: 'navigation-bar',
    templateUrl: './navigation-bar.component.html',
    styleUrls: ['./navigation-bar.component.less']
})
export class NavigationBarComponent {

    user: User
    profile: string;
    mgmtConsoleUrl: string;

    contentVersion: string;
    defaultContentVersion: string;
    prodContentVersion: string;

    versions: AkitVersion[];
    currentVersion: string;

    get contentVersionMismatch(): boolean {
        return this.prodContentVersion != null && this.contentVersion != null
            ? compareVersion(this.prodContentVersion, this.contentVersion) < 0
            : false;
    }

    get autoAkitVersion(): boolean {
        return window.sessionStorage.getItem('autoAkitVersion') !== 'false';
    }

    constructor(
        private userService: UserService,
        private profileService: AppProfileService,
        private contentVersionService: ContentVersionService,
        private akitVersionsService: AkitVersionsService,
        private route: ActivatedRoute,
    ) {
        this.userService.user$.subscribe(user => {
            this.user = user
        });

        let profilePromise = profileService.getProfile()
            .then(profile => this.profile = profile)
            .catch((error) => {
                this.profile = AKIT_CMS_PROFILE;
            });

        let contentVersionPromise = contentVersionService.getContentVersion().then(v => {
            this.contentVersion = v;
        });

        this.profileService.getManagementConsoleUrl().then(url => {
            this.mgmtConsoleUrl = url;
        });

        Promise.all([profilePromise, contentVersionPromise])
            .then(() => {
                contentVersionService.getProdContentVersion()
                    .then(v => {
                        this.prodContentVersion = v;
                    });

                this.contentVersionService.getAllVersionData(this.profile.includes('local'), true)
                    .then(versionResult => {
                        this.versions = versionResult.versions;
                        this.defaultContentVersion = versionResult.defaultContentVersion;
                    });

                this.akitVersionsService.currentVersion
                    .then(version => {
                        this.currentVersion = version;
                    })
            })
    }

    canDoArithmetic() {
        return this.profile && this.profile != 'production';
    }

    logout(): void {
        this.userService.logout();
    }

    loggedIn = (): boolean => {
        return this.userService.getUser() !== null
    }

    canPublishSnapshots() {
        return this.userService.cnPublishSomeCourse();
    }

    canChangeVersion() {
        return this.userService.canChangeVersion();
    }

    getUpdateProfileUrl() {
        return `http://${this.mgmtConsoleUrl}/user/profile?requestedFrom=cms`;
    }

    getChangePasswordUrl() {
        return `/user/change-password`;
    }

    get environmentName(): string {
        if (this.profile === 'test') {
            return 'Test';
        } else if (this.profile === 'staging') {
            return 'Staging';
        } else if (this.profile === 'staging_detached') {
            return 'Staging Detached';
        } else if (this.profile && this.profile.indexOf('local') > - 1) {
            return 'Local';
        }
        else {
            return '';// we don't want to show anything for production
        }
    }

    navbarClass() {
        let classes: string = "navbar navbar-default navbar-inverse ";
        if (this.profile === 'test') {
            classes += 'navigation-bar-bg-color--test';
        }
        else if (this.profile === 'staging') {
            classes += 'navigation-bar-bg-color--staging';
        }
        else if (this.profile === 'staging_detached') {
            classes += 'navigation-bar-bg-color--staging-detached';
        } else if (this.profile && this.profile.indexOf('local') > - 1) {
            classes += 'navigation-bar-bg-color--local';
        }
        else {
            classes += 'navigation-bar-bg-color';
        }
        return classes;
    }

    versionWarningClick() {
        this.contentVersionService.showVersionAlert(true);
    }

    switchVersion(version: string) {
        //Check if version exists and is not offline
        const targetVersion = this.versions.find(v => v.name === version);
        if (version !== 'auto' && (!targetVersion || targetVersion.offline)) {
            return;
        }

        if (version === 'auto') {
            this.akitVersionsService.switchToAutoVersion(this.route.snapshot);
        }
        else {
            this.akitVersionsService.changeVersionIfRequired(version, this.route.snapshot, true, false);
        }
    }
}
