import { Component, OnInit, ViewContainerRef, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from '../../../security/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../../security/types/user.type';

@Component({
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
    
    user: User;
    
    oldValues: any;

    form: FormGroup;
    usernameCtrl = new FormControl('', Validators.required);
    firstNameCtrl = new FormControl('', Validators.required);
    lastNameCtrl = new FormControl('', Validators.required);
    emailCtrl = new FormControl('', Validators.required);
    passwordCtrl = new FormControl('', Validators.required);
    
    constructor(
        private toastr: ToastrService,
        private formBuilder : FormBuilder,
        private route: ActivatedRoute,
        private location: Location,
        private userService: UserService,
    ) { 
        this.form = this.formBuilder.group({
            username: this.usernameCtrl,
            firstName: this.firstNameCtrl,
            lastName: this.lastNameCtrl,
            email: this.emailCtrl,
            password: this.passwordCtrl
        });
    }
    
    cancel() {
        this.location.back();
    }
    
    onSubmit() {
        let user = {
                ...this.form.value,
            };
        this.userService.updateUser(user.username, user.password, user.email, user.firstName, user.lastName)
            .then(() => {
                this.toastr.success(`User ${user.username} was updated successfully`, 'Success');
                this.location.back();
            })
            .catch((msg:any) => {
                this.toastr.error(msg.message, 'Error');
            });
    }

    ngOnInit() {
        this.user = this.userService.getUser();
        this.form.patchValue({
            ...this.user
        })
        this.oldValues = { 
            ...this.form.value
        };
    }

    formChanged() {
        for (var key in this.oldValues) {
            if (this.oldValues.hasOwnProperty(key)) {
                if(this.oldValues[key] !== this.form.value[key]) {
                    return true;
                }
            }
        }
        return false;
    }
}

