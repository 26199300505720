import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


declare let $: any;

/**
 * Class to define Reference page for authoring environment
 */
@Component({
    selector: 'reference',
    templateUrl: './reference.component.html',
    styleUrls: ['./reference.component.css', '../../../../assets/shared.less']
})
export class ReferenceComponent implements OnInit {
    sub: any;
    component: string;
    protected sectionid: string;
    protected id: string;

    loading: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {
    }

    showComponent(comp: string) {
        return this.component == comp;
    }
    showId(id: string) {
        return this.id == id;
    }
    showQuestions(component: string) {
        this.router.navigate(['/reference', component]);
    }
    showQuestion(component: string, id: string) {
        this.router.navigate(['/reference', component, id]);
    }
    showSectionQuestion(component: string, section: string, id: string) {
        this.router.navigate(['/reference', component, section, id]);
    }
    hasImage(image: String): boolean {
        return image && image.length > 0 //+ Eventueel nog meer checks
            ? true
            : false;
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            var compList = ['syntax', 'modes', 'labels', 'attributes', 'interactions'];
            if (params['component'] != null && compList.indexOf(params['component']) !== -1) {
                this.component = params['component'];
            }
            else {
                this.component = "other";
            }
            var sectionsList = ['functions', 'commands', 'operations'];
            if (params['section'] != null && sectionsList.indexOf(params['section']) !== -1) {
                this.sectionid = params['section'];
            }
            else {
                this.sectionid = "functions";
            }
            if (params['id'] != null) {
                this.id = params['id'];
            }
            else {
                this.id = 'intro';
            }
        });

    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

}