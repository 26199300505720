// component used in exercise-filter-editor to specify the attribute filter (NONE, ALLOW, FORCE)

import {Component, OnInit, Input } from '@angular/core';
import { TopicService } from '../../services/topic.service';

import {CmsTopicFilterLevelAttributeAssignement, CmsAttributeAssignmentType} from '../../types/navigator.types';

const assignmentOrder = [
    CmsAttributeAssignmentType.NONE,
    CmsAttributeAssignmentType.ALLOW,
    CmsAttributeAssignmentType.FORCE,
    CmsAttributeAssignmentType.SUPER_FORCE
]

@Component({
  selector: 'attribute-filter-toggle',
  templateUrl: './attribute-filter-toggle.component.html',
  styleUrls: ['./attribute-filter-toggle.component.css']
})
export class AttributeFilterToggleComponent implements OnInit {
    @Input() attributeAssignment: CmsTopicFilterLevelAttributeAssignement;
    
    public cmsAttributeSetting = CmsAttributeAssignmentType; //to be able to use the enum in the template

    constructor(
        private topicService: TopicService
    ) {     }
 
    getColorComplexity(attribute: CmsTopicFilterLevelAttributeAssignement): {[key:string]:string} {
        return this.topicService.getAttributeColorBg(attribute.difficulty);
    };

    select() {
        let index: number = assignmentOrder.indexOf(this.attributeAssignment.attributeAssignmentType);
        index = (index+1) % assignmentOrder.length;
        this.attributeAssignment.attributeAssignmentType = assignmentOrder[index];                   
    }

    getTitle() {
        switch(this.attributeAssignment.attributeAssignmentType) {
            case CmsAttributeAssignmentType.ALLOW:
                return "Allowed"
            case CmsAttributeAssignmentType.FORCE:
                return "Required"
            case CmsAttributeAssignmentType.SUPER_FORCE:
                return "Mandatory"
        }
        return "Not selected"
    }
    
    ngOnInit() {
    }   
}




