<div class="helpitem-img caption">
    <div class="icon-help-container">
        <span [ngClass]="{'icon': true, 'selected': selectedClass}">
            <i [ngClass]="'fa fa-9x ' + itemClass" aria-hidden="true"></i>
        </span>
        <div class="icon-help-q">
            <span *ngIf="faq" class="faq">
                <i class="fa fa-question-circle"></i>
            </span> 
        </div>
    </div>

    <div class="helpitem-img-container">
        <div [ngClass]="{'helpsubject': true, 'selectedNB': selectedClass}"><ng-content select=".subject"></ng-content></div>
        <div [ngClass]="{'helpdescription': true, 'selectedNB': selectedClass}"><ng-content class="itemdescription" select=".itemdescription"></ng-content></div>
    </div>
</div>