import { Component, Input } from "@angular/core";
import {IDebugInformation } from '../../types/algebrakit.type';

@Component({
    selector: 'debug-view',
    templateUrl: './debug-view.component.html',
    styleUrls: ['./debug-view.component.css']
})
export class DebugViewComponent {

    @Input() debug: IDebugInformation;
    
}