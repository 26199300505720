
import {filter} from 'rxjs/operators';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CmsSubject, CmsCourse, CmsExercise, CmsCourseAudience } from '../../types/navigator.types';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ExerciseService } from '../../services/exercise.service';
import { CourseService } from '../../services/course.service';
import { SubjectService } from '../../services/subject.service';
import { BehaviorSubject } from 'rxjs';
import { getExtendedToastOption } from '../../../app/options/options';
import { IdNamePair } from '../../types/cms2-metadata.types';

declare let $: any;

/**
 * A topic-leaf is a 'file' in the navigation system, which corresponds to an
 * exercise. The model is defined by class of type CmsLeaf. Classes for the various 
 * types of exercises are derived from CmsLeaf
 */
@Component({
    selector: 'move-exercise',
    templateUrl: './move-exercise.component.html',
    styleUrls: ['./move-exercise.component.css']
})
export class MoveExercisesComponent {

    @Input() selectedExercises: CmsExercise[];
    @Input() currentCourseId: string;

    @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSuccess: EventEmitter<void> = new EventEmitter<void>();

    form: FormGroup;

    courseCtrl = new FormControl('', Validators.required);
    audienceCtrl = new FormControl('', Validators.required);

    audiences: CmsCourseAudience[];

    courses: IdNamePair[];
    course$: BehaviorSubject<CmsCourse> = new BehaviorSubject(null);
    subject: CmsSubject;

    refreshTimer: any;

    isInitialized: boolean;

    constructor(
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private exerciseService: ExerciseService,
        private courseService: CourseService,
        private subjectService: SubjectService) {
        this.form = this.formBuilder.group({
            audience: this.audienceCtrl,
        })
    }

    audienceRequired() {
        let exerciseAudiences = this.selectedExercises.map(e => e.audience);
        let courseAudiences = this.course$.value && this.course$.value.audiences
            ? this.course$.value && this.course$.value.audiences
                .map(a => a.resolvedAudience ? a.resolvedAudience : a.baseAudienceId)
            : [];
        return exerciseAudiences.some(a => courseAudiences.indexOf(a) === -1);
    }

    formIsValid() {
        return this.courseCtrl.valid && this.courseCtrl.value != 0 && (
            (this.audienceRequired() && this.audienceCtrl.valid)
            || !this.audienceRequired()
        );
    }

    close() {
        this.onClose.emit();
    }

    commit() {
        let audience = this.audienceRequired()
            ? this.audienceCtrl.value
            : null;
        this.exerciseService.moveExercise(this.selectedExercises.map(e => e.id), this.subject.id, audience)
            .then(() => {
                let course = this.course$.value;
                let messageBody = 'Exercise(s) moved to ';
                let baseUrl = $('base').attr('href');

                if (course.id !== this.selectedExercises[0].id) {
                    messageBody += this.subject.id !== course.rootSubject.id
                        ? `<i>${this.subject.course.name}</i>, subject <a style="text-decoration: underline;" target="_blank" href="${baseUrl}/subject/${this.subject.id}"><i><strong>${this.subject.name || this.subject.id}</strong></i></a>.`
                        : `<a style="text-decoration: underline;" target="_blank" href="${baseUrl}/subject/${this.subject.id}"><i><strong>${this.subject.course.name}</strong></i></a>.`;
                }
                else {
                    messageBody += `<a style="text-decoration: underline;" target="_blank" href="${baseUrl}/subject/${this.subject.id}"><i><strong>${this.subject.name}</strong></i></a>.`;
                }

                this.toastr.toastrConfig.enableHtml = true;
                this.toastr.success(messageBody, 'Success', getExtendedToastOption());
                this.close();
                this.onSuccess.emit();
            })
            .catch((err: any) => {
                this.toastr.error(err.message, 'Error');
            });
    }

    setSubject(id: string): void {
        this.subjectService.getSubject(id).toPromise()
            .then((subject: CmsSubject) => {
                this.subject = subject;
            })
            .catch(err => {
                console.log(err);
                this.toastr.error("Error occurred while retrieving subject", 'Error');
            });
    }

    ngOnInit() {
        this.courseService.getCourseNameList()
            .toPromise()
            .then((arr: IdNamePair[]) => {
                this.courses = arr;
                this.courseCtrl.setValue(this.currentCourseId);
            }).catch((err: any) => {
                console.log(err);
                this.toastr.error('Could not get your courses from the server', 'Error');
            });

        this.courseCtrl.valueChanges.pipe(
            filter(value => value != 0))
            .subscribe(value => {
                this.courseService.getCourse(value)
                    .toPromise()
                    .then(course => {
                        this.course$.next(course);
                        this.audiences = course.audiences;
                        if (this.isInitialized) {
                            this.subjectService.getRootSubject(value)
                                .toPromise()
                                .then(subject => this.subject = subject);
                        }
                        else {
                            this.subjectService.getSubject(this.selectedExercises[0].subject.id)
                                .toPromise()
                                .then(subject => {
                                    this.subject = subject;
                                    this.isInitialized = true;
                                });
                        }
                    });
            });
    }

}



