import { Component, OnInit, Input, OnChanges, forwardRef } from '@angular/core';

interface Entry {
    name: string;
    langMap: { [lang: string]: string };
}

@Component({
    selector: 'collapsable',
    templateUrl: './collapsable.component.html',
    styleUrls: ['./collapsable.component.css']
})
export class CollapsableComponent {
    @Input() heading: string;
    @Input() bgColor: string = 'rgb(240,256,256)';
    @Input() borderColor: string = 'lightgray';
    @Input() dividerColor: string = 'lightblue';

    visible: boolean;

    getOpenStyle(visible: boolean) {
        let style = {
            'background-color': (this.bgColor !== 'none' && visible) ? this.bgColor : '',
            'border': (this.borderColor !== 'none' && visible) ? `1px solid ${this.borderColor}` : ''
        };
        return style;
    }

    getDividerStyle() {
        return {
            'border-bottom': this.dividerColor !== 'none' ? ('1px solid ' + this.dividerColor) : ''
        };
    }
}

