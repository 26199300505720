<div class="exercise-filter-editor">
    <div class="panel panel-default">
        <div class="panel-heading">
            <h3 class="panel-title">Move folder</h3>
        </div>
        <div class="panel-body">
            <div class="row row-spacing">
                <div class="col-sm-2">
                    <label>Selected folder</label>
                </div>
                <div class="col-sm-10">
                    <span>{{_selectedFolder.value?.name}}</span>
                </div>
            </div>
            <div class="row row-spacing" *ngIf="folderType == 'SUBJECT'">
                <div class="col-sm-2">
                    <label>Course</label>
                </div>
                <div class="col-sm-10">
                    <select class='form-control' [formControl]="courseCtrl">
                        <option value="0" selected>Select a course</option>
                        <ng-container *ngFor="let course of courses">
                            <option value="{{course.id}}">{{course.name}}
                            </option>
                        </ng-container>
                    </select>
                </div>
            </div>
            <div class="row row-spacing">
                <div class="col-sm-2">
                    <label>Folder</label>
                </div>
                <div class="col-sm-10">
                    <breadcrumb [folder]="targetFolder" (parentChange)='setSubject($event)' [showChilds]="true">
                    </breadcrumb>
                </div>
            </div>
            <div class="row row-spacing" *ngIf="folderType == 'SUBJECT' && missingAudiences && missingAudiences.length > 0">
                <div class="col-xs-12">
                    <p>
                        The following audiences are are not present in the selected course:
                    </p>
                    <ul>
                        <li *ngFor="let audience of missingAudiences">{{audience}}</li>
                    </ul>
                    <p>
                        All exercises containing these audiences need to be updated.
                        Select a new audience that will be assigned to these exercises
                    </p>
                </div>
                <div class="col-sm-2">
                    <label>Audience</label>
                </div>
                <div class="col-sm-4">
                    <select [formControl]="audienceCtrl" class='form-control'>
                        <option *ngFor="let audience of audiences" value="{{audience.baseAudienceId}}">
                            {{audience.name}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="row row-spacing">
        <div class="col-xs-3">
            <button class="btn btn-md btn-primary btn-block" type="button" (click)="commit()" [disabled]="!canConfirm()">
                <i class="fa fa-save"></i>
                <span>Confirm</span>
            </button>
        </div>
        <div class="col-xs-3">
            <button class="btn btn-md btn-default btn-block" type="button" (click)="close()">
                <i class="fa fa-ban"></i>
                <span translate>Close</span>
            </button>
        </div>
    </div>

</div>