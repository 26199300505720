
import { switchMap, map, filter } from 'rxjs/operators';
import { Component, OnInit, ViewChild, HostListener, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { UUID } from 'angular2-uuid';

import { CmsTopic, CmsExerciseScript, CmsItem, CmsItemAction, NameIdPair, CmsContainerType, CheckedEvent, CmsItemState, ReferencedBy, SortBy, CmsTopicAttributeAssignment, LibrarySearchResult, BrowseMode, ExportType } from '../../types/navigator.types';
import { TopicService } from '../../services/topic.service';
import { ExerciseScriptService } from '../../services/exercise-script.service';
import { UserService } from '../../../security/services/user.service';
import { ExerciseScriptPreviewComponent } from '../../components/exercise-script-preview/exercise-script-preview.component';
import { compareVersion, Sorting } from '../../util/sorting';
import { TypeHelpers } from '../../util/type-helpers';
import { SearchService } from '../../services/search.service';
import { SearchFieldComponent } from '../../components/search-field/search-field.component';
import { AppProfileService } from '../../../app/services/app-profile.service';
import { ContentVersionService } from '../../../app/services/content-version-service';
import { AkitVersionsService } from '../../../algebrakit/services/akit-versions.service';

declare let $: any;
declare let AlgebraKIT: any;

@Component({
    selector: 'topic-navigator',
    templateUrl: './topic-navigator.component.html',
    styleUrls: ['./topic-navigator.component.less', '../../../../assets/shared.less']
})
export class TopicNavigatorComponent implements OnInit, OnDestroy {

    //If used as a component, these properties can be used
    @Input() browseMode: BrowseMode = BrowseMode.EDIT;
    @Input() useAsComponent: boolean = false; //Must be set if used as a component!
    @Input() showDeprecated: boolean = true;
    @Input() interactionBlacklist: string[] = [];
    @Input() incompatibleExercisesSelectable = true;

    @Output() selectedChanged: EventEmitter<CmsExerciseScript[]> = new EventEmitter();

    currentFolder: BehaviorSubject<CmsTopic> = new BehaviorSubject(null);

    _exercises: CmsExerciseScript[];
    _searchExercises: CmsExerciseScript[];

    get exercises() {
        return this._exercises.filter(ex =>
            !ex.interactionTypes || !this.interactionBlacklist || ex.interactionTypes.every(type => this.interactionBlacklist.indexOf(type) === -1)
        );
    }

    get searchExercises() {
        return this._searchExercises.filter(ex =>
            !ex.interactionTypes || !this.interactionBlacklist || ex.interactionTypes.every(type => this.interactionBlacklist.indexOf(type) === -1)
        );
    }

    searchTopics: CmsTopic[];

    selectedExercises: { [id: string]: boolean } = {};
    exportScripts: NameIdPair[] = [];

    referenceExporterVisible: boolean = false;
    specExporterVisible: boolean = false;

    childFolders: CmsTopic[];
    rootFolder: CmsTopic;
    trashFolder: Partial<CmsTopic>;
    isDirty: boolean = false; //if true, it needs refresh 
    testExerciseScript: CmsExerciseScript;

    busy: boolean = false;
    searching: boolean = false;
    searchString: string;
    deleteChecking: boolean = false;

    deleteCheckResult: ReferencedBy[];
    exerciseToDelete: CmsExerciseScript;

    lastSearchToken: string = null;

    historyItem: CmsItem;

    currentSortBy: SortBy = SortBy.DIFFICULTY;
    currentSortOrder = 1;

    @ViewChild('previewComponent', { static: false }) previewComponent: ExerciseScriptPreviewComponent;
    @ViewChild('librarySearchField', { static: false }) librarySearchField: SearchFieldComponent;

    scriptsToMove: CmsExerciseScript[] = [];
    topicToMove: CmsTopic;

    moveScriptsDialogVisible: boolean;
    moveTopicDialogVisible: boolean;

    currentVersion: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private topicService: TopicService,
        private exerciseScriptService: ExerciseScriptService,
        private userService: UserService,
        private toastr: ToastrService,
        private searchService: SearchService,
        private profileService: AppProfileService,
        private contentVersionService: ContentVersionService,
        private akitVersionsService: AkitVersionsService
    ) {
        let url: string = '/' + this.route.snapshot.url.map(u => u.path).join('/');
        this.router.events.subscribe(val => {
            this.exerciseToDelete = null;
            this.deleteCheckResult = null;
            if (this.isDirty && val instanceof NavigationEnd && val.url === url) {
                this.isDirty = false;
                this.refresh();
            }
        })
    }

    //Close modals by pressing ESC
    @HostListener('document:keyup', ['$event'])
    onKeyUp(ev: KeyboardEvent) {
        if (!ev.key) {
            return;
        }
        if (ev.key.toLowerCase() === 'escape') {
            this.closeModal();
        }
    }

    setParent(id: string): void {
        this.search("");

        if (this.librarySearchField) {
            this.librarySearchField.clear();
        }

        this.referenceExporterVisible = false;

        this.exportersClose();

        if (!this.useAsComponent) {
            this.router.navigate(['topic', id]);
        }
        else {
            this.topicService.getTopic(id)
                .toPromise()
                .then(topic => this.currentFolder.next(topic))
        }
    }

    moveExerciseScripts(scripts?: CmsExerciseScript[]) {
        this.exportersClose();
        this.scriptsToMove = scripts ? scripts : this.getSelectedExerciseScripts();
        if (this.scriptsToMove.length === 0) {
            this.toastr.warning("Please select at least one item", "Warning");
            return;
        }
        this.moveScriptsDialogVisible = true;
        window.scrollTo(0, 0)
    }

    moveTopic(topic: CmsTopic) {
        this.exportersClose();
        this.topicToMove = topic;
        if (!this.topicToMove) {
            this.toastr.warning("Please select a topic", "Warning");
            return;
        }
        this.moveTopicDialogVisible = true;
        window.scrollTo(0, 0);
    }

    exportersClose() {
        this.moveScriptsDialogVisible = false;
        this.moveTopicDialogVisible = false;
    }


    copyExercise(id: string): void {
        this.exerciseScriptService.copyExercise(id)
            .then(() => this.refresh())
            .catch(err => {
                console.log(err);
                this.toastr.error('Could not create a copy of this exercise', 'Error');
            })
    }

    copyExercises() {
        let ids: string[] = this.getSelectedExerciseScriptIds().map(e => e.id);
        this.exerciseScriptService.copyExerciseScripts(ids)
            .then(() => {
                this.refresh()
                this.toastr.success("Succesfully copied the selected exercises");
            });
    }

    refresh() {
        this.topicService.getTopic(this.currentFolder.value.id)
            .toPromise().then(topic => this.currentFolder.next(topic));
    }

    currentFolderIsRoot() {
        return this.currentFolder.value.rootFolder || this.currentFolder.value.id === 'root';
    }

    deleteExercise(exercise: CmsExerciseScript): void {
        this.deleteChecking = true;
        this.exerciseToDelete = exercise;
        this.exerciseScriptService.getReferencedExercises(exercise.id).toPromise()
            .then((result) => {
                this.deleteChecking = false;
                this.deleteCheckResult = result;
                if (!result || result.length == 0) {
                    if (confirm(`Are you sure you want to delete the exercise '${exercise.name}'?`)) {
                        this.deleteConfirm(exercise.id);
                    }
                }
                else {
                    window.scrollTo(0, 0);
                }
            }).catch((err) => {
                this.deleteChecking = false;
                console.log(err);
                if (!err.originalError || !this.exerciseScriptService.showLockErrorMessage(err.originalError, false)) {
                    this.toastr.error('Could not delete this exercise', 'Error');
                }
            });
    }

    sortBy(sortBy: SortBy, sortType?: number) {
        sortType = sortType ? sortType : this.currentSortOrder;
        this.currentSortBy = sortBy;
        switch (sortBy) {
            case SortBy.DIFFICULTY:
                this._exercises = Sorting.sortItemsByDifficulty(
                    this._exercises,
                    !this.searching ? this.currentFolder.value.attributeAssignments : [],
                    sortType
                );
                break;
            default:
                this._exercises = Sorting.sortItemsByOtherProperty(
                    this._exercises,
                    sortBy,
                    sortType
                );
                break;
        }

        //move deprecated scripts to end
        let deprecated = [];
        for (let i = this._exercises.length - 1; i >= 0; i--) {
            let ex = this._exercises[i];
            if (ex.state != CmsItemState.DEPRECATED) {
                continue;
            }
            deprecated.unshift(ex);
            this._exercises.splice(i, 1);
        }
        this._exercises = this._exercises.concat(deprecated);
    }

    onSortDropDownChange() {
        this.sortBy(this.currentSortBy, this.currentSortOrder);
    }

    deleteConfirm(id: string) {
        this.exerciseScriptService.deleteExercise(id)
            .then(() => {
                this.toastr.success('Exercise template was succesfully deleted', 'Success');
                this.refresh();
            }).catch(err => {
                console.log(err);
                if (!err.originalError || !this.exerciseScriptService.showLockErrorMessage(err.originalError, false)) {
                    this.toastr.error('Could not delete this exercise', 'Error');
                }
            })
    }

    deleteGoal(topic: CmsItem): void {
        this.topicService.checkDeleteTopic(topic.id)
            .then(() => {
                if (confirm(`Are you sure you want to delete the topic '${topic.name}'?`)) {
                    this.topicService.deleteTopic(topic.id)
                        .then(() => {
                            this.refresh();
                        }).catch(err => {
                            console.log(err);
                            this.handleDeleteTopicError(err);
                        })
                }
            })
            .catch(error => {
                this.handleDeleteTopicError(error);
            })
    }

    handleDeleteTopicError(error: any) {
        console.log(error);
        let msg = 'Could not delete this topic';
        if (error.message) {
            msg = msg + ': ' + error.message;
        }
        this.toastr.error(msg, 'Error');
    }

    clearSelected(exerciseList: CmsExerciseScript[]) {
        this.selectedExercises = {};
        for (let script of exerciseList) {
            this.selectedExercises[script.id] = false;
        }
        this.emitSelected();
    }

    emitSelected() {
        let selected: NameIdPair[] = this.getSelectedExerciseScriptIds();
        let selection = this._exercises.filter(s => selected.find(e => e.id == s.id) != null);
        this.selectedChanged.emit(selection);
    }

    getColorForAttribute(attribute: CmsTopicAttributeAssignment): { [key: string]: string } {
        return this.topicService.getAttributeColorBg(attribute.difficulty);
    };

    closeModal() {
        var modal = $('.exercise-demo').first();
        if (modal) modal.modal('hide');
    }

    editExercise(id: string): void {
        this.closeModal();

        this.isDirty = true; //name of exercise might change, so refresh when we return from editing
        this.router.navigate(['edit/exercise', id])
            .catch((error) => {
                this.exerciseScriptService.showLockErrorMessage(error);
            });
    }

    editTopic(id: string) {
        this.isDirty = true; //name of exercise might change, so refresh when we return from editing
        this.router.navigate(['edit/topic', id]);
    }

    restoreTopic(id: string) {
        this.topicService.restoreTopic(id)
            .then(() => this.refresh())
            .catch((error) => this.toastr.error(error.message, "Error"));
    }

    restoreExercise(id: string) {
        this.exerciseScriptService.restoreExercise(id)
            .then(() => this.refresh())
            .catch((error) => this.toastr.error(error.message, "Error"));
    }

    createExercise(): void {
        let ex: CmsExerciseScript = this.exerciseScriptService.createExercise(
            {
                id: this.currentFolder.value.id,
                name: this.currentFolder.value.name,
                containerType: CmsContainerType.FOLDER
            }
        );
        this.exerciseScriptService.storeExercise(ex)
            .then((id: string) => {
                this.refresh();
                this.editExercise(id);
            }, err => {
                console.log(err);
                this.toastr.error('Could not store the new exercise', 'Error');
                this.refresh();
            });
    }

    createTopic(): void {
        let topic: CmsTopic = this.topicService.createTopic(this.currentFolder.value);
        this.topicService.storeTopic(topic)
            .then((id: string) => {
                this.refresh();
                this.editTopic(id);
            }).catch(err => {
                console.log(err);
                this.toastr.error('Could not store the new topic', 'Error');
                this.refresh();
            });
    }

    async testExercise(exercise: CmsExerciseScript): Promise<void> {
        if (compareVersion(this.currentVersion, exercise.contentVersion) < 0) {
            //Exercise version is too high, cannot preview.
            return;
        }
        this.historyItem = null;
        let self = this;
        //Set theme back to akit
        await AlgebraKIT._api.changeTheme('akit');

        this.testExerciseScript = exercise;
        if (exercise.definition && !exercise.definition.audience)
            exercise.definition.audience = 'uk_KS5';
        setTimeout(() => {
            var modal = $('.exercise-demo').first();
            modal.modal('show').on('hide.bs.modal', function () {
                self.testExerciseScript = null;//trigger destroy of widgets
            });
        }, 0)
    }

    refreshTest() {
        this.previewComponent.refresh();
    }

    canEditTopicsScripts(): boolean {
        return this.userService.getUser() && this.userService.getUser().canEditLibrary();
    }

    inTrash() {
        let current = this.currentFolder.value;
        return current.trash
            || (current.parents && current.parents[0] && current.parents[0].trash);
    }

    createReferences(exerciseScripts?: CmsExerciseScript[]) {
        this.exporterClose();
        this.exportScripts = exerciseScripts ? exerciseScripts.map(e => { return { id: e.id, name: e.name }; }) : this.getSelectedExerciseScriptIds();
        if (this.exportScripts.length === 0) {
            this.toastr.warning("Please select at least one item", "Warning");
            return;
        }
        if (!this.checkIfExportIsAllowed(ExportType.REFERENCE, exerciseScripts)) {
            this.toastr.warning("You can only export exercises that are published", "Warning");
            return;
        }
        this.referenceExporterVisible = true;
        window.scrollTo(0, 0)
    }

    createClientSpecs(exerciseScripts?: CmsExerciseScript[]) {
        this.exporterClose();
        this.exportScripts = exerciseScripts ? exerciseScripts.map(e => { return { id: e.id, name: e.name }; }) : this.getSelectedExerciseScriptIds();
        if (this.exportScripts.length === 0) {
            this.toastr.warning("Please select at least one item", "Warning");
            return;
        }
        if (!this.checkIfExportIsAllowed(ExportType.CLIENT_SPEC, exerciseScripts)) {
            this.toastr.warning("You can only export exercises that are approved", "Warning");
            return;
        }
        this.specExporterVisible = true;
        window.scrollTo(0, 0)
    }

    exporterClose() {
        this.referenceExporterVisible = false;
        this.specExporterVisible = false;
    }

    getCurrentExercises(): CmsExerciseScript[] {
        return this.searching ? this._searchExercises : this._exercises;
    }

    getSelectedExerciseScriptIds(): NameIdPair[] {
        let ids: string[] = [];
        Object.keys(this.selectedExercises).forEach((key) => {
            if (this.selectedExercises[key] === true) {
                ids.push(key);
            }
        });
        let result = this._exercises
            .filter(e => ids.indexOf(e.id) !== -1)
            .map(e => {
                return {
                    id: e.id,
                    name: e.name
                };
            });
        return result;
    }

    getSelectedExerciseScripts(): CmsExerciseScript[] {
        let result = this._exercises
            .filter(e => this.selectedExercises[e.id] == true);
        return result;
    }

    allSelected() {
        return this.getCurrentExercises().every(e => !!this.selectedExercises[e.id]);
    }

    checkAll(e: any) {
        let checked: boolean = !!e.target.checked;
        this.selectedExercises = {};
        for (let script of this.getCurrentExercises()) {
            if (checked) {
                this.selectedExercises[script.id] = true;
            } else {
                this.selectedExercises[script.id] = false;
            }
        }
        this.emitSelected();
    }

    exerciseSelectedChanged(event: CheckedEvent<CmsExerciseScript>) {
        let script = this.getCurrentExercises().find(e => e.id === event.item.id);
        if (!script) {
            return;
        }
        if (event.checked) {
            this.selectedExercises[script.id] = true;
        }
        else if (!event.checked) {
            this.selectedExercises[script.id] = false;
        }
        this.emitSelected();
    }

    _checkIfExportIsAllowed(exportType: ExportType, script: CmsExerciseScript) {
        if (exportType == ExportType.REFERENCE && (!script.latestVersionNumbers || !script.latestVersionNumbers.publishedMajorVersion)) {
            return false;
        }
        else if (exportType == ExportType.CLIENT_SPEC && script.state != CmsItemState.APPROVED) {
            return false;
        }
        else {
            return true;
        }
    }

    checkIfExportIsAllowed(exportType: ExportType, exerciseScripts?: CmsExerciseScript[]) {
        if (exerciseScripts) {
            return exerciseScripts.every(script => this._checkIfExportIsAllowed(exportType, script));
        }
        return this.exportScripts
            .filter(script => this.selectedExercises[script.id] != null)
            .map(script => this._exercises.find(e => e.id == script.id))
            .every(script => this._checkIfExportIsAllowed(exportType, script));
    }

    getLatestVersionNumbers() {
        if (!this._exercises || this._exercises.length == 0) {
            return;
        }
        this.exerciseScriptService.getLatestVersionNumbers(this._exercises)
            .then(versionNumbers => {
                for (let script of this._exercises) {
                    script.latestVersionNumbers = versionNumbers[script.id];
                }
            })
    }

    getVersionMismatchError(exercise: CmsExerciseScript) {
        return this.isVersionMismatch(exercise)
            ? "This exercise was edited in a newer version and cannot be opened"
            : null;
    }

    isVersionMismatch(exercise: CmsExerciseScript) {
        return compareVersion(this.currentVersion, exercise.contentVersion) < 0
    }

    search(searchString: string) {
        if (!searchString || searchString.length === 0) {
            this.searching = false;
            this._searchExercises = [];
            this.searchTopics = []
            this.clearSelected(this._exercises);
            return;
        }
        this.searching = true;
        this.busy = true;
        this.lastSearchToken = UUID.UUID();
        this.searchString = searchString;
        this.searchService.librarySearch(this.currentFolder.value.id, searchString, this.lastSearchToken, this.browseMode === BrowseMode.VIEW).toPromise()
            .then((result: LibrarySearchResult) => {
                if (result.token !== this.lastSearchToken) {
                    return;
                }

                let exercises: CmsExerciseScript[] =
                    result.exerciseScripts.map((elm: CmsExerciseScript) => {
                        return this.exerciseScriptService._getExerciseFromJson(elm)
                    });

                //exercises = sortExercisesByDifficulty(exercises, []);
                this._searchExercises = exercises.map(item => {
                    item.iconClass = TypeHelpers.getIconClass(item);
                    item.onClick = (item: CmsExerciseScript) => this.testExercise(item);
                    item.actions = this.exerciseActions;
                    return item;
                });

                this.searchTopics = result.topics.map(item => {
                    item.iconClass = 'fa fa-folder';
                    item.onClick = (item: CmsItem) => {
                        this.setParent(item.id);
                    };
                    item.actions = this.folderActions;
                    return item;
                });

                this.sortBy(this.currentSortBy, this.currentSortOrder);
                this.clearSelected(this._searchExercises);
                this.busy = false;
            });
    }

    getDump() {
        window.location.href = "api/topic/dump";
    }

    showHistory(leaf: CmsExerciseScript) {
        if (leaf.state !== CmsItemState.APPROVED) {
            alert("No history available for this item");
            return;
        }
        this.testExerciseScript = null;
        this.historyItem = leaf;
        let self = this;
        setTimeout(() => {
            var modal = $('.item-history').first();
            modal.modal('show').on('hide.bs.modal', function () {
                self.historyItem = null;
            });
        }, 0)
    }

    exerciseActions: CmsItemAction[] = [
        {
            name: (this.canEditTopicsScripts() ? 'edit' : 'view'),
            iconClass: (this.canEditTopicsScripts() ? 'fa fa-lg fa-pencil-square' : 'fa fa-lg fa-eye'),
            callback: ((leaf: CmsExerciseScript) => this.editExercise(leaf.id)),
        },
        {
            name: 'copy',
            iconClass: 'fa fa-lg fa-files-o',
            callback: ((leaf: CmsExerciseScript) => this.copyExercise(leaf.id)),
            adminOnly: true
        },
        {
            name: 'create single reference',
            iconClass: 'fa fa-lg fa-link',
            callback: ((leaf: CmsExerciseScript) => this.createReferences([leaf]))
        },
        {
            name: 'create editable copy',
            iconClass: 'fa fa-lg fa-share',
            callback: ((leaf: CmsExerciseScript) => this.createClientSpecs([leaf]))
        },
        {
            name: 'delete',
            iconClass: 'fa fa-lg fa-times',
            callback: ((leaf: CmsExerciseScript) => this.deleteExercise(leaf)),
            adminOnly: true
        },
        {
            name: 'history',
            iconClass: 'fa fa-lg fa-clock-o',
            callback: ((leaf: CmsExerciseScript) => this.showHistory(leaf)),
            adminOnly: true
        }];

    exerciseActionsTrash: CmsItemAction[] = [
        {
            name: 'restore',
            iconClass: 'fa fa-lg fa-undo',
            callback: ((leaf: CmsExerciseScript) => this.restoreExercise(leaf.id)),
            adminOnly: true
        }, {
            name: 'delete',
            iconClass: 'fa fa-lg fa-times',
            callback: ((leaf: CmsExerciseScript) => this.deleteExercise(leaf)),
            adminOnly: true
        }];

    folderActions: CmsItemAction[] = [
        {
            name: 'edit',
            iconClass: 'fa fa-lg fa-pencil-square',
            callback: (topic => this.editTopic(topic.id)),
            adminOnly: true
        }, {
            name: 'move',
            iconClass: 'fa fa-lg fa-share',
            callback: ((leaf: CmsTopic) => this.moveTopic(leaf)),
            adminOnly: true
        }, {
            name: 'delete',
            iconClass: 'fa fa-lg fa-times',
            callback: (leaf => this.deleteGoal(leaf)),
            adminOnly: true
        }];

    folderActionsTrash: CmsItemAction[] = [
        {
            name: 'restore',
            iconClass: 'fa fa-lg fa-undo',
            callback: (topic => this.restoreTopic(topic.id)),
            adminOnly: true
        }, {
            name: 'delete',
            iconClass: 'fa fa-lg fa-times',
            callback: (leaf => this.deleteGoal(leaf)),
            adminOnly: true
        }];

    getRootAndTrash(root?: CmsTopic) {

        let promise;
        if (this.browseMode === BrowseMode.EDIT) {
            promise = this.topicService.getTopicRef('trash').toPromise()
                .then(goal => {
                    this.trashFolder = {
                        ...goal,
                        iconClass: 'fa fa-trash',
                        onClick: (item: CmsItem) => this.setParent(item.id)
                    };
                }).then(() => {
                    if (root) {
                        return root;
                    }
                    return this.topicService.getRootTopic().toPromise();
                });
        } else {
            promise = this.topicService.getRootTopic().toPromise();
        }

        promise.then(goal => {
            goal.iconClass = 'fa fa-home';
            goal.onClick = (item: CmsItem) => this.setParent(item.id),
                goal.name = 'home';
            this.rootFolder = goal;
            this.currentFolder.next(goal);
            this.busy = false;
        })
            .catch(err => {
                this.toastr.error('Could not retrieve topic', 'Error');
                console.log(err.message);
            });
    }

    ngOnInit() {
        this.contentVersionService.getContentVersion().then(contentVersion => this.currentVersion = contentVersion);
        this.route.data
            .subscribe((data: { topic: CmsTopic }) => {
                this.busy = true;

                if (!data || !data.topic) {
                    this.getRootAndTrash();
                }
                else if (data.topic.rootFolder || data.topic.id === 'root') {
                    this.getRootAndTrash(data.topic);
                }
                else {
                    this.currentFolder.next(data.topic);
                    this.busy = false;
                }
            });

        this.currentFolder.pipe(
            filter(folder => folder != null),
            map(folder =>
                folder.childFolders
                    .map(childRef => ({
                        name: childRef.name,
                        id: childRef.id,  //id, name, courseId, parents, childFolders, childLeafs
                        courseId: null,
                        parents: folder.parents.concat([{ id: folder.id, name: folder.name, containerType: CmsContainerType.FOLDER }]),
                        childFolders: null,
                        childLeafs: null,
                        onClick: (item: CmsItem) => this.setParent(item.id),
                        actions: folder.trash || (folder.parents[0] && folder.parents[0].trash) ? this.folderActionsTrash : this.folderActions,
                        iconClass: TypeHelpers.getIconClass(childRef),
                        attributeAssignments: folder.attributeAssignments,
                        algebrakitOnly: childRef.algebrakitOnly
                    }))
                    .sort((f1, f2) => {
                        if (f1.name < f2.name) return -1;
                        if (f1.name > f2.name) return 1;
                        return 0;
                    })
            )).subscribe(folders => {
                if (!this.userService.canEditLibrary()) {
                    folders = folders.filter(f => !f.algebrakitOnly);
                }
                this.childFolders = folders
            });

        this.currentFolder.pipe(
            filter(folder => folder != null),
            switchMap(folder => {
                this.busy = true;
                this._exercises = [];
                this.exerciseToDelete = null;
                this.deleteCheckResult = null;
                return this.exerciseScriptService.getExerciseScriptsForTopic(folder, this.browseMode === BrowseMode.VIEW)
            }),
            map(items => {
                let folder = this.currentFolder.value;
                //items = sortExercisesByDifficulty(items, attrs);
                return items
                    .filter(item => !this.canEditTopicsScripts()
                        ? item.state === CmsItemState.APPROVED
                        : true
                    )
                    .map(item => {
                        item.iconClass = TypeHelpers.getIconClass(item);
                        item.onClick = (item: CmsExerciseScript) => this.testExercise(item);
                        item.actions = folder.trash || (folder.parents[0] && folder.parents[0].trash) ? this.exerciseActionsTrash : this.exerciseActions;
                        return item;
                    });
            }))
            .subscribe(items => {
                this._exercises = items;
                this.sortBy(this.currentSortBy, this.currentSortOrder);
                this.clearSelected(this._exercises);
                this.getLatestVersionNumbers();
                this.busy = false;
            }, (err) => {
                this.toastr.error('Could not retrieve exercises for topic "' + this.currentFolder.value.name + '"', 'Error');
                console.log(err.message);
                this.busy = false;
                return [];
            });
    }

    ngOnDestroy(): void {
        this.exporterClose();
    }
}

