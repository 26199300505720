import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorHandler } from './http-error-handler';
import { AkitVersionsService } from '../../algebrakit/services/akit-versions.service';
import { from, Observable } from 'rxjs';

type Headers = {
    [header: string]: string;
}

/**
 * HTTP client that can be used to make requests to the backend. This service will add metadata to the request
 * that the backend can use to identify the sessionmanager that the request should be sent to.
 */
export abstract class HttpClientService extends HttpErrorHandler {

    constructor(
        protected http: HttpClient,
        protected toastr: ToastrService,
        protected akitVersionsService: AkitVersionsService
    ) {
        super(toastr);
    }

    /**
     * NOTE about the following methods:
     * the way the http client defines types for options make it very difficult to 
     * define types for the options parameter (see node_modules/@angular/common/http/http.d.ts) and 
     * also makes the cast at the end necessary for the return type to be correct.
     */

    /**
     * Performs a get request with metadata about the current version of the akit API
     * @param url
     * @param options
     * @returns 
     */
    protected get<T>(url: string, options: any = {}): Observable<T> {
        const promise = this.getHeaders(options.headers).then((headers) => {
            options.headers = headers;
            return this.http.get<T>(url, options).toPromise();
        })
        return from(promise) as unknown as Observable<T>;
    }

    /**
     * Performs a post request with metadata about the current version of the akit API
     * @param url
     * @param body
     * @param headers
     * @returns 
     */
    protected post<T>(url: string, body: any, options: any = {}): Observable<T> {
        const promise = this.getHeaders(options.headers).then((headers) => {
            options.headers = headers;
            return this.http.post<T>(url, body, options).toPromise();
        })
        return from(promise) as unknown as Observable<T>;
    }

    /**
     * Performs a delete request with metadata about the current version of the akit API
     * @param url 
     * @param headers 
     * @returns 
     */
    protected delete<T>(url: string, options: any = {}): Observable<T> {
        const promise = this.getHeaders(options.headers).then((headers) => {
            options.headers = headers;
            return this.http.delete(url, options).toPromise();
        })
        return from(promise) as unknown as Observable<T>;
    }

    private async getHeaders(headers: Headers = {}): Promise<Headers> {
        const result = { ...headers };
        result['x-api-endpoint'] = await this.akitVersionsService.currentVersion;
        return result;
    }
}