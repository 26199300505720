import { Component, Input, Output, EventEmitter, ViewEncapsulation, OnInit } from '@angular/core';
import { CmsUnitTestRef } from '../../types/navigator.types';
import { SessionService } from '../../../algebrakit/services/session.service';
import { ToastrService } from 'ngx-toastr';
import { AppProfileService } from '../../../app/services/app-profile.service';

declare const AlgebraKIT: any;

/**
 * A topic-leaf is a 'file' in the navigation system, which corresponds to an
 * exercise. The model is defined by class of type CmsLeaf. Classes for the various 
 * types of exercises are derived from CmsLeaf
 */
@Component({
    selector: 'session-test',
    templateUrl: './session-test.component.html',
    styleUrls: ['./session-test.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class SessionTestComponent implements OnInit {

    @Input() testRefs: CmsUnitTestRef[];
    @Input() canCompare: boolean = false; //Can view both the old and current session run side-by-side
    @Input() canUpdateSession: boolean = false; //Update test session with curent run
    @Input() canUpdateTestCase: boolean = false; //Set testcase true or false

    @Output() onSessionReplay: EventEmitter<string> = new EventEmitter<string>();
    @Output() onSessionsDirty: EventEmitter<void> = new EventEmitter<void>();

    testSessionViewData: any; //Replayed session
    testResultSessionId: string; //newly created sessionId for test
    testCollapsed: boolean;

    testResultLog: string;

    testSessionDate: Date;

    //Used for updating existing test sessions
    allowTestSessionUpdate: boolean;
    currentTestRef: CmsUnitTestRef;
    enableUpdatePanel: boolean;

    constructor(private sessionService: SessionService, private toastr: ToastrService, private profileService: AppProfileService) {

    }

    ngOnInit(): void {
        this.profileService.getProfile().then(profile => {
            this.allowTestSessionUpdate = true;//profile == 'staging' || profile == 'prod';
        })
    }

    playTest(testRef: CmsUnitTestRef) {
        this.testSessionViewData = null;
        this.testResultSessionId = null;
        this.currentTestRef = testRef;
        this.enableUpdatePanel = false;
        this.sessionService.playTestSession(testRef).then(result => {
            //Clear cache for this sessionId
            AlgebraKIT.deleteSessionData();
            this.onSessionReplay.emit(result.sessionId);
            this.testSessionDate = new Date(testRef.timestamp);
            this.testSessionViewData = result;
            this.testCollapsed = true;
        });
        if (this.canCompare) {
            this.sessionService.testTestSession(testRef).then(result => {
                //Clear cache for this sessionId
                this.testSessionViewData = result;
                let testSessionResult = result.sessionTestResults && result.sessionTestResults.length > 0
                    ? result.sessionTestResults[0]
                    : null;
                if (testSessionResult) {
                    this.testResultSessionId = testSessionResult.sessionId;
                    let allTestsPassed = !testSessionResult.log && testSessionResult.passed === testSessionResult.total;
                    this.testResultLog = allTestsPassed
                        ? 'All tests passed'
                        : this.processLog(testSessionResult.log);
                    this.enableUpdatePanel = !allTestsPassed;
                }
            });
        }
    }

    processLog(log: string): string {
        let lines = log.split('\n');
        return lines.map(line => {
            let regex = /(\s*)((error|info|warn|debug|silly):)(.*)/;
            return line.replace(regex, '$1<span class="log-$3">$2</span>$4');
        }).join('\n');
    }

    deleteTest(testRef: CmsUnitTestRef) {
        if (!confirm(`Are you sure you want to delete the test session '${testRef.description}'?`)) {
            return;
        }
        this.sessionService.removeTestSession(testRef).then(() => {
            let i = this.testRefs.findIndex(t => t.sessionId === testRef.sessionId && t.exerciseId === testRef.exerciseId)
            this.testRefs.splice(i, 1);
            this.toastr.success(`Test session '${testRef.description}' was deleted.`)
        })
    }

    updateTest() {
        this.sessionService.updateTestSession(this.currentTestRef.sessionId, this.testResultSessionId, this.currentTestRef.exerciseId).then(() => {
            this.toastr.success(`Test session '${this.currentTestRef.description}' was updated.`);
            this.onSessionsDirty.emit();
        })
    }

    updateTestCase(testRef: CmsUnitTestRef, e: any) {
        let value = e.target.checked;
        this.sessionService.updateIsTestCase(testRef, value)
        .then(() => {
            this.toastr.success(`Test session '${testRef.description}' was updated.`);
            this.onSessionsDirty.emit();
        }).catch((err) => {
            this.toastr.error(`Test session '${testRef.description}' could not be updated.`);
            this.onSessionsDirty.emit();
        })
    }

    toggleTestCollapse() {
        this.testCollapsed = !this.testCollapsed;
    }

    sortRefs(refs: CmsUnitTestRef[]): CmsUnitTestRef[] {
        return refs.slice().sort((a, b) => {
            return a.timestamp - b.timestamp;
        });
    }

}



