<div class='user-profile-component'>
    <h2>Update profile</h2>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class='row row-spacing'>
            <div class='col-xs-3 col-sm-2'>
                <label>Username</label>
            </div>
            <div class="col-xs-7 col-sm-5">
                <input disabled formControlName='username' class='form-control'>
            </div>
        </div>
        <br/>
        <div class='row row-spacing'>
            <div class='col-xs-3 col-sm-2'>
                <label>First name</label>
            </div>
            <div class="col-xs-7 col-sm-5">
                <input formControlName='firstName' class='form-control'>
            </div>
        </div>
        <br/>
        <div class='row row-spacing'>
            <div class='col-xs-3 col-sm-2'>
                <label>Last name</label>
            </div>
            <div class="col-xs-7 col-sm-5">
                <input formControlName='lastName' class='form-control'>
            </div>
        </div>
        <br/>
        <div class='row row-spacing'>
            <div class='col-xs-3 col-sm-2'>
                <label>Email</label>
            </div>
            <div class="col-xs-7 col-sm-5">
                <input formControlName='email' class='form-control'>
            </div>
        </div>
        <br/>
        <div class='row row-spacing' *ngIf="formChanged()">
            <div class="col-xs-10 col-sm-7">
                <label>Type your current password to confirm any changes</label>
                <br/>
                <input formControlName='password' class='form-control' type="password">
            </div>
        </div>
        <br/>
        <br/>
        <div class='row row-spacing'>
            <div class="col-xs-4 col-sm-2">
                <button class="btn btn-primary btn-block" type="submit" [disabled]="!form.valid || !formChanged()">
                    <i class="fa fa-sign-in"></i>
                    <span>Save</span>
                </button>
            </div>
            <div class="col-xs-4 col-sm-2">
                <button class="btn btn-default btn-block" type="button" title="Cancel" (click)="cancel()">
                    <i class="fa fa-ban"></i>
                    <span>Cancel</span>
                </button>
            </div>
        </div>
    </form>
</div>