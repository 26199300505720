import { IValidationReport } from "../../authoring/types/ValidationReport";
import { I18nLabel, QuestionMode } from "../../authoring/types/cms-metadata.types";
import { ICMS2ExerciseSpec } from "../../authoring/types/cms2-metadata.types";
import { IExerciseSpec } from './metadata.type';

export interface WidgetSpec {
    sessionId: string;
    refId: string;
    id: string;
    tag: string;
    attributes: { [key: string]: string };
    node: Element;
}

export type ExerciseManager  = any;

/**
 * (part of) API of Algebrakit frontend
 */
export interface IAlgebraKIT {
    config: any,
    addListener: { (akitId: string, eventName: string, fn: EventListener): void },
    addExerciseListener: any,
    removeWidget: { (id: string, removeNode: boolean): void },
    removeWidgetBySessionId: { (sessionId: string, removeNode: boolean): void },
    exerciseCommand: { (sessionId: string, command: string, args: any): any },
    render: { (node: HTMLElement): void },
    elements2html: { (obj: any): Promise<string> },
    command: { (widgetId: string, name: string, args: any): any },
    waitUntilReady: (node: HTMLElement | HTMLElement[]) => Promise<WidgetSpec>,
    _api: any;
    deleteSessionData: (sessionId: string) => void;
    initializedPromise: Promise<void>; //Promise that resolves once the main API exists
    isMobileDevice: () => boolean;
    getExerciseManager: (exerciseId: string) => ExerciseManager;
    setExercise: (spec: AuthoredExercise, options?: IEditorOptions) => Promise<ExerciseManager>;
    createExercise: (options?: IEditorOptions) => Promise<ExerciseManager>;
    latex2HtmlSync: (latexStr: string, latexRenderMode?: string) => string;
}

export interface ProxyConfig {
    url: string;
    headers?: { [name: string]: string } 
}

export interface IErrorState {
    status: number,
    message: string,
    stack: string
}

export interface IAKITSession {
    appId: string,
    sessionId: string,
    timestamp: string,
    payload: {
        cache: any,
        store: {
            eventlist: [{
                name: string,
                in: any,
                out: any,
                throws: any,
                timestamp: string,

            }],
            metadata: IExerciseSpec
        }
    }
}

export interface IErrorData {
    msg: string;
    data: { [key: string]: string }
}

export interface ISessionId {
    appId: string,
    sessionId: string,
    resourceId?: string
    exerciseType?: string;
}

export interface IDebugItem {
    key?: string;
    value: string;
}
export interface IDebugBucket {
    description: string;
    items: IDebugItem[];
}
export interface IDebugInformation {
    buckets: IDebugBucket[];
}

export interface IEditorOptions {
    //Default properties
    defaultAudience: Audience;
    defaultQuestionMode: QuestionMode;
    defaultEditor: string;

    //Avaiable list items
    availableAudiences: Audience[];
    resolveAudiences?: Audience[];
    i18nLabels?: I18nLabel[];
    availableModes?: string[];
    includeInternalModes?: boolean;
    availablePalettes?: string[];
}

export interface Audience {
    name: string; //name to show in gui
    id: string; //algebrakit name,
    languages: string[];
}

export interface WKElement {
    mimeType: string;
    content: string;
}

export interface WKStrategyStep {
    name: string;
    marks: number;
}

export interface WKStrategy {
    stepList: WKStrategyStep[];
    marks?: number;
    valid?: boolean;
}

export type AkitCreateSessionResponse = AkitCreateSessionsForExerciseResult[] | AkitErrorResponse;

export class AkitErrorResponse {
    success: false;
    msg: string;
}
export class AkitCreateSessionsForExerciseResult {
    success: boolean;
    msg?: string;
    sessions: AkitCreateSessionResult[];
}

export interface AkitCreateSessionResult {
    sessionId: string;
    type: string;
    solution: boolean,
    html?: any;
    data?: any;
    success?: boolean
    msg?: string;
    errorData?: any,
    debugInformation?: any;
    marksTotal?: number; //marks for this whole exercise, depends on scoring model
    interactionMarks?: {[key:string]:number}
}

export interface EvaluationItem {
    type: string;
    expression: string;
    syntax: string;
    attributes?: string[]
}

export interface SkillTag {
    id: string;
    weight: number;
    errors?: string[];
    source?: TagSource
}
  
export enum TagSource {
ERROR_FEEDBACK='ERROR_FEEDBACK',
FINISHED_STEP='FINISHED_STEP',
HINT='HINT'
}

export interface ISessionData {
    success?: boolean;
    errorMessage?: string;
    errorData?: { [key: string]: string };
    sessionId: string;
    html?: string;
    debugInformation?: IDebugInformation;
    validationReport?: IValidationReport;
}

export interface ISolutionData {
    sessionId: string;
    appId: string;
    type: string;
    html?: string;
    success?: boolean
    msg?: string;
    errorData?: any;
}

// output from akit-exercise-editor
export class AuthoredExercise {
    id: string;
    name: string;
    majorVersion: number;
    type: string = "SPECIFICATION";
    definition: ICMS2ExerciseSpec;
    metadata: any;
  }
  

export enum AkitLearningEventType {
    EVALUATE = "EVALUATE",
    HINT = "HINT",
}
export interface AkitLearningEvent {
    type: AkitLearningEventType;
    marksEarned: number;
    marksTotal: number;
    finished: boolean;
    tags: SkillTag[];
}

export interface AkitPristineChangedEvent {
    pristine: boolean;
}

export interface ExerciseResult {
    scoring: Scorable,
    questions: QuestionResult[]
    tagDescriptions: {[key: string]: any};
}


export interface InteractionData {
    refName: string;
    refId: string;
    type: string;
    block: string;
}

export interface ExerciseData {
    name: string;
    interactions: InteractionData[];
}

export interface Scorable {
    finished: boolean;
    marksEarned: number;
    marksTotal: number;
}
export interface QuestionResult {
    id: string,
    scoring?: Scorable,
    interactions: InteractionResult[]
}

export interface InteractionResult {
    id: string;
    /** interaction type, e.g. MULTISTEP, etc */
    type: string;
    /** standardized evaluation state. E.g. FINISHED, CORRECT, UNKNOWN, etc */
    status: EvaluationStatus,
    /** progress in range [0..1]. Note that progress is not equivalent to scoring (nr of marks) */
    progress: number,
    /** result of the scoring (marking) process */
    scoring: InteractionScore,
    /** tags indicating (lack of) learning skills */
    tags: SkillTag[];
    /** standardized list of learning events (evaluation, hint, give up, etc) */
    events: ExportedEventResult[],
}

/**
 * Every question type has its own specific data scheme for the events it supports. 
 * E.g. The evaluation result of Multistep is very different from the evaluation result
 * of Geometry.
 * ExportedEventResult provides a unified scheme to share information about evaluation, hints, etc
 * to the client. So the exported evaluation result of Geometry and Multistep use this same interface.
 */
 export interface ExportedEventResult {
    event: InteractionEventType;
    timestamp: number;

    /** Note: SkillTag is not the same as WKSkillTag, which is the output of the Akit Engine */
    tags: SkillTag[];

    /** meaningful information that can be shared to a client platform. E.g hints or error feedback */
    annotations: Annotation[],
}

/** An unified interface to share hints, feedback of the question type */
export interface Annotation {
    type: AnnotationType;
    main?: WKElement[];
    sub?: WKElement[];
    expr?: WKElement[];
}

export enum AnnotationType {
    HINT = "HINT",
    ERROR_FEEDBACK = "ERROR_FEEDBACK",
    INPUT_EXPRESSION = "INPUT_EXPRESSION"
}

export interface ExportedEvaluationEventResult extends ExportedEventResult{
    /** standard type for evaluation result of the exercise */
    event: InteractionEventType.EVALUATE;
    exerciseStatus: EvaluationStatus;
    inputStatus?: string;
    // derivationTags?: string[];
    // remainingTags?: string[];
    progress: number;
}
export function isExportedEvaluationEventResult(obj: ExportedEventResult): obj is ExportedEvaluationEventResult {
    return (obj as ExportedEvaluationEventResult).exerciseStatus != null;
}

export interface ExportedHintEventResult extends ExportedEventResult {
    event: InteractionEventType.HINT
}

export function isExportedHintEventResult(obj: ExportedEventResult): obj is ExportedHintEventResult {
    return (obj as ExportedHintEventResult).event == InteractionEventType.HINT;
}

export interface ExportedGiveUpEventResult extends ExportedEventResult {

}

export interface ExportedSubmitEventResult extends ExportedEventResult {
    
}

export enum InteractionEventType {
    EVALUATE='EVALUATE',
    HINT='HINT',
    GIVEUP='GIVEUP',
    SUBMIT='SUBMIT',
}

export enum EvaluationStatus {
    FINISHED = "FINISHED",
    CORRECT = "CORRECT",  // correct but not finished
    ERROR = "ERROR",
    VIRGIN = "VIRGIN",  // no evaluations have happened (but hints might have been given)
    UNKNOWN = "UNKNOWN"
}

export interface InteractionScore extends Scorable {
    penalties: ScorePenalties
    
    //Marks min-max score, depending on steps that were done and which not. max includes steps that are implicitly covered
    marks?: ScoreRange; 
    stepList?: WKStrategyStep[];

    //TO BE REMOVED
    //sequence of steps chosen by the student
    strategy?: WKStrategy;
    //TO BE REMOVED
    //marks per step
    stepMarks?: {[stepName: string]:ScoreRange};

    failed?: boolean;
}
export interface ScorePenalties {
    marksPenalty: number;
    hintsRequested: number;
    mathErrors: number;
    notationErrors: number;
}
export interface ScoreRange {
    min: number;
    max: number;
}

export interface InteractionInfo {
    type: string;
    marks: number
}
export interface ValidateExercisResult {
    valid: boolean;
    msg?: string; //error message if not valid
    random: boolean;
    marks: number;
    interactions: { [refId: string]: InteractionInfo },
    errorData?: any
    debugInfo?: any;
}
