import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { CmsCalcbookComponent } from './components/cms-calcbook/cms-calcbook.component';
import { CmsInteractionComponent } from './components/cms-interaction/cms-interaction.component';
import { CmsSolutionComponent } from './components/cms-solution/cms-solution.component';
import { DebugViewComponent } from './components/debug-view/debug-view.component';
import { MetadataService } from './services/metadata.service';
import { SessionService } from './services/session.service';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { Safe } from './services/sanitizer';
import { ToLatexPipe } from './pipes/toLatex/latex-pipe';
import { AkitInteractionEditorComponent } from './components/akit-interaction-editor/akit-interaction-editor.component';
import { AkitErrorComponent } from './components/akit-error/akit-error.component';
import { CmsExerciseComponent } from './components/cms-exercise/cms-exercise.component';
import { AkitVersionsService } from './services/akit-versions.service';

@NgModule({
  imports: [BrowserModule, Nl2BrPipeModule],
  declarations: [ AkitErrorComponent, CmsCalcbookComponent, CmsExerciseComponent, CmsInteractionComponent, CmsSolutionComponent, DebugViewComponent, Safe, ToLatexPipe, AkitInteractionEditorComponent],
  providers: [MetadataService, SessionService, AkitVersionsService],
  exports: [ AkitErrorComponent, CmsCalcbookComponent, CmsExerciseComponent, CmsInteractionComponent, CmsSolutionComponent, ToLatexPipe, AkitInteractionEditorComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] //required to allow custom tags, such as <akit-exercise>
})
export class AlgebraKITModule { }


