<form [formGroup]="form" (ngSubmit)="onSubmit()" #contactForm="ngForm">
    <div class="row">
        <div class=" flex-row">
            <div class="col-xs-12 col-sm-6 contactblock">
                <div class="form-group">
                    <label for="name">Name*</label>
                    <input id="name" name="name" formControlName='name' class='form-control' placeholder="Name" required type="text"> 

                    <div *ngIf="name.invalid && (name.dirty || name.touched)"
                        class="alert alert-danger">
                        <div *ngIf="name.errors.required">
                          Name is required.
                        </div>
                        <div *ngIf="name.errors.minlength">
                          Name must be at least 3 characters long.
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-xs-12 col-sm-6 contactblock">
            </div>
        </div>
        <div class="col-xs-12 col-sm-6">
            <div class="form-group">
                <label for="email">Email address*</label>
                <input id="email" name="email" formControlName='email' class='form-control' placeholder="E-mail" required type="email">
                                
                <div *ngIf="email.invalid && (email.dirty || email.touched)"
                    class="alert alert-danger">
                    <div *ngIf="email.errors.required">
                      Email address is required.
                    </div>
                    <div *ngIf="email.errors.email">
                      Make sure to enter a valid email address.
                    </div>
                </div>
                
            </div>
            
            <div class="form-group">
                <label for="phone">Phone number</label>
                <input id="phone" name="phone" formControlName='phone' class='form-control' placeholder="Phone number">
            </div>
        </div>
        <div class="col-xs-12 col-sm-6">
            <div class="form-group">
                <label for="subject">Subject</label>
                <select id="subject" name="subject" formControlName='questiontype' class='form-control' placeholder="Subject" >
                    <option value="" disabled selected>Select the subject related to your question...</option>
                    <option value="library">Library</option>
                    <option value="courses">Courses</option>
                    <option value="publishing">Publishing</option>
                    <option value="sandbox">Sandbox</option>
                </select>
            </div>
            
            <div class="form-group">
                <label for="url">URL / link</label>
                <input id="url" name="url" formControlName='url' class='form-control' placeholder="URL / link" type="url"> 
            </div>

        </div>
        <div class="col-xs-12 col-sm-12">
            <div class="form-group">
                <label for="message">Message*</label>
                <textarea id="message" name="message" formControlName="message" class="form-control" placeholder="Message" required type="text"></textarea>

                <div *ngIf="message.invalid && (message.dirty || message.touched)"
                    class="alert alert-danger">
                    <div *ngIf="message.errors.required">
                      Message is required.
                    </div>
                    <div *ngIf="message.errors.minlength">
                      The message should be at least 10 characters long.
                    </div>
                    <div *ngIf="message.errors.maxlength">
                      The message can be at most 1000 characters long.
                    </div>
                </div>

            </div>
             
            <button type="submit" class="btn btn-default"
              [disabled]="form.invalid">Send</button>
        </div>
        
    </div>
    <div class="row">
        <div class="col-xs-12 col-sm-6">
            <p>Items marked with an asterix (*) are required.</p>
        </div>
    </div>
</form>