
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/Rx';

import {
    CmsExerciseScript, LibrarySearchResult, IdSearchResultList
} from '../types/navigator.types';
import { AuthoringService } from './authoring.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../security/services/user.service';
import { ConcurrencyService } from '../../app/services/concurrency.service';
import { HttpClient } from '@angular/common/http';
import { AkitVersionsService } from '../../algebrakit/services/akit-versions.service';

const URL_BASE_SEARCH = "api/search";

@Injectable()
export class SearchService extends AuthoringService {

    constructor(
        protected http: HttpClient,
        protected toastr: ToastrService,
        protected userService: UserService,
        protected concurrencyService: ConcurrencyService,
        protected akitVersionsService: AkitVersionsService
    ) {
        super(http, toastr, concurrencyService, akitVersionsService);
    }

    librarySearch(subtreeRootId: string, searchString: string, lastSearchToken: string, publishedOnly?: boolean): Observable<LibrarySearchResult> {
        let url = publishedOnly
            ? `${URL_BASE_SEARCH}/library-published/${subtreeRootId}/${searchString}/${lastSearchToken}`
            : `${URL_BASE_SEARCH}/library/${subtreeRootId}/${searchString}/${lastSearchToken}`;
        return this.get<LibrarySearchResult>(url).pipe(
            catchError(error => this._serverError(error)));
    }

    idSearch(ids: string[], lastSearchToken: string): Observable<IdSearchResultList> {
        let url = `${URL_BASE_SEARCH}/by-id`;
        return this.post<IdSearchResultList>(url, { ids: ids, token: lastSearchToken }).pipe(
            catchError(error => this._serverError(error)));
    }

    _getExerciseFromJson(obj: any): CmsExerciseScript {
        let definition = obj.definition;
        //        let definition = this._getExerciseSpecFromJson(obj.definition);
        let result: CmsExerciseScript = {
            id: obj.id,
            name: obj.name,
            topic: obj.topic,
            attributeAssignments: obj.attributeAssignments,
            owner: obj.owner,
            created: obj.created,
            modified: obj.modified,
            state: obj.state,
            comment: obj.comment,
            description: obj.description,
            definition: definition,
            testSessions: obj.testSessions,
            tested: obj.tested
        };
        return result;
    }


}
