<div class='label-navigator'>
    <h1>Attributes</h1>
    <div class="row" *ngIf="canEditTopicsScripts()">
        <div class="col-sm-12">
            <button class="btn btn-primary create-button" (click)="createAttribute()">
                <span translate>Create new Attribute...</span>
            </button>
        </div>
    </div>
    <br/>
    <busy-gears *ngIf="loading || modifyChecking"></busy-gears>
    <div class="alert alert-danger" *ngIf="attrToDelete && modifyCheckResult && modifyCheckResult.length > 0">
       <p>The attribute <strong>{{attrToDelete.name}}</strong> is assigned to the following topics:</p>
       <br/>
        <ul>
            <li *ngFor="let ref of modifyCheckResult">
                <a [routerLink]="'/edit/topic/'+ref.id" target="_blank">{{ref.name}}</a>
            </li>
        </ul>
        <br/>
        <p>Please remove these assignments before deleting this attribute.</p>
    </div>
    <div *ngIf="attributes">
        <div class="row header-row">
            <div class="col-xs-6 col-sm-4 col-lg-3"><strong>Attribute name</strong></div>
            <div class='col-xs-6 col-sm-8 col-lg-9'><strong>Description</strong></div>
        </div>

        <div *ngFor="let attribute of attributes" class='label-item row'>
            <div class='col-xs-6 col-sm-4 col-lg-3'>
                <div class="dropdown">
                    <button class="dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        <span class="fa fa-bookmark-o"></span>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenu1" *ngIf="canEditTopicsScripts() && actions">
                        <li *ngFor="let action of actions" (click)="action.callback(attribute)" class='dropdown-entry'>
                            <a>
                                <span [ngClass]="action.iconClass"></span>
                                <span class='dropdown-entry-label'>{{action.name}}</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <span> {{attribute.name}}</span>
            </div>
            <div class='col-xs-6 col-sm-8 col-lg-9'>
                <span> {{attribute.description}}</span>
            </div>
        </div>
    </div>
</div>