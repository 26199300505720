<div class='subject-editor' *ngIf="subject">
    <h2>Edit folder</h2>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row row-spacing">
            <div class="col-sm-2">
                <label>Parent</label>
            </div>
            <div class="col-sm-10">
                <breadcrumb [folder]="parent" (parentChange)='setParent($event)' [showChilds]="true"></breadcrumb>
            </div>
        </div>
        <div class='row row-spacing'>
            <div class='col-xs-3 col-sm-2'>
                <label>Name</label>
            </div>
            <div class="col-xs-7 col-sm-5">
                <input name='name' formControlName='name' class='form-control'>
            </div>
        </div>
        <div class='row row-spacing'>
            <div class='col-xs-4 col-sm-2 col-md-3'>
                <label>Default formula editor</label>
            </div>
            <div class="col-xs-4 col-sm-2">
                <select formControlName='defaultEditor' class='form-control'>
                    <option [value]="null"></option>
                    <option *ngFor="let palette of allPalettes" [value]="palette.id">{{palette.name}}</option>
                </select>
            </div>
            <div class="col-xs-4 col-sm-8 col-md-7">
                <label class="editor-checkbox" for="applyEditorToChilds">
                    <input type="checkbox" formControlName="applyEditorToChilds" id="applyEditorToChilds" />
                    Apply to all child folders
                </label>
            </div>
        </div>
        <div class='row row-spacing' *ngIf="subject && course">
            <div class='col-xs-8 col-sm-4 col-md-5'>
            </div>
            <div class="col-xs-4 col-sm-8 col-md-7">
                <label class="editor-input" for="overwriteEditorInDraftChilds">
                    <input type="checkbox" formControlName="overwriteEditorInDraftChilds"
                        id="overwriteEditorInDraftChilds" />
                    Overwrite in all DRAFT child exercises <span *ngIf="applyEditorToChildExercises">recursively</span>
                </label>
            </div>
        </div>
        <div class='row row-spacing' *ngIf="subject && course">
            <div class='col-xs-8 col-sm-4 col-md-5'>
            </div>
            <div class="col-xs-4 col-sm-8 col-md-7">
                <label class="editor-input" for="overwriteEditorInApprovedChilds">
                    <input type="checkbox" formControlName="overwriteEditorInApprovedChilds"
                        id="overwriteEditorInApprovedChilds" />
                    Overwrite in all APPROVED child exercises <span *ngIf="applyEditorToChildExercises">recursively</span>
                </label>
            </div>
        </div>
        <div class="row row-spacing">
            <hr />
        </div>
        <div class='row row-spacing'>
            <div class='col-xs-4 col-sm-2 col-md-3'>
                <label>Default question mode</label>
            </div>
            <div class="col-xs-4 col-sm-2">
                <select formControlName='defaultQuestionMode' class='form-control'>
                    <option [value]="null"></option>
                    <option *ngFor="let questionMode of questionModes" [value]="questionMode.id">{{questionMode.name}}
                    </option>
                </select>
            </div>
            <div class="col-xs-4 col-sm-8 col-md-7">
                <label class="editor-checkbox" for="applyQuestionModeToChilds">
                    <input type="checkbox" formControlName="applyQuestionModeToChilds" id="applyQuestionModeToChilds" />
                    Apply to all child folders
                </label>
            </div>
        </div>
        <div class='row row-spacing' *ngIf="subject && course">
            <div class='col-xs-8 col-sm-4 col-md-5'>
            </div>
            <div class="col-xs-4 col-sm-8 col-md-7">
                <label class="editor-input" for="overwriteQuestionModeInDraftChilds">
                    <input type="checkbox" formControlName="overwriteQuestionModeInDraftChilds"
                        id="overwriteQuestionModeInDraftChilds" />
                    Overwrite in all DRAFT child exercises <span *ngIf="applyQuestionModeToChildExercises">recursively</span>
                </label>
            </div>
        </div>
        <div class='row row-spacing' *ngIf="subject && course">
            <div class='col-xs-8 col-sm-4 col-md-5'>
            </div>
            <div class="col-xs-4 col-sm-8 col-md-7">
                <label class="editor-input" for="overwriteQuestionModeInApprovedChilds">
                    <input type="checkbox" formControlName="overwriteQuestionModeInApprovedChilds"
                        id="overwriteQuestionModeInApprovedChilds" />
                    Overwrite in all APPROVED child exercises <span *ngIf="applyQuestionModeToChildExercises">recursively</span>
                </label>
            </div>
        </div>
        <div class="row row-spacing">
            <hr />
        </div>
        <div class='row row-spacing' *ngIf="course">
            <div class='col-xs-4 col-sm-2 col-md-3'>
                <label class="editor-input">Default audience</label>
            </div>
            <div class="col-xs-4 col-sm-2">
                <select formControlName='defaultAudience' class='form-control'>
                    <option [value]="null"></option>
                    <option *ngFor="let audience of course.audiences" [value]="audience.baseAudienceId">{{audience.name || audience.baseAudienceId}}
                    </option>
                </select>
            </div>
            <div class="col-xs-4 col-sm-8 col-md-7">
                <label class="editor-input" for="applyAudienceToChilds">
                    <input type="checkbox" formControlName="applyAudienceToChilds" id="applyAudienceToChilds" />
                    Apply to all child folders
                </label>
            </div>
        </div>
        <div class='row row-spacing' *ngIf="subject && course">
            <div class='col-xs-8 col-sm-4 col-md-5'>
            </div>
            <div class="col-xs-4 col-sm-8 col-md-7">
                <label class="editor-input" for="overwriteAudienceInDraftChilds">
                    <input type="checkbox" formControlName="overwriteAudienceInDraftChilds"
                        id="overwriteAudienceInDraftChilds" />
                    Overwrite in all DRAFT child exercises <span *ngIf="applyAudienceToChildExercises">recursively</span>
                </label>
            </div>
        </div>
        <div class='row row-spacing' *ngIf="subject && course">
            <div class='col-xs-8 col-sm-4 col-md-5'>
            </div>
            <div class="col-xs-4 col-sm-8 col-md-7">
                <label class="editor-input" for="overwriteAudienceInApprovedChilds">
                    <input type="checkbox" formControlName="overwriteAudienceInApprovedChilds"
                        id="overwriteAudienceInApprovedChilds" />
                    Overwrite in all APPROVED child exercises <span *ngIf="applyAudienceToChildExercises">recursively</span>
                </label>
            </div>
        </div>
        <div class="row row-spacing">
            <hr />
        </div>
    </form>

    <h4>Additional metadata</h4>
    <metadata-collection-editor [forItem]="subject" (afterValidate)="setMetadataValid($event)">
    </metadata-collection-editor>

    <div class="row" *ngFor="let mdError of metadataErrors">
        <div class="col-xs-12">
            <div class="alert alert-danger">
                <i class="fa fa-times-circle" aria-hidden="true"></i> {{mdError}}
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-3">
            <button class="btn btn-md btn-primary btn-block" type="button" title="OK" (click)="onSubmit()"
                [disabled]="!form.valid || !metadataValid">
                <i class="fa fa-save"></i>
                <span>OK</span>
            </button>
        </div>
        <div class="col-xs-3">
            <button class="btn btn-md btn-default btn-block" type="button" (click)="cancel()" title="Cancel">
                <i class="fa fa-ban"></i>
                <span translate>Close</span>
            </button>
        </div>
    </div>

    <div class="modal fade confirmation-modal">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="gears-container" *ngIf="busy">
                    <akit-busy-gears></akit-busy-gears>
                </div>
                <ng-container *ngIf="!busy">
                    <div class="modal-header modal-danger-bg">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 class="modal-title"><span class="fa fa-warning"></span> Confirm overwriting properties in
                            child exercises</h4>
                    </div>
                    <div class="modal-body modal-danger-bg">
                        You have indicated you want to execute the following actions:
                        <ul>
                            <li *ngFor="let msg of overwriteNotifications" [innerHTML]="msg"></li>
                        </ul>
                        Are you sure you want to continue?
                    </div>
                    <div class="modal-footer modal-danger-bg">
                        <button type="button" class="btn btn-danger" (click)="confirmSubmit()">Confirm</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>