

<div class="foreground">
    <h2>Register</h2>
    <form [formGroup]="form" (ngSubmit)="register()">
        <div class="row">
            <div class="col-sm-3 col-xs-5">
                <label>User name</label>
            </div>
            <div class='col-sm-7 col-xs-7'>
                <input type="input" class="form-control text-lowercase" placeholder="user name" title="user name" ng-minlength="4" autofocus
                    formControlName="username">
                <div class="alert alert-danger" role="alert" *ngIf="form.controls.username.errors?.invalidChars">
                    Usernames may only contain letters, digits and underscores.
                </div>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="col-sm-3 col-xs-5">
                <label>Email address</label>
            </div>
            <div class='col-sm-7 col-xs-7'>
                <input disabled type="input" class="form-control text-lowercase" placeholder="email address" title="email address" ng-minlength="5"
                    formControlName="email" type="email" [email]="true">
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="col-sm-3 col-xs-5">
                <label>First name</label>
            </div>
            <div class='col-sm-7 col-xs-7'>
                <input type="input" class="form-control" placeholder="first name" title="first name" ng-minlength="1" formControlName="firstName">
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="col-sm-3 col-xs-5">
                <label>Last name</label>
            </div>
            <div class='col-sm-7 col-xs-7'>
                <input type="input" class="form-control" placeholder="last name" title="last name" ng-minlength="1" formControlName="lastName">
            </div>
        </div>
        <br/>
        <div formGroupName="passwords">
            <div class="row">
                <div class="col-sm-3 col-xs-5">
                    <label>Password</label>
                </div>
                <div class="col-sm-7 col-xs-7">
                    <input type="password" class="form-control" placeholder="password" title="password" ng-minlength="6" formControlName="password">
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-sm-3 col-xs-5">
                    <label>Confirm Password</label>
                </div>
                <div class="col-sm-7 col-xs-7">
                    <input type="password" class="form-control" placeholder="confirm password" title="confirm password" ng-minlength="6" formControlName="confirmPassword">
                    <div class="alert alert-danger" role="alert" *ngIf="form.controls.passwords.errors?.notEqual">
                        The two passwords must match
                    </div>
                </div>
            </div>
        </div>
        <br />

        <div class="row">
            <div class="col-sm-3 col-xs-6">
                <button class="btn btn-primary btn-block" type="submit" title="Register" [disabled]="!form.valid">
                    <i class="fa fa-pencil-square-o"></i>
                    <span>Register</span>
                </button>
            </div>
        </div>
    </form>
</div>