
import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { VersioningService } from '../../services/versioning.service';
import { CmsVersioningInfo } from '../../types/navigator.types';

declare let $: any;

const pageSize: number = 20;

@Component({
    selector: 'version-viewer',
    templateUrl: './version-viewer.component.html',
    styleUrls: ['./version-viewer.component.css']
})
export class VersionViewerComponent implements OnInit {

    @Input() set id(value: string) {
        this._id = value;
        this.pageSubject.next(0);
    }

    objects: CmsVersioningInfo[];
    _id: string = null;

    pageSubject: BehaviorSubject<number> = new BehaviorSubject(null);

    currentVersion: CmsVersioningInfo = null;

    totalNrOfVersions: number = 0;

    data: any = null;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private versioningService: VersioningService,
    ) {
    }

    setSelectedVersion(obj: CmsVersioningInfo) {
        this.currentVersion = obj;
        this.versioningService.getVersionData(this._id, obj.majorVersion, obj.minorVersion)
            .then((data) => {
                this.data = data;
            });
    }

    getJSON() {
        return JSON.stringify(this.data, null, 2);
    }

    ngOnInit(): void {

        this.pageSubject.subscribe((page: number) => {
            if (page !== null && this._id) {
                this.versioningService.getVersionInfo(this._id, page, pageSize)
                    .then((objects: CmsVersioningInfo[]) => {
                        objects.sort(function (a, b) {
                            return b.created.getTime() - a.created.getTime();
                        });
                        this.objects = objects;
                    })
            }
        });
    }
}

