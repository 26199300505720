
import { Component, OnDestroy, Input, ViewChild, ElementRef, NgZone, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subscription, Observable, Subject } from 'rxjs';
import { SessionService } from '../../../algebrakit/services/session.service';
import { ExerciseRef } from '../../types/cms-metadata.types';
import { IExerciseSpec, ExerciseInfo } from '../../../algebrakit/types/metadata.type';
import { ViewSpecWrapper } from '../../../algebrakit/types/metadata.type';
import { MetadataService, APP_ID } from '../../../algebrakit/services/metadata.service';
import { IErrorData } from '../../../algebrakit/types/algebrakit.type';
import { IAlgebraKIT }  from '../../../algebrakit/types/algebrakit.type';
import { Router } from '@angular/router';
import { ExerciseScriptService } from '../../services/exercise-script.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../security/services/user.service';
import { CmsExerciseFilter, GenerateExerciseFilterPreview } from '../../types/navigator.types';
import { isInlineInteraction } from '../../types/cms2-metadata.types';

const DEFAULT_NR_GENERATE = 5;
declare let AlgebraKIT: IAlgebraKIT;

let subscriptions: Subscription[] = [];

declare let $: any;

interface SpecPreview {
    viewSpec: ViewSpecWrapper;
    instruction?: string;
    solution?: string;
}

@Component({
    selector: 'exercise-preview',
    templateUrl: './exercise-preview.component.html',
    styleUrls: ['./exercise-preview.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class ExercisePreviewComponent implements OnDestroy {

    specPreviews: Subject<SpecPreview[]> = new BehaviorSubject(null); //generated metadata
    appId = APP_ID; //used in template
    selectedAudience: string;
    sessionId: string; //when generating widgets on a single spec
    interactionHTML: string;
    level: number = 0;
    nrOfLevels: number;
    interactionType: string;
    exerciseType: string;
    levels: number[];  //[0,1,2,..]
    busy: boolean = false;

    versionConflict: boolean = false;

    @ViewChild('previewTable', {static: false}) previewTable: ElementRef;
    @Input() nr: number = DEFAULT_NR_GENERATE;
    @Input() exerciseRef: ExerciseRef

    @Input() exerciseFilterReq: CmsExerciseFilter;
    @Input() scoringModel: string;
    @Output() error: EventEmitter<IErrorData> = new EventEmitter<IErrorData>();

    @Input()
    showEditViewButtenForScripts: boolean = true;

    constructor(
        private sessionService: SessionService,
        private router: Router,
        private exerciseScriptService: ExerciseScriptService,
        private _ngZone: NgZone,
        private userService: UserService
    ) { }

    insertWidgets(spec: ViewSpecWrapper) {
        this.busy = true;
        this.sessionService.createSession(spec.exerciseSpec, { 'start-active': true, 'show-progress-and-score': !!this.scoringModel }, false, this.scoringModel, spec.contentVersion).toPromise()
            .then(sessionData => {
                if (sessionData.success) {
                    this.interactionHTML = sessionData.html;
                    this.sessionId = sessionData.sessionId;
                    this.busy = false;
                } else {
                    let msg = sessionData.errorMessage || 'An error occurred while running the exercise.'
                    this.error.emit({
                        msg: msg,
                        data: sessionData.errorData
                    })
                }
            }).catch(err => {
                this.error.emit({
                    msg: err.message,
                    data: err.originalError && err.originalError.additionalInfo ? err.originalError.additionalInfo : null
                })
            });
    }

    insertWidgetsFromRef(ref: ExerciseRef) {
        this.busy = true;
        this.sessionService.createSessionFromRef(ref, { 'show-progress-and-score': !!this.scoringModel }, this.scoringModel).toPromise()
            .then(sessionData => {
                if (sessionData.success) {
                    this.interactionHTML = sessionData.html;
                    this.sessionId = sessionData.sessionId;
                    this.busy = false;
                } else {
                    let msg = sessionData.errorMessage || 'An error occurred while running the exercise.'
                    this.error.emit({
                        msg: msg,
                        data: sessionData.errorData
                    })
                }
            }).catch(err => {
                this.error.emit({
                    msg: err.message,
                    data: err.originalError && err.originalError.additionalInfo ? err.originalError.additionalInfo : null
                })
            });
    }

    setAudience(id: string) {
        if (id) {
            if (!this.selectedAudience) {//trigger first generate
                this.selectedAudience = id;
                this.generate();
            } else this.selectedAudience = id;
        }
    }

    renderString(str: string): Promise<string> {
        return this._ngZone.runOutsideAngular(function () {
            return AlgebraKIT.elements2html(str);
        });
    }

    checkSpec(spec: ViewSpecWrapper) {
        if (!spec.success) {
            return false;
        }
        if (!spec.exerciseSpec || !spec.view) {
            spec.success = false;
        }

        return spec.success;
    }

    getExerciseInfo(): Promise<ExerciseInfo> {
        let result;
        if (this.exerciseRef) {
            result = this.sessionService.getExerciseInfo(this.exerciseRef);
        }
        else if (this.exerciseFilterReq) {
            result = this.sessionService.generateExerciseFilterInfo(this.exerciseFilterReq);
        }
        else {
            throw new Error("No valid exercise to preview.");
        }
        return result.toPromise();
    }

    generateExercise(nr: number, withSolution: boolean): Observable<ViewSpecWrapper[]> {
        let result;
        if (this.exerciseRef) {
            result = this.sessionService.generateFromRef(this.exerciseRef, this.level, nr, withSolution);
        }
        else if (this.exerciseFilterReq) {
            result = this.sessionService.generateFilterExercises(this.exerciseFilterReq, this.level, nr, withSolution);
        }
        else {
            throw new Error("No valid exercise to preview.");
        }
        return result;
    }

    multiplePreviews() {
        return this.exerciseFilterReq || this.exerciseRef.multiplePreviews;
    }

    // generate again
    generate() {
        if (this.exerciseRef || this.exerciseFilterReq) {
            this.busy = true;
            return this.getExerciseInfo()
                .then(info => {
                    this.nrOfLevels = info.numberOfLevels
                    if (this.nrOfLevels > 1) {
                        this.levels = [];
                        for (let ii = 0; ii < info.numberOfLevels; ii++) {
                            this.levels.push(ii);
                        }
                    }
                    this.exerciseType = info.exerciseType;
                    if (info.interactionTypes && info.interactionTypes.length === 1) {
                        this.interactionType = info.interactionTypes[0];
                    }

                    if (this.multiplePreviews()) {
                        return this.generateExercise(5, true)
                            .subscribe(viewSpecList => {

                                //Check exercises to prevent breaking errors:
                                for (let item of viewSpecList) {
                                    this.checkSpec(item);
                                }

                                let viewspecPreviews: SpecPreview[] = [];

                                let todoCounter = 2 * viewSpecList.length;
                                let post = () => {
                                    this._ngZone.run(() => {
                                        this.specPreviews.next(viewspecPreviews);
                                        if (viewSpecList && viewSpecList.length === 1) {
                                            this.insertWidgets(viewSpecList[0]);
                                        } else {
                                            setTimeout(() => AlgebraKIT.render(this.previewTable.nativeElement), 0);
                                        }
                                        this.busy = false;
                                    });
                                }

                                for (let ii = 0; ii < viewSpecList.length; ii++) {
                                    let obj = viewSpecList[ii] as any;
                                    let solution;
                                    let expr;
                                    if (obj.view.elements.length > 1 || !obj.view.elements[0].interactions || obj.view.elements[0].interactions.length > 1 || isInlineInteraction(obj.view.elements[0].interactions[0])) {
                                        //Exercise is NOT simple
                                        solution = "";
                                        expr = '$$\\text{No preview available: play exercise to inspect.}$$'
                                    } else {
                                        let elm = obj.view.elements[0];
                                        let inter = elm.interactions[0];
                                        expr = elm.expr ? elm.expr : elm.content;
                                        solution = inter.solution;
                                    }
                                    viewspecPreviews[ii] = {
                                        viewSpec: obj
                                    }
                                    this.renderString(expr)
                                        .then(str => {
                                            viewspecPreviews[ii].instruction = str;
                                            todoCounter--;
                                            if (todoCounter == 0) post();
                                        });
                                    this.renderString(solution).then(str => {
                                        viewspecPreviews[ii].solution = str;
                                        todoCounter--;
                                        if (todoCounter == 0) post();
                                    });
                                }
                            });
                    } else {
                        this.exerciseRef.level = this.level;
                        this.insertWidgetsFromRef(this.exerciseRef);
                        this.busy = false;
                    }

                });

        }
    }

    setLevel(value: number) {
        this.level = value;
        this.generate();
    }

    getExerciseId(spec: IExerciseSpec) {
        return spec.scriptRef ? spec.scriptRef : spec.script;
    }

    editExercise(spec: IExerciseSpec) {
        let id = this.getExerciseId(spec);

        var modal = $('.exercise-demo').first();
        if (modal) modal.modal('hide');
        this.router.navigate(['edit/exercise', id])
            .catch((error) => {
                this.exerciseScriptService.showLockErrorMessage(error, true);
            });
    }

    getIconForEditViewButton() {
        return this.userService.canEditLibrary()
            ? 'fa fa-pencil'
            : 'fa fa-eye';
    }

    ngOnChanges() {
        this.generate();
    }

    ngOnDestroy() {
        for (let subscription of subscriptions) subscription.unsubscribe();
    }
}
