import { ToastrService } from 'ngx-toastr';
import { HttpClientService } from "../../app/services/http-client.service";
import { ConcurrencyService } from "../../app/services/concurrency.service";
import { HttpClient } from '@angular/common/http';
import { getExtendedToastOption } from '../../app/options/options';
import { AkitVersionsService } from '../../algebrakit/services/akit-versions.service';

export abstract class AuthoringService extends HttpClientService {

    constructor(
        protected http: HttpClient,
        protected toastr: ToastrService,
        protected concurrencyService: ConcurrencyService,
        protected akitVersionsService: AkitVersionsService
    ) { super(http, toastr, akitVersionsService) }

    public lockResource(id: string): Promise<any> {
        return this.concurrencyService.lockResource(id);
    }

    public refreshLock(id: string): Promise<any> {
        return this.concurrencyService.refreshLock(id);
    }

    public releaseLock(id: string): Promise<any> {
        return this.concurrencyService.releaseLock(id);
    }

    public checkAvailability(id: string): Promise<any> {
        return this.concurrencyService.checkAvailability(id);
    }

    public showLockErrorMessage(error: any, showIfOtherError: boolean = true) {
        if (error.error) {
            error = error.error;
        }
        let type = error.type || error.errorType;
        let msg = 'Error';
        let returnVal = true;
        if (type == "CONCURRENT_MODIFICATION_ERROR") {
            let fallBackUserInfo = 'another user';
            let userInfo = error.additionalInfo
                ? error.additionalInfo.usedByName || error.additionalInfo.usedById || fallBackUserInfo
                : fallBackUserInfo;
            msg = `The resource '${error.additionalInfo.resourceName}' is already in use by ${userInfo}`;
        }
        else if (showIfOtherError) {
            msg = error.message;
        }
        else {
            returnVal = false;
        }
        this.toastr.error(msg, 'Error', getExtendedToastOption());
        return returnVal
    }

    public scheduleRefresh(id: string) {
        let service = this;
        return setInterval(function () {
            service.refreshLock(id);
        }, 30000);
    }

    public descheduleRefresh(timer: any) {
        if (!timer) {
            return;
        }
        clearInterval(timer);
    }
}