
import {timer as observableTimer,  BehaviorSubject, Observable } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

import { CmsExercise } from '../../types/navigator.types';
import { UserService } from '../../../security/services/user.service';
import { PublishService } from '../../services/publish.service';
import { PublishedSnapshot, PublishProgress } from '../../types/published.types';
import { VerifyService } from '../../services/verify.service';
import { SortedCheckResults } from '../../types/verify.types';
import { AppProfileService } from '../../../app/services/app-profile.service';
import { ExerciseService } from '../../services/exercise.service';
import { TypeHelpers } from '../../util/type-helpers';

declare let $: any;

interface Action {
    name: string,
    callback: { (item: PublishedSnapshot): void }
    iconClass: string
}

@Component({
    templateUrl: './publish-subject.component.html',
    styleUrls: ['./publish-subject.component.css']
})
export class PublishSubjectComponent implements OnInit {
    subjectId$ = new BehaviorSubject<string>(null);
    exercisesToPublish: CmsExercise[];

    busyCheck = false;
    busyPublish = false;
    publisherInUse = false;

    sortedCheckResults: SortedCheckResults[];

    public progress: number;

    publishingWarning = false;

    @ViewChild('exercisesToPublishContainer', {static: false}) exercisesToPublishContainer: HTMLElement

    constructor(
        private router: Router,
        private location: Location,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private publishService: PublishService,
        private exerciseService: ExerciseService,
        private verifyService: VerifyService,
        private userService: UserService,
        private appProfileService: AppProfileService
    ) {
    }

    hasErrors() {
        return this.sortedCheckResults
            && this.sortedCheckResults[3]
            && this.sortedCheckResults[3].checkedResources
            && Object.keys(this.sortedCheckResults[3].checkedResources).length > 0;
    }

    hasWarnings() {
        return this.sortedCheckResults
            && (
                (this.sortedCheckResults[1]
                    && this.sortedCheckResults[1].checkedResources
                    && Object.keys(this.sortedCheckResults[1].checkedResources).length > 0)
                || (this.sortedCheckResults[2]
                    && this.sortedCheckResults[2].checkedResources
                    && Object.keys(this.sortedCheckResults[2].checkedResources).length > 0)
            );
    }

    publish() {
        this.progress = 0;
        this.busyPublish = true;
        this.publishService.publishSubject(this.subjectId$.value)
            .toPromise()
            .then(() => {
                this.toastr.success('Publishinging in progress...', 'Info');
                this.setProgressTimer();
            }).catch(error => {
                this.toastr.error(error.message, 'Error');
                this.busyPublish = false;
            })
    }

    setProgressTimer() {
        let timer = observableTimer(0, 500)
            .subscribe((t) => {
                this.publishService.publishSnapshotProgress()
                    .toPromise()
                    .then((progress: PublishProgress) => {
                        this.progress = progress.progress;
                        if (progress.finished) {
                            this.toastr.success('Publishinging succesful', 'Success');
                            this.location.back();
                            timer.unsubscribe();
                        }
                    }).catch((err) => {
                        let message = err && err.message ? err.message : err;
                        this.toastr.error(message, 'Error');
                        timer.unsubscribe();
                    });
            });
    }

    close() {
        this.location.back();
    }

    canPublish() {
        return this.exercisesToPublish && this.exercisesToPublish.length > 0 && !this.busyCheck && !this.hasErrors();
    }

    checkIfProgressShouldShow() {
        this.publishService.publishInProgress().then((res) => {
            if (res.inProgress && res.showProgress) {
                this.busyPublish = true;
                this.setProgressTimer();
            }
        })
    }

    ngOnInit() {
        this.route.params
            .subscribe((params) => {
                if (params.id) {
                    this.subjectId$.next(params.id);
                }
            });

        this.appProfileService.getSetting('publishingWarning').then((value) => {
            this.publishingWarning = value;
        });

        this.subjectId$.subscribe((id) => {
            this.exerciseService.getAllApprovedExercisesInSubtree(id).then((exercises) => {
                this.exercisesToPublish = exercises.map(ex => ({
                    ...ex,
                    iconClass: TypeHelpers.getIconClass(ex),
                }))
                    .sort((f1, f2) => {
                        if (f1.name.toLowerCase() < f2.name.toLowerCase()) return -1;
                        if (f1.name.toLowerCase() > f2.name.toLowerCase()) return 1;
                        return 0;
                    });
                if (exercises && exercises.length > 0) {
                    this.busyCheck = true;
                    this.verifyService.verifySubjectForPublish(id)
                        .toPromise().then((result) => {
                            this.sortedCheckResults = result;
                            this.busyCheck = false;
                        }).catch(err => {
                            console.error(err);
                            this.busyCheck = false;
                            this.toastr.error('Could not verify the course', 'Error');
                        });
                }
            })
        })
    }

}

