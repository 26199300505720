<div class='user-profile-component container akit-container'>
    <h2>Activate your AlgebrakiT Account</h2>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class='row row-spacing'>
            <div class='col-xs-3 col-sm-2'>
                <label>Email</label>
            </div>
            <div class="col-xs-7 col-sm-5">
                <input [disabled]="true" formControlName='email' type="email" class='form-control'>
            </div>
        </div>
        <br />
        <div class='row row-spacing'>
            <div class='col-xs-3 col-sm-2'>
                <label>First name</label>
            </div>
            <div class="col-xs-7 col-sm-5">
                <input formControlName='firstName' class='form-control'>
            </div>
        </div>
        <br />
        <div class='row row-spacing'>
            <div class='col-xs-3 col-sm-2'>
                <label>Surname</label>
            </div>
            <div class="col-xs-7 col-sm-5">
                <input formControlName='surname' class='form-control'>
            </div>
        </div>
        <br />
        <div class='row row-spacing'>
            <div class='col-xs-3 col-sm-2'>
                <label>
                    Password
                </label>
            </div>

            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">

            <div class="col-xs-7 col-sm-5">
                <div class="input-group" *ngIf="!(generatedPassword?.length > 0)">
                    <input formControlName='password' type="password"
                        placeholder="Type a password or click the button to generate one" class='form-control'>
                    <span class="input-group-btn">
                        <button class="btn btn-default" (click)="generatePassword()" type="button">

                            <i class="fa fa-key"></i>
                        </button>
                    </span>
                </div>
                <div class="input-group" *ngIf="generatedPassword?.length > 0">
                    <span class='form-control'>{{generatedPassword}}</span>
                    <span class="input-group-btn">
                        <button class="btn btn-default" (click)="copyPassword()" type="button">
                            <i class="fa fa-clipboard"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>
        <div>
            <password-errors [userPassword]="form.controls.password.value"></password-errors>
        </div>
        <br />
        <div class='row row-spacing' *ngIf="!(generatedPassword?.length > 0)">
            <div class='col-xs-3 col-sm-2'>
                <label>
                    Repeat password
                </label>
            </div>
            <div class="col-xs-7 col-sm-5">
                <input formControlName='repeatPassword' type="password"
                    placeholder="Repeat the password types in the field above" class='form-control'>
            </div>
        </div>
        <div *ngIf="form.errors?.passwordsDontMatch">
            <div style="color: red">
                The passwords you have entered do not match.
            </div>
        </div>
        <br />
        <div class='row row-spacing'>
            <div class="col-xs-12 col-sm-7">
                <div class="panel panel-default">
                    <div class="panel-body">
                        <p>To activate your account, you will need to agree to the terms and conditions
                            listed in our privacy policy. You can review our privacy policy by clicking <a
                                href="https://public.algebrakit.nl/privacyverklaring-algebrakit-v1.0"
                                target="_blank">this
                                link</a>
                        </p>
                        <p>
                            After reviewing the privacy policy, please check the box below and click "submit".
                        </p>
                        <label>
                            <input type="checkbox" formControlName='agreeToPolicy'>
                            I have read the privacy policy and agree to its terms and conditions.
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div class='row row-spacing'>
            <div class="col-xs-4 col-sm-2">
                <button class="btn btn-primary btn-block" type="submit"
                    [disabled]="!form.valid || !agreeToPolicyCtrl?.value">
                    <i class="fa fa-sign-in"></i>
                    <span>Save</span>
                </button>
            </div>
            <div class="col-xs-4 col-sm-2">
                <button class="btn btn-default btn-block" type="button" title="Cancel" (click)="cancel()">
                    <i class="fa fa-ban"></i>
                    <span>Cancel</span>
                </button>
            </div>
        </div>
    </form>
</div>