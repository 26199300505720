<div class="foreground">
    <h2>Reset Password</h2>
    <form (ngSubmit)="submit()" [formGroup]="form">
        <div class="row">
        </div>
        <div class="row">
            <div class="col-sm-3 col-xs-5">
                <label>New Password</label>
            </div>
            <div class='col-sm-7 col-xs-7'>
                <input type="password" class="form-control" placeholder="Type your new password" title="Password"
                    formControlName='newPassword' ng-minlength="8">
            </div>
        </div>
        <ng-container *ngIf="form.errors?.invalidPassword">
            <div class="col-sm-3 col-xs-5"></div>
            <div class="col-sm-7 col-xs-7">
                <div style="color: red">
                    Your password must be at least 8 characters long, must contain uppercase and lowercase
                    characters
                    and
                    digits and cannot be your username
                </div>
            </div>
        </ng-container>
        <br />
        <div class="row">
            <div class="col-sm-3 col-xs-5">
                <label>Repeat New Password</label>
            </div>
            <div class='col-sm-7 col-xs-7'>
                <input type="password" class="form-control" placeholder="Repeat your new password"
                    title="Repeat password" formControlName='repeatNewPassword' ng-minlength="8">
            </div>
        </div>
        <ng-container *ngIf="form.errors?.passwordsDontMatch">
            <div class="col-sm-3 col-xs-5"></div>
            <div class="col-sm-7 col-xs-7">
                <div style="color: red">
                    The passwords you have entered do not match.
                </div>
            </div>
        </ng-container>
        <br />

        <div class="row">
            <div class="col-sm-3 col-xs-6">
                <button class="btn btn-primary btn-block" type="submit" title="Submit" [disabled]="!form.valid">
                    <i class="fa fa-sign-in"></i>
                    <span>Submit</span>
                </button>
            </div>
        </div>
    </form>
</div>