
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/Rx';

import {
    Asset
} from '../types/navigator.types';
import { AuthoringService } from './authoring.service';
import { ToastrService } from 'ngx-toastr';
import { ConcurrencyService } from '../../app/services/concurrency.service';
import { HttpClient } from '@angular/common/http';
import { AkitVersionsService } from '../../algebrakit/services/akit-versions.service';

const URL_BASE_ASSETS = "api/assets";

@Injectable()
export class AssetService extends AuthoringService {

    constructor(
        protected http: HttpClient,
        protected toastr: ToastrService,
        protected akitVersionsService: AkitVersionsService,
        protected concurrencyService: ConcurrencyService
    ) { 
        super(http, toastr, concurrencyService, akitVersionsService);
    }

    getAssetMetadata(exerciseId: string): Observable<Asset[]> {
        return this.get<Asset[]>(`${URL_BASE_ASSETS}/exercise-metadata/${exerciseId}`).pipe(
            catchError(error => this._serverError(error)));
    }

    getThumbnails(exerciseId: string): Observable<{[id: string]: string}> {
        return this.get<{[id: string]: string}>(`${URL_BASE_ASSETS}/exercise-thumbnails/${exerciseId}`).pipe(
            catchError(error => this._serverError(error)));
    }

    getThumbnail(assetId: string): Observable<string> {
        return this.get<string>(`${URL_BASE_ASSETS}/thumbnail/${assetId}`).pipe(
            catchError(error => this._serverError(error)));
    }

    uploadExerciseAsset(exerciseId: string, assetName: string, data: any, majorVersion: number): Observable<Asset> {
        let body = new FormData();
        body.append('data', data);
        body.append('majorVersion', majorVersion.toString());

        return this.post<Asset>(`${URL_BASE_ASSETS}/exercise/${exerciseId}/${assetName}`, body).pipe(
            catchError(error => this._serverError(error)));
    }

    uploadExerciseDatasetAsset(exerciseId: string, assetName: string, data: any, includesHeader: boolean, delimiter: string, majorVersion: number): Observable<Asset> {
        let body = new FormData();
        body.append('data', data);
        body.append('includesHeader', includesHeader ? 'true' : 'false');
        // body.append('csvDelimiter', delimiter ? delimiter : null);
        body.append('majorVersion', majorVersion.toString());
        if (delimiter.length && delimiter.length > 0) body.append('csvDelimiter', delimiter);

        return this.post<Asset>(`${URL_BASE_ASSETS}/exercise/dataset/${exerciseId}/${assetName}`, body).pipe(
            catchError(error => this._serverError(error)));
    }

    deleteExerciseAsset(exerciseId: string, assetName: string) {
        return this.delete(`${URL_BASE_ASSETS}/exercise/${exerciseId}/${assetName}`)
    }
}