import { Injectable } from '@angular/core';
import 'rxjs/Rx';

import { Mode } from '../types/cms2-metadata.types';
import { UserService } from '../../security/services/user.service';
import { checkPermissions } from '../../security/services/permission-utils';


@Injectable()
export class ModesService {

    private modes: Mode[];

    constructor(
        protected userService: UserService
    ) { 
    }

    getModes(): Mode[] {
        if (this.modes) {
            return this.modes;
        }
        let user = this.userService.getUser();
        let canViewAllModes = checkPermissions(user, ['global.modes.can-use-internal']);
        var allModes: Mode[] = require('../../../assets/json/modes.json') as Mode[];
        this.modes = allModes
            .filter(m => canViewAllModes || m.internal != "internal")
            .filter(m => !m.deprecated || m.deprecated == "")
        return this.modes;
    }
}