import { CmsExerciseScript, CmsAttributeAssignmentType, CmsTopicFilterLevel, CmsTopicAttributeAssignment, RefConverter, CmsExerciseFilter, CmsTopicFilterLevelAttributeAssignement } from "../types/navigator.types";
import { TopicSpec, LevelSpec, TopicData } from "../types/cms-metadata.types";
import { compareVersion } from "./sorting";

export class FilterUtils {

    public static isExerciseSelected(ex: CmsExerciseScript, goalSpec: TopicSpec, level: number, currentVersion: string) {
        /*
        An exercise in a given topicfilter's topic is added to the list of a given level if:
            - All the attributeAssignments of the exercise are ALLOWED, FORCED or SUPER_FORCED in the topicFilterLevel
            AND
            - The exercise contains ALL attributes that are SUPER_FORCED in the topicFilterLevel
            AND
            - If FORCED attributes are specified => The exercise has at least one attribute which is FORCED in the topicFilterLevel
        */

        //If the exercise is incompatible it will NEVER be selected:
        if (compareVersion(currentVersion, ex.contentVersion) < 0) {
            return false;
        }

        let attrAssignments: string[] = ex.attributeAssignments ? ex.attributeAssignments.map(a => a.id) : [];
        let forcedAttrs: string[] = goalSpec.attributeSpecs.filter(attr => attr.attributeAssignmentType === CmsAttributeAssignmentType.FORCE).map(a => a.id);
        let allowedAttrs: string[] = goalSpec.attributeSpecs.filter(attr => attr.attributeAssignmentType === CmsAttributeAssignmentType.ALLOW).map(a => a.id);
        let superForcedAttrs: string[] = goalSpec.attributeSpecs.filter(attr => attr.attributeAssignmentType === CmsAttributeAssignmentType.SUPER_FORCE).map(a => a.id);

        if (attrAssignments.length === 0 && [...forcedAttrs, ...superForcedAttrs, ...allowedAttrs].length === 0) {
            return true; //No assignments in both topic filter level and exercise => exercise is selected.
        }

        if (!attrAssignments.every(a => allowedAttrs.indexOf(a) !== -1
            || forcedAttrs.indexOf(a) !== -1
            || superForcedAttrs.indexOf(a) !== -1)) { //Check allowed attributes
            return false
        }

        if (superForcedAttrs.some(a => attrAssignments.indexOf(a) === -1)) { //Check superForced attributes
            return false;
        }

        if (forcedAttrs.length > 0
            && !attrAssignments.some(a => forcedAttrs.indexOf(a) !== -1)) { //Check forced attributes
            return false;
        }

        return true;
    }

    public static setGoalFiltersInExercise(exercise: CmsExerciseFilter, attrPerTopicPerLevel: LevelSpec[]): CmsExerciseFilter {
        for (let level = 0; level < exercise.numberOfLevels; level++) {
            let levelSpec = attrPerTopicPerLevel[level];
            for (let goalIndex = 0; goalIndex < levelSpec.topicSpecs.length; goalIndex++) {
                let goalSpec = levelSpec.topicSpecs[goalIndex];
                exercise.topicFilters[goalIndex].levels[level].attributeAssignments = goalSpec.attributeSpecs;
            }
        }
        return exercise;
    }

    public static setAttrPerGoalPerLevel(exercise: CmsExerciseFilter, topicData: TopicData[]): LevelSpec[] {
        let attrPerTopicPerLevel: LevelSpec[] = [];
        for (var ll = 0; ll < exercise.numberOfLevels; ll++) {
            attrPerTopicPerLevel.push(this.getLevelSpec(ll, exercise, topicData));
        }
        return attrPerTopicPerLevel
    }

    public static getLevelSpec(level: number, exercise: CmsExerciseFilter, topicData: TopicData[]): LevelSpec {
        let levelSpec: LevelSpec = { topicSpecs: [], levelIndex: level };
        for (let ii = 0; ii < exercise.topicFilters.length; ii++) {
            let topicFilter = exercise.topicFilters[ii];
            let topic = topicData[ii].topic;
            if (topic.attributeAssignments == null) topic.attributeAssignments = [];
            let topicSpec: TopicSpec = {
                topic: topic,
                attributeSpecs: [] as CmsTopicFilterLevelAttributeAssignement[]
            };
            levelSpec.topicSpecs.push(topicSpec);

            let selectedAttrs: CmsTopicFilterLevel = topicFilter.levels[level];
            for (let attr of topic.attributeAssignments) {
                topicSpec.attributeSpecs.push({
                    id: attr.id,
                    attribute: attr.attribute,
                    topic: RefConverter.toTopicRef(topic),
                    difficulty: attr.difficulty,
                    attributeAssignmentType: this.getAttributeType(attr.id, selectedAttrs)
                });
            }
        }
        return levelSpec;
    }

    public static getAttributeType(attrId: string, selectedAttr: CmsTopicFilterLevel): CmsAttributeAssignmentType {
        for (var ii = 0; ii < selectedAttr.attributeAssignments.length; ii++) {
            let attr: CmsTopicFilterLevelAttributeAssignement = selectedAttr.attributeAssignments[ii];
            if (attr.id === attrId) return attr.attributeAssignmentType;
        }
        return CmsAttributeAssignmentType.NONE;
    }

    public static getScriptEntryClass(
        ex: CmsExerciseScript,
        level: number,
        topicIndex: number,
        topicSpec: TopicSpec,
        topicData: TopicData[],
        currentVersion: string) {
        //new-exercise-filter.selected -> NOT present in last accepted version AND present and selected in current version
        //new-exercise-filter -> NOT present in last accepted version AND (present and NOT selected in current version)
        //existing-exercise-filter.selected -> present in last accepted version AND present and selected in current version
        //existing-exercise-filter -> present in last accepted version AND (present and NOT selected in current version)
        //removed-exercise-filter -> (present AND selected in last accepted version) AND (present and NOT selected in current version)
        //deleted-exercise-filter -> (present AND selected in last accepted version) AND (NOT present and NOT selected in current version)

        let presentInCurrent = topicData[topicIndex].exercisesAKIT.find(e => e.id === ex.id);
        let selectedInCurrent = presentInCurrent && FilterUtils.isExerciseSelected(ex, topicSpec, level, currentVersion);

        if (presentInCurrent && selectedInCurrent) {
            return 'existing-exercise-filter selected';
        }
        if (presentInCurrent && !selectedInCurrent) {
            return 'existing-exercise-filter';
        }
    }
}