<div class="navigationbar" *ngIf="profile">
    <div (click)="versionWarningClick()" *ngIf="contentVersionMismatch" class="version-warning">Content version is
        incompatible with production</div>
    <nav [ngClass]="navbarClass()">
        <div class="container">
            <div class="navbar-header">
                <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                    data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <a class="navbar-brand" routerLink="home">
                    <div class="akit-logo-container">
                        <img src="/5fc8e1f5425ce33bc23c6cb27174e329.png" class="akit-banner-logo" />
                        <span class="navigationbar__cms_title">
                            <h3>CMS {{environmentName}}</h3>
                        </span>
                    </div>
                </a>
            </div>
            <!-- Collect the nav links, forms, and other content for toggling -->
            <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                <div class='navbar-center'>
                    <ul class="nav navbar-nav">
                        <li *ngIf="loggedIn()" class="dropdown">
                            <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                                aria-expanded="false">
                                Authoring <i class="fa fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li>
                                    <a routerLink='courses'>
                                        <i class="fa fa-book"></i>
                                        <span> Courses</span>
                                    </a>
                                </li>
                                <li>
                                    <a routerLink='topics'>
                                        <i class="fa fa-archive"></i>
                                        <span> Library</span>
                                    </a>
                                </li>
                                <li>
                                    <a routerLink='id-search'>
                                        <i class="fa fa-search"></i>
                                        <span> Search exercise ID</span>
                                    </a>
                                </li>
                                <li *ngIf="false">
                                    <a routerLink='labels'>
                                        <i class="fa fa-tags"></i>
                                        <span *ngIf="userService.canEditLibrary()"> Manage labels</span>
                                        <span *ngIf="!userService.canEditLibrary()"> View labels</span>
                                    </a>
                                </li>
                                <li *ngIf="userService.canEditLibrary() && false">
                                    <a routerLink='attributes'>
                                        <i class="fa fa-tags"></i>
                                        <span> Manage attributes</span>
                                    </a>
                                </li>
                                <li *ngIf="canPublishSnapshots() && false">
                                    <a routerLink='snapshots'>
                                        <i class="fa fa-leanpub"></i>
                                        <span> Publish</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li *ngIf="loggedIn()">
                            <a role="button" routerLink='test-algebra'>
                                Sandbox
                            </a>
                        </li>
                        <li class="dropdown">
                            <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                                aria-expanded="false">
                                Help <i class="fa fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li>
                                    <a routerLink='reference'>
                                        <i class="fa fa-code"></i>
                                        <span> Reference Guide</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://help.algebrakit.com/" target="_blank">
                                        <i class="fa fa-question-circle"></i>
                                        <span> Help</span>
                                    </a>
                                </li>
                                <li role="separator" class="divider"></li>
                                <li>
                                    <a class="menu-item-non-clickable">
                                        <span>Content version: {{contentVersion}}</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li *ngIf="canChangeVersion()" class="dropdown">
                            <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                                aria-expanded="false">
                                <b>Version: {{currentVersion}}</b> <span *ngIf="autoAkitVersion">(auto)</span> <i class="fa fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li [ngClass]="{'active': autoAkitVersion}">
                                    <a (click)="switchVersion('auto')">
                                        Automatic
                                    </a>
                                </li>
                                <li role="separator" class="divider"></li>
                                <li *ngFor="let version of versions" [ngClass]="{'active': currentVersion==version.name}">
                                    <a (click)="switchVersion(version.name)"
                                        [ngClass]="{'version': true, 'offline': version.offline}">
                                        {{version.name}} - {{version.contentVersion}}
                                        <ng-container *ngIf="version.offline">
                                            &nbsp;<span class="offline-indicator">offline</span>
                                        </ng-container>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="navbar-right">
                    <p *ngIf="!user" class='navbar-text'>
                        <a class='navbar-link' routerLink='login'>
                            <i class="fa"></i>
                            <span>Log in</span>
                        </a>
                    </p>
                    <div *ngIf="user">
                        <ul class='nav navbar-nav'>
                            <li class='dropdown'>
                                <a href='#' class="dropdown-toggle" data-toggle="dropdown" role="button"
                                    aria-haspopup="true" aria-expanded="false">
                                    <span class="visible-lg">
                                        Logged in as
                                        <span>{{user.username ? user.username.substring(0,10): ''}}</span>
                                        <i class="fa fa-chevron-down"></i>
                                    </span>
                                    <span class="hidden-lg">
                                        <i class="fa fa-user-circle fa-lg"></i>
                                    </span>
                                </a>
                                <ul class="dropdown-menu">
                                    <li class="hidden-lg">
                                        <a>
                                            Logged in as
                                            <span>{{user.username ? user.username.substring(0,10): ''}}</span>
                                        </a>
                                    </li>
                                    <li role="separator" class="divider hidden-lg"></li>
                                    <li>
                                        <a [href]='getUpdateProfileUrl()' ui-sref="settings">
                                            <i class="fa"></i>
                                            <span class="no-link">Update profile</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLink]='getChangePasswordUrl()' ui-sref="settings">
                                            <i class="fa"></i>
                                            <span>Change password</span>
                                        </a>
                                    </li>

                                    <li role="separator" class="divider"></li>

                                    <li>
                                        <a (click)="logout()">
                                            <i class="fa fa-sign-out"></i>
                                            <span>Log out</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </nav>
</div>