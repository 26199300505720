
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {
    IErrorState, IAKITSession, ISessionData, ISolutionData
} from '../types/algebrakit.type';
import {WKStrategy, IAlgebraKIT} from '../types/algebrakit.type';
import { ViewSpecWrapper, ExerciseInfo, IExerciseSpec, GenerateResponse } from '../../algebrakit/types/metadata.type';
import { ExerciseRef } from '../../authoring/types/cms-metadata.types';
import 'rxjs/Rx';
import { MetadataService } from './metadata.service';
import { CmsExerciseFilter, CmsUnitTestRef } from '../../authoring/types/navigator.types';
import { ToastrService } from 'ngx-toastr';
import { HttpClientService } from '../../app/services/http-client.service';
import { TypeHelpers } from '../../authoring/util/type-helpers';
import { HttpClient } from '@angular/common/http';
import { AkitVersionsService } from './akit-versions.service';

declare let AlgebraKIT: IAlgebraKIT;

function objToHTML(obj: any): string {
    var valueStr: any = '';
    switch (typeof (obj)) {
        case 'number':
        case 'boolean':
            valueStr = obj; break;
        case 'string':
            valueStr = obj.replace(/\n/g, '<br/>');
            valueStr = valueStr.replace(/Caused by:/g, '<br/><b>Caused by:</b>');
            break;
        case 'object':
            if (Array.isArray(obj)) {
                valueStr = '<ol>';
                for (var ii = 0; ii < obj.length; ii++) {
                    valueStr += objToHTML(obj[ii]);
                }
                valueStr += '</ol>';
            } else {
                valueStr = '<div class="object">';
                for (var key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        valueStr += '<div class="object-key">' + key + '</div>';
                        valueStr += '<div class="object-value">' + objToHTML(obj[key]) + '</div>';
                    }
                }
            }
    }
    return valueStr;
}

/**
 * Retrieves a session from an exercise definition. 
 * Caches the last n sessions such that derivations can reuse existing sessions.
 */
@Injectable()
export class SessionService extends HttpClientService {
    private _errorState = new BehaviorSubject<IErrorState>(null);
    public errorState$ = this._errorState.asObservable();

    constructor(
        protected http: HttpClient,
        private metadataService: MetadataService,
        protected toastr: ToastrService,
        protected akitVersionsService: AkitVersionsService
    ) { super(http, toastr, akitVersionsService) }

    storeTestSession(sessionId: string, title: string, exerciseId: string, isTestCase?: boolean): Promise<CmsUnitTestRef> {
        let payload: any = {
            title: title,
            sessionId: sessionId,
            author: null,
            exerciseId: exerciseId,
            isTestCase: !!isTestCase
        }
        return this.post("api/test-sessions/put/", payload).pipe(
            map((obj: any) => <CmsUnitTestRef>obj))
            .toPromise()
            .catch((obj: any) => {
                if (obj.message) {
                    obj = eval('(' + obj.message + ')')
                    let error: IErrorState = {
                        status: obj.status,
                        message: obj.message,
                        stack: objToHTML(obj.detailed)
                    }
                    this._errorState.next(error);
                }
                throw 'error';
            });
    }

    updateTestSession(testSessionId: string, sessionId: string, exerciseId: string): Promise<CmsUnitTestRef> {
        let payload: any = {
            sessionId: sessionId,
            exerciseId: exerciseId,
            existingTestID: testSessionId
        }
        return this.post("api/test-sessions/put/", payload).pipe(
            map((obj: any) => <CmsUnitTestRef>obj))
            .toPromise()
            .catch((obj: any) => {
                if (obj.message) {
                    obj = eval('(' + obj.message + ')')
                    let error: IErrorState = {
                        status: obj.status,
                        message: obj.message,
                        stack: objToHTML(obj.detailed)
                    }
                    this._errorState.next(error);
                }
                throw 'error';
            });
    }

    playTestSession(testRef: CmsUnitTestRef) {
        return this.post<CmsUnitTestRef>("api/test-sessions/play/", testRef)
            .toPromise()
    }

    testTestSession(testRef: CmsUnitTestRef) {
        return this.post<CmsUnitTestRef>("api/test-sessions/test/", testRef)
            .toPromise()
    }

    removeTestSession(testRef: CmsUnitTestRef): Promise<boolean> {
        return this.post("api/test-sessions/delete/", testRef).pipe(
            map(resp => true))
            .toPromise();
    }

    updateIsTestCase(testRef: CmsUnitTestRef, value: boolean): Promise<boolean> {
        return this.post("api/test-sessions/set-is-testcase/", {
            exerciseId: testRef.exerciseId,
            sessionId: testRef.sessionId,
            value: value
        }).pipe(
            map(resp => true))
            .toPromise();
    }

    getTestSessionRefs(exerciseId: string): Promise<CmsUnitTestRef[]> {
        return this.get("api/test-sessions/get-all/" + exerciseId).pipe(
            map((obj: any[]) => obj.map(elm => <CmsUnitTestRef>elm)))
            .toPromise();
    }

    getSession(id: string, appId: string): Promise<IAKITSession> {
        return this.get("api/exercise-scripts/session/retrieve/" + appId + "/" + id).pipe(
            map((obj: any) => <IAKITSession>obj))
            .toPromise()
            .catch((obj: any) => {
                if (obj.message) {
                    obj = eval('(' + obj.message + ')')
                    let error: IErrorState = {
                        status: obj.status,
                        message: obj.message,
                        stack: objToHTML(obj.detailed)
                    }
                    this._errorState.next(error);
                }
                throw 'error';
            });
    }

    compareSession(sessionId: string): Observable<ISessionData> {
        let url = "/api/test-sessions/compare";
        let req = {
            sessionId: sessionId
        }
        return this.post<ISessionData>(url, req).pipe(
            catchError(error => {
                return this._serverError(error, true)
            }));
    }

    createSessionFromRef(ref: ExerciseRef, attributes?: { [key: string]: any }, scoringModel?: string): Observable<ISessionData> {
        let options = {};
        let url = "api/exercises/session";
        TypeHelpers.cleanObject(ref);
        let req = {
            ...ref,
            options: options,
            attributes: attributes,
            scoringModel: scoringModel
        }
        if (AlgebraKIT.config.minified === false) {
            if (!attributes) {
                attributes = <any>{ minified: false };
            } else {
                attributes['minified'] = false;
            }
        }

        return this.post<ISessionData>(url, req).pipe(
            catchError(error => {
                return this._serverError(error, true)
            }));
    }

    getSolution(sessionId: string): Observable<ISolutionData> {
        let url = "api/exercise-scripts/session/solution/" + sessionId;
        return this.get<ISolutionData>(url).pipe(
            catchError(error => {
                return this._serverError(error, true)
            }));
    }

    getSolutionStrategies(cmsSpec: IExerciseSpec): Promise<WKStrategy[]> {
        return this.post<WKStrategy[]>('api/exercise-scripts/solution-strategies', {
            exercise: cmsSpec,
            options: {}
        }).toPromise().catch(error => {
            return this._serverError(error, true)
        });
    }

    createSession(spec: IExerciseSpec, attributes?: { [key: string]: any }, customErrorHandling?: boolean, scoringModel?: string, contentVersion?: string): Observable<ISessionData> {
        let newSpec: any;
        let options = {
            generateDebugInfo: true
        };
        let url = "api/exercise-scripts/session/create/cms_metadata";
        //        if (spec.type.indexOf('WORDPROBLEM') >= 0) {
        //            options = <any>{
        //                ...options,
        //                autoSimplify: true,
        //                productionMode: false,
        //                rendering_nr_of_decimals: 5
        //            }
        //        }
        let req = {
            exerciseSpec: {
                definition: spec,
                contentVersion: contentVersion
            },
            courseId: spec.course,
            options: options,
            replayTestSessionId: <string>null,
            attributes: <any>null,
            scoringModel: scoringModel
        }
        if (AlgebraKIT.config.minified === false) {
            if (!attributes) {
                attributes = <any>{ minified: false };
            } else {
                attributes['minified'] = false;
            }
        }
        if (attributes) {
            if (attributes['replayTestSessionId']) {
                req.replayTestSessionId = attributes['replayTestSessionId'];
                delete attributes['replayTestSessionId'];
            }
            req.attributes = attributes;
        }

        return this.post<ISessionData>(url, req).pipe(
            catchError(error => {
                if (!customErrorHandling) {
                    return this._serverError(error, true)
                }
                throw error;
            }));;

    }

    generateFromRef(ref: ExerciseRef, level: number, nr: number, withSolution: boolean = false): Observable<ViewSpecWrapper[]> {
        TypeHelpers.cleanObject(ref);
        let req = {
            ...ref,
            level: level,
            nr: nr,
            withSolution: withSolution,
            options: {}
        }
        return this.post<ViewSpecWrapper[]>("api/exercises/generate", req).pipe(
            //Algebrakit returns strings for evaluation mode and palette type. 
            //Convert to the enums we use in the frontend application
            catchError(error => {
                return this._serverError(error, true)
            }));

    }

    generateFromSpec(spec: IExerciseSpec, nr: number, withSolution: boolean = false, i18n?: string, customErrorHandling?: boolean, contentVersion?: string): Observable<GenerateResponse> {
        let regions = this.metadataService.getCountries(spec.audience);
        let req = {
            exerciseSpec: {
                definition: spec,
                contentVersion: contentVersion
            },
            courseId: spec.course,
            nr: nr,
            withSolution: withSolution,
            options: {
                generateDebugInfo: true
            },
            region: regions[0] ? regions[0] : 'en'
        }
        return this.post<GenerateResponse>("api/exercise-scripts/generate/cms_metadata", req).pipe(
            //Algebrakit returns strings for evaluation mode and palette type. 
            //Convert to the enums we use in the frontend application
            catchError(error => {
                if (!customErrorHandling) {
                    return this._serverError(error, true)
                }
                throw error;
            }));
    }

    generateFilterExercises(exercise: CmsExerciseFilter, level: number, nr: number, withSolution: boolean = false) {
        let url = "api/exercises/exercises-for-filter";
        let req = {
            exerciseFilter: exercise,
            level: level,
            nr: nr,
            withSolution: withSolution,
            options: {}
        }
        return this.post<ViewSpecWrapper[]>(url, req).pipe(
            catchError(error => {
                return this._serverError(error, true)
            }));
    }

    generateExerciseFilterInfo(req: CmsExerciseFilter) {
        //TopicFilters are not needed for preview, too much data
        let strippedReq = { ...req };
        delete strippedReq.topicFilters;
        return this.post<ExerciseInfo>('api/exercises/generate-filter-info', strippedReq).pipe(
            catchError(error => {
                return this._serverError(error, true)
            }));
    }

    getExerciseInfo(ref: ExerciseRef): Observable<ExerciseInfo> {
        let url: string;
        if (ref.publishId) {
            url = `api/exercises/info`;
        } else {
            url = `api/exercises/info`;
        }
        return this.post<ExerciseInfo>(url, ref).pipe(
            catchError(error => {
                return this._serverError(error, true)
            }));
    }
}
