<div class="exercise-filter-editor">
    <form [formGroup]="form">
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3 class="panel-title">Move exercises to another location</h3>
            </div>
            <div class="panel-body">
                <div class="row row-spacing">
                    <div class="col-sm-2">
                        <label>Selected exercises</label>
                    </div>
                    <div class="col-sm-10">
                        <ul *ngIf="selectedExercises">
                            <li *ngFor="let ex of selectedExercises">
                                {{ex.name}}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row row-spacing">
                    <div class="col-sm-2">
                        <label>Course</label>
                    </div>
                    <div class="col-sm-10">
                        <select class='form-control' [formControl]="courseCtrl">
                            <option value="0" selected>Select a course</option>
                            <ng-container *ngFor="let course of courses">
                                <option value="{{course.id}}">{{course.name}}
                                </option>
                            </ng-container>
                        </select>
                    </div>
                </div>
                <div class="row row-spacing">
                    <div class="col-sm-2">
                        <label>Subject</label>
                    </div>
                    <div class="col-sm-10">
                        <breadcrumb [folder]="subject" (parentChange)='setSubject($event)' [showChilds]="true">
                        </breadcrumb>
                    </div>
                </div>
                <div class="row row-spacing" *ngIf="audienceRequired()">
                    <div class="col-xs-12">
                        <p>The selected course does not contain all audiences from the selected exercises, please select
                            a new audience for the exercises</p>
                    </div>
                    <div class="col-sm-2">
                        <label>Audience</label>
                    </div>
                    <div class="col-sm-4">
                        <select [formControl]="audienceCtrl" class='form-control'>
                            <option *ngFor="let audience of audiences" value="{{audience.baseAudienceId}}">
                                {{audience.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="row row-spacing">
        <div class="col-xs-3">
            <button class="btn btn-md btn-primary btn-block" type="button" (click)="commit()"
                [disabled]="!formIsValid()">
                <i class="fa fa-save"></i>
                <span>Confirm</span>
            </button>
        </div>
        <div class="col-xs-3">
            <button class="btn btn-md btn-default btn-block" type="button" (click)="close()">
                <i class="fa fa-ban"></i>
                <span translate>Close</span>
            </button>
        </div>
    </div>

</div>