import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {CmsFolder, CmsAkitResourceRef, CmsDiffAnomaly, CmsDiffSeverityLevel, CmsDiffAnomalyType, ReferencedBy, DeleteCheckType} from '../../types/navigator.types';
import { Observable } from 'rxjs';

/**
 * A topic-leaf is a 'file' in the navigation system, which corresponds to an
 * exercise. The model is defined by class of type CmsLeaf. Classes for the various 
 * types of exercises are derived from CmsLeaf
 */

@Component({
    selector: 'attr-delete-check',
    templateUrl: './attr-delete-check.component.html',
    styleUrls: ['./attr-delete-check.component.css']
})
export class AttrDeleteCheckComponent {

    _filterReferences: ReferencedBy[] = [];
    _directReferences: ReferencedBy[] = [];

    @Input() id: string;
    @Input() name: string;

    @Input() set referencedBy(value: ReferencedBy[]) {
        this._filterReferences = value.filter(v => v.type == 'ExerciseFilter');
        this._directReferences = value.filter(v => v.type == 'ExerciseScript');
    }

    @Output() confirm: EventEmitter<void> = new EventEmitter<void>();

    reset() {
        this.referencedBy = [];
    }
}



