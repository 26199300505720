import { CmsExerciseType, ExerciseRef } from './cms-metadata.types';
import { User } from '../../security/types/user.type';
import { EditorAssetResolveResult as EditorAssetResolve } from '../../algebrakit/types/metadata.type';
import { IValidationReport } from './ValidationReport';
import { ICMS2ExerciseSpec } from './cms2-metadata.types';

export enum BrowseMode {
    EDIT = "EDIT",
    VIEW = "VIEW"//Used when browsing from exercise-filter-editor, should only show published scripts
}

export interface NameIdPair {
    name: string;
    id: string;
}

export interface NameIdVersion extends NameIdPair {
    majorVersion: number;
}

export enum CmsContainerType {
    FILE = "FILE",
    FOLDER = "FOLDER"
}

export interface CmsAkitResourceRef extends NameIdPair {
    containerType: CmsContainerType;
    trash?: boolean;
    root?: boolean
    state?: CmsItemState,
    tested?: boolean,
}

export interface CmsCourseRef extends NameIdPair {
    namespace: string;
    options?: CourseOptions;
}

export interface CmsSubjectRef extends CmsAkitResourceRef {
}

export interface CmsTopicRef extends CmsAkitResourceRef {
    algebrakitOnly?: boolean;
}

export interface CmsExerciseRef extends CmsAkitResourceRef {
    exerciseType: CmsExerciseType,
    title: string;
    audience?: string
    specType?: CmsExerciseScriptType,
    multiplePreviews?: boolean;
    interactionType?: string;
    contentVersion?: string;
    validationReport?: IValidationReport;
}

/*export interface ExerciseAttrRef extends ExerciseRef {
    attributes: string[],
    scriptName: string
}*/

export enum CmsExerciseScriptType {
    ALGEBRA = "ALGEBRA",
    COMPOUND = "COMPOUND",
    CHOICE = "CHOICE",
    TABLE = "TABLE",
    WORDPROBLEM = "WORDPROBLEM",
    ARITHMETIC = "ARITHMETIC",
    MULTIPART = "MULTIPART",
}

export interface CmsExerciseScriptRef extends CmsAkitResourceRef {
    scriptType: CmsExerciseScriptType;
    attributeAssignments?: CmsTopicAttributeAssignment[];
    contentVersion?: string;
}

export interface CmsUser {
    username: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface CheckedEvent<T extends CmsLeaf> {
    item: T;
    checked: boolean
}

export enum CmsAttributeAssignmentType {
    NONE = 'NONE',
    ALLOW = 'ALLOW',
    FORCE = 'FORCE',
    SUPER_FORCE = 'SUPER_FORCE'
}

export enum CmsResourceType {
    TRASH, FILE_FOLDER
}

export interface CmsTopicAttribute {
    id: string;
    name: string;
    description?: string;
}

export interface CmsTopicAttributeAssignment {
    id: string;
    topic: CmsTopicRef;
    attribute: CmsTopicAttribute;
    difficulty: Difficulty;
}

export interface Difficulty {
    id: string;
    name: string;
    complexity: number;
}

export interface CmsTopicFilterLevelAttributeAssignement extends CmsTopicAttributeAssignment {
    attributeAssignmentType: CmsAttributeAssignmentType;
}

export interface CmsItemAction {
    name: string;
    callback: { (item: CmsItem): void };
    iconClass: string;
    adminOnly?: boolean;
    preCond?: (...input: any) => boolean
}

export interface CmsTopicFilterLevel {
    levelNumber: number
    attributeAssignments: CmsTopicFilterLevelAttributeAssignement[];
}

export interface CmsTopicFilter {
    levels: CmsTopicFilterLevel[];
    topic?: CmsTopicRef;
}

export interface SessionInfo {
    title: string;
    sessionId: string;
    author: string;
    date: string;
    active?: boolean;
}

export interface CmsItem {
    id: string;
    name: string;
    metadata?: CmsMetadata[];
    description?: string;
    comment?: string;
    actions?: CmsItemAction[]
    onClick?: { (item: CmsItem): void }
    iconClass?: string;
    created?: number;  //Timestamp
    modified?: number; //Timestamp
    owner?: string;
    commitMessage?: string;
    state?: CmsItemState;
    updateType?: CmsUpdateType;
    tested?: boolean;
}

export enum CmsUpdateType {
    MAJOR = "MAJOR",
    MINOR = "MINOR"
}

export enum CmsItemState {
    NOT_APPLICABLE = "NOT_APPLICABLE",
    DRAFT = "DRAFT",
    APPROVED = "APPROVED",
    DEPRECATED = "DEPRECATED",
}

export interface CmsMetadata {
    id: string;
    name: string;
    value: string;
}

export interface CmsFolder<T extends CmsAkitResourceRef, V extends CmsAkitResourceRef> extends CmsItem {
    /** references to all parents up to the root */
    parents: T[];

    /** references to the direct child folders of this node */
    childFolders: T[]
    childLeafs: V[]
    rootFolder?: boolean;
    trash?: boolean;
}

export interface CmsLeaf extends CmsItem {
    testSessions?: SessionInfo[];
    latestVersionNumbers?: VersionNumbers;
    validationReport?: IValidationReport;
}

export interface CmsTopic extends CmsFolder<CmsTopicRef, CmsExerciseScriptRef> {
    attributeAssignments: CmsTopicAttributeAssignment[];
    course?: CmsCourseRef;
    algebrakitOnly?: boolean;
}

export interface CmsExercise extends CmsLeaf, CmsCourseDescendant {
    subject: CmsSubjectRef;
    contentVersion?: string;
    title?: string;
    audience?: string;
    type?: CmsExerciseType;
    multiplePreviews?: boolean;
    publishedMajorVersion?: number;
}

export interface VersionNumbers {
    id: string;
    majorVersion: number;
    minorVersion: number;
    publishedMajorVersion?: number;
    publishedMinorVersion?: number;
}

export interface CmsExerciseReference extends CmsExercise {
    exerciseScript: CmsExerciseScriptRef;
}

export interface CmsExerciseClientSpec extends CmsExercise {
    definition: ICMS2ExerciseSpec;
    interactionType?: string;
}

//selector for set of exercises, based on selected topic-attributes
export interface CmsExerciseFilter extends CmsExercise {
    topicFilters: CmsTopicFilter[]
    exercisesPerLevel: CmsExerciseFilterLevelSpec[];
    numberOfLevels: number;
}

export interface CmsExerciseFilterLevelSpec {
    level: number;
    scripts: CmsExerciseFilterScriptSpec[];
    courseExercises?: CmsExerciseFilterScriptSpec[]
}

export interface CmsExerciseFilterScriptSpec {
    id: string;
    topicId: string;
    majorVersion: number;
}

export interface CmsExerciseScript extends CmsLeaf {
    attributeAssignments: CmsTopicAttributeAssignment[];
    definition: ICMS2ExerciseSpec;
    contentVersion?: string,
    testSessions?: SessionInfo[];
    publishedMajorVersion?: number;
    topic: CmsTopicRef;
    interactionTypes?: string[];
}

export interface StudentLevel {
    audienceId: string;
    description: string;
    name: string;
}

export interface CmsCourse extends CmsItem, CmsCourseRef {
    audiences: CmsCourseAudience[];
    rootSubject?: CmsSubjectRef;
    trashSubject?: CmsSubjectRef;
    defaultEditor?: string;
    free?: boolean;
}

export interface CmsCourseDescendant {
    course: CmsCourseRef;
}

export interface CourseOptions {
    scoringModel?: string;
    theme?: string;
    interactionBlacklist?: string[];
    akitVersion?: string;
}

export interface CmsCourseAudience {
    courseId: string;
    baseAudienceId: string;
    name?: string;
    resolvedAudience?: string;
    editor?: string;
    sortOrder?: number;
}

export interface CmsSubject extends CmsFolder<CmsSubjectRef, CmsExerciseRef>, CmsCourseDescendant {
    versionId?: string;
    defaultEditor?: string;
    defaultQuestionMode?: string;
    defaultAudience?: string;
}

export interface CmsPublishedVersion {
    id: string;
    courseId: string;
    owner?: string;
    created?: string;
    name?: string;
}

export interface CmsVersioningInfo {
    majorVersion: number;
    minorVersion: number;
    created: Date;
    tested: boolean;
    editedBy: string;
    commitMessage: string;
}

export interface CmsVersionedObject extends CmsVersioningInfo {
    storedObject: any;
}

export interface CmsDiffResult {
    findings: CmsDiffAnomaly[]
}

export interface CmsDiffAnomaly {
    anomalizedObjectId: string;
    anomalizedObjectName: string;
    severityLevel: CmsDiffSeverityLevel;
    anomalyType: CmsDiffAnomalyType;
    subAnomalies: CmsDiffAnomaly[];
    anomalizedObjectType: String;
}

export enum CmsDiffSeverityLevel {
    INFO = 0,
    WARNING_LOW_PRIO = 1,
    WARNING_HIGH_PRIO = 2,
    ERROR = 3
}

export enum CmsDiffAnomalyType {
    EXERCISE_VERSION_MISMATCH = "EXERCISE_VERSION_MISMATCH",
    EXERCISE_AUDIENCE_EMPTY = "EXERCISE_AUDIENCE_EMPTY",
    EXERCISE_IS_DRAFT = "EXERCISE_IS_DRAFT",
    EXERCISE_FILTER_CHANGED = "EXERCISE_FILTER_CHANGED",
    EXERCISE_FILTER_LEVEL_ADDED = "EXERCISE_FILTER_LEVEL_ADDED",
    EXERCISE_FILTER_LEVEL_DELETED = "EXERCISE_FILTER_LEVEL_DELETED",
    EXERCISE_FILTER_SCRIPT_ADDED = "EXERCISE_FILTER_SCRIPT_ADDED",
    EXERCISE_FILTER_SCRIPT_REMOVED = "EXERCISE_FILTER_SCRIPT_REMOVED",
    EXERCISE_FILTER_INVALID = "EXERCISE_FILTER_INVALID",
    EXERCISE_FILTER_NO_LEVELS = "EXERCISE_FILTER_NO_LEVELS",
    EXERCISE_FILTER_EMPTY_LEVEL = "EXERCISE_FILTER_EMPTY_LEVEL",
    EXERCISE_REFERENCE_INVALID = "EXERCISE_REFERENCE_INVALID",
    EXERCISE_SPECIFICATION_INVALID = "EXERCISE_SPECIFICATION_INVALID",
    EXERCISE_SCRIPT_ATTRIBUTE_ADDED = "EXERCISE_SCRIPT_ATTRIBUTE_ADDED",
    EXERCISE_SCRIPT_ATTRIBUTE_REMOVED = "EXERCISE_SCRIPT_ATTRIBUTE_REMOVED",
    EXERCISE_SCRIPT_REMOVED = "EXERCISE_SCRIPT_REMOVED",
    EXERCISE_SCRIPT_ADDED = "EXERCISE_SCRIPT_ADDED",
    EXERCISE_SCRIPT_CHANGED = "EXERCISE_SCRIPT_CHANGED",
    EXERCISE_SCRIPT_INVALID = "EXERCISE_SCRIPT_INVALID",
    TOPIC_ATTRIBUTES_CHANGED = "TOPIC_ATTRIBUTES_CHANGED",
    TOPIC_ATTRIBUTE_ADDED = "TOPIC_ATTRIBUTE_ADDED",
    TOPIC_ATTRIBUTE_REMOVED = "TOPIC_ATTRIBUTE_REMOVED"
}

export interface ErrorWrapper extends Error {
    originalError: any;
}

export interface AkitError {
    type: string;
    message: string;
    detailed?: string;
}

export interface ReferencedBy {
    item: NameIdPair;
    type: string;
}

export enum SortBy {
    DIFFICULTY = "DIFFICULTY",
    NAME = "NAME",
    TYPE = "TYPE",
    STATE = "STATE",
    PUBLISHED = "PUBLISHED",
    TESTED = "TESTED",
}

export interface CmsAcceptedFilter {
    id: string;
    acceptedBy: User;
    acceptedOn: Date;
    type: CmsAcceptedType;
    scriptsPerLevel: CmsAcceptedLevel[];
}

export interface CmsFilterDiffCourse {
    id: string;
    name: string;
    diff: CmsFilterCompare[];
    indeterminate: CmsFilterCompare[];
}

export interface CmsFilterCompare {
    id: string;
    name: string;
    course: CmsCourseRef
    lastAcceptedFilter: CmsAcceptedFilter;
    currentFilter: CmsAcceptedFilter;
}

export enum CmsFilterDiffResult {
    UNCHANGED,
    CHANGED,
    INDETERMINATE
}

export interface CmsAcceptedLevel {
    levelNr: number;
    exerciseScripts: Map<string, CmsAcceptedLevelScript>;
}

export interface CmsAcceptedLevelScript {
    id: string;
    name: string;
    selected: boolean;
}

export enum CmsAcceptedType {
    SAVE = "SAVE",
    PUBLISH = "PUBLISH",
    ACCEPT = "ACCEPT"
}

export interface GenerateExerciseFilterPreview {
    exercise: CmsExerciseFilter;
    scriptIds: string[];
}

export enum DeleteCheckType {
    EXERCISE_SCRIPT = 'EXERCISE_SCRIPT',
    TOPIC = 'TOPIC',
}

export interface SearchResult {
    token: string;
}

export interface LibrarySearchResult extends SearchResult {
    topics: CmsTopic[];
    exerciseScripts: CmsExerciseScript[];
}

export interface IdSearchResultList extends SearchResult {
    results: IdSearchResult[];

}

export interface IdSearchResult {
    type: IdSearchResultType;
    item: CmsItem;
    path: NameIdPair[];
    published: boolean;
}

export enum IdSearchResultType {
    COURSE = "COURSE",
    LIBRARY = "LIBRARY"
}

export interface Asset {
    id: string;
    namespace: string;
    name: string;
    created: Date;
    mimeType: string;
    metadata: { [key: string]: any };
    data?: any;
}

export interface CmsUnitTestRef {
    exerciseId: string;
    sessionId: string;
    created: number; //Creation date
    timestamp: number; //Last modified date (used to be the only date value, hence the property name)
    description: string;
    userId: string;
    sessionTestResults: any
}

export class GeneralUpdateRequest {
    defaultEditor: string;
    defaultQuestionMode: string;
    defaultAudience: string;
    applyEditorToChilds: boolean = false;
    applyQuestionModeToChilds: boolean = false;
    applyAudienceToChilds: boolean = false;
    overwriteEditorInDraftChilds: boolean = false;
    overwriteQuestionModeInDraftChilds: boolean = false;
    overwriteAudienceInDraftChilds: boolean = false;
    overwriteEditorInApprovedChilds: boolean = false;
    overwriteQuestionModeInApprovedChilds: boolean = false;
    overwriteAudienceInApprovedChilds: boolean = false;

    editorAssetResolveList: EditorAssetResolve[] = null;
}

export class UpdateRequest<T> extends GeneralUpdateRequest {
    updateItem: T;

    constructor(updateItem: T, generalRequest?: GeneralUpdateRequest) {
        super();
        this.updateItem = updateItem;
        if (generalRequest) {
            this.defaultEditor = generalRequest.defaultEditor;
            this.defaultQuestionMode = generalRequest.defaultQuestionMode;
            this.defaultAudience = generalRequest.defaultAudience;
            this.applyEditorToChilds = !!generalRequest.applyEditorToChilds;
            this.applyQuestionModeToChilds = !!generalRequest.applyQuestionModeToChilds;
            this.applyAudienceToChilds = !!generalRequest.applyAudienceToChilds;
            this.overwriteEditorInDraftChilds = !!generalRequest.overwriteEditorInDraftChilds;
            this.overwriteQuestionModeInDraftChilds = !!generalRequest.overwriteQuestionModeInDraftChilds;
            this.overwriteAudienceInDraftChilds = !!generalRequest.overwriteAudienceInDraftChilds;
            this.overwriteEditorInApprovedChilds = !!generalRequest.overwriteEditorInApprovedChilds;
            this.overwriteQuestionModeInApprovedChilds = !!generalRequest.overwriteQuestionModeInApprovedChilds;
            this.overwriteAudienceInApprovedChilds = !!generalRequest.overwriteAudienceInApprovedChilds;
            this.editorAssetResolveList = generalRequest.editorAssetResolveList;
        }
    }
}

export class RefConverter {

    static createResourceRef = (id: string, name: string, containerType: CmsContainerType): CmsAkitResourceRef => {
        return { id: id, name: name, containerType: containerType };
    }

    static createExerciseRef = (id: string, name: string, title: string, exerciseType: CmsExerciseType): CmsExerciseRef => {
        return { id: id, name: name, title: title, containerType: CmsContainerType.FILE, exerciseType };
    }

    static toSubjectRef = (subject: CmsSubject): CmsSubjectRef => {
        return RefConverter.createResourceRef(
            subject.id,
            subject.name,
            CmsContainerType.FOLDER
        ) as CmsSubjectRef;
    }

    static toTopicRef = (topic: CmsTopic): CmsTopicRef => {
        return RefConverter.createResourceRef(
            topic.id,
            topic.name,
            CmsContainerType.FOLDER
        ) as CmsTopicRef;
    }

    static toExerciseFilterRef = (exercise: CmsExerciseFilter): CmsExerciseRef => {
        return RefConverter.createExerciseRef(
            exercise.id,
            exercise.name,
            exercise.title,
            CmsExerciseType.FILTER,
        );
    }

    static tocExerciseReferenceRef = (exercise: CmsExerciseReference): CmsExerciseRef => {
        return RefConverter.createExerciseRef(
            exercise.id,
            exercise.name,
            exercise.title,
            CmsExerciseType.REFERENCE,
        );
    }

    static tocExerciseScriptRef = (exerciseScript: CmsExerciseScript): CmsExerciseScriptRef => {
        return RefConverter.createResourceRef(
            exerciseScript.id,
            exerciseScript.name,
            CmsContainerType.FILE
        ) as CmsExerciseScriptRef;
    }
}

//Type checking
export function isCourseDescendant(item: unknown): item is CmsCourseDescendant {
    let descendant = item as CmsCourseDescendant;
    return descendant.course != null;
}

export function isExerciseReference(ex: CmsItem): ex is CmsExerciseReference {
    let exRef = ex as CmsExerciseReference;
    return exRef.exerciseScript != null;
}

export function isExerciseFilter(ex: CmsItem): ex is CmsExerciseFilter {
    let exFilter = ex as CmsExerciseFilter;
    return exFilter.exercisesPerLevel != null;
}

export function isExerciseClientSpec(ex: CmsItem): ex is CmsExerciseClientSpec {
    let exSpec = ex as CmsExerciseClientSpec;
    return exSpec.definition != null && exSpec.subject != null;
}

export function isExerciseScript(ex: CmsItem): ex is CmsExerciseScript {
    let exScript = ex as CmsExerciseScript;
    return exScript.definition != null && exScript.topic != null;
}

export enum ExportType {
    REFERENCE,
    CLIENT_SPEC
}

export interface ExerciseSaveResult {
    id: string;
    validationReport: IValidationReport;
}