import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';


import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { UserService } from '../../../security/services/user.service';

const DEFAULT_REDIRECT = '/home';

@Component({
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {

    form: FormGroup;
    emailCtrl = new FormControl('', Validators.required);

    constructor(
        private userService: UserService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.form = this.formBuilder.group({
            email: this.emailCtrl
        })
    }

    submit(): void {
        this.userService.requestPasswordReset(this.emailCtrl.value)
            .then(() => {
                this.toastr.success('Your password reset request has been submitted. You will receive an email with further instructions', 'Success');

                this.router.navigate(['/login']);

            })
    }
}

