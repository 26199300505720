import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClientService } from './http-client.service';
import { AkitVersionsService } from '../../algebrakit/services/akit-versions.service';

const URL_BASE_CONCURRENCY = "api/concurrency";

@Injectable({
	providedIn: 'root',
})
export class ConcurrencyService extends HttpClientService {

	private processingResources: string[] = [];

	constructor(
		protected http: HttpClient,
		protected toastr: ToastrService,
		protected akitVersionsService: AkitVersionsService
	) {
		super(http, toastr, akitVersionsService);
	}

	public lockResource(id: string): Promise<any> {
		if (!this.checkTransaction(id)) {
			return Promise.resolve();
		}
		return this.get(`${URL_BASE_CONCURRENCY}/lock/for/${id}`)
			.toPromise()
			.catch(e => this._serverError(e))
			.finally(() => {
				this.transactionFinished(id);
			});
	}

	public refreshLock(id: string): Promise<any> {
		if (!this.checkTransaction(id)) {
			return Promise.resolve();
		}
		return this.post(`${URL_BASE_CONCURRENCY}/lock/refresh/${id}`, {})
			.toPromise()
			.finally(() => {
				this.transactionFinished(id);
			});
	}

	public releaseLock(id: string): Promise<any> {
		if (!this.checkTransaction(id)) {
			return Promise.resolve();
		}
		return this.post(`${URL_BASE_CONCURRENCY}/lock/release/${id}`, {})
			.toPromise()
			.finally(() => {
				this.transactionFinished(id);
			});
	}

	public checkAvailability(id: string): Promise<any> {
		return this.get(`${URL_BASE_CONCURRENCY}/available/${id}`)
			.toPromise();
	}

	private checkTransaction(resourceId: string) {
		if (this.processingResources.indexOf(resourceId) !== -1) {
			return false;
		}
		this.processingResources.push(resourceId);
		return true;
	}

	private transactionFinished(id: string) {
		let index = this.processingResources.indexOf(id);
		if (index !== -1) {
			this.processingResources.splice(index, 1);
		}
	}

}