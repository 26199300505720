import { Component, ViewChild, ElementRef, Input, AfterViewInit, EventEmitter, Output } from "@angular/core";
import { SymbolSpec } from "../../types/cms2-metadata.types";



@Component({
    selector: 'symbol-editor-wrapper',
    templateUrl: './symbol-editor-wrapper.html'
})

export class SymbolEditorWrapper implements AfterViewInit {
    @ViewChild('symbolEditor', {static: false}) symbolEditor: ElementRef;

    @Input() set symbols(value: SymbolSpec[]) {
        this._symbols = value;
        if (this.symbolEditor) {
            this.symbolEditor.nativeElement.setSymbols([...this._symbols]);
        }
    }

    @Output() symbolsChanged: EventEmitter<SymbolSpec[]> = new EventEmitter<SymbolSpec[]>();

    _symbols: SymbolSpec[];

    public async getSymbols(): Promise<SymbolSpec[]> {
        return this.symbolEditor.nativeElement.getSymbols();
    }

    ngAfterViewInit(): void {
        this.symbolEditor.nativeElement.setSymbols([...this._symbols]);
        this.symbolEditor.nativeElement.removeWidgetListener('symbolsChanged', this.onSymbolsChanged);
        this.symbolEditor.nativeElement.addWidgetListener('symbolsChanged', this.onSymbolsChanged);

    }

    
    onSymbolsChanged = async () => {
        let symbols = await this.getSymbols();
        this.symbolsChanged.emit(symbols);
    }

}