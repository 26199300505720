import { Component, Input } from '@angular/core';
import { CmsVersioningInfo, CmsItemState } from '../../types/navigator.types';

@Component({
  selector: 'version-info-panel',
  templateUrl: './version-info-panel.component.html',
  styleUrls: ['./version-info-panel.component.css']
})
export class VersionInfoPanelComponent {

  @Input() state: CmsItemState;
  @Input() versionInfo: CmsVersioningInfo;
  @Input() collapseable = true;

}