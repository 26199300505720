
import { switchMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

import { ToastrService } from 'ngx-toastr';

import { I18nLabel, REGIONS } from '../../types/cms-metadata.types';
import { I18nLabelService } from '../../services/i18n-label.service';

@Component({
    templateUrl: './version-mismatch.component.html',
    styleUrls: ['./version-mismatch.component.css']
})
export class VersionMismatchComponent implements OnInit {

    resourceId: string

    get latestLink() {
        return this.resourceId
            ? `https://cms.algebrakit.nl/cms/edit/${this.resourceId}`
            : `https://cms.algebrakit.nl/cms`;
    }

    constructor(
        private labelService: I18nLabelService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private location: Location
    ) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params: Params) => {
            this.resourceId = params['resourceId'];
        });
    }
}

