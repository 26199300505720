import { Injectable } from '@angular/core';
import {
	CanActivate, Router,
	ActivatedRouteSnapshot,
	RouterStateSnapshot} from '@angular/router';
import { UserService } from '../../security/services/user.service';
import { checkPermissions } from '../../security/services/permission-utils';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthUserGuard implements CanActivate {
	constructor(private userService: UserService, private router: Router, private toastr: ToastrService) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return this.userService.checkSession()
			.then(async user => {
				if (user == null) {
					this.toastr.error("You are not logged in", "Unauthorized");
					return false;
				}
				let allowed = checkPermissions(user, route.data.roles as Array<string>);
				if (!allowed) {
					this.toastr.error("You not authorized to perform this action", "Unauthorized");
				}
				return allowed;
			})
			.catch((error) => {
				if (error.needsConsent) {
					this.router.navigate(['/agreement'], { queryParams: { redirect: state.url } });
					throw error;
				}
				else {
					this.router.navigate(['/login'], { queryParams: { redirect: state.url } });
				}
				return null;
			});
	}
}