<div *ngIf="_directReferences && _directReferences.length > 0" class="alert alert-danger">
    <strong>Cannot delete '{{name}}':</strong> the attribute is used in the following exercise templates
    <br/>
    <br/>
    <ul>
        <li *ngFor="let ref of _directReferences">
            <a [routerLink]="'/edit/exercise/'+ref.item.id" target="_blank">{{ref.item.name}}</a>
        </li>
    </ul>
    <br/>
    Please uncheck the attribute in these templates first.
</div>
<div *ngIf="_filterReferences && _filterReferences.length > 0" class="alert alert-warning">
    <strong>Warning:</strong> the attribute '{{name}}' is referenced by the following exercises arrangements
    <br/>
    <br/>
    <ul>
        <li *ngFor="let ref of _filterReferences">
            <a [routerLink]="'/edit/exercise-filter/'+ref.item.id" target="_blank">{{ref.item.name}}</a>
        </li>
    </ul>
    <br/>
</div>