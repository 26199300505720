<div class='topic-leaf row cms-navigator-item display-flex-center'>
    <div class='col-xs-4'>
        <div class="row display-flex-center">
            <div class="col-xs-3">
                <div class="dropdown margin-right">
                    <button class="dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="true">
                        <span [ngClass]="getIconClass()"></span>&nbsp;<i class="fa fa-chevron-down"></i>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenu2"
                        *ngIf="itemActions && itemActions.length > 0">
                        <li *ngFor="let action of itemActions" (click)="action.callback(item.item)"
                            class='dropdown-entry'>
                            <a>
                                <span [ngClass]="action.iconClass"></span>
                                <span class='dropdown-entry-label'>{{action.name}}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-xs-9">

                <span class="disabled" *ngIf="!enabled">{{item.item.name}} <i *ngIf="errorBadgeTitle"
                        [tooltip]="errorBadgeTitle" class="error-badge fa fa-exclamation-circle"></i></span>
                <a *ngIf="enabled" [routerLink]="getRouterLink()" [queryParams]="{ onCloseUrl: '_BACK' }"
                    (click)="onClick()" [innerHTML]="item.item.name"></a>
            </div>


        </div>

    </div>
    <div class="col-xs-2">
        {{getLocation()}}
    </div>
    <div class="col-xs-4">
        <a *ngIf="getParentLink() else pathContent" [routerLink]="getParentLink()">
            <ng-container *ngTemplateOutlet="pathContent"></ng-container>
        </a>
        <ng-template #pathContent>
            <span class="fa fa-home"></span>{{getPath()}}
        </ng-template>
    </div>
    <div class="col-xs-2">
        <span class="cms-state-label cms-state-label-lg">
            <ng-container [ngSwitch]="item.item.state">
                <span class="label label-default" *ngSwitchCase="'DRAFT'" title="Draft">
                    <i class="fa fa-flask"></i>
                </span>
                <span class="label label-info" *ngSwitchCase="'APPROVED'" title="Approved">
                    <i class="fa fa-handshake-o"></i>
                </span>
            </ng-container>
        </span>
    </div>
</div>