import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { CmsExerciseScript, CmsTopic } from '../types/navigator.types';
import { ExerciseScriptService } from './exercise-script.service';
import { ToastrService } from 'ngx-toastr';
import { TopicService } from './topic.service';
import { SetTitleService } from '../../app/services/set-title.service';
import { Title } from '@angular/platform-browser';

@Injectable()
export class TopicResolverService extends SetTitleService implements Resolve<CmsTopic> {
    constructor(private topicService: TopicService, private toastr: ToastrService, titleService: Title) { super(titleService) }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<CmsTopic> {
        let id = route.params['id'];
        return this.topicService.getTopic(id).toPromise().then((topic) => {
            let name = topic.rootFolder ? "Library" : topic.name;
            this.setTitle(name);
            return topic;
        });
    }
}

