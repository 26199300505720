import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl, ValidationErrors } from '@angular/forms';
import { TopicService } from '../../services/topic.service';

import { CmsTopicAttribute, CmsMetadata, CmsCourseAudience } from '../../types/navigator.types';
import { AudienceSpec } from '../../types/cms-metadata.types';
import { MetadataService } from '../../../algebrakit/services/metadata.service';
import { IAlgebraKIT } from '../../../algebrakit/types/algebrakit.type';
import { IdNamePair } from '../../types/cms2-metadata.types';

declare let AlgebraKIT: IAlgebraKIT;

@Component({
    selector: 'course-audience-editor',
    templateUrl: './course-audience-editor.component.html',
    styleUrls: ['./course-audience-editor.component.css']
})
export class CourseAudienceEditorComponent implements OnInit {

    @Input() audience: CmsCourseAudience;
    @Input() duplicateProps: string[];
    @Input() customPalettes: string[];
    @Output() onChanged: EventEmitter<CmsCourseAudience> = new EventEmitter<CmsCourseAudience>();

    form: FormGroup;

    nameCtrl = new FormControl('');
    baseAudienceIdCtrl = new FormControl('');
    resolvedAudienceCtrl = new FormControl('');
    editorCtrl = new FormControl('');

    baseAudiences: AudienceSpec[];
    courseSpecificAudiences: AudienceSpec[];

    editors: IdNamePair[];

    constructor(
        private formBuilder: FormBuilder,
        private metadataService: MetadataService
    ) {
        this.form = this.formBuilder.group({
            name: this.nameCtrl,
            baseAudienceId: this.baseAudienceIdCtrl,
            resolvedAudience: this.resolvedAudienceCtrl,
            editor: this.editorCtrl
        });

        let self = this;

        this.nameCtrl.setValidators([Validators.required, (control: FormControl) => {
            if (!self || !self.duplicateProps) {
                return null;
            }
            return self.duplicateProps.indexOf('name') === -1 ? null : { duplicate: true };
        }])

        this.baseAudienceIdCtrl.setValidators([Validators.required, (control: FormControl) => {
            if (!self || !self.duplicateProps) {
                return null;
            }
            return self.duplicateProps.indexOf('baseAudienceId') === -1 ? null : { duplicate: true };
        }])
    }

    getCountryName(audience: AudienceSpec) {
        return this.metadataService.getCountryName(audience.languages[0]);
    }

    changed() {
        this.onChanged.emit(this.audience);
    }

    ngOnChanges() {
        if (this.audience) {
            this.nameCtrl.setValue(this.audience.name);
            this.baseAudienceIdCtrl.setValue(this.audience.baseAudienceId);
            this.resolvedAudienceCtrl.setValue(this.audience.resolvedAudience);
            this.editorCtrl.setValue(this.audience.editor);
        }
        this.setPalettes();
    }

    ngOnInit() {
        this.metadataService.getAudiences().then(audiences => {
            this.courseSpecificAudiences = audiences.filter(a => a.audienceType == 'course_specific');
            this.baseAudiences = audiences.filter(a => a.audienceType == 'base');
        })
        this.setPalettes();
    }

    setPalettes() {
        this.editors = AlgebraKIT._api.getDefaultEditors()
            .filter((editor: any) => editor.id != 'automatic');
        if (this.customPalettes) {
            let customEditors: IdNamePair[] = this.customPalettes
                .filter(id => !this.editors.find(editor => editor.id === id))
                .map(id => {
                    return {
                        id: id,
                        name: id,
                    }
                });
            this.editors = this.editors.concat(customEditors);
        }
    }
}




